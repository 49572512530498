import { Card } from '@components';
import styled from 'styled-components';

export const GarantiesWrapper = styled(Card.Wrapper)`
  padding: 0;
  border: 1rem solid
    ${({ theme }) => theme.variables.colors.chartColors.green_050};
`;

export const BulleWrapper = styled.div`
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  @media (min-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    padding: 0;
    margin: 2rem 0;
  }
`;

export const ConditionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spaces.regular};
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  @media (min-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.variables.layout.spaces.larger};
  }
`;

export const ReserveWrapper = styled.div`
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  @media (min-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    padding: 0;
  }
`;
