/** eslint-disable import/prefer-default-export */
import { listCodeCountrySEPA } from './regExps';

const isSEPA = (value: string, type: string) => {
  if (type === 'IBAN') {
    return listCodeCountrySEPA.includes(value.substring(0, 2));
  }
  return listCodeCountrySEPA.includes(value.substring(4, 6));
};

const testSepaFrOrMc = (iban?: string, bic?: string): boolean => {
  return (
    iban?.substring(0, 2) === 'FR' ||
    iban?.substring(0, 2) === 'MC' ||
    bic?.substring(4, 6) === 'FR' ||
    bic?.substring(4, 6) === 'MC'
  );
};

export { isSEPA, testSepaFrOrMc };
