import styled from 'styled-components';

export const BlockWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.variables.layout.spaces.medium};
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    margin: 0;
  }
`;

export const BlockNumber = styled.p`
  width: 5%;
  color: #007a75;
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: 10%;
  }
`;

export const BlockLabel = styled.p.attrs((props: { step: number }) => props)`
  width: ${(props) => (props.step === 11 ? '95%' : '60%')};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: ${(props) => (props.step === 11 ? '90%' : '50%')};
  }
`;

export const BlockValue = styled.p`
  width: 35%;
  text-align: right;
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: 40%;
  }
`;

export const BlockEmprunteur = styled.div`
  width: 100%;
  min-height: 20px;
  border-left: solid 2px #007a75;
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  div {
    margin-left: calc(5% - ${({ theme }) =>
      theme.variables.layout.spacing.spacing_xs});

      div {
        display: flex;
        margin: 0;
        justify-content: space-between;

        p:last-child{
          font-weight: bold;
        }
      }
  }

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    div {
      margin-left: calc(10% - ${({ theme }) =>
        theme.variables.layout.spacing.spacing_xs});
    }
`;

export const BlockEmprunteurHead = styled.p`
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  background-color: #e8f5f3;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_l};

  span {
    font-weight: bold;
  }
`;

export const BlockSubText = styled.p`
  margin-left: 5%;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    margin-left: 10%;
`;
