import { useState, useEffect, useCallback, createRef } from 'react';
import { ChevronDownIcon } from '../Icons';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { IOption } from '@types';
import Input from '../Input';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { detectTabletDevice, detectMobileDevice } from '@utils';
import ErrorMessage from '../ErrorMessage';
import {
  SelectLabel,
  StyledInputContainer,
  List,
  StyledIcon,
  Item,
  StyledCheckIcon,
  Dropdown,
  SearchInput,
  ItemText,
  HighlightText,
  StyledSearchIcon,
  Wrapper,
} from './styles';

export interface ISelectProps {
  label?: string;
  name: string;
  placeholder?: string;
  options: IOption[];
  value: string;
  isPlaceholder?: boolean;
  isDisabled?: boolean;
  isLabelBold?: boolean;
  hasSearch?: boolean;
  onChange: (value: string) => void;
  isValid?: boolean;
  isSubmitted?: boolean;
  isTouched?: boolean;
  isLoading?: boolean;
  onBlur?: () => void;
  inputRef?: React.Ref<HTMLInputElement>;
  filtersFromStart?: boolean;
  error?: string;
  className?: string;
}

const Select = ({
  label,
  name,
  placeholder,
  options,
  value,
  isDisabled,
  isLabelBold,
  hasSearch = false,
  onChange = () => null,
  onBlur = () => null,
  isValid,
  isSubmitted = false,
  isTouched = false,
  isLoading = false,
  inputRef,
  filtersFromStart = false,
  error,
  className = '',
}: ISelectProps): React.ReactElement => {
  const WrapperRef = createRef<HTMLDivElement>();
  const SearchInputRef = createRef<HTMLInputElement>();
  const PerfectScrollbarRef = createRef<PerfectScrollbar>();
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownBottom, setIsDropdownBottom] = useState(false);
  const [searchValue, setSearchValue] = useState<string | number>('');

  const closeMenu = useCallback(() => {
    setIsOpen(false);
    setSearchValue('');
  }, [setIsOpen]);

  const toggleMenu = () => {
    if (!isDisabled) {
      if (isOpen === false) {
        // Set focus on the search input
        if (
          SearchInputRef?.current &&
          !detectMobileDevice() &&
          !detectTabletDevice()
        ) {
          // @ts-ignore
          SearchInputRef?.current?.focus();
        }

        // force scroll to top
        if (PerfectScrollbarRef?.current) {
          // @ts-ignore
          PerfectScrollbarRef.current._ps.element.scrollTop = 0;
          /* eslint-enable no-underscore-dangle */
        }
      }
      setSearchValue('');
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    const isAncestor = (
      mayBeTheParent: HTMLElement,
      childElement: HTMLElement
    ): boolean => {
      const { parentElement } = childElement;
      if (parentElement === mayBeTheParent) {
        return true;
      }
      if (parentElement?.parentElement) {
        return isAncestor(mayBeTheParent, parentElement);
      }
      return false;
    };

    const onWindowClick = (event: MouseEvent) => {
      if (
        WrapperRef?.current &&
        event?.target &&
        !isAncestor(WrapperRef.current, event.target as HTMLElement)
      ) {
        closeMenu();
      }
    };

    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, [closeMenu, WrapperRef]);

  const onScroll = (e: any) => {
    setIsDropdownBottom(
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    );
  };

  const handleChange = (event: React.MouseEvent<HTMLLIElement>) => {
    const newValue = event.currentTarget.getAttribute('value');
    if (newValue && value !== newValue) {
      onChange(newValue);
    }
    setIsOpen(false);
  };

  interface IOptionsReduced extends IOption {
    search?: RegExpMatchArray;
    afterSearch?: string;
    handleChange: (event: React.MouseEvent<HTMLLIElement>) => void;
  }

  const filterOptionsReduce = (
    accumulator: IOptionsReduced[],
    option: IOption
  ): IOptionsReduced[] => {
    const { id, label: optionLabel } = option;
    if (searchValue.toString().length > 0) {
      const valueRegExp = new RegExp(
        `${filtersFromStart ? '^' : ''}${searchValue}`,
        'i'
      );
      const regExpResult = optionLabel.match(valueRegExp);
      if (regExpResult) {
        const splitRegExp = new RegExp(`${regExpResult[0]}(.*)`);
        const parts = optionLabel.split(splitRegExp);

        if (parts.length > 1) {
          return [
            ...accumulator,
            {
              id,
              label: parts[0],
              search: regExpResult,
              afterSearch: parts[1],
              handleChange,
            },
          ];
        }
      } else {
        return accumulator;
      }
    }
    return [
      ...accumulator,
      {
        id,
        label: optionLabel,
        handleChange,
      },
    ];
  };

  const onSearchFocus = () => {
    setIsOpen(true);
  };

  const onSearchChange = (newValue: string | number) => {
    if (typeof newValue == 'number') {
      console.error('Number non pris en charge');
    }
    if (newValue !== ' ') {
      setSearchValue(newValue);
    }
  };

  const onClickLabel = (e: { preventDefault: () => void }) => {
    // prevent giving focus on input if it is disabled
    if (isDisabled) {
      e.preventDefault();
    }
  };

  const selectedOption = options.find((option) => option.id === value);

  const searchName = `${name}-search`;

  return (
    <Wrapper ref={WrapperRef} className={'ph-select ' + className}>
      {label && (
        <SelectLabel
          isLabelBold={isLabelBold}
          htmlFor={hasSearch ? searchName : name}
          onClick={onClickLabel}
        >
          {label}
        </SelectLabel>
      )}
      <StyledInputContainer>
        <Input
          name={name}
          inputRef={inputRef}
          isValid={isValid}
          isSubmitted={isSubmitted}
          isTouched={isTouched}
          type="text"
          placeholder={placeholder}
          value={selectedOption?.label || ''}
          onClick={toggleMenu}
          onBlur={() => {
            if (!hasSearch) {
              onBlur();
            }
          }}
          isReadOnly
          isDisabled={isDisabled}
          isLoading={isLoading}
          after={
            !isLoading && (
              <StyledIcon isOpen={isOpen}>
                <ChevronDownIcon />
              </StyledIcon>
            )
          }
          role="textbox"
        />
        <Dropdown
          isOpen={isOpen}
          isBottom={isDropdownBottom}
          hasSearch={hasSearch}
        >
          {hasSearch && (
            <SearchInput
              tabIndex={isDisabled ? -1 : 0} // prevent focus with 'tab' key when disabled
              value={searchValue || ''}
              name={searchName}
              type="text"
              onChange={onSearchChange}
              placeholder="Rechercher"
              inputRef={SearchInputRef}
              onBlur={onBlur}
              before={<StyledSearchIcon />}
              onFocus={onSearchFocus}
              role="searchbox"
            />
          )}
          <PerfectScrollbar
            role="list"
            onScroll={onScroll}
            ref={PerfectScrollbarRef}
            options={{ minScrollbarLength: 20, wheelSpeed: 0.5 }}
          >
            <List>
              {options
                .reduce(filterOptionsReduce, [])
                .map((option: IOptionsReduced, index) => (
                  <Item
                    // In case we have multiple values with the same id (cas justificatif domicile)
                    key={option.id + index}
                    value={option.id}
                    onClick={option.handleChange ?? (() => {})}
                    role="option"
                  >
                    <ItemText>
                      {option?.label ?? ''}
                      {option?.search && (
                        <HighlightText>{option.search}</HighlightText>
                      )}
                      {option?.afterSearch ?? ''}
                    </ItemText>
                    {option.id === value && <StyledCheckIcon />}
                  </Item>
                ))}
            </List>
          </PerfectScrollbar>
        </Dropdown>
      </StyledInputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};

export default Select;
