const SvgMessage2: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.074 14.758a.484.484 0 01.378.028c.703.362 1.48.549 2.27.547.812 0 1.606-.198 2.322-.572a.488.488 0 01.662.216.504.504 0 01-.212.673 5.977 5.977 0 01-5.298.121l-3.548 1.203c-.384.13-.75-.242-.622-.633l1.183-3.607a6.17 6.17 0 01-.552-2.568c0-2.335 1.299-4.47 3.351-5.512A5.905 5.905 0 018.722 4h.355c2.559.144 4.737 1.908 5.464 4.376a.501.501 0 01-.33.622.49.49 0 01-.613-.335C12.99 6.597 11.166 5.119 9.05 5H8.72a4.938 4.938 0 00-2.27.547c-1.721.875-2.81 2.663-2.81 4.62a5.156 5.156 0 00.537 2.307c.06.12.07.257.028.384l-.935 2.85 2.804-.95zm10.588 3.723a4.818 4.818 0 01-4.314-2.712 4.911 4.911 0 01-.512-2.197v-.285c.136-2.492 2.093-4.482 4.572-4.62h.253a4.7 4.7 0 012.16.519 4.916 4.916 0 012.668 4.386 4.914 4.914 0 01-.421 2.005l.906 2.764c.128.39-.238.763-.622.632l-2.719-.92a4.697 4.697 0 01-1.97.428zm3.41-2.78a.508.508 0 01.027-.383c.269-.54.408-1.138.407-1.744 0-1.48-.824-2.832-2.127-3.495a3.733 3.733 0 00-1.716-.413h-.228c-1.95.109-3.509 1.693-3.615 3.648v.26a3.901 3.901 0 00.407 1.746 3.837 3.837 0 003.437 2.16 3.731 3.731 0 001.715-.412.484.484 0 01.377-.028l1.974.669-.658-2.007z"
    />
  </svg>
);

export default SvgMessage2;
