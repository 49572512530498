import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { WrapperSubTitle, WrapperBody } from './styles';
import { Subtitle, Input, FormEditableField } from '@components';
import {
  IBankInformationsRequest,
  ProprietaireCompte,
  CodesErreurRib,
  RoleCd,
  IDocument,
  EvidenceType,
  AnalyseDocument,
} from '@types';
import {
  isSEPA,
  bicSEPARegExp,
  ibanSEPARegExp,
  testSepaFrOrMc,
  removeSpace,
  wholeWordCapitalize,
} from '@utils';
import {
  selectConfiguration,
  selectPerson,
  updateBankDetails,
  updateDocumentsEmprunteur,
  useAppDispatch,
  useAppSelector,
  useSendBankInformationsMutation,
} from '@store';

import * as messages from './messages';
import Common from './Common';

export interface IProps {
  onValidate?: () => void;
  ocrData?: AnalyseDocument;
  borrowerType?: string;
  isMaxReuploadAttempt?: boolean;
  onReupload: () => void;
  ribOwner?: string;
}

export const FormOcrRib: React.FC<IProps> = ({
  onValidate = () => undefined,
  ocrData,
  borrowerType,
  isMaxReuploadAttempt = false,
  onReupload,
  ribOwner,
}) => {
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [showNoIbanSEPAMessage, setShowNoIbanSEPAMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const storeDispatch = useAppDispatch();

  const requalificationRIBA = (
    iban: string | undefined,
    bic: string | undefined
  ): void => {
    if ((bic || iban) && !testSepaFrOrMc(iban, bic)) {
      const documentUpdate = { evidenceSubTypeCd: 'RIBS' };
    }
  };

  useEffect(() => {
    // force validation on first render
    methods.trigger();
    const iban = ocrData?.banque?.iban;
    const bic = ocrData?.banque?.bic;
    requalificationRIBA(iban, bic);
  }, [ocrData]);

  const controlVibi = (data: any) => {
    const errorMessages =
      (data ?? []).map((err: { error: any }) => err.error) ?? [];
    if (errorMessages.includes(CodesErreurRib.IBAN_KO)) {
      methods.setError('iban', { message: "L'IBAN est invalide" });
    }
    if (errorMessages.includes(CodesErreurRib.BIC_KO)) {
      methods.setError('bic', { message: 'Le BIC est invalide' });
    }
    if (errorMessages.includes(CodesErreurRib.SEPA_KO)) {
      methods.setError('bic', { message: 'Le RIB est hors zone SEPA' });
      methods.setError('iban', { message: 'Le RIB est hors zone SEPA' });
    }
  };

  const handleFailure = (error: { data: any }) => {
    console.log(error);
    controlVibi(error.data);
  };

  const handleFormSubmit = (data: any) => {
    setIsSubmitting(true);
    const modifyOcrBankInformationsRequestBody: IBankInformationsRequest = {
      numero_souscription: souscriptionId ?? '',
      isOcr: true,
      bankInformations: {
        bic: data?.bic ?? '',
        iban: data?.iban ?? '',
        proprietaire_compte: getRibOwner(ribOwner),
      },
    };

    // Save document to store
    const document: IDocument = {
      evidenceSubTypeCd: ocrData?.soustype_piece,
      evidenceTypeCd:
        EvidenceType[ocrData?.type_piece as keyof typeof EvidenceType],
      evidenceNb: ocrData?.numero_preuve,
      ocrId: ocrData?.ocr_id,
    };
    // save bankDetails to store
    storeDispatch(
      updateBankDetails({
        iban: modifyOcrBankInformationsRequestBody?.bankInformations?.iban,
        bic: modifyOcrBankInformationsRequestBody?.bankInformations?.bic,
        accountOwner:
          modifyOcrBankInformationsRequestBody?.bankInformations
            ?.proprietaire_compte,
      })
    );
    storeDispatch(updateDocumentsEmprunteur(document));

    sendBankInformations(modifyOcrBankInformationsRequestBody)
      .unwrap()
      .then(() => {
        setIsSubmitting(false);
        return onValidate();
      })
      .catch((error) => {
        setIsSubmitting(false);
        return handleFailure(error);
      });
  };

  const configuration = useAppSelector(selectConfiguration);
  const { souscriptionId } = { ...configuration };
  const [sendBankInformations, responseBankInformations] =
    useSendBankInformationsMutation();

  const getIncompleteReadBo = () =>
    !ocrData?.banque?.bic || !ocrData?.banque?.iban;

  const getRibOwner = (ribOwner: string | undefined): ProprietaireCompte => {
    console.log(ribOwner);
    switch (ribOwner) {
      case RoleCd.BORROWER:
        return ProprietaireCompte.EMPRUNTEUR_PRINCIPAL;
      case RoleCd.COBORROWER:
        return ProprietaireCompte.CO_EMPRUNTEUR;
      case RoleCd.COMPTE_JOINT:
        return ProprietaireCompte.COMPTE_JOINT;
      default:
        return ProprietaireCompte.EMPRUNTEUR_PRINCIPAL;
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <Common
            isIncompleteScan={getIncompleteReadBo()}
            isFormValid={methods.formState.isValid}
            isMaxReuploadAttempt={isMaxReuploadAttempt}
            onReupload={onReupload}
            isSubmitting={isSubmitting}
          >
            <WrapperSubTitle>
              <Subtitle>{messages.SUB_TITLE_RIB}</Subtitle>
            </WrapperSubTitle>
            <WrapperBody>
              <FormEditableField
                label={messages.BIC}
                name="bic"
                validate={(value) => {
                  return bicSEPARegExp.test(value);
                }}
                defaultValue={
                  ocrData?.banque?.bic ? ocrData.banque.bic : undefined
                }
                error={methods?.formState.errors?.bic?.message?.toString()}
                render={(
                  { onChange, value, ref, onBlur, name },
                  { invalid, isTouched }
                ) => (
                  <Input
                    onChange={(newValue: any) =>
                      onChange(wholeWordCapitalize(newValue))
                    }
                    value={wholeWordCapitalize(value)}
                    name={name}
                    isTouched={isTouched}
                    isValid={!invalid}
                    onBlur={onBlur}
                    inputRef={ref}
                    error={methods?.formState.errors?.bic?.message?.toString()}
                  />
                )}
              />
              <FormEditableField
                label={messages.IBAN}
                name="iban"
                iban
                validate={(value) => {
                  const codeIban = value;
                  if (codeIban.length >= 2) {
                    const ibanIsSEPA = isSEPA(codeIban, 'IBAN');
                    if (!ibanIsSEPA) {
                      setShowNoIbanSEPAMessage(true);
                      return ibanIsSEPA;
                    }
                    setShowNoIbanSEPAMessage(false);
                  }
                  return ibanSEPARegExp.test(value);
                }}
                defaultValue={ocrData?.banque?.iban}
                error={methods?.formState.errors?.iban?.message?.toString()}
                render={(
                  { onChange, value, ref, onBlur, name },
                  { invalid, isTouched }
                ) => (
                  <Input
                    maskChar=""
                    onChange={(newValue: any) => {
                      onChange(removeSpace(wholeWordCapitalize(newValue)));
                    }}
                    value={wholeWordCapitalize(value)}
                    name={name}
                    isTouched={isTouched}
                    isValid={!invalid}
                    onBlur={onBlur}
                    inputRef={ref}
                    error={
                      showNoIbanSEPAMessage
                        ? messages.COUNTRY_CODE_NO_SEPA
                        : methods?.formState.errors?.iban?.message?.toString()
                    }
                  />
                )}
              />
            </WrapperBody>
          </Common>
        </form>
      </FormProvider>
    </>
  );
};

export default FormOcrRib;
