export interface ITarification {
  id: string;
  libelle: string;
  duree_min: number;
  duree_max: number;
  montant_min: number;
  montant_max: number;
  modalites: IModalite[];
  reports: number[];
  date_debut: string;
  date_fin: string;
}

export interface IModalite {
  code: string;
  libelle: string;
}

export enum CodesErreurTarification {
  CHRONIQUE_NON_TROUVEE = 'CHRONIQUE_NON_TROUVEE',
}
