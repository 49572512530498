import { useAppSelector, selectError } from '@store';
import { isAuthenticationRequired } from '@utils';
import { ErrorComponent } from '@components';
import { useEffect } from 'react';
import {
  ErrorBoundary as ErrorLibBoundary,
  useErrorBoundary,
} from 'react-error-boundary';

interface IErrorBoundaryProps {
  children?: React.ReactNode;
}

const CatchErrorBoundary = (): React.ReactElement => {
  const error = useAppSelector(selectError);

  const { showBoundary } = useErrorBoundary();
  useEffect(() => {
    if (error?.code && !isAuthenticationRequired()) {
      showBoundary(JSON.stringify(error));
      // Affichage de l'erreur + envoi des logs vers le MS sous
      console.error('Error dans boundary => ', error);
    }
  }, [error]);

  return <></>;
};

const ErrorBoundary = ({
  children,
}: IErrorBoundaryProps): React.ReactElement => {
  return (
    <ErrorLibBoundary
      FallbackComponent={({ resetErrorBoundary }) =>
        ErrorComponent({ children, resetErrorBoundary })
      }
    >
      {children}
      <CatchErrorBoundary />
    </ErrorLibBoundary>
  );
};

export default ErrorBoundary;
