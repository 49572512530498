const SvgRefresh: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.003 19.114a.5.5 0 11-1 0V13.78a.5.5 0 01.5-.5h5.182a.5.5 0 110 1H3.739l3.13 3.028c1.765 1.818 4.309 2.575 6.74 2.009 2.434-.567 4.418-2.382 5.253-4.81a.5.5 0 11.946.324c-.946 2.752-3.2 4.814-5.972 5.46a8.119 8.119 0 01-7.674-2.276l-3.16-3.056v4.155zm18-14.226a.5.5 0 111 0v5.335a.5.5 0 01-.5.5H16.32a.5.5 0 110-1h3.946l-3.13-3.028c-1.765-1.818-4.31-2.576-6.741-2.01-2.434.568-4.418 2.383-5.253 4.812a.5.5 0 01-.945-.326c.945-2.751 3.2-4.813 5.971-5.46a8.119 8.119 0 017.674 2.276l3.16 3.057V4.888z"
    />
  </svg>
);

export default SvgRefresh;
