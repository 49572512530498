import Conditions from './Conditions/Conditions';
import DetailGarantiesCard from './DetailGarantiesCard';
import InfoBulle from './InfoBulle/InfoBulle';
import Reserve from './Reserve/Reserve';
import {
  BulleWrapper,
  ConditionsWrapper,
  GarantiesWrapper,
  ReserveWrapper,
} from './styles';

const DetailGaranties: React.FC = () => {
  return (
    <>
      <GarantiesWrapper>
        <DetailGarantiesCard />
      </GarantiesWrapper>
      <BulleWrapper>
        <InfoBulle />
      </BulleWrapper>
      <ConditionsWrapper>
        <Conditions />
      </ConditionsWrapper>
      <ReserveWrapper>
        <Reserve />
      </ReserveWrapper>
    </>
  );
};

export default DetailGaranties;
