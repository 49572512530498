const SvgLectureKo: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 47 47" className={className}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M0 39.29a1.29 1.29 0 012.58 0v4.13h4.13a1.29 1.29 0 010 2.58H1.29A1.29 1.29 0 010 44.71v-5.42zM46 6.71a1.29 1.29 0 01-2.58 0V2.58h-4.13a1.29 1.29 0 110-2.58h5.42C45.422 0 46 .578 46 1.29v5.42zM2.58 6.71a1.29 1.29 0 01-2.58 0V1.29C0 .578.578 0 1.29 0h5.42a1.29 1.29 0 110 2.58H2.58v4.13z" />
      </g>
      <g fillRule="nonzero">
        <path
          fill="#767676"
          d="M10.569 4.759h24.833a1 1 0 011 1v33.844a1 1 0 01-1 1H10.57a1 1 0 01-1-1V5.759a1 1 0 011-1z"
        />
        <path
          fill="#FFF"
          d="M11.152 5.337H34.82a1 1 0 011 1v32.688a1 1 0 01-1 1H11.152a1 1 0 01-1-1V6.337a1 1 0 011-1z"
        />
        <path
          fill="#CCC"
          d="M12.486 15.743h17.5V16.9h-17.5zM12.486 8.806h18.666v1.156H12.486zM12.486 20.43h18.666v1.157H12.486zM12.486 11.118h15.166v1.157H12.486zM12.49 28.306h14v1.156h-14zM12.49 30.618h11.667v1.157H12.49zM12.49 23.306h18.67v1.156H12.49zM12.49 36.556h5.833v1.156H12.49z"
        />
      </g>
      <rect width={44} height={3} x={1} y={15} fill="currentColor" rx={1.5} />
      <g transform="translate(26.48 26.639)">
        <circle cx={10.5} cy={10.5} r={5.5} fill="#FFF" />
        <path
          fill="currentColor"
          d="M10.02.278a9.583 9.583 0 110 19.166 9.583 9.583 0 010-19.166zm3.148 6.436a.669.669 0 00-.946 0l-2.201 2.2-2.202-2.2a.669.669 0 00-.862-.07l-.083.07a.669.669 0 000 .945l2.2 2.202-2.2 2.202a.669.669 0 00-.07.862l.07.083a.669.669 0 00.945 0l2.202-2.202 2.201 2.202a.669.669 0 00.863.07l.083-.07a.669.669 0 000-.945L10.966 9.86l2.202-2.202a.669.669 0 00.07-.862z"
        />
      </g>
    </g>
  </svg>
);

export default SvgLectureKo;
