const SvgStar: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 32 32" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.644 9.9L.948 11.39l-.098.02a1.116 1.116 0 00-.531 1.863l7.047 7.217L5.7 30.702l-.012.094c-.071.871.866 1.497 1.654 1.062L16 27.07l8.657 4.788.085.043c.797.36 1.702-.311 1.557-1.2l-1.666-10.21 7.048-7.218.067-.074a1.116 1.116 0 00-.696-1.809L21.356 9.9 17.01.641c-.401-.856-1.619-.856-2.02 0l-4.346 9.257zm5.355-6.158l3.59 7.645.051.098c.17.284.458.48.79.53l8.137 1.251-5.924 6.069-.078.088c-.193.244-.276.56-.225.871l1.386 8.498-7.186-3.974-.103-.05a1.116 1.116 0 00-.977.05l-7.186 3.974 1.386-8.498.013-.117a1.116 1.116 0 00-.316-.842l-5.925-6.069 8.139-1.25c.367-.057.683-.292.84-.629L16 3.742z"
    />
  </svg>
);

export default SvgStar;
