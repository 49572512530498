import { useState, useEffect, useRef, RefObject } from 'react';

const useComponentVisible = (
  initialIsVisible: boolean
): { ref: RefObject<HTMLDivElement>; isComponentVisible: boolean } => {
  const [isComponentVisible, setComponentVisible] = useState(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);

  // Hiding the component when clicking outside of it
  const handleClickOutside = (event: Event) => {
    if (!ref.current?.contains(event.target as Node)) {
      setComponentVisible(false);
    }
  };

  // Hiding the component when clicking Escape key
  const handleEscapeClick = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClickOutside(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleEscapeClick, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleEscapeClick, true);
    };
  });

  return { ref, isComponentVisible };
};

export default useComponentVisible;
