import React from 'react';
import { StyledH1, StyledH2, StyledH3 } from './styles';

export interface ITitleProps {
  children?: React.ReactNode;
  level?: 1 | 2 | 3;
  className?: string;
}

const Title = ({
  children,
  level = 1,
  className,
}: ITitleProps): React.ReactElement => {
  const StyledH = { 1: StyledH1, 2: StyledH2, 3: StyledH3 };
  const HLevelComponent = StyledH[level];

  return (
    <HLevelComponent className={'ph-title ' + className}>
      {children}
    </HLevelComponent>
  );
};

export default Title;
