import styled from 'styled-components';
import { Button, ButtonCheck, Card } from '@components';

export const StyledCard = styled(Card.Wrapper)`
  padding-bottom: 2.9rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  }
`;

export const WrapperInputCreateSimulation = styled.div`
  display: flex;
  width: 100%;
`;

export const WrapperRateCreateSimulation = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  }
`;

export const WrapperSelectCreateSimulation = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const WrapperInputMonthlyPayment = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    flex-direction: column;
    border-left: 3px solid ${({ theme }) => theme.vendor.colors.primary};
    padding-left: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
  }
`;

export const WrapperMonthlyText = styled.div`
  p {
    color: ${({ theme }) => theme.vendor.colors.primary};
    margin: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    font-weight: bold;
  }
  hr {
    display: none;
  }
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_m} 0;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: ${({ theme }) => theme.variables.layout.spacing.spacing_s} 0;

    hr {
      display: flex;
      width: 100%;
      margin: auto 0;
    }
  }
`;
