export const SUBTITLE = 'Détails des garanties';
export const INTRODUCTION_TEXT =
  'Selon le choix du client, la formule choisie peut le protéger en cas de :';
export const DECES_TITLE = 'Décès (DIMC/DIM/D)';
export const DECES_TEXT =
  'L’assureur règle le solde du crédit de votre client à la date du décès.(1)';
export const INVALIDITE_TITLE = 'Invalidité (DIMC/DIM)';
export const INVALIDITE_TEXT =
  'L’assureur règle le solde du crédit de votre client à la date de constatation médicale de la Perte Totale et Irréversible d’Autonomie.(1)';
export const MALADIE_TITLE = 'Maladie ou Accident (DIMC/DIM)';
export const MALADIE_TEXT =
  'En cas d’Incapacité Temporaire Totale de Travail, l’assureur prend en charge les mensualités de votre client pendant 18 mois maximum, à l’expiration d’un délai de franchise de 90 jours.(1)';
export const CHOMAGE_TITLE = 'Chômage (DIMC)';
export const CHOMAGE_TEXT =
  'En cas de Perte d’Emploi suite à un licenciement, l’assureur prend en charge les mensualités de votre client pendant 18 mois maximum, à l’expiration d’un délai de franchise de 90 jours.(1) Les 180 jours suivants l’adhésion ne sont pas couverts.';
