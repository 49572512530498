import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './offerTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setOffer(offerState, action: PayloadAction<IState>) {
      offerState.state = { ...action.payload };
    },
    updateOffer(offerState, action: PayloadAction<Partial<IState>>) {
      offerState.state = { ...offerState?.state, ...action.payload };
    },
    clear(offerState) {
      offerState.state = getInitialState().state;
    },
  },
});

export const selectOffer = (offerState: { offer: { state: IState } }) =>
  offerState.offer.state;

export const { setOffer, updateOffer, clear } = offerSlice.actions;

export default offerSlice.reducer;
