/*
 * Messages Validation Modal
 *
 *
 */

export const LASTNAME_FIELD_LABEL = 'Nom';
export const FIRSTNAME_FIELD_LABEL = 'Prénom';
export const MOBILE_FIELD_LABEL = 'Numéro de mobile';
export const EMAIL_FIELD_LABEL = 'Email';
export const ERROR_EMAIL = 'Saisie non conforme';
export const ERROR_MOBILE_PHONE_NUMBER =
  'Numéro de mobile non conforme, doit commencer par 06 ou 07';
export const ERROR_CONTROLE_EMAIL = "L'e-mail semble non valide";
