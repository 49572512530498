const SvgShare2: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.53 11.524c.23 0 .423.168.462.39L20 12v7.619c0 1.262-.97 2.295-2.198 2.376l-.155.005H6.353c-1.248 0-2.268-.982-2.348-2.224L4 19.619V12c0-.263.21-.476.47-.476.232 0 .424.168.464.39L4.94 12v7.619c0 .743.56 1.353 1.276 1.422l.136.007h11.294c.734 0 1.337-.567 1.405-1.291l.007-.138V12c0-.263.21-.476.47-.476zM12.005 2h.011c.014 0 .028.002.042.004L12.006 2c.158 0 .298.077.384.196l3.707 3.753a.48.48 0 010 .673.467.467 0 01-.6.056l-.065-.056-2.951-2.985v11.22a.476.476 0 01-.944.086l-.008-.086V3.625l-2.96 2.997a.467.467 0 01-.601.056l-.065-.056a.48.48 0 01-.055-.607l.055-.066 3.764-3.81.02-.017a.479.479 0 01.022-.018l-.042.035a.468.468 0 01.163-.107c.007-.001.013-.004.02-.006a.328.328 0 01.06-.017l.02-.003A.38.38 0 0111.994 2h.012z"
    />
  </svg>
);

export default SvgShare2;
