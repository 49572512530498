import { IProgressBarProps } from '@types';
import { StyledProgress, StyledProgressBar } from './styles';

const ProgressBar = ({
  progress,
  hasFailed = false,
  className = '',
}: IProgressBarProps): React.ReactElement => (
  <StyledProgress className={className}>
    <StyledProgressBar progress={progress} hasFailed={hasFailed} />
  </StyledProgress>
);

export default ProgressBar;
