import styled from 'styled-components';

export interface LogoProps {
  name?: string;
  className?: string;
}

const StyledImage = styled.img`
  height: 100%;
`;

const Logo = ({ name = '', className = '' }: LogoProps): React.ReactElement => {
  const getResourceExtension = (name = '') => {
    return name === 'cetelem' ? 'svg' : 'png';
  };

  return (
    <StyledImage
      src={
        name
          ? `${
              process.env.REACT_APP_LOGOS_PATHNAME
            }${name}.${getResourceExtension(name)}`
          : ''
      }
      alt="Logo"
      className={'ph-logo ' + className}
    />
  );
};

export default Logo;
