import MentionLegalWrapper from './styles';

export interface IMentionLegalProps {
  htmlContent?: string;
  className?: string;
}

const MentionLegal = ({
  htmlContent = '',
  className = '',
}: IMentionLegalProps): React.ReactElement => (
  <MentionLegalWrapper
    dangerouslySetInnerHTML={{ __html: htmlContent }}
    className={'ph-mention-legal ' + className}
  />
);

export default MentionLegal;
