import StyleComponents from './style';

const { BadgeWrapper, StyledCheckCircle, StyledCrossCircleOutlines } =
  StyleComponents;
interface IProps {
  isActive: boolean;
  isSelected: boolean;
  label: string;
}

const InsuranceBadge: React.FC<IProps> = ({ isActive, isSelected, label }) => (
  <BadgeWrapper isSelected={isSelected} isActive={isActive}>
    {isActive ? <StyledCheckCircle /> : <StyledCrossCircleOutlines />}
    <span>{label}</span>
  </BadgeWrapper>
);

export default InsuranceBadge;
