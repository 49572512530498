import {
  controlEmailTypes,
  selectControlEmail,
  updateControlEmail,
  useAppDispatch,
  useAppSelector,
  useLazyGetControleEmailQuery,
} from '@store';
import { RefObject, useEffect, useState } from 'react';
import usePersistingState from './usePersistingState';
import { isEmailValide } from '@utils';
import { RoleCd } from '@types';
import { ModalEmailInvalide } from '@components';

export interface ModalEmailInvalideProps {
  setIsToBeCorrected?: React.Dispatch<React.SetStateAction<boolean>>;
  emailInputRef?: () => RefObject<HTMLInputElement> | null;
  updateIsEmailVerified?: (isEmailVerified: boolean) => void;
  isModalControleEmailVisible?: boolean;
  setIsModalControleEmailVisible?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export interface CheckEmailValideAndControleEmailProps {
  mailToTest: string | number | null | undefined;
}
export interface IEmailValidationState {
  isLoading: boolean;
  isForeground: boolean;
  isValid: boolean | undefined;
}

const useEmailControle = ({
  emailInputRef,
  role = RoleCd.BORROWER,
}: {
  emailInputRef?: RefObject<HTMLInputElement> | null;
  role: RoleCd;
}) => {
  const [triggerSendControlEmail, resultSendControlEmail] =
    useLazyGetControleEmailQuery();

  const controlEmail = useAppSelector(selectControlEmail);
  const CONTROLE_KO = 'email non reconnu';
  const SUFFIX =
    role + '-' + (window.location.href?.split('/#/') ?? ['', ''])[1];

  const [isEmailFieldPending, setIsEmailFieldPending, getIsEmailFieldPending] =
    usePersistingState<boolean>('isEmailFieldPending', false);

  const dispatch = useAppDispatch();

  const [isToBeCorrected, setIsToBeCorrected, getIsToBeCorrected] =
    usePersistingState<boolean>(
      'controleEmail-isToBeCorrected-' + SUFFIX,
      false
    );

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isModalControleEmailVisible, setIsModalControleEmailVisible] =
    useState(false);

  const getEmailInputRef = () => emailInputRef; // à changer, pas de méthode

  const ControleEmailModal: React.FC<ModalEmailInvalideProps> = ({
    setIsToBeCorrected: setIsToBeCorrectedParam,
    setIsModalControleEmailVisible: setIsModalControleEmailVisibleParam,
    emailInputRef: emailInputRefParam,
  }: ModalEmailInvalideProps) => (
    <ModalEmailInvalide
      emailInputRef={emailInputRefParam ?? getEmailInputRef}
      setIsToBeCorrected={setIsToBeCorrectedParam ?? setIsToBeCorrected}
      isModalControleEmailVisible={isModalControleEmailVisible}
      setIsModalControleEmailVisible={
        setIsModalControleEmailVisibleParam ?? setIsModalControleEmailVisible
      }
      updateIsEmailVerified={updateIsEmailVerified}
    />
  );

  const updateIsEmailVerified = (isEmailVerified: boolean) => {
    setIsEmailVerified(isEmailVerified);
  };

  useEffect(() => {
    if (resultSendControlEmail.isFetching) {
      return;
    }
    if (!resultSendControlEmail?.data) {
      setIsEmailVerified(true);
      return;
    }
    updateIsEmailVerified(
      resultSendControlEmail?.data?.message !== CONTROLE_KO
    );
    setIsModalControleEmailVisible(
      resultSendControlEmail?.data?.message === CONTROLE_KO
    );
  }, [resultSendControlEmail]);

  const checkEmailValideAndControleEmail = ({
    mailToTest,
  }: CheckEmailValideAndControleEmailProps) => {
    if (
      !mailToTest ||
      typeof mailToTest !== 'string' ||
      mailToTest.length === 0 ||
      !isEmailValide(mailToTest)
    ) {
      return;
    }
    setIsToBeCorrected(false);
    if (
      Object.values(controlEmail).find(
        (lastEmailTested) => mailToTest === lastEmailTested
      )
    ) {
      setIsEmailVerified(true);
      return;
    }
    setIsEmailVerified(false);
    const lastEmailTested: Partial<controlEmailTypes.IState> =
      role === 'TIT' ? { TIT: mailToTest } : { CTI: mailToTest };
    dispatch(updateControlEmail(lastEmailTested));
    triggerSendControlEmail(mailToTest, true);
  };
  const getIsEmailValidationLoading = () =>
    resultSendControlEmail?.isFetching || resultSendControlEmail?.isLoading;

  const isEmailValideOrToBeCorrected = (mail: string) => {
    return isEmailValide(mail) && !getIsToBeCorrected();
  };
  const [isForeground, setIsForeground] = useState(false);

  const getValidationState = (): IEmailValidationState => {
    return {
      isLoading:
        resultSendControlEmail?.isFetching || resultSendControlEmail?.isLoading,
      isForeground: isForeground,
      isValid: isEmailVerified,
    };
  };
  useEffect(() => {
    setIsForeground(
      document.activeElement === emailInputRef?.current ||
        isModalControleEmailVisible ||
        getIsEmailValidationLoading()
    );
  }, [
    isModalControleEmailVisible,
    document.activeElement,
    emailInputRef?.current,
    getIsEmailValidationLoading(),
  ]);

  return {
    isResponseControleEmailLoading: resultSendControlEmail?.isLoading,
    getIsEmailValidationLoading,
    checkEmailValideAndControleEmail,
    isEmailValideOrToBeCorrected,
    ModalEmailValidation: ControleEmailModal,
    setIsEmailFieldPending,
    getIsEmailFieldPending,
    getValidationState,
    getIsToBeCorrected,
  };
};

export default useEmailControle;
