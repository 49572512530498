import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './configurationTypes';

const getInitialState = () => {
  return { state: { isCleared: false } } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfiguration(configState, action: PayloadAction<IState>) {
      configState.state = { ...action.payload, isCleared: false };
    },
    updateConfiguration(configState, action: PayloadAction<Partial<IState>>) {
      configState.state = {
        ...configState.state,
        ...action.payload,
        isCleared: false,
      };
    },
    clear(configState) {
      configState.state = { ...getInitialState().state, isCleared: true };
    },
  },
});

export const selectConfiguration = (configState: {
  configuration: { state: IState };
}) => configState.configuration.state;

export const { setConfiguration, updateConfiguration, clear } =
  configurationSlice.actions;

export default configurationSlice.reducer;
