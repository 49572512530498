// Without insurance modal
export const TITLE =
  'Vous n’avez sélectionné aucune Assurance Emprunteur facultative.';
export const SUB_TITLE = 'Êtes-vous sûr de vouloir continuer?';

export const GUIDANCE_BLOCK_TEXT_1 =
  'Nous vous invitons à informer le client des ';
export const GUIDANCE_BLOCK_TEXT_2 = 'avantages ';
export const GUIDANCE_BLOCK_TEXT_3 = 'de l’assurance et des ';
export const GUIDANCE_BLOCK_TEXT_4 = 'risques ';
export const GUIDANCE_BLOCK_TEXT_5 =
  'auxquels ils s’exposent à ne pas y souscrire.';
export const ADDITIONAL_INFORMATIONS_TITLE =
  'Avec Assurance Emprunteur facultative';
export const ADDITIONAL_INFORMATIONS_TEXT_CR_1 =
  'En cas d’utilisation spéciale de moins de 13 mois, si le crédit est gratuit, ';
export const ADDITIONAL_INFORMATIONS_TEXT_VAT_1 =
  'En cas de Décès ou de Perte Totale et Irréversible d’Autonomie,';
export const ADDITIONAL_INFORMATIONS_TEXT_VAT_1_WITH_INUSURANCE =
  'le solde restant dû par le(s) client(s) sera pris en charge.';

export const ADDITIONAL_INFORMATIONS_TEXT_CR_2 =
  'l’assurance emprunteur est offerte.';
export const ADDITIONAL_INFORMATIONS_TEXT_VAT_2 =
  'le solde restant dû par le(s) client(s) sera pris en charge.';
export const ADDITIONAL_INFORMATIONS_TEXT_VAT_3 = '1, 2 ou 3 € ';
export const ADDITIONAL_INFORMATIONS_TEXT_VAT_4 =
  'par mois selon le montant de l’achat.';
export const ADDITIONAL_INFORMATIONS_TEXT_3 =
  'Le solde restant dû du client sera pris en charge* ';
export const ADDITIONAL_INFORMATIONS_TEXT_3_WITH_INUSURANCE =
  'En cas de Décès, ';
export const ADDITIONAL_INFORMATIONS_TEXT_4 =
  'en cas de Décès (D) ou d’Invalidité (I).';
export const ADDITIONAL_INFORMATIONS_TEXT_5 =
  'En cas de d’Incapacité Temporaire Totale de Travail ou de Perte d’Emploi suite à un Licenciement, ';
export const ADDITIONAL_INFORMATIONS_TEXT_5_WITH_INUSURANCE =
  'Selon la formule souscrite, en cas de d’Incapacité Temporaire Totale de Travail ou de Perte d’Emploi suite à un Licenciement, ';

export const ADDITIONAL_INFORMATIONS_TEXT_6 =
  'jusqu’à 18 mensualités du crédit seront prises en charge. La garantie Perte d’Emploi suite à un Licenciement est réservée aux résidents de France métropolitaine.';
export const ADDITIONAL_INFORMATIONS_TEXT_7 =
  'en cas de Maladie - Accident (M)';

export const FINE_PRINT_TEXT =
  '*Détail des conditions et limites de garantie disponible dans la notice.';

export const WARNING_TITLE = 'Sans Assurance Emprunteur facultative';
export const WARNING_TEXT_1 = 'En cas de Décès, ';
export const WARNING_TEXT_2 =
  ' le co-emprunteur ou les ayants droit (conjoint, enfants…) du/des client(s) pourraient avoir à rembourser le solde du crédit.';
export const WARNING_TEXT_3 =
  'En cas de d’Incapacité Temporaire Totale de Travail ou de Perte Totale et Irréversible d’Autonomie ou de Perte d’Emploi suite à un Licenciement, ';
export const WARNING_TEXT_4 =
  'le(s) client(s) devra/devront continuer à rembourser chaque mois le crédit.';
export const WARNING_TEXT_5 = 'En cas d’Invalidité, de Maladie - Accident, ';

// With Insurance modal
export const WITH_INSURANCE_TEXT_1 =
  'Vous avez sélectionné l’offre Assurance Emprunteur facultative.';
export const WITH_INSURANCE_TEXT_2 = "Ce choix le sécurise en cas d'imprévu.";

export const CLOSE = 'Fermer';
