const SvgCross: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.34315 6.34338C5.95262 6.7339 5.95262 7.36707 6.34315 7.75759L10.5858 12.0002L6.34315 16.2429C5.95262 16.6334 5.95262 17.2666 6.34315 17.6571C6.73367 18.0476 7.36683 18.0476 7.75736 17.6571L12 13.4144L16.2426 17.6571C16.6332 18.0476 17.2663 18.0476 17.6569 17.6571C18.0474 17.2666 18.0474 16.6334 17.6569 16.2429L13.4142 12.0002L17.6569 7.75759C18.0474 7.36707 18.0474 6.7339 17.6569 6.34338C17.2663 5.95285 16.6332 5.95285 16.2426 6.34338L12 10.586L7.75736 6.34338C7.36684 5.95285 6.73367 5.95285 6.34315 6.34338Z"
      fill="#4B4F54"
    />
  </svg>
);

export default SvgCross;
