const SvgFilter: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 12.643V19a.5.5 0 00.276.447l4 2A.5.5 0 0014.5 21v-8.357l7.882-9.32A.5.5 0 0022 2.5H2a.5.5 0 00-.382.823l7.882 9.32zM20.922 3.5l-7.304 8.637a.5.5 0 00-.118.323v7.731l-3-1.5V12.46a.5.5 0 00-.118-.323L3.078 3.5h17.844z"
    />
  </svg>
);

export default SvgFilter;
