const SvgUploadKo: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 59 44" className={className}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M28.262 6.002a16.126 16.126 0 00-13.375 7.541l-.084.136-.144-.029a12.477 12.477 0 00-2.247-.203C5.557 13.447 0 19.004 0 25.86s5.557 12.413 12.412 12.413h8.713v-2.495h9.858v2.495H42.2c5.484 0 9.93-4.446 9.93-9.93l-.003-.222a9.935 9.935 0 00-7.659-9.448l-.167-.037-.021-.098C42.63 11.285 36.146 6 28.548 6m0 2.482c6.714 0 12.403 4.88 13.474 11.434.089.545.528.966 1.077 1.032a7.449 7.449 0 01-.898 14.841H12.412c-5.484 0-9.93-4.446-9.93-9.93s4.446-9.93 9.93-9.93c.914 0 1.81.124 2.673.364a1.241 1.241 0 001.426-.608 13.649 13.649 0 0112.037-7.203z"
      />
      <g transform="translate(35)">
        <circle cx={12} cy={12} r={7} fill="#FFF" />
        <path
          fill="currentColor"
          d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm3.94 8.06a.837.837 0 00-1.183 0L12 10.815 9.243 8.059a.837.837 0 00-1.098-.075l-.086.075a.837.837 0 000 1.184L10.816 12l-2.757 2.757c-.3.3-.325.77-.075 1.098l.075.086a.837.837 0 001.184 0L12 13.184l2.757 2.757c.3.3.77.325 1.098.075l.086-.075a.837.837 0 000-1.184L13.184 12l2.757-2.757c.3-.3.325-.77.075-1.098z"
        />
      </g>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M26.5 25v18M32 28.889L26.5 24 21 28.889" />
      </g>
    </g>
  </svg>
);

export default SvgUploadKo;
