import {
  WrapperLoader,
  Background,
  WrapperLoaderIcon,
  WrapperMessage,
} from './styles';

export interface ILoaderProps {
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  message?: string | React.ReactNode;
  isTextInline?: boolean;
  isHideBackground?: boolean;
  animationDuration?: number;
}

const Loader = ({
  className = '',
  isLoading = true,
  isTextInline = false,
  message,
  children,
  isHideBackground = true,
  animationDuration = 0.8,
}: ILoaderProps): React.ReactElement => (
  <WrapperLoader
    className={'ph-loader ' + className}
    isTextInline={isTextInline}
  >
    {isLoading && (
      <Background
        isLoading={isLoading}
        isTextInline={isTextInline}
        isHideBackground={isHideBackground}
      >
        <WrapperLoaderIcon
          isBig={!isTextInline}
          animationDuration={animationDuration}
        />
        {message && (
          <WrapperMessage isTextInline={isTextInline}>{message}</WrapperMessage>
        )}
      </Background>
    )}
    {children}
  </WrapperLoader>
);

export default Loader;
