import { Badge, Block } from '@components';
import { Insurance } from '@types';
import StyleComponents from './style';
import InsuranceBadge from '../InsuranceBadge';
import * as message from './messages';
import { formatNumber } from '@utils';
import { NO_INSURANCE_KEY } from '../constant';

const {
  StyledInlineBlockHeader,
  StyledRadioButton,
  StyledInsuranceCode,
  StyledBadgeBestInsuranceWrapper,
  StyledBlockContent,
  StyledDescriptionList,
} = StyleComponents;

const BadgeBestInsurance = (): React.ReactElement => (
  <StyledBadgeBestInsuranceWrapper>
    <Badge variant="standardWithoutDot">{message.BEST_INSURANCE}</Badge>
  </StyledBadgeBestInsuranceWrapper>
);
interface IProps {
  isCollapsed: boolean;
  setIsCollapsed: () => void;
  insuranceSelected: Insurance;
  className?: string;
  isBestInsurance?: boolean;
}

const InsuranceBlock: React.FC<IProps> = ({
  isCollapsed,
  setIsCollapsed,
  insuranceSelected,
  className = '',
  isBestInsurance = false,
}) => {
  const insuranceCode = insuranceSelected?.code
    ? insuranceSelected?.code
    : NO_INSURANCE_KEY;

  const isBadgeActive = (label: string): boolean => {
    if (insuranceCode === NO_INSURANCE_KEY) {
      return false;
    }
    switch (label) {
      case message.DEATH_INSURANCE_LABEL:
        return insuranceCode.includes('D');
      case message.INVALIDITY_INSURANCE_LABEL:
        return insuranceCode.includes('I');
      case message.DISEASE_OR_ACCIDENT_INSURANCE_LABEL:
        return insuranceCode.includes('M');
      case message.UNEMPLOYMENT_INSURANCE_LABEL:
        return insuranceCode.includes('C');
      default:
        return false;
    }
  };

  return (
    <Block.Wrapper className={className} id={`insurance-${insuranceCode}`}>
      <article>
        <Block.Header
          isActive={isCollapsed}
          onClick={() => {
            setIsCollapsed();
          }}
        >
          {isBestInsurance && <BadgeBestInsurance />}
          <StyledInlineBlockHeader>
            <StyledRadioButton
              id={`${insuranceCode}-radio-button`}
              name={insuranceCode}
              checked={!isCollapsed}
            >
              <StyledInsuranceCode isCollapsed={isCollapsed}>
                {insuranceCode === NO_INSURANCE_KEY
                  ? message.NO_INSURANCE_LABEL
                  : `${insuranceCode}*`}
              </StyledInsuranceCode>
            </StyledRadioButton>
            <InsuranceBadge
              isActive={isBadgeActive(message.DEATH_INSURANCE_LABEL)}
              isSelected={!isCollapsed}
              label={message.DEATH_INSURANCE_LABEL}
            />
            <InsuranceBadge
              isActive={isBadgeActive(message.INVALIDITY_INSURANCE_LABEL)}
              isSelected={!isCollapsed}
              label={message.INVALIDITY_INSURANCE_LABEL}
            />
            <InsuranceBadge
              isActive={isBadgeActive(
                message.DISEASE_OR_ACCIDENT_INSURANCE_LABEL
              )}
              isSelected={!isCollapsed}
              label={message.DISEASE_OR_ACCIDENT_INSURANCE_LABEL}
            />
            <InsuranceBadge
              isActive={isBadgeActive(message.UNEMPLOYMENT_INSURANCE_LABEL)}
              isSelected={!isCollapsed}
              label={message.UNEMPLOYMENT_INSURANCE_LABEL}
            />
          </StyledInlineBlockHeader>
        </Block.Header>
      </article>
      {insuranceCode !== NO_INSURANCE_KEY && (
        <Block.Body isCollapsed={isCollapsed}>
          <StyledBlockContent>
            <StyledDescriptionList
              list={[
                {
                  title: message.DESCRIPTION_INSURANCE_MONTHLY_PAYMENT_TITLE,
                  description: `${formatNumber(
                    insuranceSelected?.cout_mensuel
                  )} ${message.DESCRIPTION_EURO_SIGN}`,
                  fieldName: `FIELD-1-${insuranceCode}`,
                },
                {
                  title: message.DESCRIPTION_INSURANCE_TOTAL_TITLE,
                  description: `${formatNumber(
                    insuranceSelected?.cout_total
                  )} ${message.DESCRIPTION_EURO_SIGN}`,
                  fieldName: `FIELD-2-${insuranceCode}`,
                },
                {
                  title: message.DESCRIPTION_TAEA_TITLE,
                  description: `${formatNumber(insuranceSelected?.taea)} ${
                    message.DESCRIPTION_PERCENT_SIGN
                  }`,
                  fieldName: `FIELD-3-${insuranceCode}`,
                },
              ]}
            />
            <StyledDescriptionList
              list={[
                {
                  title:
                    message.DESCRIPTION_INSURANCE_MONTHLY_PAYMENT_WITH_INSURANCE_TITLE,
                  description: `${formatNumber(
                    insuranceSelected?.mensualite_credit
                  )} ${message.DESCRIPTION_EURO_SIGN}`,
                  fieldName: `FIELD-4-${insuranceCode}`,
                },
                {
                  title: message.DESCRIPTION_TOTAL_TITLE,
                  description: `${formatNumber(
                    insuranceSelected?.montant_total
                  )} ${message.DESCRIPTION_EURO_SIGN}`,
                  fieldName: `FIELD-5-${insuranceCode}`,
                },
              ]}
            />
          </StyledBlockContent>
        </Block.Body>
      )}
    </Block.Wrapper>
  );
};

export default InsuranceBlock;
