const SvgHome: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4225 4.17301L21.8225 13.611C22.0625 13.8417 22.0625 14.2228 21.8025 14.4836C21.6925 14.604 21.5325 14.6642 21.3825 14.6642C21.2325 14.6642 21.0825 14.604 20.9625 14.4836L18.6425 12.1467V19.3982C18.6525 19.5487 18.5825 19.7091 18.4725 19.8195C18.3625 19.9398 18.2125 20 18.0525 20H14.1025C13.7725 20 13.5025 19.7292 13.5025 19.3982L13.4925 15.4164H10.4725V19.3982C10.4725 19.7292 10.2025 20 9.8725 20H5.9425C5.6125 20 5.3425 19.7292 5.3425 19.3982V12.1367L3.0225 14.4636C2.7825 14.6942 2.4025 14.6942 2.1725 14.4636C1.9425 14.2228 1.9425 13.8417 2.1725 13.611L11.5725 4.17301C11.8025 3.94233 12.1925 3.94233 12.4225 4.17301ZM14.7125 18.7764H17.4625L17.4525 10.9231L12.0025 5.4468L6.5525 10.9231V18.7764H9.2825V14.7945C9.2825 14.6441 9.3525 14.4836 9.4625 14.3733C9.5725 14.2529 9.7225 14.1928 9.8825 14.1928H14.1025C14.4325 14.1928 14.7025 14.4636 14.7025 14.7945L14.7125 18.7764Z"
      fill="#4B4F54"
    />
  </svg>
);

export default SvgHome;
