import {
  BlockWrapper,
  BlockNumber,
  BlockLabel,
  BlockValue,
  BlockSubText,
} from './styles';

import CoutTotalAssuranceFacultative from './CoutTotalAssuranceFacultative';
import CoutMensuelAssuranceFacultative from './CoutMensuelAssuranceFacultative';

export interface IProps {
  blockNumber?: number;
  blockLabel: string;
  blockValue: string | any;
  blockDesc?: string;
  ageBorrower: number;
  ageCoBorrower: number;
}

export const SimulationCardBlock: React.FC<IProps> = ({
  blockNumber,
  blockLabel,
  blockValue,
  blockDesc,
  ageBorrower,
  ageCoBorrower,
}) => {
  return (
    <>
      {blockNumber !== 1 && <hr />}
      <BlockWrapper>
        <BlockNumber>{blockNumber}</BlockNumber>
        <BlockLabel step={blockNumber}>{blockLabel}</BlockLabel>
        {blockNumber === 11 ? (
          <CoutTotalAssuranceFacultative
            blockValue={blockValue}
            ageBorrower={ageBorrower}
            ageCoBorrower={ageCoBorrower}
          />
        ) : blockNumber === 12 ? (
          <CoutMensuelAssuranceFacultative
            blockValue={blockValue}
            ageBorrower={ageBorrower}
            ageCoBorrower={ageCoBorrower}
          />
        ) : (
          <>
            <BlockValue>{blockValue}</BlockValue>
            {blockDesc && <BlockSubText>{blockDesc}</BlockSubText>}
          </>
        )}
      </BlockWrapper>
    </>
  );
};
