import styled from 'styled-components';

export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h1};
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  line-height: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const StyledH2 = styled.h2`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h2};
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  line-height: 2.8rem;
`;

export const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  line-height: 2.8rem;
`;
