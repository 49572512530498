const SvgSend: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.583 3a.421.421 0 01.29.126l.01.01a.44.44 0 01.035.041l-.044-.05a.43.43 0 01.126.3l-.002.035a.411.411 0 01-.023.109l.018-.067a.429.429 0 01-.01.039l-.008.028-6 17.142a.429.429 0 01-.796.032l-3.361-7.564-7.563-3.36a.429.429 0 01-.041-.763l.073-.033 17.142-6A.456.456 0 0120.546 3h.025zm-1.038 2.06l-7.892 7.892 2.87 6.458 5.022-14.35zm-.604-.608L4.589 9.476l6.458 2.87 7.894-7.894z"
    />
  </svg>
);

export default SvgSend;
