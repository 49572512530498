const CrossCircle: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="-2 -2 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10,-2.22044605e-16 C15.5228475,-2.22044605e-16 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,-2.22044605e-16 10,-2.22044605e-16 Z M14.6439394,6.26780122 C14.3509096,5.97504473 13.8760359,5.97526637 13.5832794,6.26829625 L13.5832794,6.26829625 L8.99979155,11.8034315 L6.75536921,9.55915746 C6.46246632,9.26627391 5.98759258,9.2662896 5.69470904,9.55919249 C5.40182549,9.85209538 5.40184118,10.3269691 5.69474407,10.6198527 L5.69474407,10.6198527 L8.46974407,13.3946693 C8.76273679,13.6876427 9.23778791,13.687524 9.53063418,13.3944043 L9.53063418,13.3944043 L14.6444345,7.32846128 C14.937191,7.03543139 14.9369693,6.56055771 14.6439394,6.26780122 Z"
    />
  </svg>
);

export default CrossCircle;
