import { Company } from '@utils';
import colors from './colors';
import layout from './layout';
import fonts from './fonts';
import * as themes from './themes';

const variables = {
  colors,
  layout,
  fonts,
};

const cetelemTheme = {
  variables,
  vendor: themes.cetelem,
};

export const storybookTheme = {
  control: {
    type: 'inline-radio',
    options: ['cetelem', 'cafineo_ag', 'none'],
  },
};

export const getTheme = (companyName: string) => {
  switch (companyName) {
    case Company.CETELEM:
      return cetelemTheme;
    case Company.DOMOFINANCE:
      return {
        variables,
        vendor: themes.domofinance,
      };
    case Company.CREDITMODERNE_AG:
    case Company.CREDITMODERNE_OI:
      return {
        variables,
        vendor: themes.creditmoderne,
      };
    case Company.CAFINEO_AG:
    case Company.CAFINEO_OI:
      return {
        variables,
        vendor: themes.cafineo,
      };
    default:
      return {
        variables,
        vendor: themes.none,
      };
  }
};
