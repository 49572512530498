const SvgHome: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.91 2.333l8.843 7.456c.294.248.33.687.082.98a.7.7 0 01-.983.081l-.284-.239v8.98A1.41 1.41 0 0119.157 21h-4.294a1.41 1.41 0 01-1.413-1.408v-5.724h-2.898v5.724A1.41 1.41 0 019.138 21H4.844a1.41 1.41 0 01-1.413-1.408V10.61l-.282.24a.7.7 0 01-.984-.082.693.693 0 01.082-.98l8.838-7.456a1.417 1.417 0 011.826 0zm-.924 1.061l-7.16 6.04v10.158c0 .01.008.018.018.018h4.294c.01 0 .018-.008.018-.018v-6.42c0-.383.312-.695.697-.695h4.294c.385 0 .697.312.697.696v6.419c0 .01.008.018.018.018h4.294c.01 0 .018-.008.018-.018l-.001-10.157-7.163-6.04a.018.018 0 00-.024 0z"
    />
  </svg>
);

export default SvgHome;
