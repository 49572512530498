import styled from 'styled-components';
import { Tooltip, Icons } from '@components';

export const StyledTooltip = styled(Tooltip)`
  max-width: 60rem;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_s} 0;
`;

export const StyledCheckCircleIcon = styled(Icons.CheckCircleIcon)`
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-right: 1rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.white};
`;

export const TextParagraph = styled.p`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  letter-spacing: 0;
  margin: 0;
  padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  padding-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const FinePrint = styled.p`
  max-width: 60rem;
  text-align: left;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_400};
  align-self: left;
  letter-spacing: 0;
  margin: 0;
`;

export const TextBold = styled.a`
  font-weight: bold;
`;
