const SvgWarningFill: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      d="M10.0532964,0 C10.737333,0.00128200182 11.3568194,0.330953701 11.7405847,0.890156827 L11.8315161,1.0346823 L19.703341,14.7126268 C20.0979653,15.3980171 20.098654,16.2167355 19.7067845,16.9035145 C19.314915,17.5902936 18.6117538,18 17.8259487,18 L2.12430948,18 C1.35503316,18 0.667711963,17.5986266 0.284795546,16.9257359 C-0.0981208716,16.2528452 -0.0946773786,15.4514872 0.293748627,14.7820686 L8.27232182,1.02843255 C8.64559645,0.384012962 9.31019059,0 10.0498529,0 L10.0532964,0 Z M10.0347015,13.066857 C9.4561947,13.066857 8.98581356,13.5411442 8.98581356,14.1244551 C8.98581356,14.7077659 9.4561947,15.1820532 10.0347015,15.1820532 C10.6132083,15.1820532 11.0842782,14.7077659 11.0835902,14.1244551 C11.0835902,13.5411442 10.6132083,13.066857 10.0347015,13.066857 Z M9.98167172,5.37201497 C9.51197928,5.37201497 9.13112896,5.75602793 9.13112896,6.22962077 L9.13112896,6.22962077 L9.13112896,11.6780217 C9.13112896,12.1516145 9.51197928,12.5356275 9.98167172,12.5356275 C10.4513642,12.5356275 10.8322145,12.1516145 10.8322145,11.6780217 L10.8322145,11.6780217 L10.8322145,6.22962077 C10.8322145,5.75602793 10.4513642,5.37201497 9.98167172,5.37201497 Z"
      id="Combined-Shape"
    />
  </svg>
);

export default SvgWarningFill;
