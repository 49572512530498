import { WrapperDocuments } from './styles';
import { Documents as DocumentsContainer } from '@containers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export interface IProps {
  currentBlockName?: string;
  isButtonValidByDefault?: boolean;
}

export const Documents: React.FC<IProps> = ({ currentBlockName = 'JDID' }) => {
  return (
    <WrapperDocuments>
      <DndProvider backend={HTML5Backend}>
        <DocumentsContainer currentBlockName={currentBlockName} />
      </DndProvider>
    </WrapperDocuments>
  );
};

export default Documents;
