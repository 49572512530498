import styled from 'styled-components';

const StyledMessage = styled.span`
  color: ${({ theme }) => theme.variables.colors.chartColors.red};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  line-height: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  font-weight: normal;
`;

export default StyledMessage;
