const SvgWarning: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3c.858 0 1.654.46 2.101 1.219l7.57 12.993c.436.777.439 1.734.007 2.513A2.448 2.448 0 0119.564 21H4.428a2.446 2.446 0 01-2.106-1.275 2.591 2.591 0 01.012-2.522L9.898 4.219l.002-.003A2.442 2.442 0 0112 3zm0 1.377c-.39 0-.751.21-.954.551L3.488 17.901c-.198.353-.199.788-.003 1.142.197.355.561.575.95.58h15.121c.397-.005.762-.225.958-.58.196-.354.195-.789.002-1.133L12.954 4.93A1.11 1.11 0 0012 4.377zm0 11.574c.37 0 .67.309.67.689 0 .38-.3.689-.67.689a.68.68 0 01-.67-.689c0-.38.3-.689.67-.689zm0-6.429c.37 0 .67.308.67.689v3.673c0 .38-.3.688-.67.688a.68.68 0 01-.67-.688V10.21c0-.38.3-.689.67-.689z"
    />
  </svg>
);

export default SvgWarning;
