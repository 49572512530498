import { ThemeProvider } from 'styled-components';
import { getTheme } from '@styles';

interface IThemeProps {
  children?: React.ReactNode;
  theme?: {
    variables: unknown;
    vendor: unknown;
  };
}

const Theme = ({ children, theme }: IThemeProps): React.ReactElement => {
  const themeGlobal = getTheme('');
  const assignTheme = Object.assign({}, themeGlobal, theme);
  return <ThemeProvider theme={assignTheme}>{children}</ThemeProvider>;
};
export default Theme;
