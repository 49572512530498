import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import { VariantType } from './Button';
import Tooltip from '../Tooltip';
import Loader from '../Loader';
import { MAX_WIDTH_SIZE } from '@utils';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const StyledLoader = styled(Loader)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

const getSecondaryStyles = (isLoading = false) => {
  return css`
    color: ${({ theme }) =>
      isLoading ? 'transparent' : theme.variables.colors.chartColors.dark_700};
    border-color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
    background-color: transparent;

    &:not(:disabled):hover {
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.grey_700};
      color: ${({ theme }) => theme.variables.colors.chartColors.grey_700};
    }

    &:active {
      border-color: ${({ theme }) => theme.variables.colors.chartColors.black};
      color: ${({ theme }) => theme.variables.colors.chartColors.black};
    }

    &:disabled {
      border-color: ${({ theme }) =>
        isLoading
          ? theme.variables.colors.chartColors.dark_700
          : theme.variables.colors.chartColors.grey_100};
      color: ${({ theme }) =>
        isLoading
          ? 'transparent'
          : theme.variables.colors.chartColors.grey_100};
    }
  `;
};

const getDarkStyles = (isLoading = false) => {
  return css`
    color: ${({ theme }) =>
      isLoading ? 'transparent' : theme.variables.colors.chartColors.white};
    border-color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
    background-color: ${({ theme }) =>
      theme.variables.colors.chartColors.dark_700};

    &:hover,
    &:focus {
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.grey_700};
      background-color: ${({ theme }) =>
        theme.variables.colors.chartColors.grey_700};
    }

    &:active {
      border-color: ${({ theme }) => theme.variables.colors.chartColors.black};
      background-color: ${({ theme }) =>
        theme.variables.colors.chartColors.black};
    }

    &:disabled {
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.grey_100};
      background-color: ${({ theme }) =>
        theme.variables.colors.chartColors.grey_100};
    }
    border: 5x solid red;
  `;
};

const getLinkStyles = () => {
  return css`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
    text-decoration: none;
    color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
    font-weight: normal;
    padding: 0;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
    &:active {
      color: ${({ theme }) => theme.variables.colors.chartColors.black};
    }

    &:disabled {
      color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  `;
};

const getPrimaryStyles = (isLoading = false) => {
  const getBackgroundColor = ({ theme }: { theme: any }) => {
    return isLoading
      ? theme.vendor.colors['400']
      : lighten(0.4, theme.vendor.colors.primary);
  };

  return css`
    color: ${({ theme }) =>
      isLoading ? 'transparent' : theme.variables.colors.chartColors.white};
    border-color: ${({ theme }) => theme.vendor.colors.primary};
    background-color: ${({ theme }) => theme.vendor.colors.primary};

    &:hover {
      border-color: ${({ theme }) => darken(0.04, theme.vendor.colors.primary)};
      background-color: ${({ theme }) =>
        darken(0.04, theme.vendor.colors.primary)};
    }

    &:active {
      border-color: ${({ theme }) => darken(0.09, theme.vendor.colors.primary)};
      background-color: ${({ theme }) =>
        darken(0.09, theme.vendor.colors.primary)};
    }

    &:disabled {
      border-color: ${getBackgroundColor};
      background-color: ${getBackgroundColor};

      &:hover {
        border-color: ${({ theme }) =>
          lighten(0.3, theme.vendor.colors.primary)};
        background-color: ${({ theme }) =>
          lighten(0.3, theme.vendor.colors.primary)};
      }
    }

    ${StyledLoader} {
      & svg {
        color: ${({ theme }) => theme.variables.colors.chartColors.white};
      }
    }
  `;
};

const getLightStyles = () => {
  return css`
    font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.variables.fonts.sizes.h3};
    font-weight: ${({ theme }) => theme.variables.fonts.types.normal};
    font-size: 1.4rem;
    border-width: 0.1rem;
    border-color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
    color: ${({ theme }) => theme.vendor.colors.primary};
    white-space: nowrap;
    background-color: transparent;

    &:disabled {
      color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.grey_100};
      background-color: ${({ theme }) =>
        lighten(0.3, theme.variables.colors.chartColors.white)};
      .left-icon {
        color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
      }
    }

    ${StyledLoader} {
      color: ${({ theme }) => theme.variables.colors.chartColors.green_100};
    }
  `;
};

const getVariantCss = (variant?: VariantType, isLoading?: boolean) => {
  switch (variant) {
    case 'secondary':
      return getSecondaryStyles(isLoading);
    case 'primary':
      return getPrimaryStyles(isLoading);
    case 'dark':
      return getDarkStyles(isLoading);
    case 'link':
      return getLinkStyles();
    case 'light':
      return getLightStyles();
    default:
      return '';
  }
};

export const StyledButton = styled.button`
  padding: 0.7rem ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  line-height: 22px;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: none;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
  border-style: solid;
  border-width: 0.2rem;
  font-weight: ${({ theme }) => theme.variables.fonts.types.bold};
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  word-break: normal;
  width: 100%;
  height: 100%;

  ${({ variant, isLoading }: { variant?: VariantType; isLoading?: boolean }) =>
    getVariantCss(variant, isLoading)}

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    padding-bottom: 1.1rem;
    padding-top: 1.1rem;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  transform: translateY(calc(-100% - 0.8rem));
`;
