import { Summary as SummaryContainer } from '@containers';
import { WrapperSumary } from './styles';

export const Summary: React.FC = () => {
  return (
    <WrapperSumary>
      <SummaryContainer />
    </WrapperSumary>
  );
};

export default Summary;
