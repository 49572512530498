import React from 'react';
import { StyledButton, WrapperButton, StyledPlusIcon } from './styles';
import * as messages from './messages';
import { useNavigate } from 'react-router-dom';

export interface AddSimulationButtonProps {
  AddSimulation?: () => void;
}

export const AddSimulationButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <WrapperButton>
        <StyledButton
          onClick={() => {
            navigate(`/simulation`, {
              state: {
                simulationId: 2,
              },
            });
          }}
        >
          <StyledPlusIcon />
          {messages.COMPARER}
        </StyledButton>
      </WrapperButton>
    </React.Fragment>
  );
};
