const SvgSwipe: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.732 3.22c.9-.003 1.93.645 1.95 1.95v4.344c.12 0 1.948.206 3.276.48.91.188 1.275.36 1.9.543 1.284.389 1.685.916 1.936 1.875.26.993.26 2.112.076 3.308a14.014 14.014 0 01-1.013 3.475c-.47 1.056-1.036 1.942-1.738 2.462a.491.491 0 01-.29.1h-6.585a.495.495 0 01-.366-.169c-1.898-2.22-3.458-5.146-4.726-8.193-.259-.667-.345-1.213-.213-1.692.258-.86 1.392-1.6 2.317-1.35.68.174 1.222.88 1.524 1.533V5.17c0-1.073.88-1.952 1.952-1.952zm0 .975a.963.963 0 00-.976.976v8.78a.503.503 0 01-.374.474.503.503 0 01-.548-.253l-.968-1.943c-.222-.396-.35-.742-.869-.938-.434-.067-.94.2-1.12.67-.052.184-.032.512.19 1.083 1.066 2.615 2.7 5.717 4.413 7.736h6.151c.436-.366.93-1.074 1.334-1.981.43-.967.767-2.127.937-3.232.17-1.105.163-2.154-.045-2.889-.207-.727-.316-.89-1.117-1.245-.91-.449-4.995-1.15-5.103-.927-.093.2-.322.264-.538.215-.215-.05-.395-.207-.392-.428V5.17a.963.963 0 00-.975-.976zM5.029 2.38a.507.507 0 01-.003.697L3.662 4.44h4.68a.488.488 0 110 .976h-4.68L5.026 6.78c.185.18.187.515.003.697a.507.507 0 01-.697-.011L2.137 5.27A.466.466 0 012 4.928c.005-.123.03-.245.137-.343L4.332 2.39a.502.502 0 01.697-.01z"
    />
  </svg>
);

export default SvgSwipe;
