import {
  WrapperRadioLabel,
  WrapperRadioButton,
  StyledRadioButton,
  StyledCheckmark,
  StyledRadioLabel,
} from './styles';

export interface IRadioButtonProps {
  children: React.ReactNode;
  id: string;
  name: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  className?: string;
}

const RadioButton = ({
  id,
  name,
  value,
  checked = false,
  disabled = false,
  children,
  onChange,
  className = '',
}: IRadioButtonProps): React.ReactElement => (
  <WrapperRadioLabel
    checked={checked}
    disabled={disabled}
    isClickable={!!onChange}
    className={'ph-radio-button ' + className}
  >
    <WrapperRadioButton>
      <StyledRadioButton
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={() => {
          if (onChange) {
            onChange();
          }
        }}
      />
      <StyledCheckmark checked={checked} disabled={disabled} />
    </WrapperRadioButton>
    <StyledRadioLabel disabled={disabled}>{children}</StyledRadioLabel>
  </WrapperRadioLabel>
);

export default RadioButton;
