import { useEffect, useState } from 'react';
import { Upload, Button } from '@components';

import {
  FileStatus,
  IFile,
  UploadType,
  IImagesFile,
  EvidenceFace,
} from '@types';
import { detectMobileDevice, containsRecto } from '@utils';
import { usePersistingState } from '@hooks';
import * as messages from './messagesBlockOcr';
import {
  WrapperReminder,
  WrapperReminderDoubleUpload,
  WrapperButtonLine,
  WrapperButton,
  WrapperUpload,
  WrapperDownloadOneOrMoreFiles,
  StyledPlusCircleOutlinesIcon,
} from './styles';

interface EvidenceUploadProps {
  uploadType: UploadType;
  maxFileSize: number;
  maxPdfSize?: number;
  maxMultipleFiles: number;
  onFilesUpload: (filesToUpload: File[], category?: string) => void;
  onMaxFilesLimitReached: () => void;
  onFileDelete: (id: string) => void;
  onCancelFileUpload: (id: string) => void;
  onUploadSuccess: () => void;
  files: IFile[];
  evidenceSubType: string;
  processedImages: IImagesFile[] | undefined;
  borrowerType: string;
  evidenceType: string;
  isAnalyseSending: boolean;
}

const EvidenceUpload = ({
  uploadType,
  maxFileSize,
  maxPdfSize,
  onFilesUpload,
  onFileDelete,
  onMaxFilesLimitReached,
  onCancelFileUpload,
  onUploadSuccess,
  files,
  maxMultipleFiles,
  processedImages,
  isAnalyseSending,
}: EvidenceUploadProps): React.ReactElement => {
  // disable validate button during upload
  const [areAllFilesUploaded, setAllFilesUploaded] = useState<boolean>(false);
  const [isAddDocumentDisabled, setAddDocumentDisabled] =
    useState<boolean>(true);
  const [isRectoUploaded, setIsRectoUploaded] = usePersistingState<boolean>(
    'rectoUploaded',
    false
  );

  const onAddDocument = () => {
    setAddDocumentDisabled((prevAddDocument) => !prevAddDocument);
  };

  useEffect(() => {
    // disabling the add document button when there are no files or when we reach the max file number
    if (files.length === 0 || files.length === maxMultipleFiles) {
      setAddDocumentDisabled(true);
    }
  }, [files.length]);

  useEffect(() => {
    // wait for all documents to be uploaded to trigger the analyse
    const isOcrAnalyzeReady = files.reduce((isReady, file) => {
      if (file.status !== FileStatus.UPLOADED) {
        return false;
      }
      return isReady;
    }, true);

    if (
      isOcrAnalyzeReady &&
      uploadType === UploadType.DOUBLE &&
      containsRecto(files)
    ) {
      setIsRectoUploaded(true);
    } else {
      setIsRectoUploaded(false);
    }

    if (
      isOcrAnalyzeReady &&
      ((uploadType !== UploadType.DOUBLE && files.length > 0) ||
        (uploadType === UploadType.DOUBLE && files.length > 1))
    ) {
      setAllFilesUploaded(true);
    } else {
      setAllFilesUploaded(false);
    }
  }, [files]);

  const isMobileDevice = detectMobileDevice();
  const maxSize = `Formats acceptés  : .pdf ${
    maxPdfSize ? "(jusqu'à " + maxPdfSize + ' Mo)' : ''
  } ou .jpg, .jpeg et .png (jusqu'à ${maxFileSize} Mo)`;
  const downloadUpToFileNbMaxFiles = (fileNbMax: number): string =>
    `Téléchargez jusqu'à ${fileNbMax} fichiers`;
  return (
    <div>
      {uploadType === UploadType.SINGLE && (
        // Todo : créer composent parent qui reprend cette partie et lui filer juste les Props
        <>
          <WrapperReminder isMobileDevice={isMobileDevice}>
            {messages.reminder.title}
          </WrapperReminder>
          <WrapperUpload isMobileDevice={isMobileDevice}>
            <Upload
              subText={maxSize}
              isMobileDevice={isMobileDevice}
              fileNbMax={1}
              onFilesUpload={onFilesUpload}
              onFileDelete={onFileDelete}
              onMaxFilesLimitReached={onMaxFilesLimitReached}
              onCancelFileUpload={onCancelFileUpload}
              files={files}
              label=""
              name="single"
              processedImages={processedImages && Array.of(processedImages[0])}
              uploadType={uploadType}
            />
          </WrapperUpload>
          <WrapperButtonLine>
            <WrapperButton>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  onUploadSuccess();
                }}
                isDisabled={!areAllFilesUploaded || isAnalyseSending}
              >
                {messages.button.title}
              </Button>
            </WrapperButton>
          </WrapperButtonLine>
        </>
      )}
      {uploadType === UploadType.DOUBLE && (
        <>
          <WrapperReminderDoubleUpload>
            {messages.reminder.title}
          </WrapperReminderDoubleUpload>
          <WrapperUpload isMobileDevice={isMobileDevice} isDouble>
            <Upload
              isDisabled={isRectoUploaded}
              subText={maxSize}
              key="upload-recto"
              isMobileDevice={isMobileDevice}
              fileNbMax={1}
              onFilesUpload={(uploadingFiles) =>
                onFilesUpload(uploadingFiles, EvidenceFace.RECTO)
              }
              onMaxFilesLimitReached={onMaxFilesLimitReached}
              onFileDelete={(id) => onFileDelete(id)}
              onCancelFileUpload={(id) => onCancelFileUpload(id)}
              files={files.filter((file) => file.type === EvidenceFace.RECTO)}
              label={EvidenceFace.RECTO}
              name="recto"
              processedImages={processedImages && Array.of(processedImages[0])}
              uploadType={uploadType}
            />
            <Upload
              isDisabled={!isRectoUploaded || files.length < 1}
              subText={maxSize}
              key="upload-verso"
              isMobileDevice={isMobileDevice}
              fileNbMax={1}
              onFilesUpload={(uploadingFiles) =>
                onFilesUpload(uploadingFiles, EvidenceFace.VERSO)
              }
              onMaxFilesLimitReached={onMaxFilesLimitReached}
              onFileDelete={onFileDelete}
              onCancelFileUpload={onCancelFileUpload}
              files={files.filter((file) => file.type === EvidenceFace.VERSO)}
              label={EvidenceFace.VERSO}
              name="verso"
              processedImages={processedImages && Array.of(processedImages[1])}
              uploadType={uploadType}
            />
          </WrapperUpload>
          <WrapperButtonLine>
            <WrapperButton>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  onUploadSuccess();
                }}
                isDisabled={!areAllFilesUploaded || isAnalyseSending}
              >
                {messages.button.title}
              </Button>
            </WrapperButton>
          </WrapperButtonLine>
        </>
      )}
      {uploadType === UploadType.MULTIPLE && (
        <>
          <WrapperReminder isMobileDevice={isMobileDevice}>
            {messages.reminder.title}
          </WrapperReminder>
          <WrapperDownloadOneOrMoreFiles>
            {downloadUpToFileNbMaxFiles(maxMultipleFiles)}
          </WrapperDownloadOneOrMoreFiles>
          <WrapperUpload isUploadMultiple isMobileDevice={isMobileDevice}>
            <Upload
              subText={maxSize}
              isMobileDevice={isMobileDevice}
              fileNbMax={maxMultipleFiles}
              onMaxFilesLimitReached={onMaxFilesLimitReached}
              onFilesUpload={(filesToUpload: File[], category?: string) => {
                setAddDocumentDisabled(false);
                onFilesUpload(filesToUpload, category);
              }}
              onFileDelete={(id: string) => {
                if (files.length === maxMultipleFiles) {
                  setAddDocumentDisabled(false);
                }
                onFileDelete(id);
              }}
              onCancelFileUpload={onCancelFileUpload}
              files={files}
              label=""
              name="multiple"
              processedImages={processedImages}
              uploadType={uploadType}
              willShowUploadZone={
                isAddDocumentDisabled && files?.length < maxMultipleFiles
              }
            />
          </WrapperUpload>
          <WrapperButtonLine>
            <Button
              type="button"
              variant="secondary"
              onClick={onAddDocument}
              isDisabled={isAddDocumentDisabled}
            >
              <StyledPlusCircleOutlinesIcon />
              {messages.LABEL_BUTTON_SEND_DOCUMENT.title}
            </Button>
            <WrapperButton>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  onUploadSuccess();
                  setAddDocumentDisabled(false);
                }}
                isDisabled={!areAllFilesUploaded || isAnalyseSending}
              >
                {messages.button.title}
              </Button>
            </WrapperButton>
          </WrapperButtonLine>
        </>
      )}
    </div>
  );
};

export default EvidenceUpload;
