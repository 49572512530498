export const BEST_INSURANCE = 'Assurance conseillée';
export const DEATH_INSURANCE_LABEL = 'Décès';
export const INVALIDITY_INSURANCE_LABEL = 'Invalidité';
export const DISEASE_OR_ACCIDENT_INSURANCE_LABEL = 'Maladie ou accident';
export const UNEMPLOYMENT_INSURANCE_LABEL = 'Chômage';
export const NO_INSURANCE_LABEL = 'Aucune';
export const DESCRIPTION_DURATION_TITLE = 'Durée';
export const DESCRIPTION_DURATION_MONTH_LABEL = 'mois';
export const DESCRIPTION_PERCENT_SIGN = '%';
export const DESCRIPTION_TAEG_TITLE = 'TAEG révisable';
export const DESCRIPTION_TEG_TITLE = 'Taux débiteur révisable';
export const DESCRIPTION_INSURANCE_MONTHLY_PAYMENT_TITLE =
  "Coût mensuel de l'assurance facultative";
export const DESCRIPTION_INSURANCE_MONTHLY_PAYMENT_WITH_INSURANCE_TITLE =
  'Mensualité du crédit avec assurance';
export const DESCRIPTION_EURO_SIGN = '€';
export const DESCRIPTION_INSURANCE_TOTAL_TITLE =
  "Coût total de l'assurance facultative";
export const DESCRIPTION_TAEA_TITLE = 'TAEA';
export const DESCRIPTION_INTEREST_TITLE = 'Intérêts';
export const DESCRIPTION_TOTAL_TITLE = 'Montant total dû avec assurance';
