import { Button } from '@components';
import styled from 'styled-components';

export const WrapperButton = styled.div`
  width: fit-content;
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
`;

export const StyledConfirmOfferButton = styled(Button)``;
