import styled from 'styled-components';
import { Card, CheckBox, ButtonCheck } from '@components';
import { PeopleIcon, PersonIcon } from '@icons';

export const StyledCard = styled(Card.Wrapper)`
  padding-bottom: 2.9rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const WrapperButtonNext = styled.div`
  padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  width: 21.1rem;
`;

export const WrapperInputTypeLoan = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperInputMaterialCategory = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperPurchaseDeposit = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperInputPurchaseAmount = styled.div`
  display: flex;
  width: 50%;
  padding-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const WrapperInputDepositAmount = styled.div`
  display: flex;
  width: 50%;
  padding-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const WrapperInputLoanAmount = styled.div`
  display: flex;
  width: 49%;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperInputTermNb = styled.div`
  display: flex;
  width: 49%;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperInputPricing = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperInputDeferment = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperInputCondition = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledCheckBox = styled(CheckBox)`
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const WrapperBorrowers = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperInputBorrowers = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledButtonCheck = styled(ButtonCheck)<{ isChecked: boolean }>`
  display: flex;
  justify-content: center;
  width: 49%;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  color: ${({ theme, isChecked }) => {
    if (isChecked) {
      return theme.vendor.colors.primary;
    } else {
      return theme.variables.colors.chartColors.dark_700;
    }
  }};
`;

export const IconPerson = styled(PersonIcon)`
  height: 2.5rem;
`;

export const IconPeople = styled(PeopleIcon)`
  height: 2.5rem;
`;
