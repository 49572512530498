import React, { useRef, useEffect, useState, ReactNode } from 'react';
import { formatNumber } from '@utils';
import { ErrorMessage, Loader } from '@components';
import {
  Wrapper,
  StyledInput,
  StyledInputMask,
  StyledLabel,
  InputContainer,
  IconWrapper,
  IconBeforeWrapper,
  StyledCheckIcon,
  StyledWarningIcon,
  ButtonStyle,
  //StyledInformationToolTip,
  LabelToolTipStyle,
  StyledChild,
  LoaderWrapper,
} from './styles';

export interface IInputProps {
  name: string;
  label?: string;
  afterLabel?: string;
  type?: string;
  placeholder?: string;
  isDisabled?: boolean;
  className?: string;
  onChange?: (value: string | number) => void;
  onKeyPress?: (event: any) => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  after?: React.ReactNode;
  before?: React.ReactNode;
  value?: string | number | null;
  mask?: string;
  maskChar?: string;
  isValid?: boolean;
  isSubmitted?: boolean;
  isTouched?: boolean;
  isSymbolVisible?: boolean;
  inputRef?: any;
  inputWidth?: string;
  onClick?: () => void;
  isReadOnly?: boolean;
  onFocus?: () => void;
  role?: string;
  maxLength?: number;
  error?: string;
  informationToolTipMessage?: string;
  children?: ReactNode;
  isLoading?: boolean;
  tabIndex?: number; // if =-1, disable "tab" key from focusing the input
  prefixText?: string;
  suffixText?: string;
  inputMode?: React.HTMLAttributes<HTMLLIElement>['inputMode'];
  inputBackground?: string;
  keepValidation?: boolean;
  valueWithoutSpaces?: boolean;
  validateIfPrefilled?: (value?: string | number | null) => boolean;
  autoComplete?: 'off' | 'one-time-code' | 'new-password';
}

const Input = ({
  type = 'text',
  name,
  label,
  afterLabel,
  after,
  onChange = () => undefined,
  onKeyPress = (event) => {
    // Le champ perd le focus lorsque l'utilisateur clique sur entrée
    if (event?.charCode === 13) {
      event?.target?.blur();
    }
  },
  className = '',
  autoFocus = false,
  placeholder,
  value,
  isDisabled,
  inputMode,
  mask,
  maskChar,
  isValid,
  isSubmitted,
  isTouched = false,
  isSymbolVisible = false,
  before,
  onBlur = () => undefined,
  inputRef,
  inputWidth,
  onClick,
  isReadOnly = false,
  onFocus = () => undefined,
  role,
  maxLength = undefined,
  error,
  children,
  isLoading,
  tabIndex = 0,
  prefixText,
  suffixText,
  inputBackground,
  keepValidation = false,
  valueWithoutSpaces = true,
  validateIfPrefilled = () => true,
  autoComplete = 'off',
}: IInputProps): React.ReactElement => {
  const internalRef = useRef<HTMLInputElement>(null);
  const [valueFocus, setValueFocus] = useState(true);
  const handleBlur = () => {
    onBlur();
    if (!!value && valueWithoutSpaces) {
      onChange(value?.toString().trim());
    }
    setValueFocus(true);
  };

  // A l'initialisation du composant, on valide la valeur s'il y en a une
  useEffect(() => {
    if (
      !validateIfPrefilled(value) ||
      (!value && value !== 0) ||
      ((value + '').length ?? 0) < 1
    ) {
      return;
    }
    handleBlur();
  }, []);

  const onClickLabel = (e: { preventDefault: () => void }) => {
    // prevent giving focus on input if it is disabled
    if (isDisabled) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (inputRef && autoFocus) {
      inputRef?.current?.focus();
    }
  }, [inputRef, autoFocus]);

  useEffect(() => {
    if (internalRef?.current && autoFocus) {
      internalRef?.current?.focus();
    }
  }, [internalRef, autoFocus]);

  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e?.target?.blur();
    // Prevent the page/container scrolling
    e?.stopPropagation();
    // Refocus immediately, on the next tick (after the current
    setTimeout(() => {
      e?.target?.focus();
    }, 0);
  };

  const testValidNumberCharactere = ({
    event,
    inputMode = 'decimal',
    maxLength = 0,
    numberOfDecimals = 2,
  }: {
    event: any;
    inputMode?: string;
    maxLength?: number;
    numberOfDecimals?: number;
  }) => {
    if (
      event?.key?.length > 1 ||
      (inputMode !== 'decimal' && inputMode !== 'numeric') ||
      event?.key === 'Backspace'
    ) {
      return;
    }
    const value = event?.target?.value ?? '0';
    const limitToDecimals =
      ((value.split('.') ?? ['', ''])[1] ?? '').length + 1;
    const replaceNotNumericCharsRegexp =
      inputMode === 'decimal' ? /^[0-9,]$/g : /^[0-9]$/g;
    if (
      event?.key?.replace(replaceNotNumericCharsRegexp, '').length > 0 ||
      (inputMode === 'decimal' && limitToDecimals > numberOfDecimals) ||
      (maxLength && value.length + 1 > maxLength)
    ) {
      event?.preventDefault();
    }
  };

  const parseValue = (value: string, inputMode: string) => {
    if (value?.length <= 0) {
      return '';
    }
    if (inputMode === 'decimal') {
      return parseFloat(value?.replace(',', '.')) ?? '';
    }
    if (inputMode === 'numeric') {
      return parseInt(value) ?? '';
    }

    return value ?? '';
  };

  const needBlockDeadCharactere = (event: any) => {
    const nativeEventData = event?.nativeEvent?.data;
    return (
      nativeEventData === '^' ||
      nativeEventData === '`' ||
      nativeEventData === '¨' ||
      nativeEventData === undefined
    );
  };

  const changeApostrophe = (valueString: string) => {
    return valueString.replace(/[’`]/gi, "'");
  };

  const getInputComponent = (typeInput: string, inputMode?: string) => {
    if (typeInput === 'price') {
      return (
        <StyledInput
          tabIndex={tabIndex}
          id={name}
          type="text"
          ref={inputRef || internalRef}
          placeholder={placeholder}
          onBlur={handleBlur}
          onFocus={() => {
            onFocus();
            setValueFocus(false);
          }}
          value={
            value?.toString() ? formatNumber(parseFloat(value.toString())) : ''
          }
          onChange={(event) => {
            const newValue = event.target.value;
            if (newValue === '') {
              onChange(newValue);
            } else {
              const parsedValue = parseFloat(newValue.replace(/ /g, ''));
              if (parsedValue <= 999999999999999) {
                onChange(parsedValue);
              }
            }
          }}
          autoComplete={autoComplete}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          readOnly={isReadOnly || isDisabled}
          role={role}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
        />
      );
    }
    if (mask && (type === 'text' || type === 'tel' || type === 'password')) {
      return isReadOnly || isDisabled ? (
        <StyledInputMask
          tabIndex={tabIndex}
          id={name}
          className={'ph-input ' + className}
          type={type}
          defaultValue={value}
          placeholder={placeholder}
          mask={maskChar} // Ancien maskChar
          format={mask} // Ancien mask
          getInputRef={inputRef || internalRef}
          onBlur={handleBlur}
          onFocus={() => {
            onFocus();
            setValueFocus(false);
          }}
          autoComplete={autoComplete}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          readOnly
          role={role}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
        />
      ) : (
        <StyledInputMask
          tabIndex={tabIndex}
          id={name}
          className={'ph-input ' + className}
          type={type}
          value={value}
          placeholder={placeholder}
          mask={maskChar} // Ancien maskChar
          format={mask} // Ancien mask
          getInputRef={inputRef || internalRef}
          onBlur={handleBlur}
          onFocus={() => {
            onFocus();
            setValueFocus(false);
          }}
          onChange={(event: { target: { value: string | number } }) =>
            onChange(event.target.value)
          }
          autoComplete={autoComplete}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          role={role}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
        />
      );
    }

    if (type === 'number') {
      return (
        <StyledInput
          step={'any'}
          tabIndex={tabIndex}
          id={name}
          className={'ph-input ' + className}
          type={'number'}
          ref={inputRef || internalRef}
          value={parseValue(value?.toString() ?? '', inputMode ?? 'decimal')}
          placeholder={placeholder}
          onBlur={handleBlur}
          onFocus={() => {
            onFocus();
            setValueFocus(false);
          }}
          onChange={(event) => {
            const newValue = needBlockDeadCharactere(event)
              ? value + ''
              : event?.target?.value;
            onChange(changeApostrophe(newValue));
          }}
          autoComplete={autoComplete}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          readOnly={isReadOnly || isDisabled}
          role={role}
          maxLength={maxLength}
          autoFocus={autoFocus}
          onKeyDown={(event) =>
            testValidNumberCharactere({ event, inputMode, maxLength })
          }
          onWheel={numberInputOnWheelPreventChange}
          onKeyPress={onKeyPress}
        />
      );
    }

    return (
      <StyledInput
        tabIndex={tabIndex}
        id={name}
        className={'ph-input ' + className}
        type={type}
        ref={inputRef || internalRef}
        value={value ?? ''}
        placeholder={placeholder}
        onBlur={handleBlur}
        onFocus={() => {
          onFocus();
          setValueFocus(false);
        }}
        onChange={(event) => onChange(changeApostrophe(event.target.value))}
        autoComplete={autoComplete}
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        readOnly={isReadOnly || isDisabled}
        role={role}
        maxLength={maxLength}
        autoFocus={autoFocus}
        onKeyPress={onKeyPress}
      />
    );
  };

  const isValidationEnabled =
    (!isDisabled || keepValidation) && !isLoading && (isSubmitted || isTouched);

  return (
    <Wrapper className={'ph-input ' + className}>
      <LabelToolTipStyle>
        {label && (
          <StyledLabel htmlFor={name} onClick={onClickLabel} after={afterLabel}>
            {label}
          </StyledLabel>
        )}
        {/*informationToolTipMessage && (
                    <StyledInformationToolTip>
                        {informationToolTipMessage}
                    </StyledInformationToolTip>
                )*/}
      </LabelToolTipStyle>

      <ButtonStyle>
        <InputContainer
          isClickable={!!onClick}
          withValidationStyle={isValidationEnabled}
          isValid={isValid}
          isSubmitted={!!isSubmitted}
          inputWidth={inputWidth}
          isTouched={isTouched}
          isSymbolVisible={isSymbolVisible}
          isDisabled={isDisabled}
          inputBackground={inputBackground}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          isFocused={!valueFocus}
        >
          {prefixText && <IconWrapper>{prefixText}</IconWrapper>}
          {before && <IconBeforeWrapper>{before}</IconBeforeWrapper>}
          {getInputComponent(type, inputMode)}
          {isValidationEnabled && isValid && !isSymbolVisible && !after && (
            <IconWrapper>
              <StyledCheckIcon />
            </IconWrapper>
          )}
          {isValidationEnabled && !isValid && !isSymbolVisible && !after && (
            <IconWrapper>
              <StyledWarningIcon />
            </IconWrapper>
          )}
          {isLoading && !isSymbolVisible && !after && (
            <IconWrapper>
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            </IconWrapper>
          )}
          {after && <IconWrapper>{after}</IconWrapper>}
          {suffixText && <IconWrapper>{suffixText}</IconWrapper>}
        </InputContainer>

        {children && <StyledChild>{children}</StyledChild>}
      </ButtonStyle>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};

export default Input;
