import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import './index.css';
import { App } from './layouts';
import store from '@store';
import { StoreProvider } from '@components';
import { getStrictMode, getURLParams } from '@utils';

const searchParams = getURLParams();
const mockParam = searchParams.get('mock');

const ReactModeHandler = ({
  children,
}: {
  children?: React.ReactNode;
}): React.ReactElement => {
  const HandlerElement = getStrictMode() ? React.StrictMode : React.Fragment;
  return <HandlerElement>{children}</HandlerElement>;
};

const prepare = async () => {
  if (process.env.REACT_APP_ENABLE_MOCKS === 'true' || mockParam) {
    const browser = await import('./mocks/browser');

    return browser.default.worker.start({
      onUnhandledRequest: 'bypass',
      serviceWorker: {
        url: '/mockServiceWorker.js',
        options: {
          // Narrow the scope of the Service Worker to intercept requests
          // only from pages under this path.
          scope: '/', // TODO scope when it will be defined
        },
      },
    });
  }
  return Promise.resolve();
};

document
  .getElementById('prepare-env')
  ?.addEventListener('prepare-env-loaded', () => {
    prepare().then(() => {
      const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
      );
      root.render(
        <ReactModeHandler>
          <HashRouter>
            <StoreProvider store={store}>
              <App />
            </StoreProvider>
          </HashRouter>
        </ReactModeHandler>
      );
    });
  });
