import * as messages from './messages';
import { RadioList } from '@components';
import { StyledInput, ItemWrapper, FormWrapper } from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import {
  selectPerson,
  useAppSelector,
  personTypes,
  selectBankDetails,
} from '@store';
import {
  ibanRegExp,
  bicRegExp,
  dateMonthYearMask,
  ibanFormatter,
  bicFormatter,
  isPersonFieldDisabled,
} from '@utils';
import {
  MobileKeyboardType,
  EvidenceType,
  CodesErreurRib,
  ProprietaireCompte,
} from '@types';
import useInformationsForm from '../useInformationsForm';
import { useBorrowers, useValidateInput } from '@hooks';
import { useEffect } from 'react';
import dayjs from 'dayjs';

interface IProps {
  role: string;
  errorRib: string | null;
  setErrorRib: React.Dispatch<React.SetStateAction<string | null>>;
}

const BankingInformation: React.FC<IProps> = ({
  role,
  errorRib,
  setErrorRib,
}) => {
  const { control } = useFormContext();

  const persons = useAppSelector(selectPerson);
  const person =
    role === personTypes.RoleCd.BORROWER
      ? persons?.emprunteur
      : persons?.coemprunteur;

  const bankDetails = useAppSelector(selectBankDetails);
  const { hasCoborrower } = useBorrowers();
  const { trigger } = useFormContext();
  const { shouldValidateInput } = useValidateInput();

  useEffect(() => {
    if (!bankDetails) {
      return;
    }
    if (bankDetails?.accountOwner) {
      shouldValidateInput('inputAccountOwner', bankDetails?.accountOwner);
    }
    shouldValidateInput('iban', bankDetails?.iban);
    shouldValidateInput('bic', bankDetails?.bic);
    if (bankDetails?.accountOpeningDt) {
      shouldValidateInput(
        'accountOpeningDt',
        dayjs(bankDetails?.accountOpeningDt).format('MM / YYYY')
      );
    }
  }, [bankDetails]);

  const listAccountOwner = [
    { id: ProprietaireCompte.EMPRUNTEUR_PRINCIPAL, label: 'Emprunteur' },
    { id: ProprietaireCompte.CO_EMPRUNTEUR, label: 'Co-emprunteur' },
    { id: ProprietaireCompte.COMPTE_JOINT, label: 'Compte joint' },
  ];

  const { onValidateAccountOpeningDate } = useInformationsForm({ role });

  return (
    <FormWrapper>
      {/* ACCOUNT OWNER */}
      <ItemWrapper isHidden={!hasCoborrower()}>
        <Controller
          control={control}
          name={'inputAccountOwner'}
          defaultValue={bankDetails?.accountOwner}
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid },
          }) => (
            <RadioList
              name={name}
              valueList={listAccountOwner?.map((item) => item.id) ?? []}
              isColorLabelForm
              label={messages.LABEL_ACCOUNT_OWNER}
              options={listAccountOwner ?? []}
              value={value}
              onChange={(value) => {
                onChange(value);
                trigger('accountOpeningDt');
              }}
              isValid={!invalid}
              isGrid
              isDisabled={isPersonFieldDisabled(
                bankDetails?.accountOwner,
                person,
                EvidenceType.JBAN
              )}
              inputRef={ref}
            />
          )}
        />
      </ItemWrapper>

      {/* IBAN */}
      <ItemWrapper>
        <Controller
          control={control}
          name={'iban'}
          defaultValue={bankDetails?.iban}
          rules={{
            required: true,
            pattern: ibanRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <StyledInput
              name={name}
              label={messages.LABEL_IBAN}
              type="text"
              value={value}
              onChange={(value) => {
                setErrorRib(null);
                onChange(ibanFormatter(value.toString()));
              }}
              isTouched={isTouched}
              isValid={
                !invalid &&
                errorRib !== CodesErreurRib.IBAN_KO &&
                errorRib !== CodesErreurRib.SEPA_KO
              }
              onBlur={onBlur}
              inputRef={ref}
              maxLength={42} // 34 + 8 espaces
              isSubmitted={isSubmitted}
              error={
                (invalid && isTouched) || errorRib === CodesErreurRib.IBAN_KO
                  ? messages.ERROR_IBAN
                  : errorRib === CodesErreurRib.SEPA_KO
                  ? messages.ERROR_SEPA
                  : ''
              }
              isDisabled={isPersonFieldDisabled(
                bankDetails?.iban,
                person,
                EvidenceType.JBAN
              )}
            />
          )}
        />
      </ItemWrapper>

      {/* BIC */}
      <ItemWrapper>
        <Controller
          control={control}
          name={'bic'}
          defaultValue={bankDetails?.bic}
          rules={{
            required: true,
            pattern: bicRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <StyledInput
              name={name}
              label={messages.LABEL_BIC}
              type="text"
              value={value}
              onChange={(value) => {
                setErrorRib(null);
                onChange(bicFormatter(value.toString()));
              }}
              isTouched={isTouched}
              isValid={
                !invalid &&
                errorRib !== CodesErreurRib.BIC_KO &&
                errorRib !== CodesErreurRib.SEPA_KO
              }
              onBlur={onBlur}
              maxLength={11}
              inputRef={ref}
              isSubmitted={isSubmitted}
              error={
                (invalid && isTouched) || errorRib === CodesErreurRib.BIC_KO
                  ? messages.ERROR_BIC
                  : errorRib === CodesErreurRib.SEPA_KO
                  ? messages.ERROR_SEPA
                  : ''
              }
              isDisabled={isPersonFieldDisabled(
                bankDetails?.bic,
                person,
                EvidenceType.JBAN
              )}
            />
          )}
        />
      </ItemWrapper>

      {/* DATE D'OUVERTURE DU COMPTE BANCAIRE */}
      <ItemWrapper>
        <Controller
          control={control}
          name={'accountOpeningDt'}
          defaultValue={
            bankDetails?.accountOpeningDt &&
            dayjs(bankDetails?.accountOpeningDt).format('MM / YYYY')
          }
          rules={{
            required: true,
            validate: onValidateAccountOpeningDate,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <StyledInput
              inputMode={MobileKeyboardType.NUMERIC}
              name={name}
              label={messages.LABEL_OPENING_DATE}
              placeholder={messages.PLACEHOLDER_OPENING_DATE}
              type="text"
              mask={dateMonthYearMask}
              maskChar=" "
              value={value}
              onChange={(value) => {
                onChange(value);
              }}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              inputWidth="17rem"
              isSubmitted={isSubmitted}
              error={invalid && isTouched ? messages.ERROR_OPENING_DATE : ''}
            />
          )}
        />
      </ItemWrapper>
    </FormWrapper>
  );
};

export default BankingInformation;
