import { Title } from '@components';
import WrapperAssurance from './styles';
import * as messages from './messages';
import { Insurance as AssuranceContainer } from '@containers';

export const Insurance: React.FC = () => {
  return (
    <WrapperAssurance>
      <Title>{messages.LAYOUT_TITLE}</Title>

      <AssuranceContainer />
    </WrapperAssurance>
  );
};

export default Insurance;
