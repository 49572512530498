import { FetchArgs, createApi } from '@reduxjs/toolkit/query/react';
import { IEndPoints, getDynamicQueriesWithRetries } from '../rtkQueryUtils';
import {
  ISimulationDetailleResponse,
  ISimulationDetailleeRequest,
  ISimulationSimpleRequest,
  ISimulationSimpleResponse,
} from './simulationApiTypes';

const simulationApiSlice = createApi({
  reducerPath: 'simulationApi',
  baseQuery: getDynamicQueriesWithRetries(IEndPoints.simulation),
  endpoints: (builder) => ({
    sendSimulationSimple: builder.mutation<
      ISimulationSimpleResponse,
      {
        simulationRequest: ISimulationSimpleRequest;
        apimAccessToken?: string;
        authInitToken?: string;
        requestId?: string;
      }
    >({
      query: ({
        simulationRequest,
        apimAccessToken,
        authInitToken,
        requestId,
      }): FetchArgs => {
        const theQuery: {
          url: string;
          method: string;
          headers?: any;
          credentials: any;
          body: ISimulationSimpleRequest;
        } = {
          url: `simulations/calcul-simple`,
          method: 'POST',
          credentials: 'include',
          body: {
            ...simulationRequest,
          },
        };
        if (apimAccessToken) {
          theQuery.headers = {
            'apim-access-token': apimAccessToken,
          };
        }
        if (authInitToken) {
          theQuery.headers = {
            'auth-init-token': authInitToken,
          };
        }
        if (requestId) {
          theQuery.headers = {
            ...theQuery.headers,
            'requestId-SOAH': requestId,
          };
        }
        return theQuery;
      },
    }),
    sendSimulationDetaillee: builder.mutation<
      ISimulationDetailleResponse,
      { simulationRequest: ISimulationDetailleeRequest }
    >({
      query: ({ simulationRequest }) => ({
        url: `simulations/calcul-detaillee`,
        method: 'POST',
        credentials: 'include',
        body: {
          ...simulationRequest,
        },
      }),
    }),
  }),
});

export default simulationApiSlice;
export const {
  useSendSimulationSimpleMutation,
  useSendSimulationDetailleeMutation,
} = simulationApiSlice;
export const simulationApiReducer = simulationApiSlice.reducer;
export const simulationApiMiddleware = simulationApiSlice.middleware;
