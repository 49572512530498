import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GlobalStyles } from '@styles';
import { Badge, Centering, ErrorBoundary, Theme } from '@components';
import './App.css';
import {
  Footer,
  Header,
  ParcoursSimulation,
  useOfferDescriptionReAuth,
} from '@containers';
import {
  AppWrapper,
  BreadcrumbWrapper,
  StyledBreadcrumb,
  FormationModeBadgeWrapper,
  FooterWrapper,
  StyledCentering,
  StyledLoader,
} from './styles';
import { LABEL_FORMATION_MODE } from './messages';
import {
  useNavigate,
  IStep,
  useRedirection,
  useSendLogs,
  useParcoursType,
  useDetectMobileDevice,
} from '@hooks';
import {
  Informations,
  SelectOffer,
  Insurance,
  Summary,
  Signature,
  Documents,
  Choice,
  VerificationContactDetails,
} from '@layouts';
import { selectConfiguration, useAppSelector } from '@store';
import useInitApp from './useInitApp';

const FormationModeWrapper = () => (
  <>
    <div className="formation-mode-top" />
    <div className="formation-mode-bottom" />
  </>
);

const App: React.FC = () => {
  const {
    theme,
    isFormation,
    isSimu,
    vendorId,
    urlFinSouscription,
    marqueBlanche,
    isRedirectable,
    resultGetParameters,
    isInitializingByReauth,
  } = useInitApp();

  useSendLogs();

  const [currentStep, setCurrentStep] = useState(0);
  const location = useLocation();
  const { steps, getCurrentStepIndex } = useNavigate();
  const { redirigerAccueil, logout } = useRedirection({
    urlFinSouscription,
  });
  const { isParcoursSwitchDevice } = useParcoursType();
  const { isMobileScreenSize } = useDetectMobileDevice();
  const { reauth: onSelectOfferReauthentication } = useOfferDescriptionReAuth();
  const { isLoading } = useAppSelector(selectConfiguration);

  useEffect(() => {
    setCurrentStep(getCurrentStepIndex());
    window.scrollTo(0, 0);
  }, [location, window.location?.hash]);

  const getByPath = (
    path: string
  ): {
    component: React.FC;
    onReauthentication?: (forceReauth?: boolean) => void;
  } => {
    switch (path) {
      case '/offre':
        return {
          component: SelectOffer,
          onReauthentication: onSelectOfferReauthentication,
        };
      case '/informations':
        return { component: Informations };
      case '/assurance':
        return { component: Insurance };
      case '/recapitulatif':
        return { component: Summary };
      case '/signature':
        return { component: Signature };
      case '/signature/choix':
        return { component: Choice };
      case '/signature/verification':
        return { component: VerificationContactDetails };
      case '/documents':
        return { component: Documents };
      default:
        return { component: SelectOffer };
    }
  };

  useEffect(() => {
    //Reprise de parcours avec ping
    if (!isInitializingByReauth) {
      return;
    }
    (getByPath(location?.pathname)?.onReauthentication ?? (() => {}))(
      isInitializingByReauth
    );
  }, [isInitializingByReauth]);

  // Mode formation
  if (isFormation) {
    document.body.classList.add('formation-mode');
  }

  return (
    <>
      {theme && (
        <Theme theme={theme}>
          {isFormation && <FormationModeWrapper />}
          <StyledLoader
            isLoading={
              !!(
                (resultGetParameters?.isLoading || isLoading) &&
                location.pathname !== '/offre'
              )
            }
          >
            <AppWrapper className="app" isSimu={isSimu}>
              {isSimu ? (
                <ParcoursSimulation
                  vendorName={marqueBlanche ? '' : theme?.vendor?.logo}
                />
              ) : (
                <>
                  {
                    <StyledCentering>
                      <ErrorBoundary>
                        <div>
                          <Header
                            vendorId={vendorId}
                            vendorName={
                              marqueBlanche ? '' : theme?.vendor?.logo
                            }
                            redirigerAccueil={redirigerAccueil}
                            logout={logout}
                          />
                          {(!isParcoursSwitchDevice() ||
                            !isMobileScreenSize()) && (
                            <BreadcrumbWrapper>
                              <StyledBreadcrumb
                                steps={steps}
                                currentStep={currentStep}
                              />
                            </BreadcrumbWrapper>
                          )}
                          {isFormation && (
                            <FormationModeBadgeWrapper>
                              <Badge variant="withDot">
                                {LABEL_FORMATION_MODE}
                              </Badge>
                            </FormationModeBadgeWrapper>
                          )}
                        </div>
                        <Routes>
                          {steps.map((step: IStep) =>
                            step.paths.map((path) => {
                              const StepComponent = getByPath(path).component;
                              return (
                                <Route
                                  key={path}
                                  path={path}
                                  element={<StepComponent />}
                                />
                              );
                            })
                          )}
                        </Routes>

                        <GlobalStyles />
                      </ErrorBoundary>
                      <Centering>
                        <FooterWrapper>
                          <Footer />
                        </FooterWrapper>
                      </Centering>
                    </StyledCentering>
                  }
                </>
              )}
              <div id="modal"></div>
            </AppWrapper>
          </StyledLoader>
        </Theme>
      )}
    </>
  );
};

export default App;
