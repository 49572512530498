import { Icons, ModalContent } from '@components';
import * as messages from './messages';

interface MaxFilesLimitReachedProps {
  onButtonClick: () => void;
  maxFilesLimit: number;
}

export const MaxFilesLimitReached: React.FC<MaxFilesLimitReachedProps> = ({
  onButtonClick = () => undefined,
  maxFilesLimit = 4,
}): React.ReactElement => {
  return (
    <ModalContent
      icon={<Icons.UploadKoIcon />}
      title={messages.TITLE_MAX_FILES_LIMIT_REACHED}
      text={
        <>
          {messages.TEXT_START_MAX_FILES_LIMIT_REACHED}
          <br />
          <strong>
            {messages.TEXT_BOLD_MAX_FILES_LIMIT_REACHED(maxFilesLimit)}
          </strong>
        </>
      }
      tertiaryButtonLabel={messages.RELOAD_MAX_FILES_LIMIT_REACHED}
      onTertiaryButtonClick={onButtonClick}
    />
  );
};

export default MaxFilesLimitReached;
