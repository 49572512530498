const SvgCheckCircle2: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 32 32" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16,2.6666666 C23.3637967,2.6666666 29.3333334,8.63620329 29.3333334,16 C29.3333334,23.3637967 23.3637967,29.3333334 16,29.3333334 C8.63620329,29.3333334 2.6666666,23.3637967 2.6666666,16 C2.6666666,8.63620329 8.63620329,2.6666666 16,2.6666666 Z M16,4.33333327 C9.55667788,4.33333327 4.33333327,9.55667788 4.33333327,16 C4.33333327,22.4433221 9.55667788,27.6666667 16,27.6666667 C22.4433221,27.6666667 27.6666667,22.4433221 27.6666667,16 C27.6666667,9.55667788 22.4433221,4.33333327 16,4.33333327 Z M22.1919192,11.0237349 C22.5826257,11.4140769 22.5829212,12.0472418 22.1925792,12.4379483 L15.3741788,20.5258723 C14.9837172,20.9166986 14.3503157,20.9168569 13.9596587,20.5262257 L10.2596587,16.8264701 C9.86912151,16.4359588 9.86910059,15.8027938 10.259612,15.4122566 C10.6501234,15.0217194 11.2832884,15.0216985 11.6738255,15.4122099 L14.6663887,18.4045753 L20.7777058,11.0243949 C21.1680478,10.6336884 21.8012127,10.6333929 22.1919192,11.0237349 Z"
    />
  </svg>
);

export default SvgCheckCircle2;
