import React from 'react';
import { SkinType } from '@types';
import { WarningIcon } from '../Icons';
import {
  StyledWrapperWarningMessage,
  StyledWarningMessage,
  StyledAdviceIcon,
  Wrapper,
  StyledTitleWrapper,
  Title,
} from './styles';

export interface IInformationsBlockProps {
  children?: React.ReactNode;
  isVisible?: boolean;
  skin?: SkinType;
  hasArrowDown?: boolean;
  className?: string;
  icon?: React.FC;
  title?: string;
  titleColor?: string;
}

const Icon = ({
  skin = 'advice',
  icon,
}: {
  skin?: SkinType;
  className?: string;
  icon?: React.FC;
}): React.ReactElement => {
  const isVisible =
    icon ||
    skin === 'warning' ||
    skin === 'advice' ||
    skin === 'info' ||
    skin === 'primary';
  const getAs = () => {
    if (icon) {
      return icon;
    }
    if (skin === 'warning') {
      return WarningIcon;
    }
  };
  return <>{isVisible && <StyledAdviceIcon skin={skin} as={getAs()} />}</>;
};

const InformationsBlock = ({
  children,
  isVisible = false,
  hasArrowDown = true,
  skin = 'advice',
  className = '',
  icon,
  title,
  titleColor,
}: IInformationsBlockProps): React.ReactElement => {
  return (
    <Wrapper isVisible={isVisible}>
      <StyledWrapperWarningMessage
        skin={skin}
        className={'ph-informations-block ' + className}
        hasArrowDown={hasArrowDown}
        isVisible={isVisible}
      >
        <StyledTitleWrapper>
          {icon && <Icon skin={skin} icon={icon} />}
          <Title color={titleColor}>{title}</Title>
        </StyledTitleWrapper>
        <StyledWarningMessage>{children}</StyledWarningMessage>
      </StyledWrapperWarningMessage>
    </Wrapper>
  );
};

export default InformationsBlock;
