export const countriesListSepa = [
  { id: 'DE', label: 'Allemagne' },
  { id: 'AT', label: 'Autriche' },
  { id: 'BE', label: 'Belgique' },
  { id: 'BG', label: 'Bulgarie' },
  { id: 'CY', label: 'Chypre' },
  { id: 'HR', label: 'Croatie' },
  { id: 'DK', label: 'Danemark' },
  { id: 'ES', label: 'Espagne' },
  { id: 'EE', label: 'Estonie' },
  { id: 'FI', label: 'Finlande' },
  { id: 'FR', label: 'France' },
  { id: 'GR', label: 'Grèce' },
  { id: 'HU', label: 'Hongrie' },
  { id: 'IE', label: 'Irlande' },
  { id: 'IT', label: 'Italie' },
  { id: 'GB', label: 'Royaume-Uni' },
  { id: 'GI', label: 'Gibraltar' },
  { id: 'NL', label: 'Les Pays-Bas' },
  { id: 'LV', label: 'Lettonie' },
  { id: 'LI', label: 'Liechtenstein' },
  { id: 'LT', label: 'Lituanie' },
  { id: 'LU', label: 'Luxembourg' },
  { id: 'MT', label: 'Malte' },
  { id: 'MC', label: 'Monaco' },
  { id: 'NO', label: 'Norvège' },
  { id: 'PL', label: 'Pologne' },
  { id: 'PT', label: 'Portugal' },
  { id: 'RO', label: 'Roumanie' },
  { id: 'CZ', label: 'République Tchèque' },
  { id: 'SM', label: 'Saint-Marin' },
  { id: 'SK', label: 'Slovaquie' },
  { id: 'SI', label: 'Slovénie' },
  { id: 'CH', label: 'Suisse' },
  { id: 'SE', label: 'Suède' },
  { id: 'VA', label: 'Etat de la Cité du Vatican' },
  { id: 'IS', label: 'Islande' },
  { id: 'AD', label: 'Andorre' },
];
