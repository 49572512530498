const SvgSortAZ: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 5.5a.5.5 0 01.492.41L18.5 6v10.793l3.146-3.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-3.812 3.811a.767.767 0 01-.998.074l-.086-.074-3.812-3.811a.5.5 0 01.638-.765l.07.057 3.146 3.147V6a.5.5 0 01.5-.5zM7.333 16.833a.5.5 0 01.09.992l-.09.008H2a.5.5 0 01-.09-.992l.09-.008h5.333zM10 11.5a.5.5 0 01.09.992L10 12.5H2a.5.5 0 01-.09-.992L2 11.5h8zm2.667-5.333a.5.5 0 01.09.992l-.09.008H2a.5.5 0 01-.09-.992L2 6.167h10.667z"
    />
  </svg>
);

export default SvgSortAZ;
