export enum ProprietaireCompte {
  EMPRUNTEUR_PRINCIPAL = 'EMPRUNTEUR_PRINCIPAL',
  CO_EMPRUNTEUR = 'COEMPRUNTEUR',
  COMPTE_JOINT = 'JOINT',
}

export enum CodesErreurRib {
  IBAN_KO = 'CONTROLE_IBAN_KO',
  BIC_KO = 'CONTROLE_BIC_KO',
  SEPA_KO = 'CONTROLE_SEPA_KO',
}
