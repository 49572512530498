const SvgChrono: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.5 2h-5a.5.5 0 000 1h5a.5.5 0 100-1zm-2.505 12a.5.5 0 00.5-.5v-5a.5.5 0 00-1 0v5a.5.5 0 00.5.5zm6.677-7.146l.937-.936a.624.624 0 00-.043-.921.75.75 0 00-1.01.047l-.936.936A8.962 8.962 0 0012 4a9 9 0 00-9 9c0 4.97 4.02 9 9 9s9-4.03 9-9c0-2.12-.908-4.366-2.328-6.146zM12 21c-4.423 0-8-3.577-8-8s3.577-8 8-8 8 3.577 8 8-3.577 8-8 8z"
    />
  </svg>
);

export default SvgChrono;
