const SvgLetter: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.34 4h15.32C20.952 4 22 5.01 22 6.256v11.488C22 18.99 20.952 20 19.66 20H4.34C3.048 20 2 18.99 2 17.744V6.256C2 5.01 3.048 4 4.34 4zM3.29 6.095l7.714 6.69a1.53 1.53 0 001.992 0l7.716-6.68c.007.049.011.1.011.151v11.488c0 .566-.476 1.025-1.063 1.025H4.34c-.587 0-1.063-.459-1.063-1.025V6.256c0-.054.004-.108.013-.16zm16.37-.864c.047 0 .093.003.139.008l-7.656 6.63a.219.219 0 01-.285 0L4.211 5.24c.043-.006.086-.008.13-.008z"
    />
  </svg>
);

export default SvgLetter;
