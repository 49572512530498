import styled from 'styled-components';
import { MaximizeIcon } from '../../Icons';

export const StyledWrapper = styled.div`
  width: inherit;
  float: right;
  display: inline-flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const StyledEnlargeCard = styled.div`
  box-shadow: ${({ theme }) => theme.variables.colors.shadows[15]};
  background-color: ${({ theme }) => theme.variables.colors.chartColors.white};
  height: 4rem;
  border: 0rem;
  border-radius: 0rem 0rem
    ${({ theme }) => theme.variables.layout.spacing.spacing_xxs}
    ${({ theme }) => theme.variables.layout.spacing.spacing_xxs};
  display: block;
  align-items: center;
  padding: 0rem 0.85rem 0rem 1.15rem;
  &:hover {
    ${({ theme }) => `background-color: ${theme.vendor.colors.lightest};`};
    cursor: pointer;
  }
`;

export const StyledEnlargeText = styled.p`
  font-weight: normal;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  margin: 0rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;

export const StyledMaximizeIcon = styled(MaximizeIcon)`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  width: 2.4rem;
  margin-left: 0.5rem;
`;
