import { Card, Icons, ModalContent } from '@components';
import styled from 'styled-components';

export const StyledCardWrapper = styled(Card.Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const StyledModalContent = styled(ModalContent)`
  padding: 0;
`;

export const StyledContractIcon = styled(Icons.ContractIcon)`
  width: 2.5rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const StyledText = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  text-align: left;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledTextBold = styled(StyledText)`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;
