const SvgPartners: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.609 9.826c1.2 0 2.174.973 2.174 2.174v4.217c0 .793-.643 1.435-1.435 1.435h-.305v2.913c0 .793-.642 1.435-1.434 1.435H10.39a1.435 1.435 0 01-1.434-1.435v-3.782h-.305a1.435 1.435 0 01-1.435-1.435V12c0-1.201.973-2.174 2.174-2.174h5.218zm0 .87H9.39c-.72 0-1.304.583-1.304 1.304v3.348c0 .312.253.565.565.565h.74c.24 0 .434.195.434.435v4.217c0 .312.253.565.565.565h3.218a.565.565 0 00.565-.565v-3.348c0-.24.195-.434.435-.434h.739a.565.565 0 00.565-.566V12c0-.72-.584-1.304-1.304-1.304zm-9.566-.87a.435.435 0 010 .87h-.87c-.72 0-1.303.583-1.303 1.304v3.348c0 .312.253.565.565.565h.739c.24 0 .435.195.435.435v2.478c0 .312.253.565.565.565h1.609a.435.435 0 010 .87h-1.61a1.435 1.435 0 01-1.434-1.435v-2.043h-.304A1.435 1.435 0 012 15.348V12c0-1.201.973-2.174 2.174-2.174zm14.783 0C21.027 9.826 22 10.8 22 12v3.348c0 .792-.642 1.435-1.435 1.435h-.304v2.043c0 .792-.643 1.435-1.435 1.435h-1.609a.435.435 0 110-.87h1.61a.565.565 0 00.564-.565v-2.478c0-.24.195-.435.435-.435h.74a.565.565 0 00.564-.565V12c0-.72-.583-1.304-1.304-1.304h-.87a.435.435 0 110-.87zM5.043 3.74a2.173 2.173 0 11.001 4.347 2.173 2.173 0 010-4.347zm13.914 0a2.173 2.173 0 110 4.347 2.173 2.173 0 010-4.347zM12 2a3.043 3.043 0 110 6.087A3.043 3.043 0 0112 2zM5.043 4.609a1.304 1.304 0 100 2.607 1.304 1.304 0 000-2.607zm13.914 0a1.304 1.304 0 10-.001 2.607 1.304 1.304 0 000-2.607zM12 2.869a2.174 2.174 0 100 4.348 2.174 2.174 0 000-4.347z"
    />
  </svg>
);

export default SvgPartners;
