const ArrowLeftCircleIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.24999995,11.9999999 C3.24999995,16.8324915 7.16750839,20.7499999 12,20.7499999 C16.8324915,20.7499999 20.75,16.8324915 20.75,11.9999999 C20.75,7.16750839 16.8324915,3.24999995 12,3.24999995 C7.16750839,3.24999995 3.24999995,7.16750839 3.24999995,11.9999999 Z M22,11.9999999 C22,17.5228474 17.5228474,21.9999999 12,21.9999999 C6.47715245,21.9999999 1.99999995,17.5228474 1.99999995,11.9999999 C1.99999995,6.47715245 6.47715245,1.99999995 12,1.99999995 C17.5228474,1.99999995 22,6.47715245 22,11.9999999 Z M16.375,11.3749999 C16.7201779,11.3749999 17,11.654822 17,11.9999999 C17,12.3451779 16.7201779,12.6249999 16.375,12.6249999 L9.13374995,12.6249999 L11.8175,15.3074999 C11.97559,15.46559 12.0373312,15.6960112 11.9794662,15.9119662 C11.9216012,16.1279212 11.7529212,16.2966012 11.5369662,16.3544662 C11.3210112,16.4123312 11.09059,16.35059 10.9325,16.1924999 L7.18249995,12.4424999 C7.06494782,12.3252446 6.99888272,12.166034 6.99888272,11.9999999 C6.99888272,11.8339659 7.06494782,11.6747553 7.18249995,11.5574999 L10.9325,7.80749995 C11.176886,7.56311395 11.5731139,7.56311395 11.8175,7.80749995 C12.061886,8.05188595 12.061886,8.44811395 11.8175,8.69249995 L9.13374995,11.3749999 L16.375,11.3749999 Z"
    ></path>
  </svg>
);

export default ArrowLeftCircleIcon;
