import Styled from 'styled-components';
import { StyledCheckmark } from '../RadioButton/styles';

export const StyledWrapper = Styled.div`
  box-shadow: ${({ theme }) => theme.variables.colors.shadows[15]};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
`;

export const StyledHeader = Styled.div<{
  isClickable: boolean;
  isActive: boolean;
}>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  padding: 2.8rem ${({ theme }) =>
    theme.variables.layout.spacing.spacing_m} 2.8rem ${({ theme }) =>
  theme.variables.layout.spacing.spacing_s};
  background-color: ${({ isActive, theme }) =>
    !isActive ? theme.vendor.colors.light : 'white'};
  transition: background-color 0.2s;
  ${({ isClickable }) => isClickable && 'cursor: pointer;'}
  border-top-left-radius: ${({ theme }) =>
    theme.variables.layout.borderRadius.radius_s};
  border-top-right-radius: ${({ theme }) =>
    theme.variables.layout.borderRadius.radius_s};
  border-bottom-left-radius: ${({ isActive, theme }) =>
    !isActive ? 0 : theme.variables.layout.borderRadius.radius_s};
  border-bottom-right-radius: ${({ isActive, theme }) =>
    !isActive ? 0 : theme.variables.layout.borderRadius.radius_s};

    &:hover ${StyledCheckmark} {
      ${({ isClickable, theme }) =>
        isClickable &&
        `
        box-shadow: 0 0 0 ${theme.variables.layout.spaces.small} ${theme.vendor.colors.light};
        border: ${theme.vendor.colors.primary} solid 0.1rem;
      `}
    }
`;

export const StyledBody = Styled.div<{
  isCollapsed: boolean;
}>`
  display: flex;
  padding: ${({ isCollapsed, theme }) =>
    isCollapsed ? `${theme.variables.layout.spacing.spacing_m} 0` : '0'};
  height: ${({ isCollapsed }) => (isCollapsed ? 'auto' : '0')};
  overflow: ${({ isCollapsed }) => (isCollapsed ? 'visibled' : 'hidden')};
  transition: padding 0.2s;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
`;

export const StyledContent = Styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  padding-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
  border-bottom: solid 0.1rem ${({ theme }) =>
    theme.variables.colors.chartColors.grey_50};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border: none;
  }
`;
