const ResponseOK: React.FC<{ className?: string; backgroundColor?: string }> =
  ({ className, backgroundColor = '#E8F5E9' }) => (
    <svg viewBox="0 0 94 89" className={className}>
      <g fill="none" fillRule="evenodd">
        <circle cx={44} cy={44.5} r={44} fill={backgroundColor} />
        <path
          fill="currentColor"
          d="M93.081 15.486l-5.956-6.029a3.153 3.153 0 00-2.24-.957 3.153 3.153 0 00-2.252.927L45.232 46.75l-15.815-16.04a3.14 3.14 0 00-4.46-.032l-6.02 5.994a3.231 3.231 0 00-.03 4.521l24.016 24.362a3.181 3.181 0 004.492.032l45.633-45.583a3.194 3.194 0 00.033-4.52z"
        />
        <path
          fill="currentColor"
          d="M91.081 14.486l-5.956-6.029a3.153 3.153 0 00-2.24-.957 3.153 3.153 0 00-2.252.927L43.232 45.75l-15.815-16.04a3.14 3.14 0 00-4.46-.032l-6.02 5.994a3.231 3.231 0 00-.03 4.521l24.016 24.362a3.181 3.181 0 004.492.032l45.633-45.583a3.194 3.194 0 00.033-4.52z"
        />
      </g>
    </svg>
  );

export default ResponseOK;
