const SvgPrint: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 1.5A1.5 1.5 0 0118.5 3v5.5H20a2.5 2.5 0 012.5 2.5v5a2.5 2.5 0 01-2.5 2.5h-1.5V21a1.5 1.5 0 01-1.5 1.5H7A1.5 1.5 0 015.5 21v-2.5H4A2.5 2.5 0 011.5 16v-5A2.5 2.5 0 014 8.5h1.5V3A1.5 1.5 0 017 1.5h10zm3 8H4A1.5 1.5 0 002.5 11v5A1.5 1.5 0 004 17.5h1.5V15A1.5 1.5 0 017 13.5h10a1.5 1.5 0 011.5 1.5v2.5H20a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0020 9.5zm-3-7H7a.5.5 0 00-.5.5v5.5h11V3a.5.5 0 00-.5-.5zm-10 12a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5H7zm8.5 4.5a.5.5 0 110 1h-7a.5.5 0 110-1h7zm0-2a.5.5 0 110 1h-7a.5.5 0 110-1h7z"
    />
  </svg>
);

export default SvgPrint;
