import { Icons, ModalContent } from '@components';
import { useState } from 'react';
import * as propertiesMessage from './messages';
import { StyledWrapperText, StyledText, WrapperPrompt } from './styles';

export interface ExpiredPJProps {
  onReupload: () => void;
  onAccepted: () => void;
  isExpired: boolean;
  isMaxReuploadAttempt: boolean;
  redirectToPopHome: () => void;
}

const ExpiredEvidence = ({
  onReupload = () => undefined,
  onAccepted = () => undefined,
  isExpired = true,
  isMaxReuploadAttempt = false,
  redirectToPopHome,
}: ExpiredPJProps): React.ReactElement => {
  const ERROR_OUTDATED_PJ_MESSAGE_2_NODE = (
    <>
      {propertiesMessage.ERROR_EXPIRED_PJ_MESSAGE_2}
      <strong>{propertiesMessage.ERROR_EXPIRED_PJ_MESSAGE_2_BIS}</strong>
      <br />
      {propertiesMessage.ERROR_EXPIRED_PJ_MESSAGE_2_TER}
    </>
  );

  const body = (
    <StyledWrapperText>
      <StyledText>{propertiesMessage.ERROR_EXPIRED_PJ_MESSAGE}</StyledText>
      <StyledText>
        {isExpired
          ? propertiesMessage.ERROR_EXPIRED_PJ_MESSAGE_3
          : ERROR_OUTDATED_PJ_MESSAGE_2_NODE}
      </StyledText>
    </StyledWrapperText>
  );

  return (
    <WrapperPrompt>
      <ModalContent
        icon={<Icons.WarningIcon />}
        title={propertiesMessage.TITLE_MODAL_EXPIRED}
        text={body}
        primaryButtonLabel={propertiesMessage.PROOF_BUTTON}
        onPrimaryButtonClick={onReupload}
        isPrimaryButtonDisabled={isMaxReuploadAttempt}
        secondaryButtonLabel={
          isExpired
            ? propertiesMessage.STOP_PROCESS
            : propertiesMessage.TO_SEND_BUTTON
        }
        onSecondaryButtonClick={isExpired ? redirectToPopHome : onAccepted}
      />
    </WrapperPrompt>
  );
};

export default ExpiredEvidence;
