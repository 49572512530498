export const TITLE_PAGE_DOCUMENT = 'Téléchargement des pièces justificatives';

export const WARNING_LABEL =
  'Pensez à supprimer les informations client sur cet appareil chaque jour';
export const LABEL_FINISH_BUTTON = 'Terminer';
export const TITLE_UPLOADING_JUSTIFICATIF_MOBILE = 'Merci de patienter';
export const TEXT_UPLOADING_JUSTIFICATIF_MOBILE =
  'Nous contrôlons votre pièce justificative.';

export const SCAN =
  'Un scan du justificatif d’identité et du RIB va être réalisé permettant de pré-remplir le formulaire. Il est important que vos documents soient bien lisibles et à jour pour l’étude de votre dossier.';

export const UPLOAD_LATER =
  'Les pièces "Justificatif de domicile" et "Justificatif de revenus" pourront être téléchargées plus loin à l’étape "Documents complémentaires". ';
