import { emailRegExp } from './regExps';

export const isEmailValide = (email: string): boolean => {
  if (!email) {
    return false;
  }

  const parts = email.split('@') ?? '';
  const name = parts.length > 1 ? parts[0] : '';
  const domain = parts.length > 1 ? parts[1] : '';
  const isBlackListedName = name && nameBlackList.includes(name);
  const isBlackListedDomaine = domain && domainBlackList.includes(domain);

  if (email.length > 50) {
    return false;
  }

  return isBlackListedName || isBlackListedDomaine
    ? false
    : emailRegExp.test(email);
};

export const nameBlackList = [
  'aaaa',
  'abc',
  'abcd',
  'abcde',
  'abcdef',
  'azerty',
  'bbbb',
  'odns',
  'host',
  'ipadmin',
  'masterdns',
  'noc',
  'nospam',
  'postmaster',
  'qwerty',
  'root',
  'security',
  'sysadmin',
  'usenet',
  'webmaster',
];

export const domainBlackList = [
  'corp.aol.com',
  'corp.aol.fr',
  'mail.com',
  'activexx.com',
  'ahoo.fr',
  'alicedsl.fr',
  'anadoo.fr',
  'aol.cm',
  'aposte.net',
  'ayhoo.com',
  'caramail.fr',
  'carmail.com',
  'chello.fr',
  'club-internet.com',
  'clubinternet.fr',
  'com.com',
  'corp.free.fr',
  'euf.fr',
  'fre.fr',
  'gemail.com',
  'geocitie.com',
  'geocitiesyahoo.com',
  'geocitis.com',
  'geocties.com',
  'gmail.fr',
  'gocities.com',
  'goecities.com',
  'googl.com',
  'hayoo.fr',
  'homail.co.uk',
  'homail.com',
  'homail.fr',
  'homtail.com',
  'hormail.com',
  'hotail.com',
  'hotamail.fr',
  'hotamil.com',
  'hotmai.com',
  'hotmai.fr',
  'hotmailcom.com',
  'hotmaill.com',
  'hotmil.com',
  'hoymail.co.uk',
  'hoymail.fr',
  'htomail.com',
  'htomail.fr',
  'iahoo.com',
  'jubii.co.uk',
  'jubii.com',
  'jubii.de',
  'jubii.dk',
  'jubii.es',
  'jubii.fr',
  'jubii.it',
  'jubii.nl',
  'jubiiconnect.dk',
  'lapsote.net',
  'lemel.fr',
  'lfdv.fr',
  'lycos.at',
  'lycos.co.uk',
  'lycos.de',
  'lycos.es',
  'lycos.fr',
  'lycos.it',
  'lycos.nl',
  'lymedias.com',
  'mobistar.be',
  'mobistarmail.be',
  'myahoo.com',
  'myyahoo.com',
  'oange.fr',
  'online.net',
  'orane.fr',
  'orange-business.fr',
  'orangefrance.com',
  'otmail.com',
  'otmail.fr',
  'pourriels.org',
  'range.fr',
  'ree.fr',
  'tele.fr',
  'tele2.be',
  'tele2.fr',
  'terra.es',
  'tiscali.be',
  'tiscali.fr',
  'voila.fr',
  'wanadoo.com',
  'wandaoo.fr',
  'wannadoo.fr',
  'yafoo.com',
  'yahll.com',
  'yaho.com',
  'yahomail.com',
  'yahooguide.com',
  'yahoonews.com',
  'yahoosearch.com',
  'yahos.com',
  'yahow.com',
  'yahu.com',
  'yahuu.com',
  'yahwho.com',
  'yahwoo.com',
  'yanoo.com',
  'yaoo.fr',
  'yauoo.com',
  'yayoo.fr',
  'yyahoo.com',
];
