const spaces = {
  xlarge: '6rem',
  larger: '4rem',
  large: '3rem',
  medium: '2rem',
  regular: '1rem',
  small: '0.5rem',
};

const spacing = {
  spacing_xxs: '0.4rem',
  spacing_xs: '0.8rem',
  spacing_s: '1.6rem',
  spacing_m: '2.4rem',
  spacing_l: '3.2rem',
  spacing_xl: '4.8rem',
  spacing_xxl: '5.6rem',
  spacing_xxxl: '7.2rem',
  spacingTop24px: '2.4rem',
  spacing40px: '4rem',
};

const borderRadius = {
  radius_xs: '0.2rem',
  radius_s: '0.4rem',
  radius_m: '0.6rem',
  radius_l: '0.8rem',
  radius_xl: '1rem',
};

const border = {
  xs: '0.1rem',
};

const breakpoints = {
  xs: '320px', // Mobile x-small
  s: '414px', // Mobile
  m: '768px', // Tablet
  l: '1024px', // Desktop
};

const layout = {
  spaces,
  spacing,
  borderRadius,
  border,
  breakpoints,
};

export default layout;
