const SvgPluscircleoutlines: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-1.395a8.605 8.605 0 100-17.21 8.605 8.605 0 000 17.21zm0-13.25c.385 0 .698.313.698.698l-.001 3.249h3.25a.698.698 0 010 1.396l-3.25-.001v3.25a.698.698 0 01-1.395 0v-3.25H8.053a.698.698 0 010-1.395h3.249V8.053c0-.385.313-.697.698-.697z"
    />
  </svg>
);

export default SvgPluscircleoutlines;
