const SvgLocker: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.998 2.017c2.755 0 4.929 2.158 4.929 4.894l-.001 1.914h1.93a.64.64 0 01.642.639v11.915a.64.64 0 01-.643.638H5.141a.64.64 0 01-.643-.638V9.464a.64.64 0 01.643-.638h1.928V6.91c0-2.736 2.174-4.894 4.929-4.894zm6.214 8.085H5.784v10.639h12.428V10.102zm-6.214 2.128a2.349 2.349 0 012.357 2.34 2.344 2.344 0 01-1.714 2.253v1.152a.64.64 0 01-.643.638.64.64 0 01-.643-.638v-1.152a2.344 2.344 0 01-1.714-2.253 2.349 2.349 0 012.357-2.34zm0 1.277c-.592 0-1.071.476-1.071 1.063 0 .588.48 1.064 1.071 1.064.592 0 1.071-.476 1.071-1.064 0-.587-.48-1.063-1.071-1.063zm0-10.213c-2.045 0-3.643 1.586-3.643 3.617v1.915h7.286V6.91c0-2.03-1.598-3.617-3.643-3.617z"
    />
  </svg>
);

export default SvgLocker;
