/*
 * Messages for documents container
 *
 *
 */

export const reminder = {
  title:
    'Veuillez vérifier la lisibilité des documents que vous nous fournissez.',
  placeholder: '',
};
export const documentTransmission = {
  title: 'Transmission du document…',
  placeholder: '',
};
export const mandatory = {
  title: '(Obligatoire)',
  placeholder: '',
};
export const JDID = {
  title: "Justificatif d'identité",
  placeholder: "Choisissez votre justificatif d'identité",
};
export const JBAN = {
  title: "Relevé d'Identité Bancaire SEPA (RIB)",
  placeholder: '',
};
export const JDDO = {
  title: 'Justificatif de domicile',
  placeholder: 'Choisissez votre justificatif de domicile',
};
export const JDRE = {
  title: 'Justificatif de revenus',
  placeholder: 'Choisissez votre justificatif de revenus',
};
export const JDOP = {
  title: 'Bon de commande',
  placeholder: '',
};

export const defaultEvidenceLabel = {
  title: 'Nom du champ',
  placeholder: '',
};
export const button = {
  title: 'ENVOYER',
  placeholder: '',
};
export const LABEL_BUTTON_SEND_DOCUMENT = {
  title: 'AJOUTER UN DOCUMENT',
  placeholder: '',
};

export const PJ_EMP = {
  title: 'Pièces justificatives emprunteur',
  placeholder: '',
};

export const labelCustomerHasHisRib = {
  title: 'Le client fournit un RIB SEPA',
  placeholder: '',
};

export const RIB_OWNER = {
  title: 'Justificatif de revenus',
  placeholder: 'Choisissez le titulaire du RIB',
};

export const RIB_BORROWER = {
  title: "RIB de l'emprunteur",
  placeholder: '',
};

export const RIB_COBORROWER = {
  title: 'RIB du co-emprunteur',
  placeholder: '',
};

export const RIB_COMPTE_JOINT = {
  title: "RIB d'un compte joint",
  placeholder: '',
};

export const DOCUMENT_SENT = {
  title: 'Document envoyé',
  placeholder: '',
};
export const DOCUMENT_NOT_SENT = {
  title: 'Document non transmis',
  placeholder: '',
};

/*export const labelRibToTheHoldersAddress =
  'Utiliser le RIB à l’adresse du titulaire comme justificatif de domicile (uniquement France ou Monaco)';



export const MESSAGE_TOAST =
  'Ne cochez pas cette case si le RIB est sans adresse ou si le RIB est avec une adresse hors France ou Monaco';*/
