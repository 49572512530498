import * as messages from './messages';
import { CardTitle, IntroText, TextWrapper, TitleWrapper } from './styles';
import GarantiesBlock from './GarantiesBlock/GarantiesBlock';

const DetailGarantiesCard: React.FC = () => {
  const garantiesInformations = [
    {
      id: 0,
      title: messages.DECES_TITLE,
      text: messages.DECES_TEXT,
    },
    {
      id: 1,
      title: messages.INVALIDITE_TITLE,
      text: messages.INVALIDITE_TEXT,
    },
    {
      id: 2,
      title: messages.MALADIE_TITLE,
      text: messages.MALADIE_TEXT,
    },
    {
      id: 3,
      title: messages.CHOMAGE_TITLE,
      text: messages.CHOMAGE_TEXT,
    },
  ];
  return (
    <>
      <TitleWrapper>
        <CardTitle>{messages.SUBTITLE}</CardTitle>
      </TitleWrapper>
      <TextWrapper>
        <IntroText>{messages.INTRODUCTION_TEXT}</IntroText>
      </TextWrapper>

      {garantiesInformations.map((garantieInformation) => {
        return (
          <GarantiesBlock
            key={garantieInformation.id}
            title={garantieInformation.title}
            text={garantieInformation.text}
          />
        );
      })}
    </>
  );
};

export default DetailGarantiesCard;
