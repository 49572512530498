const SvgPhoneMessageSend: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#4B4F54"
      fillRule="evenodd"
      d="M6 4.5C6 3.12 7.075 2 8.4 2h7.2C16.925 2 18 3.12 18 4.5v15c0 1.38-1.075 2.5-2.4 2.5H8.4C7.075 22 6 20.88 6 19.5v-15Zm10.8 0c0-.69-.537-1.25-1.2-1.25H8.4c-.663 0-1.2.56-1.2 1.25v15c0 .69.537 1.25 1.2 1.25h7.2c.663 0 1.2-.56 1.2-1.25v-15Zm-4.8 15c.663 0 1.2-.56 1.2-1.25S12.663 17 12 17c-.663 0-1.2.56-1.2 1.25s.537 1.25 1.2 1.25Zm1.873-10.49c.09-.13.23-.21.39-.23.16-.03.31.02.44.12.13.09.21.23.23.39a.54.54 0 0 1-.11.44l-3.128 4.16a.61.61 0 0 1-.48.24h-.01a.615.615 0 0 1-.479-.26l-1.878-2.76c-.09-.14-.12-.29-.09-.45.03-.16.12-.29.25-.38.27-.18.65-.11.829.16l1.409 2.07 2.627-3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPhoneMessageSend;
