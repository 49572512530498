import styled from 'styled-components';
import { Tooltip } from '@components';

export const WrapperDocuments = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperButtons = styled.div`
  display: flex;
  padding-top: 1.6rem;
`;

export const WrapperButton = styled.div`
  margin-left: auto;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledTooltip = styled(Tooltip)`
  max-width: 60rem;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xl} 0;
`;

export const StyledTooltipInfo = styled(Tooltip)`
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xl} 0;
`;

export const StyledMessage = styled.div`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
`;

export const StyledTextTooltip = styled.span`
  font-size: 1.4rem;
`;

export const StyledTextBold = styled.span`
  font-weight: bold;
  font-size: 1.4rem;
`;
