import * as messages from './messages';
import { Input, RadioList, Title, Select } from '@components';
import {
  FormWrapper,
  TitleWrapper,
  ItemWrapper,
  RadioListWrapper,
} from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import {
  activityTypeRegExp,
  dateMonthYearMask,
  employeesNumberMask,
  employeesNumberRegExp,
  employerNameRegExp,
  sirenMask,
  sirenRegExp,
  zipCodeMask,
} from '@utils';
import useInformationsForm from '../useInformationsForm';
import { MobileKeyboardType, CodesErreurValidationSiren } from '@types';
import { selectPerson, useAppSelector, personTypes } from '@store';
import dayjs from 'dayjs';
import { useValidateInput } from '@hooks';
import { useEffect } from 'react';

interface IProps {
  role: string;
}

const ProfessionalSituation: React.FC<IProps> = ({ role }) => {
  const { control } = useFormContext();
  const {
    onValidateInputEmployerZipCode,
    onValidateInputEmployerDate,
    listSecteursActivite,
    listProfessions,
    listTypesContrat,
    isActivityTypeVisible,
    isArtisanCommercant,
    isNameEmployerVisible,
    isZipCdEmployerVisible,
    isContractTypeVisible,
    isCitiesByEmployerZipCdLoading,
    onChangeSirenNumber,
    errorSirenNumber,
    isSirenNumberLoading,
    listStatutsLocaux,
    listStatutsProfessionnels,
  } = useInformationsForm({ role });

  const { shouldValidateInput } = useValidateInput();
  const persons = useAppSelector(selectPerson);
  const person =
    role === personTypes.RoleCd.BORROWER
      ? persons?.emprunteur
      : persons?.coemprunteur;
  const suffixRole = '_' + role;

  const titleMessage =
    role === personTypes.RoleCd.BORROWER
      ? messages.LABEL_BORROWER_PROFESSIONAL_SITUATION
      : messages.LABEL_COBORROWER_PROFESSIONAL_SITUATION;
  const labelActivitySector =
    role === personTypes.RoleCd.BORROWER
      ? messages.LABEL_BORROWER_SELECT_ACTIVITY_SECTOR
      : messages.LABEL_COBORROWER_SELECT_ACTIVITY_SECTOR;

  const shouldValidateInputWithTestBefore = (value: any, nameInput: string) => {
    if (value) {
      shouldValidateInput(nameInput, value);
    }
  };

  useEffect(() => {
    if (!person?.professionalSituation) {
      return;
    }
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.activitySector,
      'inputEmployerActivitySector' + suffixRole
    );

    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.profession,
      'inputProfessionOrSituation' + suffixRole
    );
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.activityType,
      'inputActivityType' + suffixRole
    );
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.siren,
      'inputSIREN' + suffixRole
    );
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.employeesNumber,
      'inputEmployeesNumber' + suffixRole
    );
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.employerName,
      'inputEmployerName' + suffixRole
    );
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.employerZipCode,
      'inputEmployerZipCode' + suffixRole
    );
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.workplaceStatusCd,
      'inputWorkplaceStatusCd' + suffixRole
    );
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.professionnalStatusCd,
      'inputProfessionnalStatusCd' + suffixRole
    );
    shouldValidateInputWithTestBefore(
      person?.professionalSituation?.contractType,
      'inputContractType' + suffixRole
    );
    if (person?.professionalSituation?.beginningEmployerDate) {
      shouldValidateInput(
        'inputEmployerDate' + suffixRole,
        dayjs(person?.professionalSituation?.beginningEmployerDate).format(
          'MM / YYYY'
        )
      );
    }
  }, [person]);

  return (
    <FormWrapper>
      <TitleWrapper>
        <Title level={2}>{titleMessage}</Title>
      </TitleWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          name={'inputEmployerActivitySector' + suffixRole}
          defaultValue={person?.professionalSituation?.activitySector}
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid },
          }) => (
            <RadioList
              name={name}
              className={name}
              label={labelActivitySector}
              onChange={onChange}
              value={value}
              valueList={listSecteursActivite?.map((item) => item.id) ?? []}
              options={listSecteursActivite ?? []}
              isBig
              isGrid
              isValid={!invalid}
              inputRef={ref}
            />
          )}
        />
      </ItemWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          name={'inputProfessionOrSituation' + suffixRole}
          defaultValue={person?.professionalSituation?.profession}
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Select
              name={name}
              className={name}
              label={messages.LABEL_PROFESSION_OR_SITUATION}
              options={listProfessions ?? []}
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              isDisabled={!listSecteursActivite}
              onChange={onChange}
              isSubmitted={isSubmitted}
              hasSearch
            />
          )}
        />
      </ItemWrapper>
      {isActivityTypeVisible && (
        <ItemWrapper>
          <Controller
            control={control}
            name={'inputActivityType' + suffixRole}
            defaultValue={person?.professionalSituation?.activityType}
            rules={{
              required: isActivityTypeVisible,
              pattern: activityTypeRegExp,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Input
                name={name}
                className={name}
                label={messages.LABEL_ACTIVITY_TYPE}
                type="text"
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                onChange={onChange}
                maxLength={15}
                isSubmitted={isSubmitted}
              />
            )}
          />
        </ItemWrapper>
      )}
      {isArtisanCommercant && (
        <>
          <ItemWrapper>
            <Controller
              control={control}
              name={'inputSIREN' + suffixRole}
              defaultValue={person?.professionalSituation?.siren}
              rules={{
                required: isArtisanCommercant,
                pattern: sirenRegExp,
              }}
              render={({
                field: { onChange, value, name, onBlur, ref },
                fieldState: { isTouched, invalid },
                formState: { isSubmitted },
              }) => (
                <Input
                  name={name}
                  className={name}
                  label={messages.LABEL_SIREN}
                  type="text"
                  value={value}
                  isTouched={isTouched}
                  isValid={!invalid && errorSirenNumber === null}
                  isLoading={isSirenNumberLoading}
                  onBlur={onBlur}
                  mask={sirenMask}
                  maskChar={''}
                  inputRef={ref}
                  onChange={(newValue) => {
                    onChangeSirenNumber(newValue, onChange);
                  }}
                  maxLength={9}
                  isSubmitted={isSubmitted}
                  error={
                    isTouched && invalid
                      ? messages.ERROR_SIREN_FORMAT
                      : errorSirenNumber ===
                        CodesErreurValidationSiren.NUMERO_SIREN_INVALIDE
                      ? messages.ERROR_SIREN_INVALID
                      : ''
                  }
                />
              )}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Controller
              control={control}
              name={'inputEmployeesNumber' + suffixRole}
              defaultValue={person?.professionalSituation?.employeesNumber}
              rules={{
                required: isArtisanCommercant,
                pattern: employeesNumberRegExp,
              }}
              render={({
                field: { onChange, value, name, onBlur, ref },
                fieldState: { isTouched, invalid },
                formState: { isSubmitted },
              }) => (
                <Input
                  name={name}
                  className={name}
                  label={messages.LABEL_EMPLOYEES_NUMBER}
                  type="text"
                  value={value}
                  isTouched={isTouched}
                  isValid={!invalid}
                  onBlur={onBlur}
                  mask={employeesNumberMask}
                  maskChar={''}
                  inputRef={ref}
                  onChange={(value) => {
                    onChange(parseInt(value.toString(), 10).toString());
                  }}
                  maxLength={4}
                  isSubmitted={isSubmitted}
                  error={
                    invalid && isTouched ? messages.ERROR_EMPLOYEE_NUMBER : ''
                  }
                />
              )}
            />
          </ItemWrapper>
        </>
      )}
      {isNameEmployerVisible && (
        <ItemWrapper>
          <Controller
            control={control}
            name={'inputEmployerName' + suffixRole}
            defaultValue={person?.professionalSituation?.employerName}
            rules={{
              required: isNameEmployerVisible,
              pattern: employerNameRegExp,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Input
                name={name}
                className={name}
                label={messages.LABEL_EMPLOYER_NAME}
                type="text"
                placeholder={messages.LABEL_EMPLOYER_NAME}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                onChange={onChange}
                maxLength={28}
                isSubmitted={isSubmitted}
              />
            )}
          />
        </ItemWrapper>
      )}
      {isZipCdEmployerVisible && (
        <ItemWrapper>
          <Controller
            control={control}
            name={'inputEmployerZipCode' + suffixRole}
            defaultValue={person?.professionalSituation?.employerZipCode}
            rules={{
              required: isZipCdEmployerVisible,
              validate: onValidateInputEmployerZipCode,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Input
                inputMode={MobileKeyboardType.NUMERIC}
                name={name}
                className={name}
                label={messages.LABEL_EMPLOYER_ZIP_CODE}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                isSubmitted={isSubmitted}
                onBlur={onBlur}
                inputRef={ref}
                onChange={onChange}
                mask={zipCodeMask}
                maskChar=""
                maxLength={5}
                isLoading={isCitiesByEmployerZipCdLoading}
                error={
                  isTouched && invalid ? messages.LABEL_ERROR_ZIP_CODE : ''
                }
              />
            )}
          />
        </ItemWrapper>
      )}
      {isArtisanCommercant && (
        <>
          <RadioListWrapper>
            <Controller
              control={control}
              name={'inputWorkplaceStatusCd' + suffixRole}
              rules={{
                required: isArtisanCommercant,
              }}
              defaultValue={person?.professionalSituation?.workplaceStatusCd}
              render={({
                field: { onChange, value, ref, name },
                fieldState: { invalid },
              }) => (
                <RadioList
                  label={messages.LABEL_WORKPLACE_STATUS}
                  onChange={onChange}
                  name={name}
                  value={value}
                  valueList={listStatutsLocaux?.map((item) => item.id) ?? []}
                  options={listStatutsLocaux ?? []}
                  isBig
                  isGrid
                  isValid={!invalid}
                  inputRef={ref}
                />
              )}
            />
          </RadioListWrapper>
          <RadioListWrapper>
            <Controller
              control={control}
              name={'inputProfessionnalStatusCd' + suffixRole}
              rules={{
                required: isArtisanCommercant,
              }}
              defaultValue={
                person?.professionalSituation?.professionnalStatusCd
              }
              render={({
                field: { onChange, value, ref, name },
                fieldState: { invalid },
              }) => (
                <RadioList
                  label={messages.LABEL_PROFESSIONNAL_STATUS}
                  onChange={onChange}
                  name={name}
                  value={value}
                  valueList={
                    listStatutsProfessionnels?.map((item) => item.id) ?? []
                  }
                  options={listStatutsProfessionnels ?? []}
                  isBig
                  isGrid
                  isValid={!invalid}
                  inputRef={ref}
                />
              )}
            />
          </RadioListWrapper>
        </>
      )}
      <ItemWrapper>
        <Controller
          control={control}
          name={'inputEmployerDate' + suffixRole}
          defaultValue={
            person?.professionalSituation?.beginningEmployerDate &&
            dayjs(person.professionalSituation.beginningEmployerDate).format(
              'MM / YYYY'
            )
          }
          rules={{
            required: true,
            validate: onValidateInputEmployerDate,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              inputMode={MobileKeyboardType.NUMERIC}
              name={name}
              className={name}
              label={messages.LABEL_EMPLOYER_DATE}
              placeholder={messages.PLACEHOLDER_EMPLOYER_DATE}
              mask={dateMonthYearMask}
              maskChar=" "
              value={value}
              onChange={onChange}
              isTouched={isTouched}
              isValid={!invalid}
              isSubmitted={isSubmitted}
              onBlur={onBlur}
              inputRef={ref}
              inputWidth="17.5rem"
              error={invalid && isTouched ? messages.ERROR_EMPLOYER_DATE : ''}
            />
          )}
        />
      </ItemWrapper>
      <>
        {isContractTypeVisible && (
          <ItemWrapper>
            <Controller
              control={control}
              name={'inputContractType' + suffixRole}
              defaultValue={person?.professionalSituation?.contractType}
              rules={{
                required: isContractTypeVisible,
              }}
              render={({
                field: { onChange, value, name, onBlur, ref },
                fieldState: { isTouched, invalid },
                formState: { isSubmitted },
              }) => (
                <Select
                  name={name}
                  className={name}
                  label={messages.LABEL_CONTRACT_TYPE}
                  options={listTypesContrat ?? []}
                  value={value}
                  isTouched={isTouched}
                  isValid={!invalid}
                  onBlur={onBlur}
                  inputRef={ref}
                  isDisabled={!listSecteursActivite}
                  onChange={onChange}
                  isSubmitted={isSubmitted}
                  hasSearch
                />
              )}
            />
          </ItemWrapper>
        )}
      </>
    </FormWrapper>
  );
};

export default ProfessionalSituation;
