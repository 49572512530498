const SvgReglette: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 32 32" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.333 17.6c.737 0 1.334.716 1.334 1.6 0 .82-.515 1.497-1.178 1.59l-.156.01H6.667v9.6c0 .884-.597 1.6-1.334 1.6-.683 0-1.247-.618-1.324-1.413L4 30.4v-9.6H1.333C.597 20.8 0 20.084 0 19.2c0-.82.515-1.497 1.178-1.59l.155-.01h8zM16 14.4c.684 0 1.247.618 1.324 1.413l.01.187v14.4c0 .884-.598 1.6-1.334 1.6-.684 0-1.247-.618-1.324-1.413l-.01-.187V16c0-.884.598-1.6 1.334-1.6zm14.667 6.4c.736 0 1.333.716 1.333 1.6 0 .82-.515 1.497-1.178 1.59l-.155.01H28v6.4c0 .884-.597 1.6-1.333 1.6-.684 0-1.248-.618-1.325-1.413l-.009-.187V24h-2.666c-.737 0-1.334-.716-1.334-1.6 0-.82.515-1.497 1.178-1.59l.156-.01h8zm-4-20.8c.683 0 1.247.618 1.324 1.413L28 1.6V16c0 .884-.597 1.6-1.333 1.6-.684 0-1.248-.618-1.325-1.413L25.333 16V1.6c0-.884.597-1.6 1.334-1.6zM5.333 0c.684 0 1.248.618 1.325 1.413l.009.187v11.2c0 .884-.597 1.6-1.334 1.6-.683 0-1.247-.618-1.324-1.413L4 12.8V1.6C4 .716 4.597 0 5.333 0zM16 0c.684 0 1.247.618 1.324 1.413l.01.187V8H20c.736 0 1.333.716 1.333 1.6 0 .82-.514 1.497-1.178 1.59L20 11.2h-8c-.736 0-1.333-.716-1.333-1.6 0-.82.514-1.497 1.178-1.59L12 8h2.667V1.6c0-.884.597-1.6 1.333-1.6z"
    />
  </svg>
);

export default SvgReglette;
