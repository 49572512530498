import styled from 'styled-components';
import ChevronLeftIcon from '../Icons/DesignSystem/ChevronLeft';
import { MAX_WIDTH_SIZE } from '@utils';

// Navigation
export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xxl};

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
    flex-direction: column;
    justify-content: center;
  }
`;
export const WrapperBtnBack = styled.div`
  padding: 0.7rem ${({ theme }) => theme.variables.layout.spacing.spacing_m}
    0.7rem 0;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    padding-bottom: 0;
    padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    padding-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;
export const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  height: 1.1rem;
`;

export const WrapperChoiceButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperBtnNext = styled.div`
  margin-left: auto;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-left: 0;
    padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s} 0;
  }
`;
