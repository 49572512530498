import { Icons } from '@components';
import {
  WrapperTablet,
  WrapperUploadZoneIcon,
  WrapperUploadZoneLabel,
} from './styles';

export interface IUploadZoneDesktopProps {
  label: string;
}

const UploadZoneDesktop = ({
  label,
}: IUploadZoneDesktopProps): React.ReactElement => {
  return (
    <WrapperTablet>
      <WrapperUploadZoneIcon>
        <Icons.PlusCircleOutlinesIcon />
      </WrapperUploadZoneIcon>
      <WrapperUploadZoneLabel>{label}</WrapperUploadZoneLabel>
    </WrapperTablet>
  );
};

export default UploadZoneDesktop;
