const SvgAward1: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.043 3a6.46 6.46 0 015.552 9.763L21.04 19.6a.43.43 0 01-.489.612l-3.15-.788-1.555 2.332a.43.43 0 01-.748-.058l-2.694-5.79a6.563 6.563 0 01-.72 0l-2.696 5.79a.43.43 0 01-.748.058l-1.554-2.332-3.15.788a.43.43 0 01-.49-.612l3.445-6.838A6.46 6.46 0 0112.043 3zM13.3 15.796l2.255 4.844 1.297-1.946a.43.43 0 01.463-.179l2.533.633-2.815-5.587a6.456 6.456 0 01-3.733 2.235zm-6.247-2.235l-2.815 5.587 2.533-.633a.43.43 0 01.463.18L8.53 20.64l2.255-4.844a6.456 6.456 0 01-3.733-2.235zm4.99-9.7a5.598 5.598 0 100 11.196 5.598 5.598 0 000-11.196zm0 3.445a2.153 2.153 0 110 4.306 2.153 2.153 0 010-4.306zm0 .861a1.292 1.292 0 100 2.584 1.292 1.292 0 000-2.584z"
    />
  </svg>
);

export default SvgAward1;
