import * as messages from './messages';
import { Icons, Input, Title } from '@components';
import {
  FormWrapper,
  ItemWrapper,
  StyledTextTooltip,
  StyledTitleTooltip,
  StyledTooltip,
  TitleWrapper,
  WrapperInputMobilePhone,
} from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { selectPerson, useAppSelector, personTypes } from '@store';
import { phoneMask, mobilePhoneRegExp } from '@utils';
import { MobileKeyboardType, RoleCd } from '@types';
import { useEffect, useRef } from 'react';
import { useEmailControle } from '@hooks';
import { IPropsContact } from '../Contact/Contact';

export interface IProps extends IPropsContact {}

const ContactCoBorrower: React.FC<IProps> = ({
  setEmailValidationState = () => null,
}) => {
  const { control, trigger } = useFormContext();

  const persons = useAppSelector(selectPerson);
  const person = persons?.coemprunteur;
  const suffixRole = '_' + personTypes.RoleCd.COBORROWER;
  const emailInputRef = useRef<HTMLInputElement>(null);

  const {
    checkEmailValideAndControleEmail,
    isEmailValideOrToBeCorrected,
    ModalEmailValidation,
    getValidationState,
    getIsToBeCorrected,
  } = useEmailControle({ emailInputRef, role: RoleCd.COBORROWER });

  useEffect(() => {
    setEmailValidationState(getValidationState());
  }, [
    getValidationState()?.isLoading,
    getValidationState()?.isValid,
    getValidationState()?.isForeground,
  ]);

  return (
    <FormWrapper>
      <TitleWrapper>
        <Title level={2}>{messages.CONTAINER_TITLE}</Title>
      </TitleWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          name={'inputEmail' + suffixRole}
          defaultValue={person?.contactInformation?.email}
          rules={{
            required: true,
            validate: isEmailValideOrToBeCorrected,
          }}
          render={({
            field: { onChange, value, name, onBlur },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <>
              <Input
                name={name}
                inputMode={MobileKeyboardType.EMAIL}
                label={messages.INPUT_EMAIL}
                placeholder={messages.PLACEHOLDER_EMAIL}
                value={value}
                isValid={!invalid}
                isTouched={isTouched}
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                  checkEmailValideAndControleEmail({
                    mailToTest: value,
                  });
                }}
                onFocus={() => {
                  trigger(name);
                }}
                isSubmitted={isSubmitted}
                inputRef={emailInputRef}
                error={
                  isTouched && invalid
                    ? getIsToBeCorrected()
                      ? messages.ERROR_CONTROLE_EMAIL
                      : messages.ERROR_EMAIL
                    : ''
                }
              />
              <ModalEmailValidation />
            </>
          )}
        />
      </ItemWrapper>
      <WrapperInputMobilePhone>
        <Controller
          control={control}
          name={'inputMobilePhoneNumber' + suffixRole}
          defaultValue={person?.contactInformation?.mobilePhoneNumber}
          rules={{
            required: true,
            pattern: mobilePhoneRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              inputMode={MobileKeyboardType.TEL}
              name={name}
              label={messages.INPUT_MOBILE_PHONE_NUMBER}
              placeholder={messages.PLACEHOLDER_MOBILE_PHONE_NUMBER}
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              onChange={onChange}
              mask={phoneMask}
              maskChar=" "
              isSubmitted={isSubmitted}
              error={
                isTouched && invalid ? messages.ERROR_MOBILE_PHONE_NUMBER : ''
              }
            />
          )}
        />
      </WrapperInputMobilePhone>
      <StyledTooltip
        skin="advice"
        hasArrowDown={false}
        icon={Icons.InfoOutlinesIcon}
        isVisible={true}
      >
        <StyledTitleTooltip level={2}>
          {messages.TOOLTIP_CONTACT_TITLE}
        </StyledTitleTooltip>
        <StyledTextTooltip>{messages.TOOLTIP_CONTACT_NOTE}</StyledTextTooltip>
      </StyledTooltip>
    </FormWrapper>
  );
};

export default ContactCoBorrower;
