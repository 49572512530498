const SvgCar: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.307 3.012c.834 0 1.555.536 1.795 1.335l1.494 4.982a1.87 1.87 0 011.387 1.801v5.62A1.25 1.25 0 0120.735 18v.624c0 1.033-.84 1.873-1.874 1.873h-1.249a1.875 1.875 0 01-1.873-1.873v-.625H8.245v.625c0 1.033-.84 1.873-1.874 1.873H5.122a1.875 1.875 0 01-1.873-1.873v-.625A1.25 1.25 0 012 16.75v-5.62c0-.864.59-1.586 1.387-1.801l1.495-4.982a1.862 1.862 0 011.794-1.335zM7.275 18.002h-3v.75c0 .414.336.75.75.75h1.5a.75.75 0 00.75-.75v-.75zM19.755 18h-3v.75c0 .413.336.75.75.75h1.5a.75.75 0 00.75-.75V18zM20 10H4a1 1 0 00-1 1v6h18v-6a1 1 0 00-1-1zM6.445 12.084c1.033 0 1.873.84 1.873 1.873s-.84 1.873-1.873 1.873a1.875 1.875 0 01-1.874-1.873c0-1.033.84-1.873 1.874-1.873zm11.57 0c1.032 0 1.873.84 1.873 1.873s-.84 1.873-1.874 1.873a1.875 1.875 0 01-1.873-1.873c0-1.033.84-1.873 1.873-1.873zm-11.579.878a1 1 0 10.002 2.001 1 1 0 00-.002-2.001zm11.601-.014a1 1 0 10.002 2 1 1 0 00-.002-2zM17.485 4H6.53a.637.637 0 00-.617.445l-1.406 4.55h15L18.1 4.446A.637.637 0 0017.485 4z"
    />
  </svg>
);

export default SvgCar;
