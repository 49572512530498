export const CONTAINER_OFFER_TITLE = 'Objet de la demande';
export const CONTAINER_PLACE_TITLE = "Où s'effectuent les travaux ?";
export const INPUT_TYPE_LOAN = 'Type de prêt';
export const INPUT_MATERIAL_CATEGORY = 'Catégorie de matériel / travaux';
export const INPUT_PURCHASE_AMOUNT = "Montant de l'achat";
export const INPUT_DEPOSIT_AMOUNT = "Montant de l'apport";
export const INPUT_LOAN_AMOUNT = 'Montant du crédit';
export const INPUT_DESIRED_TERM_NB = 'Durée souhaitée';
export const INPUT_PRICING = 'Tarification';
export const INPUT_DEFERMENT = 'Report (en nombre de jours)';
export const INPUT_FREE_PRICING = 'Gratuit';
export const INPUT_PAID_PRICING = 'Payant';
export const INPUT_PLACE = 'Choisissez le bien concerné par les travaux';
export const PLACEHOLDER_TYPE_LOAN = 'Choisir une option';
export const PLACEHOLDER_PURCHASE_AMOUNT = '0,00';
export const PLACEHOLDER_DEPOSIT_AMOUNT = '0,00';
export const PLACEHOLDER_LOAN_AMOUNT = '0,00';
export const GENERIC_PLACHOLDER_LIST = 'Choisir dans la liste';
export const ERROR_LOAN_AMOUNT =
  'Le montant du crédit doit être compris entre ';
export const ERROR_TERM_NB = 'La durée saisie doit être comprise entre ';
export const AND = ' et ';
export const ERROR_PRICING = 'Aucune tarification trouvée';
export const ERROR_CHRONICLE =
  'Aucune tarification pour ce couple montant / durée.';
export const EURO = '€';
export const MONTHS = ' mois';
export const BORROWERS_TITLE = "Nombre d'emprunteurs";
export const LOADER = 'Chargement...';
