import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin-bottom: 4rem;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;

export const TitleWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const ItemWrapper = styled.div<{
  isHidden?: boolean;
}>`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m}};
`;

export const RadioListWrapper = styled.div`
  max-width: 80%;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m}};
`;

// TODO : uniformiser les margin-bottom sur le ItemWrapper (ou équivalent) entre les différents blocs liés à Informations.
