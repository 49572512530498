import { Centering, Title, Tooltip } from '@components';
import { MAX_WIDTH_SIZE } from '@utils';
import styled from 'styled-components';

export const StyledWrapperSummary = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 52.4rem;
  margin-top: 4rem;
`;

export const StyledCentering = styled(Centering)`
  padding: 0;
`;

export const StyledTitle = styled(Title)`
  padding: 0;
  margin: 0;
  font-size: 3.2rem;
  line-height: 4rem;
`;

export const StyledDescription = styled.div`
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s} 0;
  line-height: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;
export const StyledTooltip = styled(Tooltip)`
  max-width: 60rem;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_m} 0;
  margin-top: 0;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin: 0;
  }
`;
