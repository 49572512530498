import styled from 'styled-components';
import { Tooltip } from '@components';

export const StyledTooltip = styled(Tooltip)`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const StyledTextTooltipHead = styled.p`
  display: flex;
  color: #4a90e2;
  margin-top: 0;
`;

export const StyledTextTooltipBody = styled.p`
  display: flex;
  color: #4a90e2;
  margin-bottom: 0;
`;
