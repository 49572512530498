import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  selectConfiguration,
  useLazyGetCountriesQuery,
  useAppSelector,
  useSendModifyBorrowerMutation,
  updatePersonEmprunteur,
  useAppDispatch,
  updateDocumentsEmprunteur,
  personTypes,
  updatePersonCoEmprunteur,
  updateDocumentsCoEmprunteur,
} from '@store';
import { WrapperBody, WrapperSubTitle } from './styles';
import {
  FormEditableField,
  Input,
  RadioList,
  Select,
  Subtitle,
} from '@components';
import * as messages from './messages';
import {
  cityRegExp,
  convertDate,
  convertDateReverse,
  dateMask,
  getLabelFromList,
  nameRegExp,
  validateBirthDate,
  validateEvidenceNumber,
} from '@utils';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Common from './Common';
import {
  IModifyBorrowerRequest,
  IDocument,
  IIdentity,
  EvidenceType,
  AnalyseDocument,
  IOption,
} from '@types';
import { getformattedCityName } from 'containers/Informations/InformationsUtils';

dayjs.extend(customParseFormat);

export interface IProps {
  onValidate?: () => void;
  ocrData?: AnalyseDocument;
  borrowerType?: string;
  isMaxReuploadAttempt?: boolean;
  onReupload: () => void;
}

export const FormOcrIdentity: React.FC<IProps> = ({
  onValidate = () => undefined,
  ocrData,
  borrowerType,
  isMaxReuploadAttempt = false,
  onReupload,
}) => {
  const [countries, setCountries] = useState<IOption[]>([]);
  const [triggerGetCountries, resultGetCountries] = useLazyGetCountriesQuery();
  const [sendModifyInfoBorrower, responseModifyInfoBorrower] =
    useSendModifyBorrowerMutation();
  const storeDispatch = useAppDispatch();

  // Get countries
  useEffect(() => {
    // force validation on first render
    methods.trigger();
    triggerGetCountries(undefined, true);
  }, []);

  // TRAITEMENT DES RETOURS DE WEBSERVICES
  useEffect(() => {
    if (!resultGetCountries.data) {
      return;
    }
    const dataCountries = resultGetCountries.data?.map((pays) => {
      return { id: pays.code, label: pays.label };
    });

    if (dataCountries) {
      // On met la France en haut de la liste des pays
      const franceElement = dataCountries.splice(
        dataCountries.findIndex(({ id }) => {
          return id === 'FR';
        }),
        1
      )[0];
      dataCountries.unshift(franceElement);
    }
    setCountries(dataCountries);
  }, [resultGetCountries.data]);

  const titles: IOption[] = [
    { id: 'MONSIEUR', label: 'Monsieur' },
    { id: 'MADAME', label: 'Madame' },
  ];
  const handleFormSubmit = (data: IIdentity) => {
    const birthDt = data.birthDt
      ? convertDateReverse(data.birthDt, true)
      : ocrData?.identite?.date_naissance;

    const modifyInfoBorrowerRequestArgs = {
      etape: 'OCR_IDENTITE',
      typeEmprunteur: borrowerType ?? '',
    };

    const modifyOcrBorrowerRequestBody: IModifyBorrowerRequest = {
      civilite: data.titleCd,
      date_naissance: birthDt,
      nationalite: data.nationalityIsoCd,
      nom: data.lastName,
      nom_naissance: data.birthName,
      numero_piece_identite: data.evidenceNb,
      prenom: data.firstName,
      type_piece_identite: data.evidenceSubTypeCd,
      ville_naissance: getformattedCityName(data.birthCity),
    };
    // Save document to store
    const document: IDocument = {
      evidenceSubTypeCd: ocrData?.soustype_piece,
      evidenceTypeCd:
        EvidenceType[ocrData?.type_piece as keyof typeof EvidenceType],
      evidenceNb: ocrData?.numero_preuve,
      ocrId: ocrData?.ocr_id,
    };
    if (borrowerType == personTypes.RoleCd.BORROWER) {
      // store information to store
      storeDispatch(
        updatePersonEmprunteur({
          identity: { ...data, birthDt },
        })
      );
      storeDispatch(updateDocumentsEmprunteur(document));
    } else {
      // store information to store
      storeDispatch(
        updatePersonCoEmprunteur({
          identity: { ...data, birthDt },
        })
      );
      storeDispatch(updateDocumentsCoEmprunteur(document));
    }

    sendModifyInfoBorrower({
      numeroSouscription: souscriptionId ?? '',
      arg: modifyInfoBorrowerRequestArgs,
      modifyBorrowerRequest: modifyOcrBorrowerRequestBody,
    }).catch((error) => console.log(error));
    onValidate();
  };

  const getIncompleteReadBo = () =>
    !ocrData?.soustype_piece ||
    !ocrData?.numero_preuve ||
    !ocrData.identite?.civilite ||
    !ocrData.identite?.nom_naissance ||
    !ocrData.identite?.prenom ||
    !ocrData.identite?.nom ||
    !ocrData.identite?.date_naissance ||
    !convertDate(ocrData.identite?.date_naissance) ||
    !ocrData.identite?.ville_naissance ||
    !ocrData.identite?.nationalite;

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const configuration = useAppSelector(selectConfiguration);
  const { souscriptionId } = { ...configuration };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <Common
            isIncompleteScan={getIncompleteReadBo()}
            isFormValid={methods.formState.isValid}
            isMaxReuploadAttempt={isMaxReuploadAttempt}
            onReupload={onReupload}
          >
            <WrapperSubTitle>
              <Subtitle>{messages.SUB_TITLE_IDENTITY}</Subtitle>
            </WrapperSubTitle>
            <WrapperBody>
              <FormEditableField
                label={messages.DOCUMENT_TYPE}
                name="evidenceSubTypeCd"
                defaultValue={ocrData?.soustype_piece}
                isReadOnly
              />
              <FormEditableField
                label={messages.EVIDENCE_NUMBER}
                name="evidenceNb"
                defaultValue={ocrData?.numero_preuve}
                minLength={4}
                maxLength={14}
                validate={(value) =>
                  validateEvidenceNumber({
                    value,
                    evidenceSubTypeJDID: methods
                      .getValues('evidenceSubTypeCd')
                      ?.toString(),
                  })
                }
              />
              <FormEditableField
                label={messages.CIVILITY}
                name="titleCd"
                defaultValue={
                  ocrData?.identite?.civilite
                    ? ocrData.identite.civilite
                    : undefined
                }
                render={({ value, onChange, name }, { isTouched, invalid }) => (
                  <RadioList
                    name={name}
                    valueList={titles.map((item) => item.id)}
                    isColorLabelForm
                    options={titles}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      methods.trigger('titleCd');
                    }}
                    isValid={!invalid}
                  />
                )}
              />
              <FormEditableField
                label={messages.BIRTH_NAME}
                name="birthName"
                defaultValue={
                  ocrData?.identite?.nom_naissance?.toString()?.trim() ?? ''
                }
                maxLength={30}
                pattern={nameRegExp}
              />
              <FormEditableField
                label={messages.LASTNAME}
                name="lastName"
                defaultValue={
                  ocrData?.identite?.nom
                    ? ocrData.identite.nom?.toString()?.trim()
                    : ocrData?.identite?.nom_naissance?.toString()?.trim()
                }
                maxLength={30}
                pattern={nameRegExp}
              />
              <FormEditableField
                label={messages.FIRSTNAME}
                name="firstName"
                defaultValue={
                  ocrData?.identite?.prenom?.toString()?.trim() ?? ''
                }
                maxLength={30}
                pattern={nameRegExp}
              />
              <FormEditableField
                label={messages.BIRTH_DATE}
                name="birthDt"
                defaultValue={
                  ocrData?.identite?.date_naissance &&
                  convertDate(ocrData.identite.date_naissance)
                }
                validate={validateBirthDate}
                render={(
                  { onChange, value, ref, onBlur, name },
                  { invalid, isTouched }
                ) => (
                  <Input
                    mask={dateMask}
                    maskChar=""
                    value={value}
                    name={name}
                    onChange={(newValue) => {
                      onChange(newValue + '');
                    }}
                    isTouched={isTouched}
                    isValid={!invalid}
                    onBlur={onBlur}
                    inputRef={ref}
                    inputWidth="17rem"
                  />
                )}
              />
              <FormEditableField
                label={messages.BIRTH_CITY}
                name="birthCity"
                defaultValue={
                  ocrData?.identite?.ville_naissance &&
                  ocrData.identite.ville_naissance
                }
                pattern={cityRegExp}
                maxLength={30}
              />
              <FormEditableField
                label={messages.NATIONALITY}
                name="nationalityIsoCd"
                defaultValue={
                  ocrData?.identite?.nationalite && ocrData.identite.nationalite
                }
                renderPlaceholder={(value) =>
                  getLabelFromList(value, countries ?? [])
                }
                validate={(newValue) => {
                  return true;
                }}
                render={(
                  { onChange, value, ref, onBlur, name },
                  { invalid, isTouched }
                ) => (
                  <Select
                    name={name}
                    onChange={(newValue) => {
                      onChange(newValue);
                      methods.trigger('nationalityIsoCd');
                    }}
                    value={value}
                    placeholder={messages.NATIONALITY_ISO_LABEL}
                    hasSearch
                    options={countries || []}
                    isTouched={isTouched}
                    isValid={!invalid}
                    onBlur={onBlur}
                    inputRef={ref}
                  />
                )}
              />
            </WrapperBody>
          </Common>
        </form>
      </FormProvider>
    </>
  );
};

export default FormOcrIdentity;
