import styled from 'styled-components';
import { lighten } from 'polished';
import { IProgressBarProps } from '@types';

export const StyledProgress = styled.div`
  display: flex;
  width: 100%;
  border-radius: 1.5px;
  background-color: ${({ theme }) =>
    theme.variables.colors.chartColors.grey_100};
`;

export const StyledProgressBar = styled.div<IProgressBarProps>`
  display: flex;
  height: 3px;
  background-color: ${({ theme, hasFailed }) =>
    hasFailed
      ? theme.variables.colors.chartColors.red
      : lighten(0.3, theme.vendor.colors.primary)};
  transition: width 0.6s ease;
  width: ${({ progress }) => progress}%;
`;
