const CopyFile: React.FC = () => (
  <svg viewBox="0 0 32 34" version="1.1">
    <g id="Popin-erreur-technique" stroke="none" strokeWidth="1">
      <g
        id="13---Erreur-technique"
        transform="translate(-717.000000, -467.000000)"
      >
        <g id="Group-2" transform="translate(263.000000, 446.917241)">
          <g id="Group" transform="translate(454.000000, 0.000000)">
            <g id="bouton" transform="translate(0.000000, 20.337931)">
              <rect id="Rectangle" fill="currentColor" />
              <g
                id="file_copy_FILL0_wght400_GRAD0_opsz48"
                transform="translate(8.000000, 7.168966)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <path
                  d="M14.7368421,16.3862069 L3.78947368,16.3862069 C3.45263158,16.3862069 3.15789474,16.2568421 2.90526316,15.9981125 C2.65263158,15.7393829 2.52631579,15.4375318 2.52631579,15.092559 L2.52631579,1.29364791 C2.52631579,0.948675136 2.65263158,0.646823956 2.90526316,0.388094374 C3.15789474,0.129364791 3.45263158,0 3.78947368,0 L11.1157895,0 L16,5.00210526 L16,15.092559 C16,15.4375318 15.8736842,15.7393829 15.6210526,15.9981125 C15.3684211,16.2568421 15.0736842,16.3862069 14.7368421,16.3862069 Z M10.4842105,5.56268603 L10.4842105,1.29364791 L3.78947368,1.29364791 L3.78947368,15.092559 L14.7368421,15.092559 L14.7368421,5.56268603 L10.4842105,5.56268603 Z M1.26315789,18.9735027 C0.926315789,18.9735027 0.631578947,18.8441379 0.378947368,18.5854083 C0.126315789,18.3266788 0,18.0248276 0,17.6798548 L0,4.33372051 L1.26315789,4.33372051 L1.26315789,17.6798548 L11.7473684,17.6798548 L11.7473684,18.9735027 L1.26315789,18.9735027 Z M3.82894737,1.29364791 L3.82894737,5.56268603 L3.82894737,1.29364791 L3.82894737,15.092559 L3.82894737,1.29364791 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CopyFile;
