import { Icons, Modal, ModalContent } from '@components';
import * as messages from './messages';
import { RefObject, useEffect, useRef, useState } from 'react';

const ModalEmailInvalide = ({
  setIsToBeCorrected = () => null,
  emailInputRef = () => null,
  updateIsEmailVerified = (isEmailVerified: boolean) => null,
  isModalControleEmailVisible = false,
  setIsModalControleEmailVisible = () => null,
}: {
  setIsToBeCorrected?: React.Dispatch<React.SetStateAction<boolean>>;
  emailInputRef: () => RefObject<HTMLInputElement> | null | undefined;
  updateIsEmailVerified?: (isEmailVerified: boolean) => void;
  isModalControleEmailVisible: boolean;
  setIsModalControleEmailVisible: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  return (
    <Modal isVisible={isModalControleEmailVisible}>
      <ModalContent
        title={
          messages.MODAL_CONTROLE_EMAIL_TITLE_EMAIL_SAISI +
          emailInputRef()?.current?.value +
          messages.MODAL_CONTROLE_EMAIL_TITLE_EST_PAS_RECONNU
        }
        icon={<Icons.WarningIcon />}
        text={messages.MODAL_CONTROLE_EMAIL_TEXT}
        primaryButtonLabel={messages.MODAL_CONTROLE_EMAIL_PRIMARY_BUTTON_TEXT}
        onPrimaryButtonClick={() => {
          updateIsEmailVerified(true);
          setIsModalControleEmailVisible(false);
        }}
        secondaryButtonLabel={
          messages.MODAL_CONTROLE_EMAIL_SECONDARY_BUTTON_TEXT
        }
        onSecondaryButtonClick={() => {
          setIsModalControleEmailVisible(false);
          setIsToBeCorrected(true);
          emailInputRef()?.current?.focus();
        }}
      ></ModalContent>
    </Modal>
  );
};

export default ModalEmailInvalide;
