import styled from 'styled-components';

export const WrapperBorrower = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const FormWrapper = styled.div`
  margin-bottom: 4rem;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;

export const TitleWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const RadioListWrapper = styled.div`
  max-width: 80%;
`;

export const ItemWrapper = styled.div<{
  isHidden?: boolean;
}>`
  margin-bottom: 4rem;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  gap: 1.6rem;
  align-items: flex-end;
`;
