export const cetelem = {
  logo: 'cetelem',
  colors: {
    primary: '#3A913F',
    title: '#3A913F',
    light: '#E8F5E9',
    lightest: '#F5F9F6',
  },
  colorsDemat: {
    breadCrumbs: '#3A913F',
    titlesText: '#3A913F',
    nextButton: '#3A913F',
    elementsColor: '#3A913F',
    backGroundColor: '#E8F5E9',
    logosColor: '#3A913F',
  },
};

export const domofinance = {
  logo: 'domofinance',
  colors: {
    primary: '#001a70',
    title: '#001a70',
    light: '#001a700a',
    lightest: '#001a700a',
  },
  colorsDemat: {
    breadCrumbs: '#001a70',
    titlesText: '#001a70',
    nextButton: '#001a70',
    elementsColor: '#001a70',
    backGroundColor: '#001a700a',
    logosColor: '#001a70',
  },
};

export const creditmoderne = {
  logo: 'creditmoderne',
  colors: {
    primary: '#3A913F',
    title: '#3A913F',
    light: '#E8F5E9',
    lightest: '#F5F9F6',
  },
  colorsDemat: {
    breadCrumbs: '#3A913F',
    titlesText: '#3A913F',
    nextButton: '#3A913F',
    elementsColor: '#3A913F',
    backGroundColor: '#E8F5E9',
    logosColor: '#3A913F',
  },
};

export const cafineo = {
  logo: 'cafineo',
  colors: {
    primary: '#115587',
    title: '#115587',
    light: '#e1eaf0',
    lightest: '#e1eaf0',
  },
  colorsDemat: {
    breadCrumbs: '#115587',
    titlesText: '#115587',
    nextButton: '#115587',
    elementsColor: '#115587',
    backGroundColor: '#e1eaf0',
    logosColor: '#115587',
  },
};

export const none = {
  logo: '',
  colors: {
    primary: '#151515',
    title: '#151515',
    light: '#F2F2F2',
    lightest: '#FAFAFA',
  },
  colorsDemat: {
    breadCrumbs: '#151515',
    titlesText: '#151515',
    nextButton: '#151515',
    elementsColor: '#151515',
    backGroundColor: '#F2F2F2',
    logosColor: '#151515',
  },
};
