import { useRef, useEffect } from 'react';

const useFirstRender = (): { firstRender: boolean } => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return { firstRender: firstRender.current };
};

export default useFirstRender;
