import {
  Button,
  ButtonsFooter,
  Card,
  Icons,
  Title,
  Tooltip,
} from '@components';
import styled from 'styled-components';

export const ShowDecisionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 55rem;
`;

export const StyledCardWrapper = styled(Card.Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const StyledCheckCircle2Icon = styled(Icons.CheckCircle2Icon)`
  width: 3rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  color: ${({ theme }) => theme.variables.colors.chartColors.green_700};
`;

export const StyledSearchIcon = styled(Icons.SearchIcon)`
  width: 3rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  color: ${({ theme }) => theme.variables.colors.interaction.warning_700};
`;

export const StyledCloseIcon = styled(Icons.CloseIcon)`
  width: 3rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  color: ${({ theme }) => theme.variables.colors.chartColors.red};
`;

export const StyledTitle = styled(Title)`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledText = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  text-align: left;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledTextBold = styled(StyledText)`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const StyledButtonHome = styled(Button)`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledTooltip = styled(Tooltip)`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledTitleTooltip = styled(Title)`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  line-height: 2rem;
`;

export const StyledTextTooltip = styled.div`
  display: flex;
`;

export const StyledSignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  & button {
    margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;

export const StyledButtonFooter = styled(ButtonsFooter)`
  margin-bottom: 0;

  & > div:first-child {
    display: none;
  }
  & > div:last-child {
    width: 100%;
  }
`;
