import styled from 'styled-components';
import { Icons } from '@components';

export const WrapperButton = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    padding-bottom: 0;
  }
`;

export const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  border: 3px dashed
    ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  background-color: ${({ theme }) => theme.variables.colors.chartColors.white};
  border-radius: 1rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-size: 2.4rem;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    border: none;
    font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  }
`;

export const StyledPlusIcon = styled(Icons.PlusCircleOutlinesIcon)`
  width: 2.4rem;
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  }
`;
