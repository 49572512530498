const SvgArrowBarUp: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 40 32" className={className}>
    <path
      d="M20,23.2734363 C20.7889782,23.2734363 21.4285714,22.6222671 21.4285714,21.8190091 L21.4285714,4.96801461 L27.56,11.2133253 C27.9213486,11.5812141 28.4480257,11.7248909 28.9416371,11.5902343 C29.4352485,11.4555777 29.8208029,11.0630451 29.9530657,10.5604998 C30.0853285,10.0579545 29.9442058,9.52174503 29.5828571,9.15385632 L21.0114286,0.427292652 C20.7434164,0.153739022 20.3795065,0 20,0 C19.6204935,0 19.2565836,0.153739022 18.9885714,0.427292652 L10.4171429,9.15385632 C9.85854628,9.72256299 9.85854628,10.6446187 10.4171429,11.2133253 C10.9757394,11.782032 11.8814034,11.782032 12.44,11.2133253 L18.5714286,4.96801461 L18.5714286,21.8190091 C18.5714286,22.6222671 19.2110218,23.2734363 20,23.2734363 Z M0,30.5455727 C0,29.7423147 0.639593215,29.0911454 1.42857143,29.0911454 L38.5714286,29.0911454 C39.3604068,29.0911454 40,29.7423147 40,30.5455727 C40,31.3488307 39.3604068,32 38.5714286,32 L1.42857143,32 C0.639593215,32 0,31.3488307 0,30.5455727 Z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgArrowBarUp;
