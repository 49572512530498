import * as messages from './messages';
import { Input, Title, RadioList } from '@components';
import {
  FormWrapper,
  TitleWrapper,
  RadioListWrapper,
  ItemWrapper,
} from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import useInformationsForm from '../useInformationsForm';
import {
  personTypes,
  selectConfiguration,
  selectPerson,
  useAppSelector,
} from '@store';
import { MobileKeyboardType } from '@types';
import { dateMonthYearMask, LOCATAIRE, PROPRIETAIRE_AVEC_PRET } from '@utils';
import dayjs from 'dayjs';
import { useValidateInput } from '@hooks';
import { useEffect } from 'react';

interface IProps {
  role: string;
}

const HousingSituation: React.FC<IProps> = ({ role }) => {
  const { shouldValidateInput } = useValidateInput();
  const configuration = useAppSelector(selectConfiguration);
  const persons = useAppSelector(selectPerson);

  useEffect(() => {
    if (!persons?.emprunteur?.housingSituation) {
      return;
    }
    shouldValidateInput(
      'housingCd' + suffixRole,
      persons?.emprunteur?.housingSituation?.housingStatus
    );
    if (persons?.emprunteur?.housingSituation?.housingEntryDate) {
      shouldValidateInput(
        'inputAccomodationDate' + suffixRole,
        dayjs(persons?.emprunteur?.housingSituation?.housingEntryDate).format(
          'MM / YYYY'
        )
      );
    }
  }, [persons]);

  const person =
    role === personTypes.RoleCd.BORROWER
      ? persons?.emprunteur
      : persons?.coemprunteur;

  const { listSituationLogement } = {
    ...configuration,
  };

  const { control, setValue } = useFormContext();

  const { onValidateInputAccomodationDate } = useInformationsForm({ role });

  const { trigger } = useFormContext();

  const suffixRole = '_' + role;

  return (
    <FormWrapper>
      <TitleWrapper>
        <Title level={2}>{messages.CONTAINER_TITLE}</Title>
      </TitleWrapper>
      <RadioListWrapper>
        <Controller
          control={control}
          name={'housingCd' + suffixRole}
          rules={{
            required: true,
          }}
          defaultValue={person?.housingSituation?.housingStatus}
          render={({
            field: { onChange, value, ref, name },
            fieldState: { invalid },
          }) => (
            <RadioList
              label={messages.ACCOMODATION_SITUATION}
              onChange={(newValue) => {
                onChange(newValue);
                trigger('monthlyRentAmount' + suffixRole);
                if (
                  newValue?.toString() === PROPRIETAIRE_AVEC_PRET ||
                  newValue?.toString() === LOCATAIRE
                ) {
                  setValue('monthlyRentAmountBtn' + suffixRole, false);
                }
                trigger('inputAccomodationDate' + suffixRole);
              }}
              name={name}
              value={value}
              valueList={listSituationLogement?.map((item) => item.id) ?? []}
              options={listSituationLogement ?? []}
              isBig
              isGrid
              isValid={!invalid}
              inputRef={ref}
            />
          )}
        />
      </RadioListWrapper>

      <ItemWrapper>
        <Controller
          control={control}
          name={'inputAccomodationDate' + suffixRole}
          defaultValue={
            person?.housingSituation?.housingEntryDate &&
            dayjs(person.housingSituation.housingEntryDate).format('MM / YYYY')
          }
          rules={{
            required: true,
            validate: onValidateInputAccomodationDate,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              inputMode={MobileKeyboardType.NUMERIC}
              name={name}
              label={messages.LABEL_IN_THE_ACCOMODATION_SINCE}
              placeholder={messages.PLACEHOLDER_IN_THE_ACCOMODATION_SINCE}
              mask={dateMonthYearMask}
              maskChar=" "
              value={value}
              onChange={onChange}
              isTouched={isTouched}
              isValid={!invalid}
              isSubmitted={isSubmitted}
              onBlur={onBlur}
              inputRef={ref}
              inputWidth="17.5rem"
              error={
                invalid && isTouched ? messages.ERROR_ACCOMODATION_DATE : ''
              }
            />
          )}
        />
      </ItemWrapper>
    </FormWrapper>
  );
};

export default HousingSituation;
