import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const usePersistingState = <Type>(
  name: string,
  defaultValue: Type
): [Type, Dispatch<SetStateAction<Type>>, () => Type] => {
  const [value, setValue] = useState<Type>(defaultValue);

  useEffect(() => {
    const localStore = sessionStorage.getItem(name);
    const parsedLocalStore =
      localStore !== null && localStore !== undefined && localStore !== ''
        ? JSON.parse(localStore)
        : defaultValue;

    setValue(parsedLocalStore);
  }, []);

  const setValueMiddleWare = (update: any) => {
    if (typeof update === 'function') {
      setValue((newValue) => {
        const updatetedValue = update(newValue);
        sessionStorage.setItem(
          name,
          updatetedValue ? JSON.stringify(updatetedValue) : ''
        );
        return updatetedValue;
      });
    } else {
      sessionStorage.setItem(
        name,
        update !== null && update !== undefined ? JSON.stringify(update) : ''
      );
      setValue(update);
    }
  };

  const getValue = () => {
    const localStore = sessionStorage.getItem(name);
    const parsedLocalStore =
      localStore !== null && localStore !== undefined && localStore !== ''
        ? JSON.parse(localStore)
        : defaultValue;
    return parsedLocalStore;
  };

  return [value, setValueMiddleWare, getValue];
};

export default usePersistingState;
