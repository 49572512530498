import styled, {css} from 'styled-components';

import { Card, Button, Icons } from '@components';

export const StyledCard = styled(Card.Wrapper)`
  position: relative;

  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};

  * {
    margin-top: 0;
  }
`;

export const CardBadge = styled.div`
  border: 1px solid ${({ theme }) => theme.vendor.colors.primary};
  width: fit-content;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0;
  font-weight: bold;
  background-color: ${({ theme }) => theme.vendor.colors.primary};
  color: ${({ theme }) => theme.variables.colors.chartColors.white};
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs}
    ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  border-radius: 2rem;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    display: none;
  }
`;

export const CardTitles = styled.h4`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs}
    ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  font-weight: normal;
  width: 100%;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_m};
  background-color: #e8f5f3;
  text-align: center;
`;

export const CardSectionWrapper = styled.div`
  div {
    display: flex;
    justify-content: space-between;

    p:last-child {
      font-weight: bold;
    }
  }

  hr {
    border-color: ${({ theme }) => theme.variables.colors.chartColors.grey_10};
  }
`;

export const AmountWrapper = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.titleModal};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const TextWrapper = styled.p<{ $marginRight ?: boolean }>`
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  ${props => props.$marginRight && css`
    margin-right: 1rem;
  `}
`;

export const HighlightInfo = styled.span`
  color: #007a75;
  font-weight: bold;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.titleModal};
`;

export const ModalOpener = styled.a`
  display: block;
  color: ${({ theme }) => theme.vendor.colors.primary};
  text-decoration: underline;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_m} 0;
  cursor: pointer;
`;

export const StyledDetailButton = styled(Button).attrs(
  (props: { displayBorder: boolean }) => props
)`
  height: 48px;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  border-color: ${(props) =>
    ({ theme }) =>
      props.displayBorder && theme.vendor.colors.primary};
  color: ${({ theme }) => theme.vendor.colors.primary};
  display: flex;
  justify-content: center;
  border: ${(props) => !props.displayBorder && 'none'};
  font-size: 1.8rem;

  &:not(:disabled):hover {
    border-color: ${(props) =>
      ({ theme }) =>
        props.displayBorder && theme.vendor.colors.primary};
    color: ${({ theme }) => theme.vendor.colors.primary};
  }
`;

export const StyledEditIcon = styled(Icons.EditIcon)`
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  width: 2.8rem;
`;
