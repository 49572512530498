import Styled, { css } from 'styled-components';
import { DescriptionListVariant } from '@types';

export const StyledList = Styled.dl`
  width: 100%;
  margin: 0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;

export const Item = Styled.div<{
  isLineBold: boolean;
  variant?: DescriptionListVariant;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  font-weight: ${({ isLineBold }) => (isLineBold ? 'bold' : 'inherit')};
  ${({ variant }) => {
    if (variant === DescriptionListVariant.SPLIT_BY_LINES) {
      return css`
        border-bottom: 1px solid #f2f3f3;
        padding-bottom: 1rem;
      `;
    }
  }}
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
`;
export const Wrapper = Styled.div`
  display: flex;
`;

export const Title = Styled.dt<{
  variant?: DescriptionListVariant;
}>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};

  ${({ variant }) => {
    if (variant === DescriptionListVariant.FILLED_BY_DOTS) {
      return css`
        &::after {
          content: '';
          flex-grow: 1;
          border-bottom: 0.1rem dotted transparent;
          margin: 0 1rem;
          background-image: linear-gradient(
            to right,
            ${({ theme }) => theme.variables.colors.chartColors.grey_200} 33%,
            rgba(255, 0, 0, 0) 0%
          );
          background-repeat: repeat-x;
          background-size: 4px 1px;
          margin-top: 1rem;
        }
      `;
    }
  }}
`;

export const Description = Styled.dd<{ isValueBold: boolean }>`
font-weight: ${({ isValueBold }) => (isValueBold ? 'bold' : 'inherit')};
font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  margin: 0;
`;

export const SubTitle = Styled.dd`
  margin: 0;
  font-weight: ${({ theme }) => theme.variables.fonts.types.normal};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_500};
`;

export const StyledSubTitle = Styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
  color: ${({ theme }) => theme.variables.colors.chartColors.blue_400};
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const IconWrapper = Styled.div`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  white-space: nowrap;
  pointer-events: none;
`;

export const LoaderWrapper = Styled.div`
  height: 2rem;
`;
