const SvgShare: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.684 2A3.325 3.325 0 0121 5.333a3.325 3.325 0 01-3.316 3.334 3.303 3.303 0 01-2.597-1.26L9.39 10.75a3.34 3.34 0 010 2.5l5.7 3.34a3.303 3.303 0 012.594-1.257A3.325 3.325 0 0121 18.667 3.325 3.325 0 0117.684 22a3.325 3.325 0 01-3.316-3.333c0-.444.087-.868.244-1.255l-5.7-3.339a3.303 3.303 0 01-2.596 1.26A3.325 3.325 0 013 12a3.325 3.325 0 013.316-3.333c1.052 0 1.99.492 2.597 1.26l5.697-3.343a3.34 3.34 0 01-.242-1.25A3.325 3.325 0 0117.684 2zm0 14.286c-.817 0-1.538.416-1.964 1.05l-.034.055a.48.48 0 01-.037.077l-.05.07-.033.062c-.16.32-.25.683-.25 1.067a2.375 2.375 0 002.368 2.38 2.375 2.375 0 002.369-2.38 2.375 2.375 0 00-2.369-2.381zM6.316 9.619A2.375 2.375 0 003.947 12a2.365 2.365 0 004.413 1.198l.008-.01A2.38 2.38 0 008.684 12a2.38 2.38 0 00-.324-1.198l-.005-.013a2.365 2.365 0 00-2.04-1.17zm11.368-6.667a2.375 2.375 0 00-2.368 2.381 2.38 2.38 0 00.324 1.198l.006.015a2.365 2.365 0 004.407-1.213 2.375 2.375 0 00-2.369-2.38z"
    />
  </svg>
);

export default SvgShare;
