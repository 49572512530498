import { Icons, ModalContent } from '@components';
import { countriesListSepa } from './constants/CountriesListSepa';
import * as messages from './messages';
import {
  StyledWrapperText,
  StyledTitle,
  StyledText,
  StyledSubTitle,
  StyledListCountries,
  StyledWrapperList,
  WrapperIcon,
  BlockSEPA,
} from './styles';

interface NoRibSepaProps {
  withButton?: boolean;
  onClose?: () => void;
  onValidate?: () => void;
  isMaxReuploadAttempt?: boolean;
}

const countriesSepa = countriesListSepa
  .sort((a, b) => (a.id > b.id ? 1 : -1))
  .map((country) => (
    <StyledListCountries key={country.id}>
      {country.id}, {country.label}
    </StyledListCountries>
  ));

const NoRibSepa: React.FC<NoRibSepaProps> = ({
  withButton = false,
  onClose,
  onValidate,
  isMaxReuploadAttempt = false,
}) => {
  const redirigerAccueil = () => {
    if (onClose && onValidate) {
      onClose();
      onValidate();
    }
  };

  return (
    <ModalContent
      isSpecificStyle={false}
      primaryButtonLabel={withButton ? messages.BUTTON_HOME : ''}
      secondaryButtonLabel={withButton ? messages.BUTTON_UPLOAD_RIB : ''}
      onSecondaryButtonClick={onClose}
      onPrimaryButtonClick={redirigerAccueil}
      icon={
        <WrapperIcon>
          {withButton ? <Icons.WarningIcon /> : <Icons.InfoOutlinesIcon />}
        </WrapperIcon>
      }
      title={
        <StyledTitle>
          {withButton ? messages.RIB_NO_SEPA : messages.TITLE}
        </StyledTitle>
      }
      text={
        <BlockSEPA>
          <StyledWrapperText>
            <StyledSubTitle>{messages.TITLE_2}</StyledSubTitle>
            {messages.TEXT_14}
            <br />
            {messages.TEXT_15}
            <StyledText isAddMarginBottom>{messages.TEXT_16}</StyledText>
            <StyledSubTitle>{messages.TITLE_3}</StyledSubTitle>
            <StyledWrapperList>{countriesSepa}</StyledWrapperList>
          </StyledWrapperText>
        </BlockSEPA>
      }
      isSecondaryButtonDisabled={isMaxReuploadAttempt}
    />
  );
};

export default NoRibSepa;
