import styled, { css } from 'styled-components';
import { BadgeVariantType } from './Badge';

const getWithDotStyles = () => {
  return css`
    border-radius: 1.6rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    letter-spacing: 0.05rem;

    &:before {
      content: ' ';
      display: inline-block;
      background-color: white;
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 50%;
      margin-right: 0.8rem;
    }
  `;
};

const getVariantCss = (variant?: BadgeVariantType) => {
  switch (variant) {
    case 'withDot':
      return getWithDotStyles();
    case 'formationModeModal':
      return css`
        ${getWithDotStyles()};
        padding-left: 3rem;
        border-radius: 0rem 1.6rem 1.6rem 0rem;
      `;
    case 'standardWithoutDot':
      return css`
        font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
        padding: ${({ theme }) =>
          `${theme.variables.layout.spacing.spacing_xxs} ${theme.variables.layout.spacing.spacing_xs}`};
        letter-spacing: 0;
        font-weight: bold;
        text-align: center;
        border-radius: ${({ theme }) =>
          theme.variables.layout.borderRadius.radius_s};
        border-bottom-left-radius: ${({ theme }) =>
          theme.variables.layout.borderRadius.radius_xs};
      `;
    case 'light':
      return css`
        color: #292c2e;
        background-color: #e5f5fd;
        padding-right: ${({ theme }) =>
          theme.variables.layout.spacing.spacing_m};
      `;
    default:
      return '';
  }
};

const BadgeWrapper = styled.div`
  color: ${({ theme }) => theme.variables.colors.chartColors.white};
  background-color: ${({ theme }) =>
    theme.variables.colors.interaction.emphasis_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
  border-radius: 0.8rem 0.8rem 0.8rem 0.4rem;
  padding: 0.3rem 0.8rem;

  ${({ variant }: { variant?: BadgeVariantType; isLoading?: boolean }) =>
    getVariantCss(variant)}
`;

export default BadgeWrapper;
