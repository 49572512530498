import { IImagesFile } from '@types';
import { createRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EnlargeFile from './components/EnlargeFile';
import { StyledFileViewer, StyledImage } from './styles';
import { useDetectMobileDevice } from '@hooks';

export interface FileViewerProps {
  processedImages: IImagesFile | undefined;
}
const FileViewer = ({ processedImages }: FileViewerProps) => {
  const PerfectScrollbarRef = createRef<PerfectScrollbar>();
  const images = processedImages?.images || [];
  const imagesNumber = images?.length ?? 0;
  const { isMobileScreenSize } = useDetectMobileDevice();
  // in order to show the the file Viewer, we need to have at least one image to show
  const isVisible = imagesNumber > 0;

  return isVisible ? (
    <>
      <StyledFileViewer>
        <PerfectScrollbar
          options={{
            minScrollbarLength: 20,
            maxScrollbarLength: 60,
            wheelSpeed: 0.5,
          }}
          onScroll={() => {}}
          ref={PerfectScrollbarRef}
        >
          {images?.map((image, i) => {
            const index = `${image.id}-${i}`;
            return (
              <StyledImage
                key={`image-id-${index}`}
                src={image.src}
                alt={
                  image.src
                    ? `miniature document ${i}`
                    : 'miniature indisponible'
                }
              />
            );
          })}
        </PerfectScrollbar>
      </StyledFileViewer>
      {processedImages?.file &&
        images.length > 0 &&
        images[0].src &&
        !isMobileScreenSize() && (
          <EnlargeFile
            file={processedImages.file}
            fileType={processedImages.fileType}
          />
        )}
    </>
  ) : null;
};

export default FileViewer;
