import { Row, StyledAdviceIcon, UnderText } from './styles';
import { ButtonsFooter, Loader, MentionLegal, Title } from '@components';
import * as messages from './messages';
import {
  clearInsuranceCoEmprunteur,
  selectConfiguration,
  selectInsurances,
  selectOffer,
  updateInsurances,
  useAppDispatch,
  useAppSelector,
  useLazyGetInsurancesBorrowerQuery,
  useLazyGetInsurancesCoBorrowerQuery,
  useSendInsuranceBorrowerMutation,
  useSendInsuranceCoBorrowerMutation,
} from '@store';
import { useEffect, useState } from 'react';
import { IInsuranceRequest, Insurance as InsuranceType } from '@types';
import InsuranceInformations from './InsuranceInformations';
import InsuranceBorrower from './InsuranceBorrower';
import { NO_INSURANCE_KEY } from './constant';
import { useBorrowers } from '@hooks';

const Insurance: React.FC = () => {
  const configuration = useAppSelector(selectConfiguration);
  const storeInsurance = useAppSelector(selectInsurances);
  const offer = useAppSelector(selectOffer);

  const dispatch = useAppDispatch();

  const { souscriptionId, mentionLegaleAssurance } = {
    ...configuration,
  };
  const { emprunteur, coemprunteur } = {
    ...storeInsurance,
  };

  const [triggerGetInsurancesBorrower, resultGetInsurancesBorrower] =
    useLazyGetInsurancesBorrowerQuery();
  const [sendInsuranceBorrower, responseInsuranceBorrower] =
    useSendInsuranceBorrowerMutation();
  const [triggerGetInsurancesCoBorrower, resultGetInsurancesCoBorrower] =
    useLazyGetInsurancesCoBorrowerQuery();
  const [sendInsuranceCoBorrower, responseInsuranceCoBorrower] =
    useSendInsuranceCoBorrowerMutation();

  const [hasSelectedProductWithInsurance, setHasSelectedProductWithInsurance] =
    useState(false);

  const [selectedInsuranceCdBorrower, setSelectedInsuranceCdBorrower] =
    useState<string | undefined>(emprunteur?.insuranceSelected);

  const [
    selectedInsuranceCodeBaremeBorrower,
    setSelectedInsuranceCodeBaremeBorrower,
  ] = useState<string | undefined>(emprunteur?.insuranceCodeBaremeSelected);

  const [selectedInsuranceCdCoBorrower, setSelectedInsuranceCdCoBorrower] =
    useState<string | undefined>(coemprunteur?.insuranceSelected);

  const [
    selectedInsuranceCodeBaremeCoBorrower,
    setSelectedInsuranceCodeBaremeCoBorrower,
  ] = useState<string | undefined>(coemprunteur?.insuranceCodeBaremeSelected);

  const [willShowInformationsModal, setWillShowInformationsModal] =
    useState(false);

  const [hasCoBorrower, setHasCoBorrower] = useState(false);
  const { hasCoborrower } = useBorrowers();

  useEffect(() => {
    triggerGetInsurancesBorrower(souscriptionId ?? '');
  }, []);

  useEffect(() => {
    setHasCoBorrower(hasCoborrower());
  }, [offer.inputBorrowers]);

  useEffect(() => {
    if (hasCoBorrower) {
      triggerGetInsurancesCoBorrower(souscriptionId ?? '');
    } else {
      dispatch(clearInsuranceCoEmprunteur());
    }
  }, [hasCoBorrower]);

  /* APPELS DE SERVICE */
  useEffect(() => {
    dispatch(
      updateInsurances({
        emprunteur: {
          insurances: resultGetInsurancesBorrower?.data?.map((insurance) => {
            return {
              code: insurance?.code ?? '',
              code_bareme: insurance?.code_bareme ?? '',
              label: insurance?.label ?? '',
              cout_mensuel: insurance?.cout_mensuel ?? '',
              cout_total: insurance?.cout_total ?? '',
              taea: insurance?.taea ?? '',
              mensualite_credit: insurance?.mensualite_credit ?? '',
              montant_total: insurance?.montant_total ?? '',
            };
          }),
        },
      })
    );
  }, [resultGetInsurancesBorrower.data]);

  useEffect(() => {
    dispatch(
      updateInsurances({
        coemprunteur: {
          insurances: resultGetInsurancesCoBorrower?.data?.map((insurance) => {
            return {
              code: insurance?.code ?? '',
              code_bareme: insurance?.code_bareme ?? '',
              label: insurance?.label ?? '',
              cout_mensuel: insurance?.cout_mensuel ?? '',
              cout_total: insurance?.cout_total ?? '',
              taea: insurance?.taea ?? '',
              mensualite_credit: insurance?.mensualite_credit ?? '',
              montant_total: insurance?.montant_total ?? '',
            };
          }),
        },
      })
    );
  }, [resultGetInsurancesCoBorrower.data]);

  const buildInsuranceRequest = (
    selectedInsuranceCd: string,
    selectedInsuranceCodeBareme: string
  ): IInsuranceRequest => {
    return {
      numero_souscription: souscriptionId ?? '',
      insurance: {
        code: selectedInsuranceCd,
        code_bareme: selectedInsuranceCodeBareme,
      },
    };
  };

  const sendInsuranceRequestBorrower = () => {
    const request = buildInsuranceRequest(
      selectedInsuranceCdBorrower ?? '',
      selectedInsuranceCodeBaremeBorrower ?? ''
    );
    sendInsuranceBorrower(request);
    dispatch(
      updateInsurances({
        emprunteur: {
          ...emprunteur,
          insuranceSelected: request?.insurance?.code,
          insuranceCodeBaremeSelected: request?.insurance?.code_bareme,
        },
      })
    );
  };

  const sendInsuranceRequestCoBorrower = () => {
    const request = buildInsuranceRequest(
      selectedInsuranceCdCoBorrower ?? '',
      selectedInsuranceCodeBaremeCoBorrower ?? ''
    );
    sendInsuranceCoBorrower(request);
    dispatch(
      updateInsurances({
        coemprunteur: {
          ...coemprunteur,
          insuranceSelected: request?.insurance?.code,
          insuranceCodeBaremeSelected: request?.insurance?.code_bareme,
        },
      })
    );
  };

  const actionOnSubmit = () => {
    sendInsuranceRequestBorrower();
    if (hasCoBorrower) {
      sendInsuranceRequestCoBorrower();
    }
  };

  const closeInformationsModal = () => {
    setWillShowInformationsModal(false);
  };

  const checkSpecificCharInInsurancesCd = (
    list: InsuranceType[],
    char: string
  ): boolean =>
    list
      .map((insurance: InsuranceType) => insurance?.code)
      .filter((element) => element !== undefined)
      .some((insuranceCd) => insuranceCd.includes(char));

  const setIsCollapsed = (
    insurance: InsuranceType,
    setSelectedInsuranceCd: (code: string) => void,
    setSelectedInsuranceCodeBareme: (codeBareme: string) => void,
    selectedInsuranceCd?: string
  ): void => {
    // We Only show the modal if we change the selected insurance
    if (insurance.code !== selectedInsuranceCd) {
      setWillShowInformationsModal(true);
    }
    setHasSelectedProductWithInsurance(
      insurance.code === NO_INSURANCE_KEY ? false : true
    );
    setSelectedInsuranceCd(insurance.code);
    setSelectedInsuranceCodeBareme(insurance.code_bareme);
  };

  return (
    <Loader
      isLoading={
        resultGetInsurancesBorrower?.isLoading ||
        resultGetInsurancesCoBorrower?.isLoading
      }
      isTextInline={true}
      message={messages.LOADER}
    >
      <Title level={2}>
        {hasCoBorrower
          ? messages.CHOOSE_INSURANCE_COBORROWER
          : messages.CHOOSE_INSURANCE}
      </Title>
      <Row>
        <StyledAdviceIcon />
        <UnderText>
          {hasCoBorrower
            ? messages.INFORM_CLIENT_INSURANCE_COBORROWER
            : messages.INFORM_CLIENT_INSURANCE}
        </UnderText>
      </Row>
      {willShowInformationsModal && (
        <InsuranceInformations
          isJobless={checkSpecificCharInInsurancesCd(
            emprunteur?.insurances ?? [],
            NO_INSURANCE_KEY
          )}
          withInsurance={hasSelectedProductWithInsurance}
          isCR={false}
          onClose={closeInformationsModal}
        />
      )}
      <InsuranceBorrower
        title={messages.OPTION_INSURANCE_BORROWER}
        insurances={emprunteur?.insurances}
        selectedInsuranceCd={selectedInsuranceCdBorrower}
        setSelectedInsuranceCd={setSelectedInsuranceCdBorrower}
        setSelectedInsuranceCodeBareme={setSelectedInsuranceCodeBaremeBorrower}
        setIsCollapsed={setIsCollapsed}
      />
      {hasCoBorrower && (
        <InsuranceBorrower
          title={messages.OPTION_INSURANCE_COBORROWER}
          insurances={coemprunteur?.insurances}
          selectedInsuranceCd={selectedInsuranceCdCoBorrower}
          setSelectedInsuranceCd={setSelectedInsuranceCdCoBorrower}
          setSelectedInsuranceCodeBareme={
            setSelectedInsuranceCodeBaremeCoBorrower
          }
          setIsCollapsed={setIsCollapsed}
        />
      )}

      <ButtonsFooter
        isNextDisabled={
          hasCoBorrower
            ? selectedInsuranceCdBorrower === undefined ||
              selectedInsuranceCdCoBorrower === undefined
            : selectedInsuranceCdBorrower === undefined
        }
        onClickNext={actionOnSubmit}
        requestsToWait={
          hasCoBorrower
            ? [responseInsuranceBorrower, responseInsuranceCoBorrower]
            : [responseInsuranceBorrower]
        }
      />
      <MentionLegal htmlContent={mentionLegaleAssurance} />
    </Loader>
  );
};

export default Insurance;
