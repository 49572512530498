const SvgSignatureelectronique1: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.965 18.324a.622.622 0 00.496.228c.597 0 1.101-.47 1.546-.885.125-.117.299-.279.419-.365.03.094.06.223.078.305.073.327.183.821.65.821.549 0 1.572-.59 1.572-1.72a.41.41 0 10-.82 0c0 .47-.345.736-.574.843a7.984 7.984 0 01-.029-.123c-.083-.374-.223-1-.807-1-.364 0-.68.296-1.048.639-.246.23-.567.53-.826.63.015-.197.08-.506.124-.713.145-.68.282-1.324-.035-1.716a.727.727 0 00-.591-.266c-1.31 0-2.982 1.3-3.611 1.79l-.173.132a.41.41 0 00.476.668c.043-.03.11-.083.2-.152.505-.393 2.029-1.578 3.069-1.616.032.2-.076.706-.136.99-.14.657-.25 1.177.02 1.51m11.822-10.07a.725.725 0 00-1.026 0l-5.73 5.74a.41.41 0 00-.083.12l-.719 1.583a.096.096 0 00.128.128l1.58-.72a.406.406 0 00.12-.083l5.73-5.74a.728.728 0 000-1.028m-5.4 11.807a.776.776 0 01-.774.776H6.935a.776.776 0 01-.774-.776V3.94c0-.428.348-.776.774-.776h9.678c.427 0 .774.348.774.776v7.6l1.161-1.163V3.94c0-1.071-.866-1.939-1.935-1.939H6.935A1.937 1.937 0 005 3.939V20.06C5 21.131 5.866 22 6.935 22h9.678a1.937 1.937 0 001.935-1.939v-5.437l-1.161 1.163v4.274z"
    />
  </svg>
);

export default SvgSignatureelectronique1;
