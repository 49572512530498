const QrCode: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11,0 L11,11 L0,11 L0,0 L11,0 Z M8.25,2.0625 L2.0625,2.0625 L2.0625,8.9375 L8.25,8.9375 L8.25,2.0625 Z M6.875,4.125 L6.875,6.875 L4.125,6.875 L4.125,4.125 L6.875,4.125 Z M24,0 L24,11 L13,11 L13,0 L24,0 Z M21.25,2.0625 L15.0625,2.0625 L15.0625,8.9375 L21.25,8.9375 L21.25,2.0625 Z M19.875,4.125 L19.875,6.875 L17.125,6.875 L17.125,4.125 L19.875,4.125 Z M11,13 L11,24 L0,24 L0,13 L11,13 Z M8.25,15.0625 L2.0625,15.0625 L2.0625,21.9375 L8.25,21.9375 L8.25,15.0625 Z M6.875,17.125 L6.875,19.875 L4.125,19.875 L4.125,17.125 L6.875,17.125 Z M19.875,13 L19.875,15.0625 L21.9375,15.0625 L21.9375,13 L24,13 L24,19.1875 L17.125,19.1875 L17.125,17.125 L15.75,17.125 L15.75,24 L13,24 L13,13 L19.875,13 Z M19.875,21.25 L19.875,24 L17.125,24 L17.125,21.25 L19.875,21.25 Z M24,21.25 L24,24 L21.25,24 L21.25,21.25 L24,21.25 Z"
    />
  </svg>
);

export default QrCode;
