import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './documentsSynchronizationTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const documentsSynchronizationSlice = createSlice({
  name: 'documentsSynchronization',
  initialState,
  reducers: {
    setDocumentsSynchronization(
      documentsSynchronizationState,
      action: PayloadAction<IState>
    ) {
      documentsSynchronizationState.state = { ...action.payload };
    },
    updateDocumentsSynchronization(
      documentsSynchronizationState,
      action: PayloadAction<Partial<IState>>
    ) {
      documentsSynchronizationState.state = {
        ...documentsSynchronizationState?.state,
        ...action.payload,
      };
    },
    clear(documentsSynchronizationState) {
      documentsSynchronizationState.state = getInitialState().state;
    },
  },
});

export const selectDocumentsSynchronization = (documentsSynchronizationState: {
  documentsSynchronization: { state: IState };
}) => documentsSynchronizationState.documentsSynchronization.state;

export const {
  setDocumentsSynchronization,
  updateDocumentsSynchronization,
  clear,
} = documentsSynchronizationSlice.actions;

export default documentsSynchronizationSlice.reducer;
