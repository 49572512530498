const SvgMenu: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.524 18.077a.476.476 0 01.085.945l-.085.007H2.476a.476.476 0 01-.085-.944l.085-.008h19.048zm0-6.539a.476.476 0 01.085.945l-.085.008H2.476a.476.476 0 01-.085-.945l.085-.008h19.048zm0-6.538a.476.476 0 01.085.945l-.085.007H2.476a.476.476 0 01-.085-.944L2.476 5h19.048z"
    />
  </svg>
);

export default SvgMenu;
