import { StyledWrapper, StyledOverlay } from './styles';

const Overlay = ({
  children,
  isEnabled = false,
}: {
  children?: React.ReactNode;
  isEnabled: boolean;
}) => {
  return (
    <StyledWrapper>
      {children}
      {isEnabled && <StyledOverlay />}
    </StyledWrapper>
  );
};

export default Overlay;
