const SvgWallet: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <title>Icon/32px/Document/wallet32x</title>
    <g
      id="Icon/32px/Document/wallet"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="PORTEFEUILLE" fill="#4B4F54" fillRule="nonzero">
        <g id="_x31_02" transform="translate(2.666667, 4.000000)">
          <path
            d="M26.6666667,11.6451666 L24.9733333,11.6451666 C24.8133333,8.77327201 22.6266667,6.48428676 19.9066667,6.41320026 L18.44,1.86366435 C18.36,1.62197026 18.1866667,1.42292806 17.96,1.33762426 C17.7333333,1.25232046 17.48,1.28075506 17.28,1.40871076 L15.2666667,2.68826773 L12.3733333,0.186022986 C12.0533333,-0.0841057089 11.6,-0.0556711095 11.32,0.242892184 L5.38666667,6.39898296 L5.21333333,6.39898296 C2.34666667,6.39898296 0,8.88701041 0,11.9579471 L0,18.4410358 C0,21.5119726 2.34666667,24 5.21333333,24 L19.7733333,24 C22.5466667,24 24.8,21.6683628 24.96,18.7538164 L26.6666667,18.7538164 L26.6666667,11.6451666 Z M11.9066667,1.99162005 L13.7866667,3.61239222 L9.38666667,6.39898296 L7.66666667,6.39898296 L11.9066667,1.99162005 Z M17.24,3.39913272 L18.2,6.38476566 L12.5066667,6.38476566 L17.24,3.39913272 Z M19.7733333,22.3081413 L5.21333333,22.3081413 C3.21333333,22.3081413 1.6,20.5736308 1.6,18.4552531 L1.6,11.9721644 C1.6,9.83956949 3.22666667,8.11927622 5.21333333,8.11927622 L5.56,8.11927622 C5.6,8.11927622 5.64,8.14771082 5.68,8.14771082 C5.72,8.14771082 5.76,8.13349352 5.8,8.11927622 L19.7733333,8.11927622 C21.6666667,8.11927622 23.2133333,9.68317919 23.36,11.6593839 L20.9466667,11.6593839 C19.1066667,11.6593839 17.6133333,13.2517214 17.6133333,15.2137088 C17.6133333,17.1756961 19.1066667,18.7680337 20.9466667,18.7680337 L23.36,18.7680337 C23.2133333,20.7442384 21.6666667,22.3081413 19.7733333,22.3081413 Z M25.0666667,17.0477404 L20.9466667,17.0477404 C19.9866667,17.0477404 19.2133333,16.2231371 19.2133333,15.1994915 C19.2133333,14.1758459 19.9866667,13.3512425 20.9466667,13.3512425 L25.0666667,13.3512425 L25.0666667,17.0477404 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgWallet;
