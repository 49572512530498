const ContractPaper: React.FC<{ className?: string }> = ({ className }) => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <title>Icon/32px/Document/contract-paper32x</title>
    <g
      id="Icon/32px/Document/contract-paper"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
    >
      <rect fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
      <g id="CONTRAT_PAPIER" fill="currentColor" fillRule="evenodd">
        <g id="Group" transform="translate(5.333333, 2.666667)">
          <path
            d="M26.0451108,3.74854094 C25.0189686,2.94814074 24.2628637,3.62848091 23.3177327,4.72236118 C23.0071896,5.08254127 21.4409725,7.16358179 19.6722273,9.53810238 C19.6722273,9.00264947 19.6722273,8.60105979 19.6722273,8.33333333 C19.6722273,6.65934819 19.6722273,4.14837048 19.6722273,0.8004002 C19.6722273,0.36018009 19.3076768,0 18.862115,0 L6.11634795,0 C5.900318,0 5.69778992,0.08004002 5.53576746,0.24012006 L0.229531822,5.46940137 C0.0810112312,5.6161414 0,5.82958146 0,6.04302151 L0,25.8662665 C0,26.3064866 0.36455054,26.6666667 0.810112312,26.6666667 L18.8486131,26.6666667 C19.2941749,26.6666667 19.6587254,26.3064866 19.6587254,25.8662665 L19.6587254,14.9141237 L26.2476389,6.12306153 C26.2746426,6.09638152 26.2881445,6.05636151 26.3151483,6.0163415 C26.6526951,5.34934134 27.0037437,4.48224112 26.0451108,3.74854094 Z M1.62022462,6.84342171 L5.98132923,6.84342171 C6.426891,6.84342171 6.79144155,6.48324162 6.79144155,6.04302151 L6.79144155,1.6008004 L18.0385008,1.6008004 L18.0385008,11.7125229 C15.9592125,14.5005836 14.0284449,17.1152243 13.8529205,17.3553443 C13.798913,17.4353844 13.7584074,17.5154244 13.7314037,17.6088044 L12.961797,20.8771052 C12.8942876,21.1439053 13.1778269,21.1038853 13.2858419,21.0371853 L16.2157481,19.3963648 C16.2967593,19.3430048 16.3777706,19.2896448 16.4317781,19.2096048 L18.0249989,17.0885443 L18.0249989,25.0525263 L1.62022462,25.0525263 L1.62022462,6.84342171 Z"
            id="Shape"
          ></path>
          <path
            d="M9.19477474,19.1962648 L4.75265889,19.1962648 C4.30709712,19.1962648 3.95604845,19.5564449 3.95604845,19.996665 C3.95604845,20.4368851 4.30709712,20.7970652 4.75265889,20.7970652 L9.19477474,20.7970652 C9.62683464,20.7970652 9.99138518,20.4368851 9.99138518,19.996665 C9.99138518,19.5564449 9.64033651,19.1962648 9.19477474,19.1962648 Z"
            id="Path"
          ></path>
          <path
            d="M10.6124713,14.6339837 L4.68514954,14.6339837 C4.25308964,14.6339837 3.8885391,14.9941637 3.8885391,15.4343839 C3.8885391,15.874604 4.23958776,16.2347841 4.68514954,16.2347841 L10.6124713,16.2347841 C11.0445312,16.2347841 11.4090817,15.874604 11.4090817,15.4343839 C11.4090817,14.9941637 11.0445312,14.6339837 10.6124713,14.6339837 Z"
            id="Path"
          ></path>
          <path
            d="M4.83367013,11.3923628 L14.9735759,11.3923628 C15.4056358,11.3923628 15.7701863,11.0321828 15.7701863,10.5919626 C15.7701863,10.1517425 15.4191377,9.79156245 14.9735759,9.79156245 L4.83367013,9.79156245 C4.40161023,9.79156245 4.03705969,10.1517425 4.03705969,10.5919626 C4.03705969,11.0321828 4.38810835,11.3923628 4.83367013,11.3923628 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default ContractPaper;
