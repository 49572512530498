import styled from 'styled-components';
import { Wrapper, StyledLabel } from '../Input/styles';

export const Label = styled.span<{
  isBold: boolean;
}>`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 2rem;
  margin-bottom: 0.8rem;
  font-weight: ${({ isBold }) => isBold && 'bold'};
`;

export const List = styled.ul<{
  isGrid: boolean;
}>`
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  display: ${({ isGrid }) => (isGrid ? 'grid' : 'flex')};
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  gap: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export { Wrapper, StyledLabel };

export const HiddenInput = styled.input`
  height: 0;
  opacity: 0;
`;
