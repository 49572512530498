import styled, { css } from 'styled-components';
import { PatternFormat } from 'react-number-format';
//import { InformationTooltip } from '@components';
import { CheckIcon, ExclamationMarkIcon } from '@icons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  width: 100%;
`;

export const ButtonStyle = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  align-items: flex-end;
  width: 100%;
`;

export const StyledChild = styled.div`
  width: auto;
`;

export const LabelToolTipStyle = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: flex-end;
  width: 100%;
`;

export const StyledInput = styled.input<{ type?: string }>`
  flex-grow: 1;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin: 0;
  border: none;
  color: currentColor;
  background-color: transparent;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  line-height: 1.5;
  min-width: 0;

  &:active,
  &:focus {
    border-color: none;
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  ${({ type }) => {
    if (type === 'number') {
      return css`
        ::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
         {
          -moz-appearance: textfield;
          appearance: textfield;
          margin: 0;
        }
      `;
    }
  }}
`;

export const StyledInputMask = styled(PatternFormat)`
  flex-grow: 1;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin: 0;
  border: none;
  color: currentColor;
  background-color: transparent;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  line-height: 1.5;
  min-width: 0;

  &:active,
  &:focus {
    border-color: none;
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  }
`;

const getCursor = (isClickable?: boolean, isDisabled?: boolean) => {
  if (isDisabled) {
    return 'not-allowed';
  }
  if (isClickable) {
    return 'pointer';
  }
  return 'auto';
};

export const InputContainer = styled.div<{
  isDisabled?: boolean;
  withValidationStyle: boolean;
  isValid?: boolean;
  isSubmitted: boolean;
  isTouched?: boolean;
  isFocused?: boolean;
  isSymbolVisible?: boolean;
  inputWidth?: string;
  isClickable?: boolean;
  inputBackground?: string;
}>`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  border: 1px solid
    ${({
      withValidationStyle,
      isValid,
      isSubmitted,
      isTouched,
      isFocused,
      isDisabled,
      theme,
    }) => {
      if (withValidationStyle) {
        if ((isSubmitted || isTouched) && isValid) {
          return theme.vendor.colors.primary;
        }
        if (!isValid) {
          return theme.variables.colors.chartColors.red;
        }
      }
      if (isFocused) {
        return theme.variables.colors.interaction.emphasis_700;
      }
      if (isDisabled) {
        return theme.variables.colors.interaction.grey_100;
      }
      return theme.variables.colors.chartColors.grey_300;
    }};
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
  background-color: ${({ isDisabled, inputBackground, theme }) =>
    isDisabled ? theme.variables.colors.chartColors.grey_50 : inputBackground};
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? theme.variables.colors.chartColors.grey_200
      : theme.variables.colors.chartColors.dark_700};
  ${({ isDisabled, theme }) =>
    isDisabled
      ? `-webkit-text-fill-color:${theme.variables.colors.chartColors.dark_700};`
      : ''}

  transition: border-color 0.2s;
  max-width: ${({ inputWidth }) => inputWidth || 'auto'};
  cursor: ${({ isClickable, isDisabled }) =>
    getCursor(isClickable, isDisabled)};

  &:focus {
    border-color: ${({ theme }) =>
      theme.variables.colors.interaction.emphasis_700};
  }
  ${StyledInput} {
    cursor: ${({ isClickable, isDisabled }) =>
      getCursor(isClickable, isDisabled)};
  }
`;

export const StyledLabel = styled.label<{
  after?: string;
}>`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  font-weight: ${({ theme }) => theme.variables.fonts.types.normal};
  cursor: pointer;
  &:after {
    content: '${({ after }) => after ?? ''}';
    color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
  }
`;

export const IconWrapper = styled.div`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  white-space: nowrap;
  pointer-events: none;
`;

export const LoaderWrapper = styled.div`
  height: 2rem;
`;

export const IconBeforeWrapper = styled(IconWrapper)`
  margin: 0;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.vendor.colors.primary};
  height: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledWarningIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.variables.colors.chartColors.red};
  height: 1.4rem;
`;

/*export const StyledInformationToolTip = styled(InformationTooltip)`
  bottom: 0.9rem;
`;*/
