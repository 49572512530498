const accentChars = 'àâéèêëîïôùüçäÁÀÂÃÅÄÇÈÉÊËÎÌÏÑÔÕÙÜŸ';

// Spec fonctionnelles, bloc 2 : Caractères accentués, tiret(-), espace et apostrophe (')
const accentCharsSinglequoteSpace = `${accentChars}\\' \\-‘ \\\``;

// Spec fonctionnelles, bloc 1 : Caractères alphabétiques (y compris majuscules et/ou minuscules)
const alphabeticChars = 'a-zA-Z';
// Spec fonctionnelles, bloc 4 : Numérique (integer)
const numericChars = '0-9';

// Accepte les lettres et les espaces à l'intérieur (blocs 1 & 2) - 2 à 30 chars
export const nameRegExp = new RegExp(
  `^[${alphabeticChars}${accentChars}][${alphabeticChars}${accentChars}\\' \\-‘]{0,28}[${alphabeticChars}${accentChars}]$`
);

// blocs 1, 2 et 4 => tout sauf caractères spéciaux - 2 à 30 chars
export const cityRegExp = new RegExp(
  `^[${alphabeticChars}${accentChars}][${alphabeticChars}${accentCharsSinglequoteSpace}${numericChars}]*[${alphabeticChars}${accentChars}${numericChars}]$`
);

// blocs 1, 3 et 4 => accepte tous les caractères alphabétique (sauf avec accent) et numérique + tiret et espace - au moins un caractère numérique obligatoire
export const evidenceNumberRegExp = new RegExp(
  `^[${alphabeticChars}${numericChars}-\\s]+$`
);

export const evidenceNumberTDSERegExp = new RegExp(
  `^[${alphabeticChars}${numericChars}-]+$`
);

export const alphaNumericWithAccentRegExp = new RegExp(
  `^[${alphabeticChars}${numericChars}${accentChars}-]+$`
);

// Blocs 1, 2, 4, point et virgule - 1 à 30 chars
export const addressRegExp = new RegExp(
  `^[${alphabeticChars}${accentCharsSinglequoteSpace}${numericChars}.,]{1,30}$`
);

// RegExp de validation des adresse mail
export const emailRegExp = new RegExp(
  `(^(([a-zA-Z0-9_]|([-]{1}[a-zA-Z0-9_]+))+([.]{1}([a-zA-Z0-9_]|([a-zA-Z0-9_]+[-]{1}))+)*)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|((([a-zA-Z0-9]|([a-zA-Z0-9]+[-]{1}[a-zA-Z0-9]+))+\\.)+[a-zA-Z]{2,}))$)`,
  'i'
);

// RegExp de validation des numéros de téléphone mobile
export const mobilePhoneRegExp = new RegExp(
  `(^[0][6-7]((\\s|)[0-9][0-9]){4}$)`,
  'i'
);

// RegExp de validation des numéros de téléphone fixe
export const fixPhoneRegExp = new RegExp(
  `(^[0][1-5|9]((\\s|)[0-9][0-9]){4}$)`,
  'i'
);

// Regexp de validation de code postal
export const zipCodeRegexp = new RegExp('^[0-9]{5,5}$');

export const purchaseOrderNumberRegExp = new RegExp(
  `^[${alphabeticChars}${numericChars}-]+[${alphabeticChars}${numericChars}- ]*[${alphabeticChars}${numericChars}-]+$`
);

// Accepte les lettres, chiffres et les espaces à l'intérieur (blocs 1 & 2) - 1 à 28 chars
export const employerNameRegExp = new RegExp(
  `^[${alphabeticChars}${accentChars}${numericChars}\\' \\-‘]{1,28}$`
);

// Accepte les lettres et les espaces à l'intérieur - 1 à 15 chars
export const activityTypeRegExp = new RegExp(
  `^[${alphabeticChars}${accentChars}\\' \\-‘]{1,15}$`
);

// Accepte les chiffres - 9 chars
export const sirenRegExp = new RegExp(`^[${numericChars}]{9}$`);

// Accepte les chiffres - 4 chars - ne doit pas être 0
export const employeesNumberRegExp = new RegExp(
  `^[1-9][${numericChars}]{0,3}$`
);

export const FORMAT_NUMBER_PATTERN = /(\d)(?=(\d{3})+(?!\d))/g;
export const NUMBER_PATTERN = /^\d+(,\d{0,2})?$/;
export const LIMIT_TO_2_DECIMALS_PATTERN = /(,[0-9]{2})([0-9]*)/;
export const LIMIT_TO_0_DECIMALS_PATTERN = /(,[0-9]{0})([0-9]*)/;

export const listCodeCountrySEPA = [
  'AD',
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GI',
  'GR',
  'HR',
  'HU',
  'IE',
  'IS',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'SM',
  'VA',
];
const codeCountrySEPA = listCodeCountrySEPA.join('|');
export const ibanSEPARegExp = new RegExp(
  `^(?=.{15,34}$)(${codeCountrySEPA})\\d{2}\\w*`
);

export const bicSEPARegExp = new RegExp(`^[a-zA-Z]{6}\\w{2}(?:\\w{3})?$`);

// Blocs 1 et 4 et les espaces du champ de saisie
// minimum 15 caractères + 3 espaces
// maximum 34 caractères + 8 espaces
export const ibanRegExp = new RegExp(
  `^(${codeCountrySEPA})\\d{2}[${alphabeticChars}${numericChars} ]{14,38}$`
);

// Blocs 1 et 4 - 8 ou 11 chars
export const bicRegExp = new RegExp(`^[a-zA-Z]{6}\\w{2}(?:\\w{3})?$`);
