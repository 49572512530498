export enum Company {
  CETELEM = 'cetelem',
  CREDITMODERNE_AG = 'cmag',
  CREDITMODERNE_OI = 'cmoi',
  CAFINEO_AG = 'cafineo_ag',
  CAFINEO_OI = 'cafineo_oi',
  DOMOFINANCE = 'domofinance',
}

export const getCompanyName = (vendorIdOrCompanyCd?: string): Company => {
  const companyCd = (vendorIdOrCompanyCd ?? '').trim().substring(0, 3);
  switch (companyCd) {
    case '185':
      return Company.DOMOFINANCE;
    case '133':
    case '134':
      return Company.CREDITMODERNE_AG;
    case '236':
      return Company.CREDITMODERNE_OI;
    case '237':
    case '238':
    case '239':
      return Company.CAFINEO_AG;
    case '240':
      return Company.CAFINEO_OI;
    default:
      break;
  }
  return Company.CETELEM;
};

export const isDomofinance = (companyCd?: string): boolean => {
  return getCompanyName(companyCd) === Company.DOMOFINANCE;
};

export const isCMOI = (companyCd?: string): boolean => {
  return getCompanyName(companyCd) === Company.CREDITMODERNE_OI;
};

export const isCMAG = (companyCd?: string): boolean => {
  return getCompanyName(companyCd) === Company.CREDITMODERNE_AG;
};

export const isCafineoAG = (companyCd?: string): boolean => {
  return getCompanyName(companyCd) === Company.CAFINEO_AG;
};

export const isCafineoOI = (companyCd?: string): boolean => {
  return getCompanyName(companyCd) === Company.CAFINEO_OI;
};

export const isCreditModerne = (companyCd?: string): boolean => {
  return (
    isCMOI(companyCd) ||
    isCMAG(companyCd) ||
    isCafineoAG(companyCd) ||
    isCafineoOI(companyCd)
  );
};
