import styled, { css } from 'styled-components';

const CenteredContent = styled.div<{
  isDesktopDevice: boolean;
}>`
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  padding-right: ${({ theme }) => theme.variables.layout.spacing.spacing_m};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.s}) {
    padding-left: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    padding-right: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  }

  ${({ isDesktopDevice }) =>
    isDesktopDevice &&
    css`
      max-width: 1024px;
    `}
`;

interface ICenteringProps {
  children?: React.ReactNode;
  className?: string;
}

const Centering = ({
  children,
  className = '',
}: ICenteringProps): React.ReactElement => (
  <CenteredContent className={'ph-centering ' + className} isDesktopDevice>
    {children}
  </CenteredContent>
);

export default Centering;
