import { Icons, ModalContent } from '@components';
import * as messages from './messages';

export interface IProps {
  onClickCancel: () => void;
  onClickConfirm: () => void;
  text?: string;
}

const Leaving: React.FC<IProps> = ({ onClickCancel, onClickConfirm, text }) => {
  return (
    <ModalContent
      icon={<Icons.LogOutIcon />}
      title={messages.TITLE}
      text={text ?? messages.TEXT}
      primaryButtonLabel={messages.BUTTON_CONFIRM}
      secondaryButtonLabel={messages.BUTTON_CANCEL}
      onPrimaryButtonClick={onClickConfirm}
      onSecondaryButtonClick={onClickCancel}
    />
  );
};

export default Leaving;
