import { VerificationContactDetails as VerificationContactDetailsContainer } from '@containers';
import { WrapperVerificationContactDetails } from './styles';

const VerificationContactDetails: React.FC = () => {
  return (
    <WrapperVerificationContactDetails>
      <VerificationContactDetailsContainer />
    </WrapperVerificationContactDetails>
  );
};
export default VerificationContactDetails;
