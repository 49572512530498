import React from 'react';
import { WarningIcon } from '@icons';
import {
  StyledWrapperWarningMessage,
  StyledWarningMessage,
  StyledAdviceIcon,
  Wrapper,
} from './styles';

export type SkinType =
  | 'advice'
  | 'help'
  | 'info'
  | 'deleteCustomerInfo'
  | 'warning'
  | 'primary'
  | 'caution'
  | 'secondary';

export interface ITooltipProps {
  children?: React.ReactNode;
  isVisible?: boolean;
  skin?: SkinType;
  hasArrowDown?: boolean;
  className?: string;
  icon?: React.FC;
}

const Icon = ({
  skin = 'advice',
  icon,
}: {
  skin?: SkinType;
  className?: string;
  icon?: React.FC;
}): React.ReactElement => {
  const isVisible =
    icon ||
    skin === 'warning' ||
    skin === 'advice' ||
    skin === 'info' ||
    skin === 'primary';
  const getAs = () => {
    if (icon) {
      return icon;
    }
    if (skin === 'warning') {
      return WarningIcon;
    }
  };
  return <>{isVisible && <StyledAdviceIcon skin={skin} as={getAs()} />}</>;
};

const Tooltip = ({
  children,
  isVisible = false,
  hasArrowDown = true,
  skin = 'advice',
  className = '',
  icon,
}: ITooltipProps): React.ReactElement => {
  return (
    <Wrapper isVisible={isVisible}>
      <StyledWrapperWarningMessage
        skin={skin}
        className={'ph-tooltip ' + className}
        hasArrowDown={hasArrowDown}
        isVisible={isVisible}
      >
        <Icon skin={skin} icon={icon} />
        <StyledWarningMessage>{children}</StyledWarningMessage>
      </StyledWrapperWarningMessage>
    </Wrapper>
  );
};

export default Tooltip;
