import StyledButtonCheck from './styles';

export interface IButtonCheckProps {
  name: string;
  children?: React.ReactNode;
  onClick?: (value: string) => void;
  className?: string;
  isChecked?: boolean;
  value?: string;
  isBig?: boolean;
  isGrid?: boolean;
  isWide?: boolean;
  isDisabled?: boolean;
  isValid?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

const ButtonCheck = ({
  name,
  onClick = () => null,
  isChecked = false,
  className = '',
  children,
  value,
  isBig = false,
  isGrid = false,
  isWide = false,
  isDisabled = false,
  isValid = true,
  inputRef = undefined,
}: IButtonCheckProps): React.ReactElement => {
  const handleClick = () => {
    onClick(name); // name contains value as ButtonCheck is not driven by react-hook-form (the ref deletes the value)
  };

  return (
    <StyledButtonCheck
      name={name}
      type="button"
      onClick={handleClick}
      className={'ph-button-check ' + className}
      value={value}
      isChecked={isChecked}
      isBig={isBig}
      isGrid={isGrid}
      isWide={isWide}
      disabled={isDisabled}
      isValid={isValid}
      // ref={inputRef}
    >
      {children}
    </StyledButtonCheck>
  );
};

export default ButtonCheck;
