export const INPUT_LOAN_AMOUNT = 'Montant du crédit';
export const INPUT_MONTHLY_AMOUNT = 'Montant des mensualités';
export const INPUT_MONTHLY_NUMBER = 'Nombre de mensualités';
export const MONTHLY_TEXT_OR = 'OU';
export const SELECT_RATE = 'Tarification';
export const SELECT_REPORT = 'Report';
export const ERROR_LOAN_AMOUNT =
  'Le montant du crédit doit être compris entre ';
export const ERROR_LOAN_AMOUNT_KO =
  'Veuillez saisir une valeur correcte pour le montant du crédit';
export const ERROR_MONTHLY_AMOUNT =
  'La mensualité saisie doit être comprise entre ';
export const ERROR_MONTHLY_NUMBER = 'La durée saisie doit être comprise entre ';
export const ERROR_SELECT = 'Veuillez choisir une valeur dans la liste';
export const SELECT_OPTION_DEFAULT = 'Choisir une option';
export const SELECT_REPORT_DEFAULT = 'Choisir un report';
export const AND = ' et ';
export const EURO = '€';
export const MONTHS = ' mois';
export const PRICE_PLACEHOLDER = '00,00';
export const MOUNTH_PLACEHOLDER = '0';
