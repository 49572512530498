/*
 * Messages modal d'erreur
 */

export const TITLE = 'Une erreur technique est survenue';
export const REFUSE_CONTACT =
  "Merci de prendre contact avec le service relation vendeur ou retourner à l'accueil.";
export const REQUESTID_HANDLER = 'code erreur : ';
export const BACK_BUTTON = "Retourner à l'accueil";
export const REFUSE_CONTACT_MOBILE_DEVICE =
  'Merci de contacter votre centre de relation clientèle.';
export const MESSAGE_COPY_ERROR_CODE =
  'Copiez ce code erreur et envoyez le par email à votre contact support.';
