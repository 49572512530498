import React from 'react';
import * as messages from './messages';
import {
  StyledCancelButton,
  StyledConfirmButton,
  TextWrapper,
  TitleWrapper,
} from './styles';

export interface IProps {
  onClickCancel: () => void;
  onClickConfirm: () => void;
}

const LeavingMobile: React.FC<IProps> = ({ onClickCancel, onClickConfirm }) => {
  return (
    <React.Fragment>
      <TitleWrapper>{messages.TITLE}</TitleWrapper>
      <TextWrapper>{messages.TEXT}</TextWrapper>

      <StyledConfirmButton variant="primary" onClick={onClickConfirm}>
        {messages.BUTTON_CONFIRM}
      </StyledConfirmButton>

      <StyledCancelButton variant="secondary" onClick={onClickCancel}>
        {messages.BUTTON_CANCEL}
      </StyledCancelButton>
    </React.Fragment>
  );
};

export default LeavingMobile;
