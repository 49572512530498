import { Card } from '@components';
import styled from 'styled-components';

export const StyledCard = styled(Card.Wrapper)`
  padding-bottom: 2.9rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const WrapperPurchaseOrder = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;
