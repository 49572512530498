import {
  StyledTooltip,
  StyledTextTooltipHead,
  StyledTextTooltipBody,
} from './styles';
import * as messages from './messages';

export const InfoBulle: React.FC = () => {
  return (
    <StyledTooltip skin="advice" hasArrowDown={false} isVisible={true}>
      <StyledTextTooltipHead>{messages.INFO_HEAD}</StyledTextTooltipHead>
      <StyledTextTooltipBody>{messages.INFO_BODY}</StyledTextTooltipBody>
    </StyledTooltip>
  );
};

export default InfoBulle;
