export enum typeAppareil {
  ORDINATEUR = 'ORDINATEUR',
  MOBILE = 'MOBILE',
  SERVEUR = 'SERVEUR',
}

export enum EventSwitchDevice {
  CREATION = 'CREATION',
  CONNEXION = 'CONNEXION',
  NO_FERMETURE = 'NO_FERMETURE',
  FERMETURE = 'FERMETURE',
  ANALYSE_OCR_KO = 'ANALYSE_OCR_KO',
  INACTIVITE = 'INACTIVITE',
  SERVICE_OCR_KO = 'SERVICE_OCR_KO',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
}

export enum EModalErrorState {
  UNAVAILABLE_SERVICE = 'UNAVAILABLE_SERVICE',
}

interface IEventSwitchDevice {
  label: string;
  isFinal: boolean;
}
export const listeEvenements: IEventSwitchDevice[] = [
  { label: 'CREATION', isFinal: false },
  { label: 'CONNEXION', isFinal: false },
  { label: 'FERMETURE', isFinal: true },
  { label: 'ANALYSE_OCR_KO', isFinal: true },
  { label: 'INACTIVITE', isFinal: true },
  { label: 'SERVICE_OCR_KO', isFinal: true },
  { label: 'TECHNICAL_ERROR', isFinal: true },
];

export const FINAL_EVENTS_SWITCH_DEVICE = listeEvenements
  .filter((e) => e.isFinal === true)
  .map((e) => e.label);
