const SvgMobile: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 35 56" className={className}>
    <g fill="none" fillRule="evenodd">
      <rect
        width={33}
        height={54}
        x={1}
        y={1}
        stroke="currentColor"
        strokeWidth={2}
        rx={6}
      />
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth={1.5}
        d="M1.346 47h32.308"
      />
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth={2}
        d="M15.333 7h5.334"
      />
      <g fill="currentColor" fillRule="nonzero" stroke="#979797">
        <path
          strokeWidth={0.5}
          d="M24.785 33.073c0 1.466-1.166 2.66-2.6 2.66h-11.37c-1.434 0-2.6-1.194-2.6-2.66v-6.785c0-1.466 1.166-2.66 2.6-2.66h1.627l.56-.397.46-1.292c.244-.448.681-.696 1.167-.696h3.669c.486 0 .948.273 1.19.745l.292.547 1.07-.596-.292-.547c-.462-.87-1.312-1.392-2.26-1.392h-3.645c-.947 0-1.822.547-2.26 1.392l-.364 1.019h-1.214C8.7 22.41 7 24.15 7 26.313v6.785C7 35.26 8.7 37 10.815 37h11.37C24.3 37 26 35.26 26 33.098v-5.22h-1.215v5.195z"
        />
        <path
          strokeWidth={0.5}
          d="M16.5 25a4.512 4.512 0 00-4.5 4.5c0 2.476 2.024 4.5 4.5 4.5s4.5-2.024 4.5-4.5-2.024-4.5-4.5-4.5zm0 7.81a3.302 3.302 0 01-3.31-3.31 3.302 3.302 0 013.31-3.31 3.302 3.302 0 013.31 3.31 3.302 3.302 0 01-3.31 3.31z"
        />
        <path
          strokeWidth={0.3}
          d="M25.615 22.395V20h-1.23v2.395H22v1.235h2.385V26h1.23v-2.37H28v-1.235z"
        />
      </g>
    </g>
  </svg>
);

export default SvgMobile;
