import { StyledBody } from './styles';

interface ICardBodyProps {
  children?: React.ReactNode;
  isCollapsed?: boolean;
  className?: string;
}

const Body: React.FC<ICardBodyProps> = ({
  children,
  isCollapsed = false,
  className = '',
}: ICardBodyProps): React.ReactElement => (
  <StyledBody className={className} isCollapsed={!isCollapsed}>
    {children}
  </StyledBody>
);

export default Body;
