import { Button } from '@components';
import {
  AdviceIcon,
  CheckCircleIcon,
  FileIcon,
  Illustration2Icon,
  WarningIcon,
} from '@icons';
import { MAX_WIDTH_SIZE } from '@utils';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  width: 100%;
`;

export const WrapperGlobalTitle = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledFileIcon = styled(FileIcon)`
  height: 2.4rem;
  margin-right: 0.8rem;
`;

export const StyledTitle = styled.h1`
  margin: 0;
  display: flex;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.titleModalDocument};
  text-transform: uppercase;
`;

export const WrapperSuccessScan = styled.div`
  display: flex;
  height: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
  background: ${({ theme }) => theme.variables.colors.chartColors.green_700};
  justify-content: center;
  margin: 0
    calc(${({ theme }) => theme.variables.layout.spacing.spacing_xl} * -1); // negative margins
`;
export const WrapperIncompleteScan = styled(WrapperSuccessScan)`
  background: ${({ theme }) => theme.variables.colors.chartColors.orange_10};
`;
export const WrapperIncompleteScanRIBA = styled.div`
  height: 7.2rem;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.variables.colors.chartColors.orange_10};
`;

export const IconContainer = styled(Illustration2Icon)`
  width: 14.7rem;
  align-self: center;
  margin-right: 1.2rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const StyledText = styled.p`
  margin: 0;
  display: flex;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
`;

export const WrapperSubTitle = styled.div`
  display: flex;
  justify-content: left;
  border-bottom: 0.1rem solid #b2b2b2;
  margin-bottom: 2.6rem;
  margin-top: 2.4rem;
`;

export const WrapperText = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-right: 1rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.white};
`;
export const StyledAdviceIcon = styled(AdviceIcon)`
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-right: 1rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const StyledWarningIcon = styled(WarningIcon)`
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-right: 1rem;
  color: ${({ theme }) => theme.variables.colors.interaction.warning_700};
`;

export const StyledWarningIconRIBA = styled(WarningIcon)`
  width: 2rem;
  height: 2rem;
  align-self: top;
  margin: 0.7rem 1rem 0rem 0rem;
  color: ${({ theme }) => theme.variables.colors.interaction.warning_700};
`;

export const StyledSubTitle = styled.p`
  margin: 0.6rem 0rem 0.6rem 0rem;
  display: flex;
  color: ${({ theme }) => theme.variables.colors.chartColors.white};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
`;
export const StyledSubTitleIncomplete = styled(StyledSubTitle)`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;
export const StyledSubTitleIncompleteRIBA = styled(StyledSubTitle)`
  width: 56.8rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  line-height: 20px;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_m}
    ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;

export const WrapperBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  margin-bottom: 1.6rem;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid
      ${({ theme }) => theme.variables.colors.chartColors.neutral_050};
    font-size: 1.2rem;

    &:last-child {
      border: none;
    }
  }
`;

export const WrapperFieldValue = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  min-width: 70%;
  align-items: start;
`;

export const WrapperDefaultValue = styled.div`
  display: block;
`;

export const StyledLabel = styled.span<{
  isErrorMsgVisible: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: ${({ isErrorMsgVisible }) =>
    isErrorMsgVisible ? 'start' : 'center'};
  margin-top: ${({ isErrorMsgVisible }) =>
    isErrorMsgVisible ? '0.9rem' : '0'};
  min-width: 17rem;
  text-align: left;
`;

export const WrapperBodyOCR = styled.div`
  display: block;
  width: 100%;
  min-height: 4rem;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  font-weight: bold;
  align-items: center;
  text-align: left;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    font-weight: normal;
  }
`;

export const WrapperIcon = styled.div``;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const StyledProofButton = styled(Button)`
  margin-right: 1.6rem;
`;

export const EditButton = styled.button`
  color: ${({ theme }) => theme.vendor.colors.primary};
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  width: 2.2rem;
  padding: 0;
  margin-left: 8px;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    position: relative;
    top: -0.6rem;
    width: 2.8rem;
  }
`;

export const OcrValue = styled.span<{
  isInEditMode: boolean;
}>`
  display: ${({ isInEditMode }) => (isInEditMode ? 'none' : 'block')};
`;

export const OcrField = styled.div<{
  isInEditMode: boolean;
}>`
  display: ${({ isInEditMode }) => (isInEditMode ? 'block' : 'none')};
  width: 100%;
`;
