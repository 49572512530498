import { Input, RadioList } from '@components';
import { EvidenceType, MobileKeyboardType } from '@types';
import { WrapperInput } from './styles';
import * as messages from './messages';
import { Controller, useFormContext } from 'react-hook-form';
import useInformationsForm from '../useInformationsForm';
import { isPersonFieldDisabled, nameRegExp, dateMask } from '@utils';
import { selectPerson, useAppSelector, personTypes } from '@store';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect } from 'react';
import { useValidateInput } from '@hooks';

dayjs.extend(customParseFormat);

interface IProps {
  role: string;
}

const InformationsIdentity: React.FC<IProps> = ({ role }) => {
  const { control, listCivility, onValidateInputBirthDate } =
    useInformationsForm({ role });

  const persons = useAppSelector(selectPerson);
  const person =
    role === personTypes.RoleCd.BORROWER
      ? persons?.emprunteur
      : persons?.coemprunteur;
  const suffixRole = '_' + role;

  const { trigger } = useFormContext();
  const { shouldValidateInput } = useValidateInput();

  useEffect(() => {
    if (!person?.identity) {
      return;
    }
    shouldValidateInput(
      'inputCivility' + suffixRole,
      person?.identity?.titleCd
    );
    shouldValidateInput(
      'inputFirstName' + suffixRole,
      person?.identity?.firstName
    );
    shouldValidateInput(
      'inputLastName' + suffixRole,
      person?.identity?.lastName
    );
    shouldValidateInput(
      'inputBirthName' + suffixRole,
      person?.identity?.birthName
    );
    if (person?.identity?.birthDt) {
      shouldValidateInput(
        'inputBirthDate' + suffixRole,
        dayjs(person?.identity?.birthDt).format('DD / MM / YYYY')
      );
    }
  }, [person]);

  return (
    <>
      <WrapperInput>
        <Controller
          control={control}
          name={'inputCivility' + suffixRole}
          defaultValue={person?.identity?.titleCd}
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid },
          }) => (
            <RadioList
              name={name}
              valueList={listCivility?.map((item) => item.id) ?? []}
              isColorLabelForm
              label={messages.INPUT_CIVILITY}
              options={listCivility ?? []}
              value={value}
              onChange={onChange}
              isValid={!invalid}
              isGrid
              isDisabled={isPersonFieldDisabled(
                person?.identity?.titleCd,
                person,
                EvidenceType.JDID
              )}
              inputRef={ref}
            />
          )}
        />
      </WrapperInput>

      <WrapperInput>
        <Controller
          control={control}
          name={'inputFirstName' + suffixRole}
          defaultValue={person?.identity?.firstName}
          rules={{
            required: true,
            pattern: nameRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              name={name}
              label={messages.INPUT_FIRST_NAME}
              type="text"
              placeholder={messages.INPUT_FIRST_NAME}
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              onChange={onChange}
              maxLength={30}
              isSubmitted={isSubmitted}
              isDisabled={isPersonFieldDisabled(
                person?.identity?.firstName,
                person,
                EvidenceType.JDID
              )}
            />
          )}
        />
      </WrapperInput>

      <WrapperInput>
        <Controller
          control={control}
          name={'inputLastName' + suffixRole}
          defaultValue={person?.identity?.lastName}
          rules={{
            required: true,
            pattern: nameRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              name={name}
              label={messages.INPUT_LAST_NAME}
              type="text"
              placeholder={messages.INPUT_LAST_NAME}
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              onChange={onChange}
              maxLength={30}
              isSubmitted={isSubmitted}
              isDisabled={isPersonFieldDisabled(
                person?.identity?.lastName,
                person,
                EvidenceType.JDID
              )}
            />
          )}
        />
      </WrapperInput>

      <WrapperInput>
        <Controller
          control={control}
          name={'inputBirthName' + suffixRole}
          defaultValue={person?.identity?.birthName}
          rules={{
            required: true,
            pattern: nameRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              name={name}
              label={messages.INPUT_BIRTH_NAME}
              type="text"
              placeholder={messages.INPUT_BIRTH_NAME}
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              onChange={onChange}
              maxLength={30}
              isSubmitted={isSubmitted}
              isDisabled={isPersonFieldDisabled(
                person?.identity?.birthName,
                person,
                EvidenceType.JDID
              )}
            />
          )}
        />
      </WrapperInput>

      <WrapperInput>
        <Controller
          control={control}
          name={'inputBirthDate' + suffixRole}
          defaultValue={
            person?.identity?.birthDt &&
            dayjs(person?.identity?.birthDt).format('DD / MM / YYYY')
          }
          rules={{
            required: true,
            validate: onValidateInputBirthDate,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              inputMode={MobileKeyboardType.NUMERIC}
              name={name}
              label={messages.INPUT_BIRTH_DATE}
              placeholder={messages.PLACEHOLDER_BIRTH_DATE}
              type="text"
              mask={dateMask}
              maskChar=" "
              value={value}
              onChange={(value) => {
                onChange(value);
                trigger('inputAccomodationDate' + suffixRole);
                trigger('accountOpeningDt');
                trigger('inputEmployerDate' + suffixRole);
              }}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              inputWidth="17rem"
              isSubmitted={isSubmitted}
              error={invalid && isTouched ? messages.ERROR_DATE_NAISSANCE : ''}
              isDisabled={isPersonFieldDisabled(
                person?.identity?.birthDt,
                person,
                EvidenceType.JDID
              )}
            />
          )}
        />
      </WrapperInput>
    </>
  );
};

export default InformationsIdentity;
