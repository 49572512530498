import {
  Wrapper,
  StyledTitle,
  WrapperButton,
  StyledFileIcon,
  StyledCheckCircleIcon,
  WrapperGlobalTitle,
  StyledProofButton,
  WrapperText,
  WrapperContainer,
  StyledText,
  IconContainer,
  Column,
  StyledWarningIconRIBA,
  StyledWarningIcon,
  WrapperScan,
  StyledGeneralSubTitle,
} from './styles';
import { Button } from '@components';
import { useFormState } from 'react-hook-form';
import * as messages from './messages';

const WrapperMessageToDisplay: React.FC<{
  isIncompleteScan?: boolean;
  requalificationRIBAonRIBS?: boolean;
}> = ({ isIncompleteScan, requalificationRIBAonRIBS }) => {
  let [background, color, Icon, message] = [
    'orange_10',
    'dark_700',
    StyledWarningIcon,
    messages.INCOMPLETE_SCAN,
  ];
  if (!isIncompleteScan && !requalificationRIBAonRIBS) {
    [background, color, Icon, message] = [
      'green_700',
      'white',
      StyledCheckCircleIcon,
      messages.SUCCESS_SCAN,
    ];
  }
  if (requalificationRIBAonRIBS) {
    console.log(12);
    [background, Icon, message] = [
      'warning_700',
      StyledWarningIconRIBA,
      isIncompleteScan ? messages.ERROR_RIBA : messages.WARNING_RIBA,
    ];
  }
  return (
    <WrapperScan background={background}>
      <Icon />
      <StyledGeneralSubTitle color={color}>{message}</StyledGeneralSubTitle>
    </WrapperScan>
  );
};

export interface IProps {
  isIncompleteScan?: boolean;
  isFormValid: boolean;
  children: React.ReactNode;
  onReupload: () => void;
  isMaxReuploadAttempt: boolean;
  requalificationRIBAonRIBS?: boolean;
  isSubmitting?: boolean;
}

export const Common: React.FC<IProps> = ({
  isIncompleteScan,
  children,
  isFormValid,
  isMaxReuploadAttempt,
  onReupload,
  requalificationRIBAonRIBS,
  isSubmitting = false,
}) => {
  const { isSubmitting: isFormStateSubmitting } = useFormState();

  return (
    <Wrapper>
      <Column>
        <WrapperGlobalTitle>
          <StyledFileIcon />
          <StyledTitle>{messages.TITLE_MODAL}</StyledTitle>
        </WrapperGlobalTitle>
        <WrapperMessageToDisplay
          isIncompleteScan={isIncompleteScan}
          requalificationRIBAonRIBS={requalificationRIBAonRIBS}
        />
        <WrapperContainer>
          <WrapperText>
            <IconContainer />
            <StyledText>{messages.CHECK_INFO}</StyledText>
          </WrapperText>
          {children}
        </WrapperContainer>
        <WrapperButton>
          <StyledProofButton
            variant="secondary"
            type="button"
            onClick={onReupload}
            isDisabled={isMaxReuploadAttempt}
          >
            {messages.PROOF_BUTTON}
          </StyledProofButton>
          <Button
            variant="primary"
            type="submit"
            isDisabled={!isFormValid || isSubmitting || isFormStateSubmitting}
            isLoading={isSubmitting || isFormStateSubmitting}
          >
            {messages.TOSEND_BUTTON}
          </Button>
        </WrapperButton>
      </Column>
    </Wrapper>
  );
};
export default Common;
