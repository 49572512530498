import React from 'react';
import { StyledConfirmOfferButton } from './styles';

export interface DesktopProps {
  btnLabel: string;
  confirmOffer: () => void;
  isLoading?: boolean;
}

const ChoisirOffreButton: React.FC<DesktopProps> = ({
  btnLabel,
  confirmOffer,
  isLoading = false,
}) => {
  return (
    <React.Fragment>
      {btnLabel && (
        <StyledConfirmOfferButton
          variant="primary"
          onClick={confirmOffer}
          isLoading={isLoading}
        >
          {btnLabel}
        </StyledConfirmOfferButton>
      )}
    </React.Fragment>
  );
};
export default ChoisirOffreButton;
