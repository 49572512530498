import { Title, RadioList } from '@components';
import * as messages from './messages';
import {
  StyledCard,
  WrapperButtonEmprunteur,
  WrapperButtonCoemprunteur,
} from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import { selectSimulation, useAppSelector } from '@store';

export interface EmprunteurFormProps {
  simulationId?: number;
}

const choixEmprunteur = [
  { id: '1', label: messages.AGE_EMPRUNTEUR_LOWER },
  { id: '2', label: messages.AGE_EMPRUNTEUR_HIGHER },
];

const choixCoEmprunteur = [
  { id: '1', label: messages.WITHOUT_COEMPRUNTEUR },
  { id: '2', label: messages.AGE_EMPRUNTEUR_LOWER },
  { id: '3', label: messages.AGE_EMPRUNTEUR_HIGHER },
];

/**
 * Second card of create simulation page
 */
const EmprunteurForm: React.FC<EmprunteurFormProps> = ({ simulationId }) => {
  const { control } = useFormContext();

  const simulationPreviouslyEnteredValues =
    simulationId === 1
      ? useAppSelector(selectSimulation).simulation1?.enteredValue
      : useAppSelector(selectSimulation).simulation2?.enteredValue;

  return (
    <StyledCard>
      <Title level={3}>{messages.EMPRUNTEUR_TITLE}</Title>
      <WrapperButtonEmprunteur>
        <Controller
          control={control}
          name={'inputEmprunteurStatus'}
          defaultValue={
            simulationPreviouslyEnteredValues
              ? simulationPreviouslyEnteredValues.borrower
              : ''
          }
          rules={{ required: true }}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid },
          }) => (
            <RadioList
              name={name}
              onChange={(newValue: unknown) => {
                onChange(newValue);
              }}
              value={value}
              valueList={choixEmprunteur.map((item) => item.id) ?? []}
              options={choixEmprunteur ?? []}
              isBig
              isValid={!invalid}
              inputRef={ref}
            />
          )}
        />
      </WrapperButtonEmprunteur>
      <Title level={3}>{messages.COEMPRUNTEUR_TITLE}</Title>

      <WrapperButtonCoemprunteur>
        <Controller
          control={control}
          name={'inputCoEmprunteurStatus'}
          defaultValue={
            simulationPreviouslyEnteredValues
              ? simulationPreviouslyEnteredValues.coborrower
              : ''
          }
          rules={{ required: true }}
          render={({
            field: { onChange, value, name, ref },
            fieldState: { invalid },
          }) => (
            <RadioList
              name={name}
              onChange={(newValue: unknown) => {
                onChange(newValue);
              }}
              value={value}
              valueList={choixCoEmprunteur.map((item) => item.id) ?? []}
              options={choixCoEmprunteur ?? []}
              isBig
              isValid={!invalid}
              inputRef={ref}
            />
          )}
        />
      </WrapperButtonCoemprunteur>
    </StyledCard>
  );
};

export default EmprunteurForm;
