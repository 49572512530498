const Tablet: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 40 28" className={className}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path
          d="M2.75,28 C2.01666667,28 1.375,27.725 0.825,27.175 C0.275,26.625 0,25.9833333 0,25.25 L0,2.75 C0,2.01666667 0.275,1.375 0.825,0.825 C1.375,0.275 2.01666667,0 2.75,0 L37.25,0 C37.9833333,0 38.625,0.275 39.175,0.825 C39.725,1.375 40,2.01666667 40,2.75 L40,25.25 C40,25.9833333 39.725,26.625 39.175,27.175 C38.625,27.725 37.9833333,28 37.25,28 L2.75,28 Z M5.85,26.45 L34.15,26.45 L34.15,1.55 L5.85,1.55 L5.85,26.45 Z M4.3,1.55 L2.75,1.55 C2.41666667,1.55 2.13333333,1.66666667 1.9,1.9 C1.66666667,2.13333333 1.55,2.41666667 1.55,2.75 L1.55,25.25 C1.55,25.5833333 1.66666667,25.8666667 1.9,26.1 C2.13333333,26.3333333 2.41666667,26.45 2.75,26.45 L4.3,26.45 L4.3,1.55 Z M35.7,1.55 L35.7,26.45 L37.25,26.45 C37.5833333,26.45 37.8666667,26.3333333 38.1,26.1 C38.3333333,25.8666667 38.45,25.5833333 38.45,25.25 L38.45,2.75 C38.45,2.41666667 38.3333333,2.13333333 38.1,1.9 C37.8666667,1.66666667 37.5833333,1.55 37.25,1.55 L35.7,1.55 Z M38.45,1.55 L35.7,1.55 L38.45,1.55 Z M1.55,1.55 L4.3,1.55 L1.55,1.55 L1.55,1.55 Z"
          fill="currentColor"
        />
      </g>
      <g transform="translate(10.000000, 6.000000)" fill="currentColor">
        <path
          d="M17.7851662,13.0730994 C17.7851662,14.5394737 16.6189258,15.7324561 15.185422,15.7324561 L3.81457801,15.7324561 C2.38107417,15.7324561 1.21483376,14.5394737 1.21483376,13.0730994 L1.21483376,6.2880117 C1.21483376,4.82163743 2.38107417,3.62865497 3.81457801,3.62865497 L5.44245524,3.62865497 L6.00127877,3.23099415 L6.4629156,1.93859649 C6.70588235,1.49122807 7.14322251,1.24269006 7.62915601,1.24269006 L11.297954,1.24269006 C11.7838875,1.24269006 12.2455243,1.51608187 12.488491,1.98830409 L12.7800512,2.53508772 L13.8491049,1.93859649 L13.5575448,1.39181287 C13.0959079,0.521929825 12.2455243,0 11.297954,0 L7.65345269,0 C6.70588235,0 5.83120205,0.546783626 5.39386189,1.39181287 L5.02941176,2.41081871 L3.81457801,2.41081871 C1.70076726,2.41081871 0,4.1505848 0,6.3128655 L0,13.0979532 C0,15.2602339 1.70076726,17 3.81457801,17 L15.185422,17 C17.2992327,17 19,15.2602339 19,13.0979532 L19,7.87865497 L17.7851662,7.87865497 L17.7851662,13.0730994 Z"
          fill="currentColor"
        />
        <path
          d="M9.5,5 C7.02380952,5 5,7.02380952 5,9.5 C5,11.9761905 7.02380952,14 9.5,14 C11.9761905,14 14,11.9761905 14,9.5 C14,7.02380952 11.9761905,5 9.5,5 Z M9.5,12.8095238 C7.66666667,12.8095238 6.19047619,11.3333333 6.19047619,9.5 C6.19047619,7.66666667 7.66666667,6.19047619 9.5,6.19047619 C11.3333333,6.19047619 12.8095238,7.66666667 12.8095238,9.5 C12.8095238,11.3333333 11.3333333,12.8095238 9.5,12.8095238 Z"
          fill="currentColor"
        />
        <polygon
          fill="currentColor"
          points="18.6147541 3.39506173 18.6147541 1 17.3852459 1 17.3852459 3.39506173 15 3.39506173 15 4.62962963 17.3852459 4.62962963 17.3852459 7 18.6147541 7 18.6147541 4.62962963 21 4.62962963 21 3.39506173"
        />
      </g>
    </g>
  </svg>
);

export default Tablet;
