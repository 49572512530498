import { StyledWrapper } from './styles';
import { BorderColorType } from '@types';

export interface ICardWrapperProps {
  children?: React.ReactNode;
  hasBorder?: boolean;
  isDisabled?: boolean;
  className?: string;
  borderColor?: BorderColorType;
  onClick?: () => void;
  isActive?: boolean;
}

const Wrapper = ({
  children,
  className,
  hasBorder = false,
  isDisabled = false,
  borderColor,
  onClick,
}: ICardWrapperProps): React.ReactElement => (
  <StyledWrapper
    hasBorder={hasBorder}
    isDisabled={isDisabled}
    className={'ph-card ' + className}
    borderColor={borderColor}
    onClick={onClick}
  >
    {children}
  </StyledWrapper>
);

export default Wrapper;
