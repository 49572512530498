import { Centering, ErrorBoundary } from '@components';
import {
  CreateSimulation,
  ResultsSimulation,
  DetailResultSimulation,
} from '@layouts';
import { GlobalStyles } from '@styles';
import { Routes, Route, useLocation } from 'react-router-dom';
import SimulationHeader from './SimulationHeader/SimulationHeader';
import { selectConfiguration, useAppSelector } from '@store';
import { useEffect, useState } from 'react';
import { useNavigate } from '@hooks';
import { FooterSimu } from '@containers';

const ParcoursSimulation: React.FC<{
  vendorName: string;
}> = ({ vendorName = '' }) => {
  const configuration = useAppSelector(selectConfiguration);
  const location = useLocation();
  const { navigate } = useNavigate();

  useEffect(() => {
    if (configuration?.souscriptionId) {
      navigate('/offre', { state: { isModalOpen: true } });
    } else {
      return;
    }
  }, [location]);
  return (
    <>
      <>
        <SimulationHeader vendorName={vendorName} />
        <Centering>
          <ErrorBoundary>
            <Routes>
              <Route
                key="/simulation"
                path="/simulation"
                element={<CreateSimulation />}
              />
              <Route
                key="/simulation-results"
                path="/simulation-results"
                element={<ResultsSimulation />}
              />
              <Route
                key="/simulation-result-details"
                path="/simulation-result-details"
                element={<DetailResultSimulation />}
              />
            </Routes>
            <FooterSimu />
            <GlobalStyles />
          </ErrorBoundary>
        </Centering>
      </>
    </>
  );
};

export default ParcoursSimulation;
