const SvgBankId: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <title>Icon/32px/Document/bank ID32x</title>
    <g
      id="Icon/32px/Document/bank-ID"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-3">
        <path
          d="M25.9999999,6.99999995 C26.9204745,6.99999995 27.6666666,7.74619203 27.6666666,8.66666662 L27.6666666,21.9999999 C27.6666666,22.9204745 26.9204745,23.6666666 25.9999999,23.6666666 L5.99999993,23.6666666 C5.07952535,23.6666666 4.33333327,22.9204745 4.33333327,21.9999999 L4.33333327,8.66666662 C4.33333327,7.74619203 5.07952535,6.99999995 5.99999993,6.99999995 L25.9999999,6.99999995 Z M5.99999993,5.33333328 C4.15905077,5.33333328 2.6666666,6.82571745 2.6666666,8.66666662 L2.6666666,21.9999999 C2.6666666,23.8409491 4.15905077,25.3333333 5.99999993,25.3333333 L25.9999999,25.3333333 C27.8409491,25.3333333 29.3333333,23.8409491 29.3333333,21.9999999 L29.3333333,8.66666662 C29.3333333,6.82571745 27.8409491,5.33333328 25.9999999,5.33333328 L5.99999993,5.33333328 Z"
          id="credit-card-2-front"
          fill="#4B4F54"
          fillRule="nonzero"
        ></path>
        <rect
          id="Rectangle"
          fill="#4B4F54"
          fillRule="nonzero"
          x="6.55555553"
          y="19.2222222"
          width="18.8888888"
          height="2.22222221"
          rx="0.3"
        ></rect>
        <rect
          id="Rectangle-Copy"
          fill="#4B4F54"
          fillRule="nonzero"
          x="6.55555553"
          y="15.8888888"
          width="2.69841269"
          height="1.66666666"
          rx="0.2"
        ></rect>
        <rect
          id="Rectangle-Copy-2"
          fill="#4B4F54"
          fillRule="nonzero"
          x="10.6031746"
          y="15.8888888"
          width="2.69841269"
          height="1.66666666"
          rx="0.2"
        ></rect>
        <rect
          id="Rectangle-Copy-3"
          fill="#4B4F54"
          fillRule="nonzero"
          x="14.6507936"
          y="15.8888888"
          width="6.74603172"
          height="1.66666666"
          rx="0.2"
        ></rect>
        <rect
          id="Rectangle-Copy-4"
          fill="#4B4F54"
          fillRule="nonzero"
          x="22.7460317"
          y="15.8888888"
          width="2.69841269"
          height="1.66666666"
          rx="0.2"
        ></rect>
        <g
          id="Group-2"
          transform="translate(6.555556, 9.222222)"
          fill="#4B4F54"
          fillRule="nonzero"
        >
          <rect
            id="Rectangle-Copy-5"
            x="0"
            y="0"
            width="6.66666664"
            height="1"
          ></rect>
          <rect
            id="Rectangle-Copy-6"
            x="0"
            y="1.66666666"
            width="18.8888888"
            height="1"
          ></rect>
        </g>
        <g id="Group" transform="translate(6.555556, 15.888889)"></g>
      </g>
    </g>
  </svg>
);

export default SvgBankId;
