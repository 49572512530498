// Fichier de styles communs à l'arrache, à supprimer

import styled from 'styled-components';
import { Icons } from '@components';
import { MAX_WIDTH_SIZE } from '@utils';

// Message d'erreur
export const ErrorMsg = styled.div`
  display: flex;
  color: ${({ theme }) => theme.variables.colors.chartColors.red};
  justify-content: center;
  font-size: 1.5rem;
`;

// Navigation
export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xxl};
`;
export const WrapperBtnNext = styled.div`
  margin-left: auto;
`;
export const StyledChevronLeftIcon = styled(Icons.ChevronLeftIcon)`
  margin-right: 0.8rem;
`;
export const WrapperBtnBack = styled.div`
  padding: 0.7rem ${({ theme }) => theme.variables.layout.spacing.spacing_m}
    0.7rem 0;
`;
