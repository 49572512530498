import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './controlEmailTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const controlEmailSlice = createSlice({
  name: 'controlEmail',
  initialState,
  reducers: {
    setControlEmail(controlEmailState, action: PayloadAction<IState>) {
      controlEmailState.state = { ...action.payload };
    },
    updateControlEmail(
      controlEmailState,
      action: PayloadAction<Partial<IState>>
    ) {
      controlEmailState.state = {
        ...controlEmailState?.state,
        ...action.payload,
      };
    },
    clear(controlEmailState) {
      controlEmailState.state = getInitialState().state;
    },
  },
});

export const selectControlEmail = (controlEmailState: {
  controlEmail: { state: IState };
}) => controlEmailState.controlEmail.state;

export const { setControlEmail, updateControlEmail, clear } =
  controlEmailSlice.actions;

export default controlEmailSlice.reducer;
