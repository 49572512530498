const SvgPlayglyphe: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1a9 9 0 100 18 9 9 0 000-18zm-1.723 4.584l6 4a.5.5 0 010 .832l-6 4A.5.5 0 019.5 16V8a.5.5 0 01.777-.416z"
    />
  </svg>
);

export default SvgPlayglyphe;
