const SvgLaptop: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.306 5c.798 0 1.447.66 1.447 1.473v10.006h.948c.2 0 .299.153.299.305 0 1.168-.898 2.083-2.045 2.083H3.045C1.898 18.867 1 17.952 1 16.784c0-.152.15-.305.3-.305h.997V6.473C2.297 5.66 2.946 5 3.744 5zm2.045 12.14H1.65c.15.66.748 1.117 1.396 1.117h17.91c.698 0 1.247-.457 1.396-1.117zm-9.678.254c.4 0 .4.61 0 .61h-1.346c-.4 0-.4-.61 0-.61h1.346zM20.306 5.61H3.744a.849.849 0 00-.848.863v10.006h.748V7.54c0-.71.549-1.27 1.197-1.27H19.16c.648 0 1.197.56 1.197 1.27v8.94h.748V6.473c0-.457-.349-.863-.798-.863zM19.16 6.88H4.84c-.299 0-.598.304-.598.66v8.94h15.514V7.54c0-.356-.25-.66-.598-.66z"
    />
  </svg>
);

export default SvgLaptop;
