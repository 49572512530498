import styled from 'styled-components';
import { Button, ButtonCheck, Card } from '@components';

export const StyledCard = styled(Card.Wrapper)`
  padding-bottom: 2.9rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  }
`;

export const WrapperButtonEmprunteur = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};

  li {
    width: auto;
    flex-grow: 1;
  }

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    margin-right: 0;
  }
`;

export const StyledButtonEmprunteur = styled(ButtonCheck)<{
  isChecked: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 49%;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  color: ${({ theme, isChecked }) => {
    if (isChecked) {
      return theme.vendor.colors.primary;
    } else {
      return theme.variables.colors.chartColors.dark_700;
    }
  }};
  p {
    font-size: 14px;
    margin: 0;
  }
`;

export const WrapperButtonCoemprunteur = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};

  li {
    width: auto;
    max-width: calc(100% / 3);
    flex-grow: 1;
  }

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    flex-wrap: wrap;

    li {
      width: auto;
      max-width: 50%;
      flex-grow: 1;
    }

    li:last-child {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`;

export const StyledButtonWithoutCoemprunteur = styled(ButtonCheck)<{
  isChecked: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 33%;
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  color: ${({ theme, isChecked }) => {
    if (isChecked) {
      return theme.vendor.colors.primary;
    } else {
      return theme.variables.colors.chartColors.dark_700;
    }
  }};
  span {
    display: none;
  }
  p {
    font-size: 14px;
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: 100%;
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    span {
      display: flex;
      margin: auto 0;
    }
  }
`;

export const StyledButtonCoemprunteur = styled(ButtonCheck)<{
  isChecked: boolean;
}>`
display: flex;
justify-content: center;
width: 33%;
margin-right:${({ theme }) => theme.variables.layout.spacing.spacing_s};
padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
color: ${({ theme, isChecked }) => {
  if (isChecked) {
    return theme.vendor.colors.primary;
  } else {
    return theme.variables.colors.chartColors.dark_700;
  }
}};
p {
  font-size: 14px;
  margin: 0;
}

@media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
  margin-right:0;
  width:49%


`;

export const StyledButtonCoemprunteurMore65 = styled(ButtonCheck)<{
  isChecked: boolean;
}>`
display: flex;
justify-content: center;
width: 33%;
padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
color: ${({ theme, isChecked }) => {
  if (isChecked) {
    return theme.vendor.colors.primary;
  } else {
    return theme.variables.colors.chartColors.dark_700;
  }
}};
p {
  font-size: 14px;
  margin: 0;
}

@media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
  width:49%


`;

export const SimulationResult = styled(ButtonCheck)<{
  isChecked: boolean;
}>`
  display: flex;
  text-align: center;
  width: 100%;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  color: ${({ theme, isChecked }) => {
    if (isChecked) {
      return theme.vendor.colors.primary;
    } else {
      return theme.variables.colors.chartColors.dark_700;
    }
  }};
`;

export const SimulationValidateButtton = styled(Button)``;
