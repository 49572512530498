import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const FormWrapper = styled.div`
  margin-bottom: 4rem;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;

export const TitleWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;
