import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {    
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-size: 62.5%; /* 1rem = 10px */
    height: 100%;
  }
  input, select, textarea, button {
    font-family: inherit;
  }

  body {
    width: 100%;
    padding: 0;
    margin: 0;
    line-height: 1.5;
    font-size: 1.5rem;

    &.formation-mode {
      border: 8px solid #4a90e2;

      .formation-mode-top, .formation-mode-bottom {
        background: #4a90e2;
        position: fixed;
        width: 100%;
        z-index: 10;
        height: 8px;
      }
      .formation-mode-top {
        top: 0;
      }
      .formation-mode-bottom {
        bottom: 0;
      }

      .app {
        min-height: calc(100vh - 2 * 8px);
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  #app {
    background-color: #fff;
    height: 100%;
  }

  /* Style for PerfectScrollbar : alwars on the right */
  .ps__rail-y {
    left: auto !important;
  }
`;

export default GlobalStyle;
