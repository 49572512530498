import styled from 'styled-components';
import { StyledLabel, Wrapper } from '../Input/styles';
import { CheckIcon, SearchIcon } from '../Icons';
import Input from '../Input';

export { Wrapper };

export const SelectLabel = styled(StyledLabel)<IStylePropsLabel>`
  margin-bottom: 0.8rem;
  font-weight: ${({ isLabelBold }) => (isLabelBold ? 'bold' : 'normal')};
`;

interface IStyleProps {
  isOpen: boolean;
  isDisabled?: boolean;
  isValid?: boolean;
  isTouched?: boolean;
  isScrollBar?: boolean;
  isPlaceholder?: boolean;
  isBottom?: boolean;
  hasSearch?: boolean;
}

interface IStylePropsLabel {
  isLabelBold?: boolean;
  isPlaceholder?: boolean;
}

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledIcon = styled.div<IStyleProps>`
  width: 1.5rem;
  transition: transform 0.2s;
  transform: rotate(${({ isOpen }) => (isOpen ? '-180deg' : '0')});
`;

export const Item = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: ${({ theme }) =>
    `${theme.variables.layout.spacing.spacing_xs} ${theme.variables.layout.spacing.spacing_s}`};
  transition: padding 0.2s, opacity 0.1s;

  &:last-child {
    border: none;
    padding-bottom: 1.5rem;
  }

  &:first-child {
    padding-top: 1.5rem;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    width: calc(100% - 3.2rem);
    border-bottom: ${({ theme }) =>
      `solid 0.1rem ${theme.variables.colors.chartColors.grey_50}`};
  }

  &:hover {
    background-color: ${({ theme }) => `${theme.vendor.colors.lightest}`};
  }
`;

export const SearchInput = styled(Input)`
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

const listHeight = 15.9;

export const List = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: ${listHeight}rem;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  margin-left: 0.8rem;
  width: 1.4rem;
  height: 1.4rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const Dropdown = styled.div<IStyleProps>`
  position: absolute;
  width: calc(100% + 2px);
  left: -1px;
  top: calc(100% + 1rem);
  z-index: 5;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
  box-shadow: ${({ theme }) => theme.variables.colors.shadows[15]};
  overflow: hidden;
  cursor: auto;
  max-height: ${({ isOpen }) => (isOpen ? `${listHeight + 6}rem` : '0')};
  transition: max-height 0.2s;
  background: ${({ theme }) => theme.variables.colors.chartColors.white};
  &:before {
    content: '';
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1),
      ${({ theme }) => theme.variables.colors.chartColors.white}
    );
    width: calc(100% - 1.5rem);
    height: ${({ isBottom, hasSearch }) => {
      const searchHeight = hasSearch ? '20%' : '25%';
      return isBottom ? '0' : searchHeight;
    }};
    pointer-events: none;
    z-index: 1;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  width: 2.2rem;
`;

export const ItemText = styled.span`
  pointer-events: none;
  font-weight: normal;
`;

export const HighlightText = styled.span`
  pointer-events: none;
  font-weight: bold;
`;
