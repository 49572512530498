import { Title, Tooltip } from '@components';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.variables.layout.spaces.larger};
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;

export const TitleWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const ItemWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperRowInputs = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperInputZipCd = styled.div`
  display: flex;
  width: 14rem;
`;

export const WrapperInputCity = styled.div`
  display: flex;
  width: 100%;
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const WrapperInputMobilePhone = styled.div`
  display: flex;
  width: 49%;
`;

export const WrapperInputFixPhone = styled.div`
  display: flex;
  width: 49%;
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const StyledTooltip = styled(Tooltip)`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledTitleTooltip = styled(Title)`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  line-height: 2rem;
`;

export const StyledTextTooltip = styled.div`
  display: flex;
`;
