import { IOption, IOptionPricing } from '@types';
import { IUseOfferForm } from './types';
import { selectConfiguration, selectOffer, useAppSelector } from '@store';
import { useFormContext } from 'react-hook-form';
import {
  formatNumber,
  formatAsNumber,
  getPricingsList,
  LIMIT_TO_0_DECIMALS_PATTERN,
  FORMAT_NUMBER_PATTERN,
  LIMIT_TO_2_DECIMALS_PATTERN,
  NUMBER_PATTERN,
} from '@utils';
import { useEffect, useRef, useState } from 'react';
import { usePersistingForm, usePersistingState } from '@hooks';
import * as messages from './messages';

const useOfferForm = (): IUseOfferForm => {
  const configuration = useAppSelector(selectConfiguration);
  const offer = useAppSelector(selectOffer);
  const { purchaseAmt, depositAmt } = {
    ...configuration?.project,
  };
  const { products, listPlaces } = {
    ...configuration,
  };
  const inputPurchaseDefaultValue = formatNumber(purchaseAmt) || '';
  const inputDepositDefaultValue = formatNumber(depositAmt) || '0';

  const { control, watch, getValues, setValue, trigger } = useFormContext();

  usePersistingForm('form-offer', watch, setValue);

  const typeLoan = getValues('inputTypeLoan');
  const purchaseAmount = getValues('inputPurchaseAmount');
  const depositAmount = getValues('inputDepositAmount');
  const loanAmount = getValues('inputLoanAmount');
  const materialCategory = getValues('inputMaterialCategory');
  const termNb = getValues('inputTermNb');
  const pricingId = getValues('inputPricing');
  const condition = getValues('inputCondition');

  const defaultValuePricing = offer?.inputPricing;

  const refPricing = useRef<string | undefined>();

  const [listTypeLoan, setListTypeLoan] = usePersistingState<
    IOption[] | undefined
  >('list-type-loan', undefined);

  const [listMaterials, setListMaterials] = usePersistingState<
    IOption[] | undefined
  >('list-materials', undefined);

  const [listPricings, setListPricings] = usePersistingState<
    IOptionPricing[] | undefined
  >('list-pricings', undefined);

  const [listDeferments, setListDeferments] = usePersistingState<
    IOption[] | undefined
  >('list-deferments', undefined);

  const [deferment, setDeferment] = usePersistingState<string>(
    'deferment',
    getValues('inputDeferment')
  );

  const [listConditions, setListConditions] = usePersistingState<
    { id: string }[] | undefined
  >('list-conditions', undefined);

  const [isConditionChoiceAvailable, setIsConditionChoiceAvailable] =
    usePersistingState('is-condition-choice-available', false);

  const [isDefermentVisible, setIsDefermentVisible] = usePersistingState(
    'is-deferment-visible',
    false
  );

  const [isErrorPricingVisible, setIsErrorPricingVisible] = usePersistingState(
    'is-error-pricing-visible',
    false
  );

  const [minimumLoanAmount, setMinimumLoanAmount] = usePersistingState<
    number | undefined
  >('minimum-loan-amount', undefined);

  const [maximumLoanAmount, setMaximumLoanAmount] = usePersistingState<
    number | undefined
  >('maximum-loan-amount', undefined);

  const [minimumTermNumber, setMinimumTermNumber] = usePersistingState<
    number | undefined
  >('minimum-term-number', undefined);

  const [maximumTermNumber, setMaximumTermNumber] = usePersistingState<
    number | undefined
  >('maximum-term-number', undefined);

  const [errorLoanAmount, setErrorLoanAmount] = useState('');
  const [errorTermNb, setErrorTermNb] = useState('');

  const formattedInputPurchase = formatAsNumber(purchaseAmount);
  const formattedDepositAmount = formatAsNumber(depositAmount);

  const shouldTouchAndValidate = {
    shouldTouch: true,
    shouldValidate: true,
  };

  const shouldValidate = {
    shouldValidate: true,
  };

  const onValidateInputPurchase = (value: string) => {
    // try/catch pour gérer les retours vers la page offre et réinit de la page
    try {
      return formatAsNumber(value) - formattedDepositAmount > 0;
    } catch {
      return false;
    }
  };

  const onValidateInputDeposit = (value: string) => {
    // try/catch pour gérer les retours vers la page offre et réinit de la page
    try {
      return formattedInputPurchase - formatAsNumber(value) > 0;
    } catch {
      return false;
    }
  };

  const onValidateInputLoanAmount = () => {
    if (!typeLoan) {
      return true;
    }
    const loanAmount = formattedInputPurchase - formattedDepositAmount;
    const isValid =
      (minimumLoanAmount ?? 0) <= loanAmount &&
      loanAmount <= (maximumLoanAmount ?? 0);
    if (isValid) {
      setErrorLoanAmount('');
    } else {
      setErrorLoanAmount(
        messages.ERROR_LOAN_AMOUNT +
          formatNumber(minimumLoanAmount) +
          messages.AND +
          formatNumber(maximumLoanAmount) +
          messages.EURO
      );
    }
    return isValid;
  };

  const onValidateInputTermNumber = (value: string) => {
    if (!typeLoan) {
      return true;
    }
    const isValid =
      (minimumTermNumber ?? 0) <= formatAsNumber(value) &&
      formatAsNumber(value) <= (maximumTermNumber ?? 0);
    if (isValid) {
      setErrorTermNb('');
    } else {
      setErrorTermNb(
        messages.ERROR_TERM_NB +
          formatNumber(minimumTermNumber) +
          messages.AND +
          formatNumber(maximumTermNumber) +
          messages.MONTHS
      );
    }
    return isValid;
  };

  const onFocusInputPrice = (
    value: string,
    onChange: (arg0: string) => void
  ) => {
    if (value) {
      onChange(value.toString().replace(/ /, ''));
    }
  };

  const onChangeInputPrice = (
    value: string,
    newValue: string | number,
    onChange: (arg0: string) => void
  ): string => {
    const formattedValue = newValue
      .toString()
      .replace(/^0([0-9]+)/, '$1')
      .replace(/ /, '')
      .replace(/\./, ',')
      .replace(LIMIT_TO_2_DECIMALS_PATTERN, '$1'); // limit to 2 decimals

    if (formattedValue && !NUMBER_PATTERN.test(formattedValue)) {
      onChange(value);
    } else if (
      (Number.parseFloat(formattedValue) >= 0 &&
        Number.parseFloat(formattedValue) < 1000000) ||
      formattedValue === ''
    ) {
      onChange(formattedValue);
      return formattedValue;
    }
    return value;
  };

  const onChangeInputTermNb = (
    value: string,
    newValue: string | number,
    onChange: (arg0: string) => void
  ) => {
    const formattedValue = newValue
      .toString()
      .replace(/^0([0-9]+)/, '$1')
      .replace(LIMIT_TO_0_DECIMALS_PATTERN, '$1');
    const patternNumber = /^\d+?$/;
    if (formattedValue && !patternNumber.test(formattedValue)) {
      onChange(value);
    } else {
      onChange(formattedValue);
    }
  };

  const getMaterialCategoryLabel = (newMaterialCategoryId: string) => {
    return (
      (listMaterials ?? []).find(
        (material) => material?.id === newMaterialCategoryId
      )?.label ?? ''
    );
  };

  const onBlurInputPrice = (
    value: string,
    onChange: (arg0: string) => void
  ) => {
    if (value !== '') {
      // format to display
      let newValue = value
        .toString()
        .replace('.', ',') // replace decimal point character with ,
        .replace(FORMAT_NUMBER_PATTERN, '$1 '); // use ' ' as a separator

      // remove comma if no decimal digits ?
      if (newValue.indexOf(',') !== -1 && newValue.split(',')[1].length === 0) {
        newValue = newValue.replace(',', '');
      }

      if (!newValue) {
        newValue = '0';
      }

      onChange(newValue);
    }
  };

  const onClickFreePricing = () => {
    const newCondition = listConditions?.find(
      (element) => element.id !== condition
    );
    setValue('inputCondition', newCondition?.id, shouldTouchAndValidate);
  };

  useEffect(() => {
    const newList: IOption[] =
      products?.map((product) => {
        return { id: product.id, label: product.libelle };
      }) || [];
    setListTypeLoan(newList);
  }, [products]);

  useEffect(() => {
    if (listTypeLoan?.length === 1) {
      setValue('inputTypeLoan', listTypeLoan[0].id, shouldTouchAndValidate);
    }
  }, [listTypeLoan]);

  useEffect(() => {
    // Ces champs sont vidés à l'initialisation, on contourne donc le problème pour éviter de vider les champs lors d'un retour arrière
    if (!typeLoan) {
      return;
    }

    const newList: IOption[] =
      products
        ?.find((product) => product.id === typeLoan)
        ?.materiels?.map((categorie) => {
          return { id: categorie.code_materiel, label: categorie.nom };
        }) || [];
    setListMaterials(newList);

    const product = products?.find((product) => product.id === typeLoan);
    setMinimumLoanAmount(product?.montant_min);
    setMaximumLoanAmount(product?.montant_max);
    setMinimumTermNumber(product?.duree_min);
    setMaximumTermNumber(product?.duree_max);
  }, [typeLoan, products]);

  useEffect(() => {
    trigger('inputLoanAmount');
    trigger('inputTermNb');
  }, [
    minimumLoanAmount,
    maximumLoanAmount,
    minimumTermNumber,
    maximumTermNumber,
  ]);

  useEffect(() => {
    if (materialCategory === undefined) {
      return;
    }

    const foundMaterialCategory = listMaterials?.find(
      (element) => element.id === materialCategory
    );
    if (listMaterials?.length === 1) {
      setValue(
        'inputMaterialCategory',
        listMaterials[0].id,
        shouldTouchAndValidate
      );
    } else {
      setValue(
        'inputMaterialCategory',
        foundMaterialCategory?.id ?? '',
        shouldValidate
      );
    }
  }, [listMaterials]);

  useEffect(() => {
    trigger('inputPurchaseAmount');
    trigger('inputDepositAmount');
  }, [purchaseAmount, depositAmount]);

  useEffect(() => {
    if (formattedDepositAmount < formattedInputPurchase) {
      const newValueInputLoanAmount =
        Math.round((formattedInputPurchase - formattedDepositAmount) * 100) /
        100;
      setValue(
        'inputLoanAmount',
        newValueInputLoanAmount.toString(),
        shouldTouchAndValidate
      );
    } else {
      setValue('inputLoanAmount', '', shouldTouchAndValidate);
    }
  }, [formattedInputPurchase, formattedDepositAmount]);

  useEffect(() => {
    // Ces champs sont vidés à l'initialisation, on contourne donc le problème pour éviter de vider les champs lors d'un retour arrière
    if (!loanAmount || !materialCategory || !termNb || !typeLoan) {
      return;
    }

    const newListPricings = getPricingsList(
      typeLoan,
      loanAmount,
      termNb,
      products
    );
    setListPricings(newListPricings);
    setIsErrorPricingVisible(!!loanAmount && !!materialCategory && !!termNb);
  }, [loanAmount, materialCategory, termNb, typeLoan]);

  useEffect(() => {
    if (pricingId === undefined) {
      return;
    }

    let foundPricing = listPricings?.find(
      (element) => element.id === pricingId
    );
    if (!foundPricing) {
      foundPricing = listPricings?.find(
        (element) => element.id === defaultValuePricing
      );
    }
    if (listPricings?.length === 1) {
      setValue('inputPricing', listPricings[0].id, shouldTouchAndValidate);
    } else {
      setValue('inputPricing', foundPricing?.id ?? '', shouldValidate);
    }

    const foundCondition = listConditions?.find(
      (element) => element.id === condition
    );
    setValue('inputCondition', foundCondition?.id ?? '', shouldValidate);
  }, [listPricings]);

  useEffect(() => {
    if (pricingId === undefined) {
      return;
    }

    const foundPricing = listPricings?.find(
      (element) => element.id === pricingId
    );

    const newDefermentList = foundPricing?.deferment?.map((defermentItem) => {
      return {
        id: defermentItem.toString(),
        label: defermentItem.toString(),
      };
    });
    setListDeferments(newDefermentList);

    const newConditionList = foundPricing?.modality?.map((conditionItem) => {
      return {
        id: conditionItem.code,
      };
    });
    setListConditions(newConditionList);
  }, [pricingId, listPricings]);

  useEffect(() => {
    if (pricingId === undefined) {
      return;
    }

    const foundCondition = listConditions?.find(
      (element) => element.id === condition
    );

    setIsConditionChoiceAvailable(listConditions?.length === 2);

    if (foundCondition) {
      setValue('inputCondition', foundCondition?.id, shouldTouchAndValidate);
      return;
    }

    if (listConditions?.length === 1) {
      setValue('inputCondition', listConditions[0].id, shouldTouchAndValidate);
    } else {
      const newCondition = listConditions?.find(
        (element) => element.id.charAt(1) === 'C'
      );
      setValue('inputCondition', newCondition?.id, shouldTouchAndValidate);
    }
  }, [listConditions, listPricings]);

  // AER_HABITAT-539 - mémorisation de deferment
  useEffect(() => {
    // Deferment est visible s'il y a une tarification sélectionnée et une liste de deferments
    let isVisible =
      pricingId && listDeferments && (listDeferments?.length ?? 0) > 0;

    // Par défaut, la valeur de deferment est 30, on ne l'affiche pas si c'est la seule valeur proposée
    if (listDeferments?.length === 1) {
      if (listDeferments[0].id === '30') {
        isVisible = false;
      }
      setValue('inputDeferment', listDeferments[0].id, shouldTouchAndValidate);
    } else if (!!refPricing.current && refPricing.current !== pricingId) {
      setValue('inputDeferment', '', shouldValidate);
    }
    refPricing.current = pricingId;

    if (getValues('inputDeferment') !== deferment) {
      setDeferment(getValues('inputDeferment'));
    }
    setIsDefermentVisible(isVisible);
  }, [
    listPricings,
    listConditions,
    listDeferments,
    pricingId,
    deferment,
    getValues('inputDeferment'),
  ]);

  return {
    control,
    listMaterials,
    listTypeLoan,
    listDeferments,
    listPricings,
    listPlaces,
    inputPurchaseDefaultValue,
    inputDepositDefaultValue,
    onValidateInputPurchase,
    onValidateInputDeposit,
    onValidateInputLoanAmount,
    onValidateInputTermNumber,
    onClickFreePricing,
    onFocusInputPrice,
    onChangeInputPrice,
    onChangeInputTermNb,
    getMaterialCategoryLabel,
    onBlurInputPrice,
    isConditionChoiceAvailable,
    isDefermentVisible,
    isErrorPricingVisible,
    errorLoanAmount,
    errorTermNb,
  };
};

export default useOfferForm;
