const SvgDownload: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.5 14.75a.75.75 0 111.5 0v3.667A2.583 2.583 0 0118.417 21H5.583A2.583 2.583 0 013 18.417V14.75a.75.75 0 111.5 0v3.667c0 .598.485 1.083 1.083 1.083h12.834c.598 0 1.083-.485 1.083-1.083V14.75zM12 3a.75.75 0 01.75.75v9.188l3.303-3.302a.75.75 0 011.06 1.061L12.53 15.28a.754.754 0 01-.078.069l.078-.069a.754.754 0 01-.53.22h-.028a.754.754 0 01-.046-.004L12 15.5a.754.754 0 01-.53-.22l-4.584-4.583a.75.75 0 011.061-1.06l3.303 3.303V3.75A.75.75 0 0112 3z"
    />
  </svg>
);

export default SvgDownload;
