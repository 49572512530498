import { Logo } from '@components';
import { WrapperHeader, HeaderLink, StyledDropdownMenu } from './styles';
import * as LABELS from './messages';
import { useParcoursType } from '@hooks';

export interface IProps {
  vendorId?: string;
  vendorName?: string;
  children?: React.ReactElement;
  redirigerAccueil: () => void;
  logout: () => void;
}

const Header: React.FC<IProps> = ({
  vendorId,
  vendorName,
  children,
  redirigerAccueil,
  logout,
}) => {
  const { isParcoursSwitchDevice } = useParcoursType();

  return (
    <WrapperHeader className="ph-header">
      <HeaderLink>{vendorName && <Logo name={vendorName} />}</HeaderLink>
      {children}
      {!isParcoursSwitchDevice() && (
        <StyledDropdownMenu
          vendorId={vendorId}
          name={LABELS.LABEL_PARTNER}
          redirigerAccueil={redirigerAccueil}
          logout={logout}
        />
      )}
    </WrapperHeader>
  );
};

export default Header;
