const SvgBank: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.5 20a.5.5 0 110 1h-19a.5.5 0 110-1h19zm-2-2a.5.5 0 110 1h-15a.5.5 0 110-1h15zM6.532 8.5a1.5 1.5 0 011.5 1.5v6a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5zM12 8.5a1.5 1.5 0 011.5 1.5v6a1.5 1.5 0 01-3 0v-6A1.5 1.5 0 0112 8.5zm5.532 0a1.5 1.5 0 011.5 1.5v6a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5zm-11 1a.5.5 0 00-.5.5v6a.5.5 0 101 0v-6a.5.5 0 00-.5-.5zM12 9.5a.5.5 0 00-.5.5v6a.5.5 0 101 0v-6a.5.5 0 00-.5-.5zm5.532 0a.5.5 0 00-.5.5v6a.5.5 0 101 0v-6a.5.5 0 00-.5-.5zM12.28 3.029l8.968 2.284a1 1 0 01.753.97V7a1 1 0 01-1 1H3a1 1 0 01-1-1v-.708a1 1 0 01.754-.97l9.032-2.294a1 1 0 01.493 0zm-.247.969L3 6.292V7h18v-.718l-8.968-2.284z"
    />
  </svg>
);

export default SvgBank;
