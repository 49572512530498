import styled from 'styled-components';
import { CheckIcon } from '../Icons';

export const StyledCheckBox = styled.input`
  display: flex;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
`;

export const StyledCheckBoxLabel = styled.span<{
  isDisabled: boolean;
}>`
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? theme.variables.colors.chartColors.grey_200
      : theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  align-self: center;
`;

export const StyledCheckIcon = styled(CheckIcon)<{
  isVisible: boolean;
}>`
  width: 100%;
  height: 100%;
  padding: 0.4rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.white};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  pointer-events: 'none';
  stroke: white;
`;

export const StyledCheckmark = styled.span<{
  isChecked: boolean;
  isDisabled: boolean;
}>`
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 2px;
  width: ${({ theme }) => theme.variables.layout.spaces.medium};
  height: ${({ theme }) => theme.variables.layout.spaces.medium};
  border-radius: 15%;
  border: ${({ isChecked, theme }) =>
    !isChecked
      ? `${theme.variables.colors.chartColors.grey_200} solid 1px`
      : 'none'};
  background-color: ${({ isChecked, isDisabled, theme }) => {
    if (isChecked && !isDisabled) {
      return theme.vendor.colors.primary;
    }
    if (isChecked && isDisabled) {
      return theme.variables.colors.chartColors.grey_200;
    }
    return 'transparent';
  }};
`;

export const WrapperCheckBoxLabel = styled.label<{
  isChecked: boolean;
  isDisabled: boolean;
}>`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  cursor: pointer;
  pointer-events: 'none';
  margin: 0 0 1.2rem 0;

  &:hover ${StyledCheckmark} {
    ${({ isDisabled, theme }) =>
      !isDisabled &&
      `
      box-shadow: 0 0 0 ${theme.variables.layout.spaces.small} ${theme.vendor.colors.light};
    `}

    ${({ isChecked, isDisabled, theme }) =>
      !isChecked &&
      !isDisabled &&
      `
      border: ${theme.vendor.colors.primary} solid 0.1rem;
    `}
  }
`;

export const WrapperCheckBox = styled.span`
  display: flex;
`;
