import styled from 'styled-components';

const FooterWrapper = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_l};

  p {
    margin: 0;
    padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

    &.text {
      font-weight: bold;
      color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
    }
    &.links {
      border-top: 1px solid
        ${({ theme }) => theme.variables.colors.chartColors.grey_50};
      font-size: 1.3rem;

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.variables.colors.chartColors.grey_500};
        padding: 0 1rem;
        border-left: 1px solid
          ${({ theme }) => theme.variables.colors.chartColors.grey_300};

        &:first-child {
          border-left: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export default FooterWrapper;
