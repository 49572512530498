import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Button, Title, Link, Card } from '@components';

export const StyledWrapperHeaderNode = styled.div`
  font-size: 14px;
`;

export const StyledTitleContainer = styled.div`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const StyledTitle = styled.h1`
  margin: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  padding: 0;
  display: flex;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.titleModalDocument};
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;

export const ElectronicSigningButton = styled(Button)<{
  isExpanded?: boolean;
}>`
  background-color: ${({ theme, isExpanded }) =>
    isExpanded
      ? `${lighten(0.3, theme.vendor.colors.primary)} !important`
      : theme.vendor.colors.primary};
  border-color: ${({ theme, isExpanded }) =>
    isExpanded
      ? `${lighten(0.3, theme.vendor.colors.primary)} !important`
      : theme.vendor.colors.primary};
`;

export const BottomButton = styled.div<{
  isExpanded?: boolean;
}>`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  background-color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.variables.colors.chartColors.grey_50 : 'transparent'};
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  border-radius: ${({ theme }) =>
    `${theme.variables.layout.spacing.spacing_xs} ${theme.variables.layout.spacing.spacing_xs} 0 0`};
`;

export const StyledContainer = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ElectronicSignatureContainer = styled.div<{
  isExpanded: boolean;
}>`
  text-align: center;
  background-color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.variables.colors.chartColors.grey_50 : 'transparent'};
  padding: ${({ theme, isExpanded }) =>
    isExpanded ? theme.variables.layout.spacing.spacing_m : '1.2rem'};
  max-height: ${({ isExpanded }) => (isExpanded ? '48rem' : '0rem')};
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  overflow: hidden;
  transition: max-height 0.2s, background-color 0.2s, opacity 0.2s;
  border-radius: 0 0 0.8rem 0.8rem;
  margin: 0
    calc(${({ theme }) => theme.variables.layout.spacing.spacing_xl} * -1)
    calc(${({ theme }) => theme.variables.layout.spacing.spacing_xl} * -1); // negative margins
`;

export const ElectronicSignatureTitle = styled(Title).attrs({ level: 2 })`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;

export const ElectronicSignatureText = styled.p`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  line-height: 2rem;
`;

export const StyledLink = styled(Link)<{ isDisabled?: boolean }>`
  display: inline-block;
  color: ${({ theme }) => theme.vendor.colors.primary};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  line-height: 2rem;
  ${({ isDisabled }) => {
    if (isDisabled === true) {
      return css`
        cursor: default;
        pointer-events: none;
        text-decoration: none;
        color: grey;
      `;
    }
  }}
`;

export const StyledButtonBackPOP = styled.button`
  border: none;
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
  color: inherit;

  &:hover {
    cursor: pointer;
  }
`;

export const ValidateSubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 72.8rem;
`;

export const StyledCardWrapper = styled(Card.Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
  border: 0;
`;
export const StyledFooter = styled.div`
  width: calc(100% + 9.6rem);
  margin-left: -${({ theme }) => theme.variables.layout.spacing.spacing_xl};
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;
