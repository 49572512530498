const SvgCrosscircleoutlines: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-1.395a8.605 8.605 0 100-17.21 8.605 8.605 0 000 17.21zm3.284-11.889a.698.698 0 010 .987L12.987 12l2.297 2.297a.698.698 0 01-.987.987L12 12.987l-2.297 2.297a.698.698 0 01-.987-.987L11.013 12 8.716 9.703a.698.698 0 11.987-.987L12 11.013l2.297-2.297a.698.698 0 01.987 0z"
    />
  </svg>
);

export default SvgCrosscircleoutlines;
