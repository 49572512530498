const SvgSetting: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 15.227a3.227 3.227 0 110-6.454 3.227 3.227 0 010 6.454zm0-1a2.227 2.227 0 100-4.454 2.227 2.227 0 000 4.454zm8.1-.909h.082a1.318 1.318 0 000-2.636h-.157a2 2 0 01-1.83-1.212l-.03-.144a2.002 2.002 0 01.436-2.134l.055-.055a1.318 1.318 0 10-1.866-1.865l-.059.058a1.998 1.998 0 01-2.2.402A2 2 0 0113.317 3.9v-.082a1.318 1.318 0 10-2.636 0v.157a2 2 0 01-1.212 1.83l-.144.03c-.73.287-1.565.12-2.134-.436l-.055-.055A1.318 1.318 0 105.272 7.21l.058.059c.572.584.73 1.458.412 2.176a2.004 2.004 0 01-1.842 1.31h-.082a1.318 1.318 0 100 2.636h.157c.797.003 1.516.48 1.828 1.207.33.748.172 1.622-.404 2.21l-.055.055a1.318 1.318 0 101.866 1.865l.059-.058a1.987 1.987 0 012.176-.412c.771.283 1.29 1.01 1.31 1.842v.082a1.318 1.318 0 002.636 0v-.157a1.998 1.998 0 011.207-1.828 2.002 2.002 0 012.21.404l.055.055a1.318 1.318 0 101.865-1.866l-.058-.059a2 2 0 01-.401-2.203 2 2 0 011.831-1.21zm-.96-4.186a1 1 0 00.887.55h.155a2.318 2.318 0 010 4.636h-.08a1 1 0 00-.915.606l-.002.005a.998.998 0 00.196 1.1l.054.054a2.318 2.318 0 11-3.28 3.28l-.05-.051a1.002 1.002 0 00-1.108-.198 1 1 0 00-.606.913v.155a2.318 2.318 0 01-4.636 0v-.07c-.01-.41-.27-.774-.684-.927a.998.998 0 00-1.1.196l-.054.054a2.318 2.318 0 11-3.28-3.28l.051-.05c.286-.292.365-.73.198-1.108a1 1 0 00-.913-.606h-.155a2.318 2.318 0 010-4.636h.07c.41-.01.774-.27.927-.684a.998.998 0 00-.196-1.1l-.054-.054a2.318 2.318 0 113.28-3.28l.05.051a1 1 0 001.103.2l.134-.028a1 1 0 00.55-.887v-.155a2.318 2.318 0 114.636 0v.08c.002.399.24.758.611.917a.998.998 0 001.1-.196l.054-.054a2.318 2.318 0 113.28 3.28l-.051.05a1 1 0 00-.2 1.103l.028.134z"
    />
  </svg>
);

export default SvgSetting;
