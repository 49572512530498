import styled, { css } from 'styled-components';
import { InfoCard, FormEditableField } from '@components';

export const StyledInfoCardContainer = styled.div`
  margin: ${({ theme }) => `0 ${theme.variables.layout.spacing.spacing_m}`};
`;

export const StyledInfoCard = styled(InfoCard)`
  padding-bottom: 0px;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const StyledFormContainer = styled.div<{ withHeaderNode: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4.8rem;

  ${({ withHeaderNode }) =>
    !withHeaderNode &&
    css`
      & > :first-child {
        padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
      }
    `}
`;

export const StyledFormField = styled(FormEditableField)`
  min-width: 68%;
`;
