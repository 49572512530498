import SubtitleWrapper from './styles';

export interface ISubtitleProps {
  children?: React.ReactNode;
  className?: string;
}

const Subtitle = ({
  children,
  className = '',
}: ISubtitleProps): React.ReactElement => (
  <SubtitleWrapper className={className}>{children}</SubtitleWrapper>
);

export default Subtitle;
