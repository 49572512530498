import {
  configuration,
  selectConfiguration,
  useAppSelector,
  useSendLogsMutation,
} from '@store';
import { getIhmVersion } from '@utils';
import { useEffect } from 'react';

export type TLevelLog = 'log' | 'error' | 'warn' | 'debug';

const useSendLogs = (): {
  sendLogs: (level?: TLevelLog | 'log') => void;
} => {
  // Tous les types de logs
  const logLevels: TLevelLog[] = ['log', 'error', 'warn', 'debug'];
  const [sendLogsMutation, response] = useSendLogsMutation();
  const {
    souscriptionId,
    requestId,
    vendorId,
    isInFormationMode,
    isSimu,
    isPassageDeContexte,
  } = useAppSelector(selectConfiguration);

  // Au chargement, on bind chaque type de log
  useEffect(() => {
    // @ts-ignore
    if (!console.completeLogs) {
      // @ts-ignore
      console.completeLogs = [];
    }
    logLevels.forEach((logType: TLevelLog) => {
      if (logType === 'error') {
        return;
      }
      // @ts-ignore
      bindConsole(logType);
    });
    bindConsoleError();
  }, []);

  // Pour un niveau de log, on écoute et stocke dans un tableau
  const bindConsole = (level: TLevelLog) => {
    // @ts-ignore
    if (console[level + 's']) {
      return;
    }
    // @ts-ignore
    console['std' + level] = console[level].bind(console);
    // @ts-ignore
    console[level + 's'] = [];
    console[level] = function () {
      // @ts-ignore
      console[level + 's'].push(Array.from(arguments));
      // @ts-ignore
      console.completeLogs.push(Array.from(arguments));
      // @ts-ignore
      console['std' + level].apply(console, arguments);
    };
  };

  // Pour un niveau de log, on écoute et stocke dans un tableau
  const bindConsoleError = () => {
    // @ts-ignore
    if (console['errors']) {
      return;
    }
    // @ts-ignore
    console['stderror'] = console.error.bind(console);
    // @ts-ignore
    console['errors'] = [];
    console.error = function () {
      // @ts-ignore
      console['errors'].push(Array.from(arguments));
      // @ts-ignore
      console.completeLogs.push(Array.from(arguments));
      // @ts-ignore
      if ((console.sendLogsFailed ?? 0) < 3) {
        const version = getIhmVersion();
        sendLogsMutation({
          niveauLog: 'error',
          requestId: requestId ?? '',
          numeroSouscription: souscriptionId ?? '',
          device: { userAgent: navigator?.userAgent },
          logs: {
            logs: prepareDataFromLog([
              ...Array.from(arguments),
              'Front Version => ' + version,
              'Request ID => ' + requestId,
              'Vendor ID => ' + vendorId,
              'Souscription ID => ' + souscriptionId,
              'Mode formation => ' + isInFormationMode,
              'Simulation => ' + isSimu,
              'Passage de contexte => ' + isPassageDeContexte,
            ]),
            version,
          },
        });
      }
      // @ts-ignore
      console['stderror'].apply(console, arguments);
    };
  };

  // sur une erreur (runtime par exemple), on log en erreur
  window.onerror = (message, file, line, column, errorObj) => {
    // Affichage de l'erreur + envoi des logs vers le MS sous
    console.error('Error dans window.onerror => ', errorObj);
  };

  const getDataSplit = (data = '') => {
    return data?.match(/(\S|\s){1,400}/g) ?? [];
  };

  const prepareDataFromLog = (currentLog: any) => {
    const preparedData: string[] = [];
    (currentLog ?? []).forEach((logArgument: any) => {
      if (!logArgument) {
        return;
      }
      if (logArgument.message || logArgument.stack) {
        preparedData.push(
          ...getDataSplit(
            (logArgument.message ?? '') + (logArgument.stack ?? '')
          )
        );
      } else {
        try {
          preparedData.push(...getDataSplit(JSON.stringify(logArgument)));
        } catch {}
      }
    });
    return preparedData;
  };

  const prepareData = (
    level: TLevelLog | 'completeLog' = 'completeLog'
  ): string[] => {
    const preparedData: string[] = [];
    try {
      // @ts-ignore
      const logsToSend = [...console[level + 's']];
      // @ts-ignore
      console[level + 's'] = [];
      // @ts-ignore , pour chaque log
      logsToSend.forEach((log) => {
        preparedData.push(...(prepareDataFromLog(log) ?? []));
      });
    } catch {}
    return preparedData;
  };

  const sendLogs = (level?: TLevelLog) => {
    sendLogsMutation({
      niveauLog: level ?? '',
      requestId: requestId ?? '',
      numeroSouscription: souscriptionId ?? '',
      device: { userAgent: navigator?.userAgent },
      logs: { logs: prepareData(level) },
    });
  };

  return { sendLogs };
};

export default useSendLogs;
