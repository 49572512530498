import { DetailResultSimulationWrapper, StyledButton } from './styles';
import { DetailResultSimulationCard } from './DetailResultSimulationCard/DetailResultSimulationCard';
import InfoBulle from './InfoBulle';
import { useLocation, useNavigate } from 'react-router-dom';
import * as messages from './messages';
import {
  DesktopWrapperButton,
  MobileWrapper,
} from 'containers/ResultsSimulation/styles';
import {
  selectConfiguration,
  updateConfiguration,
  updateOffer,
  useAppDispatch,
  useAppSelector,
  useSendCreateSouscriptionMutation,
} from '@store';
import {
  clear,
  selectSimulation,
  setSimulation,
} from 'store/simulation/simulationSlice';
import ChoisirOffreButton from 'containers/CreateSimulation/SimulationButton/ChoisirOffreButton';
import { getURLParams } from '@utils';
import { useEffect } from 'react';

export const DetailResultSimulation: React.FC = () => {
  const navigate = useNavigate();
  const { simulation1, simulation2, selectedSimulationDetails } =
    useAppSelector(selectSimulation);
  const location = useLocation();
  const simulationId =
    location?.state?.simulationId ??
    selectedSimulationDetails?.simulationId ??
    1;
  const bornesMontant = simulation1?.calculatedValue;
  const [triggerSendSouscription, souscriptionResponse] =
    useSendCreateSouscriptionMutation();
  const dispatch = useAppDispatch();
  const {
    vendorId,
    isInFormationMode,
    marqueBlanche,
    typeVente,
    requestId,
    souscriptionId,
    isSimu,
  } = useAppSelector(selectConfiguration);

  useEffect(() => {
    if (
      !souscriptionResponse?.data ||
      // Récupération du numéro de souscription dans la réponse
      (
        getURLParams(souscriptionResponse?.data?.apply_url ?? '').get(
          'numeroSouscription'
        ) ?? ''
      ).length < 1
    ) {
      return;
    }
    dispatch(
      updateConfiguration({
        souscriptionId:
          getURLParams(souscriptionResponse?.data?.apply_url ?? '').get(
            'numeroSouscription'
          ) ?? '',
        isSimu: true,
      })
    );
  }, [souscriptionResponse?.data]);

  useEffect(() => {
    if (!isSimu || (souscriptionId ?? '').length < 1) {
      return;
    }
    navigate(`/offre`);
  }, [souscriptionId, isSimu]);

  const buildRequest = () => {
    return {
      souscriptionRequest: {
        numero_point_de_vente: vendorId,
        mode_formation: isInFormationMode,
        fetch_access_token: true,
        marque_blanche: marqueBlanche,
        type_vente: typeVente,
        request_id: requestId,
      },
    };
  };

  const confirmOffer = () => {
    triggerSendSouscription(buildRequest());
    const simulation = simulationId === 1 ? simulation1 : simulation2;
    dispatch(
      updateOffer({
        inputPurchaseAmount: simulation?.enteredValue?.amount,
        inputTermNb: simulation?.enteredValue?.duration,
        inputPricing: simulation?.enteredValue?.rate,
        inputDeferment: simulation?.enteredValue?.report,
        inputBorrowers:
          simulation?.enteredValue?.coborrower !== '1' ? '2' : '1',
      })
    );
  };

  return (
    <DetailResultSimulationWrapper>
      <DetailResultSimulationCard />
      <InfoBulle />

      <MobileWrapper>
        <StyledButton
          onClick={() => {
            dispatch(clear());
            dispatch(
              setSimulation({
                /* TODO à vérifier ici pourquoi bloqué sur simulation1 */
                simulation1: {
                  calculatedValue: {
                    minAmount: bornesMontant?.minAmount,
                    maxAmount: bornesMontant?.maxAmount,
                  },
                },
              })
            );
            navigate(`/simulation${location.search}`);
          }}
        >
          {messages.REINIT}
        </StyledButton>
      </MobileWrapper>
      <DesktopWrapperButton>
        <ChoisirOffreButton
          btnLabel={messages.CHOISIR_OFFRE}
          confirmOffer={confirmOffer}
          isLoading={souscriptionResponse?.isLoading}
        />
      </DesktopWrapperButton>
    </DetailResultSimulationWrapper>
  );
};

export default DetailResultSimulation;
