export const CHOOSE_INSURANCE =
  "Proposez au client les options d'assurance facultative";
export const CHOOSE_INSURANCE_COBORROWER =
  "Proposez aux clients les options d'assurance facultative";
export const INFORM_CLIENT_INSURANCE =
  'Merci de présenter au client le détail des garanties.';
export const INFORM_CLIENT_INSURANCE_COBORROWER =
  'Merci de présenter aux clients le détail des garanties.';
export const OPTION_INSURANCE_BORROWER = "Option d'assurance de l'emprunteur";
export const OPTION_INSURANCE_COBORROWER =
  "Option d'assurance du co-emprunteur";
export const LOADER = 'Chargement...';
