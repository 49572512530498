import styled, { css } from 'styled-components';
import { CheckIcon } from '../Icons';

export const Steps = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 3rem;
  z-index: 0;
`;

export const Wrapper = styled.div`
  width: 3.4rem;
  height: 3.4rem;
  position: relative;
`;

export const Label = styled.span`
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 1.5rem;
  height: 1.5rem;
  stroke: white;
`;

const Step = styled.li`
  position: relative;
  text-align: center;
  line-height: 1.6rem;
  flex-grow: 1;
  color: ${({ theme }) => theme.vendor.colors.primary};

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    top: 1.6rem;
    height: 0.2rem;
    left: 1.7rem;
    z-index: -1;
    background-color: ${({ theme }) => theme.vendor.colors.primary};
  }

  &:last-child {
    flex-grow: 0;

    &::after {
      content: none;
    }
  }
`;

export const PastStep = styled(Step)``;

export const CurrentStep = styled(Step)`
  ${Label} {
    font-weight: bold;
  }

  &::after {
    background-color: ${({ theme }) =>
      theme.variables.colors.chartColors.grey_200};
  }
`;

export const FuturStep = styled(Step)`
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_200};

  ${Wrapper} {
    padding: 0.75rem;
  }

  &::after {
    background-color: ${({ theme }) =>
      theme.variables.colors.chartColors.grey_200};
  }
`;

export const FullCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: white;
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  border: 0.2rem solid ${({ theme }) => theme.vendor.colors.primary};

  ${({ variant }: { variant?: 'past' }) => {
    if (variant === 'past') {
      return css`
        background-color: ${({ theme }) => theme.vendor.colors.primary};
        color: white;
      `;
    }
  }}
`;

export const Circle = styled(FullCircle)`
  border-color: ${({ theme }) => theme.variables.colors.chartColors.grey_200};
  background-color: ${({ theme }) =>
    theme.variables.colors.chartColors.grey_200};
`;
