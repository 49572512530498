import BadgeWrapper from './styles';

export interface IBadgeProps {
  children?: React.ReactNode;
  variant?: BadgeVariantType;
  className?: string;
}

export type BadgeVariantType =
  | 'default'
  | 'withDot'
  | 'formationModeModal'
  | 'standardWithoutDot'
  | 'light';

const Badge = ({
  children,
  variant = 'default',
  className = '',
}: IBadgeProps): React.ReactElement => (
  <BadgeWrapper variant={variant} className={'ph-badge ' + className}>
    {children}
  </BadgeWrapper>
);

export default Badge;
