const SvgFile: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.941 2c.032 0 .062.003.092.01h.007a.455.455 0 01.234.13l-.057-.05a.474.474 0 01.049.042l.008.007 6.588 6.667a.474.474 0 01.136.296l.002.04V19.62C20 20.934 18.947 22 17.647 22H6.353C5.053 22 4 20.934 4 19.619V4.381C4 3.066 5.053 2 6.353 2h6.588zm-.471.952H6.353c-.78 0-1.412.64-1.412 1.429v15.238a1.42 1.42 0 001.412 1.429h11.294c.78 0 1.412-.64 1.412-1.429l-.001-10h-5.176a1.418 1.418 0 01-1.405-1.29l-.006-.139-.001-5.238zm5.922 5.714l-4.981-5.041V8.19c0 .234.167.429.387.469l.084.008 4.51-.001z"
    />
  </svg>
);

export default SvgFile;
