import { useState, createRef, UIEventHandler } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Wrapper,
  WrapperButton,
  IconWrapper,
  StyledTitle,
  StyledText,
  StyledButton,
  TitleWithIconContainer,
  TitleWithIconWrapper,
  IconWithTitleWrapper,
  StyledHeaderWrapper,
  StyledPerfectScrollBar,
} from './styles';
import 'react-perfect-scrollbar/dist/css/styles.css';

export type variantType = 'column' | 'inline';

export interface ModalContentProps {
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  variant?: variantType;
  text?: string | React.ReactNode;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  isSecondaryButtonDisabled?: boolean;
  primaryButtonLabel?: string;
  onPrimaryButtonClick?: () => void;
  isPrimaryButtonDisabled?: boolean;
  tertiaryButtonLabel?: string;
  onTertiaryButtonClick?: () => void;
  isSpecificStyle?: boolean;
  withScroll?: boolean;
  className?: string;
}

const ModalContent = ({
  icon,
  title,
  variant = 'column',
  text,
  secondaryButtonLabel,
  primaryButtonLabel,
  tertiaryButtonLabel,
  onSecondaryButtonClick,
  onPrimaryButtonClick,
  onTertiaryButtonClick,
  isSecondaryButtonDisabled,
  isPrimaryButtonDisabled,
  isSpecificStyle,
  withScroll = true,
  className = '',
}: ModalContentProps): React.ReactElement => {
  const PerfectScrollbarRef = createRef<PerfectScrollbar>();
  const [isScrollBottom, setScrollBottom] = useState(false);
  const [isScrollTop, setScrollTop] = useState(false);

  const onScroll: UIEventHandler<HTMLElement> = (e: any) => {
    setScrollBottom(
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    );
    setScrollTop(e.target.scrollTop);
  };

  let titleWithIcon = null;

  switch (variant) {
    case 'column': {
      titleWithIcon = (
        <>
          <StyledHeaderWrapper>
            {icon && <IconWrapper>{icon}</IconWrapper>}
          </StyledHeaderWrapper>
          {title && <StyledTitle>{title}</StyledTitle>}
        </>
      );
      break;
    }
    case 'inline': {
      titleWithIcon = (
        <>
          <TitleWithIconContainer>
            {icon && <IconWithTitleWrapper>{icon}</IconWithTitleWrapper>}
            {title && <TitleWithIconWrapper>{title}</TitleWithIconWrapper>}
          </TitleWithIconContainer>
        </>
      );
      break;
    }
  }
  return (
    <Wrapper
      isSpecificStyle={isSpecificStyle}
      isBottom={isScrollBottom}
      isTop={isScrollTop}
      withScroll={withScroll}
      className={'ph-modal-content ' + className}
    >
      {titleWithIcon}
      <StyledPerfectScrollBar
        role="list"
        onScroll={onScroll}
        ref={PerfectScrollbarRef}
        options={{
          minScrollbarLength: 20,
          maxScrollbarLength: 60,
          wheelSpeed: 0.5,
        }}
      >
        {text && (
          <StyledText
            isSpecificStyle={isSpecificStyle}
            className="ph-modal-content-text"
          >
            {text}
          </StyledText>
        )}
      </StyledPerfectScrollBar>
      <WrapperButton>
        {secondaryButtonLabel && (
          <StyledButton
            variant="secondary"
            onClick={onSecondaryButtonClick}
            isDisabled={isSecondaryButtonDisabled}
          >
            {secondaryButtonLabel}
          </StyledButton>
        )}
        {primaryButtonLabel && (
          <StyledButton
            variant="primary"
            onClick={onPrimaryButtonClick}
            isDisabled={isPrimaryButtonDisabled}
          >
            {primaryButtonLabel}
          </StyledButton>
        )}
        {tertiaryButtonLabel && (
          <StyledButton variant="dark" onClick={onTertiaryButtonClick}>
            {tertiaryButtonLabel}
          </StyledButton>
        )}
      </WrapperButton>
    </Wrapper>
  );
};

export default ModalContent;
