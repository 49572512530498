const SvgIllustration2: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 113 39" className={className}>
    <g fill="none" fillRule="nonzero">
      <path fill="currentColor" d="M2.85 3.6h66.3v34.725H2.85z" opacity={0.3} />
      <path
        fill="#3F3D56"
        d="M74.25 2.086h26.592v1.471H74.25zM74.25 4.802h38.7v1.471h-38.7zM74.25 11.365h26.592v1.471H74.25zM74.25 22.228h26.592V23.7H74.25zM74.25 14.08h38.7v1.472h-38.7zM74.25 16.797h38.7v1.47h-38.7zM74.25 19.512h38.7v1.472h-38.7z"
      />
      <path fill="currentColor" d="M74.25 28.791h13.918v3.848H74.25z" />
      <path fill="#3F3D56" d="M0 0h66.3v34.725H0z" />
      <ellipse cx={34.383} cy={18.042} fill="#FFF" rx={7.503} ry={7.516} />
      <path
        fill="currentColor"
        d="M34.383 9.245a8.967 8.967 0 108.967 8.967 8.993 8.993 0 00-8.967-8.967zm-1.84 13.747l-4.596-4.595 1.29-1.289 3.31 3.31 6.988-6.987 1.289 1.289-8.281 8.272z"
      />
    </g>
  </svg>
);

export default SvgIllustration2;
