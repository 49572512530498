import { BankIdIcon, PersonIcon, WalletIcon } from '@icons';
import styled from 'styled-components';

export const WrapperInput = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledBankIcon = styled(BankIdIcon)`
  margin-top: 0.3rem;
`;

export const StyledWalletIcon = styled(WalletIcon)`
  margin-top: 0.2rem;
`;

export const StyledPersonIcon = styled(PersonIcon)`
  margin-top: 0.2rem;
  padding-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs};
`;
