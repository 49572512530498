import { Input } from '@components';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.variables.layout.spaces.larger};
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const ItemWrapper = styled.div<{
  isHidden?: boolean;
  width?: string;
}>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  gap: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  align-items: flex-end;
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  margin-bottom: ${({ theme }) =>
    theme.variables.layout.spacing.spacingTop24px}};
`;

export const TitleWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;
