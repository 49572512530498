const SvgPerson: React.FC<{
  className?: string;
  width?: number;
  height?: number;
}> = ({ className, width = 22, height = 22 }) => (
  <svg
    width={width + 'px'}
    height={height + 'px'}
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12,12 C15.3137085,12 18,9.3137085 18,6 C18,2.6862915 15.3137085,0 12,0 C8.6862915,0 6,2.6862915 6,6 C6,9.3137085 8.6862915,12 12,12 Z M16,6 C16,8.209139 14.209139,10 12,10 C9.790861,10 8,8.209139 8,6 C8,3.790861 9.790861,2 12,2 C14.209139,2 16,3.790861 16,6 L16,6 Z M24,22 C24,24 22,24 22,24 L2,24 C2,24 0,24 0,22 C0,20 2,14 12,14 C22,14 24,20 24,22 Z M22,21.992 C21.998,21.5 21.692,20.02 20.336,18.664 C19.032,17.36 16.578,16 12,16 C7.42,16 4.968,17.36 3.664,18.664 C2.308,20.02 2.004,21.5 2,21.992 L22,21.992 Z"
    />
  </svg>
);

export default SvgPerson;
