import { StyledWrapperVerificationContactDetails } from './styles';
import { ValidateSubscriptionSignature } from '@containers';

const VerificationContactDetails: React.FC = () => {
  return (
    <>
      <StyledWrapperVerificationContactDetails>
        <ValidateSubscriptionSignature />
      </StyledWrapperVerificationContactDetails>
    </>
  );
};
export default VerificationContactDetails;
