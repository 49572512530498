import { BlockEmprunteur, BlockEmprunteurHead } from './styles';

import * as messages from './messages';

export interface IProps {
  blockValue: any;
  ageBorrower: number;
  ageCoBorrower: number;
}

export const CoutTotalAssuranceFacultative: React.FC<IProps> = ({
  blockValue,
  ageBorrower,
  ageCoBorrower,
}) => {
  const calculateTotalDimcD = () => {
    if (
      !blockValue?.borrower?.totalInsuranceCost?.dimc?.toFixed &&
      !blockValue?.coborrower?.totalInsuranceCost?.d?.toFixed
    ) {
      return '-';
    }
    return (
      (blockValue?.borrower?.totalInsuranceCost?.dimc ?? 0) +
      (blockValue?.coborrower?.totalInsuranceCost?.d ?? 0)
    ).toFixed(2);
  };

  const calculateTotalDDimc = () => {
    if (
      !blockValue?.coborrower?.totalInsuranceCost?.dimc?.toFixed &&
      !blockValue?.borrower?.totalInsuranceCost?.d?.toFixed
    ) {
      return '-';
    }
    return (
      (blockValue?.coborrower?.totalInsuranceCost?.dimc ?? 0) +
      (blockValue?.borrower?.totalInsuranceCost?.d ?? 0)
    ).toFixed(2);
  };

  const calculateTotalDimD = () => {
    if (
      !blockValue?.borrower?.totalInsuranceCost?.dim?.toFixed &&
      !blockValue?.coborrower?.totalInsuranceCost?.d?.toFixed
    ) {
      return '-';
    }
    return (
      (blockValue?.borrower?.totalInsuranceCost?.dim ?? 0) +
      (blockValue?.coborrower?.totalInsuranceCost?.d ?? 0)
    ).toFixed(2);
  };

  const calculateTotalDDim = () => {
    if (
      !blockValue?.coborrower?.totalInsuranceCost?.dim?.toFixed &&
      !blockValue?.borrower?.totalInsuranceCost?.d?.toFixed
    ) {
      return '-';
    }
    return (
      (blockValue?.coborrower?.totalInsuranceCost?.dim ?? 0) +
      (blockValue?.borrower?.totalInsuranceCost?.d ?? 0)
    ).toFixed(2);
  };

  return (
    <>
      <BlockEmprunteur>
        {blockValue?.borrower && (
          <div>
            {/* Cas emprunteur */}
            <BlockEmprunteurHead>
              {messages.POUR_EMPRUNTEUR}
            </BlockEmprunteurHead>
            {blockValue?.borrower?.totalInsuranceCost?.dimc?.toFixed && (
              <div>
                <p>{messages.DIMC}</p>
                <p>
                  {blockValue?.borrower?.totalInsuranceCost?.dimc?.toFixed(2)}€
                </p>
              </div>
            )}
            {blockValue?.borrower?.totalInsuranceCost?.dim?.toFixed && (
              <div>
                <p>{messages.DIM}</p>
                <p>
                  {blockValue?.borrower?.totalInsuranceCost?.dim?.toFixed(2)}€
                </p>
              </div>
            )}
            {blockValue?.borrower?.totalInsuranceCost?.d?.toFixed && (
              <div>
                <p>{messages.D}</p>
                <p>
                  {blockValue?.borrower?.totalInsuranceCost?.d?.toFixed(2)}€
                </p>
              </div>
            )}

            {/* Cas co-emprunteur (si ages differents)*/}
            {(blockValue?.coborrower?.totalInsuranceCost?.dimc ||
              blockValue?.coborrower?.totalInsuranceCost?.dim ||
              blockValue?.coborrower?.totalInsuranceCost?.d) &&
              ageBorrower !== ageCoBorrower && (
                <>
                  <BlockEmprunteurHead>
                    {messages.POUR_COEMPRUNTEUR}
                  </BlockEmprunteurHead>
                  <>
                    {blockValue?.coborrower?.totalInsuranceCost?.dimc
                      ?.toFixed && (
                      <div>
                        <p>{messages.DIMC}</p>
                        <p>
                          {(
                            blockValue?.coborrower?.totalInsuranceCost?.dimc ??
                            0
                          ).toFixed(2)}
                          €
                        </p>
                      </div>
                    )}
                    {blockValue?.coborrower?.totalInsuranceCost?.dim
                      ?.toFixed && (
                      <div>
                        <p>{messages.DIM}</p>
                        <p>
                          {blockValue?.coborrower?.totalInsuranceCost?.dim?.toFixed(
                            2
                          )}
                          €
                        </p>
                      </div>
                    )}
                    {blockValue?.borrower?.totalInsuranceCost?.d?.toFixed && (
                      <div>
                        <p>{messages.D}</p>
                        <p>
                          {blockValue?.coborrower?.totalInsuranceCost?.d?.toFixed(
                            2
                          )}
                          €
                        </p>
                      </div>
                    )}
                  </>
                </>
              )}

            {/* Cas emprunteur et co-emprunteur (si co-emprunteur existe) */}
            {(blockValue?.coborrower?.totalInsuranceCost?.dimc ||
              blockValue?.coborrower?.totalInsuranceCost?.dim ||
              blockValue?.coborrower?.totalInsuranceCost?.d) && (
              <>
                <BlockEmprunteurHead>
                  {messages.POUR_EMPRUNTEUR} {''}
                  {(blockValue.coborrower?.totalInsuranceCost?.dimc ||
                    blockValue.coborrower?.totalInsuranceCost?.dim ||
                    blockValue.coborrower?.totalInsuranceCost?.d) && (
                    <>
                      {messages.ET} {messages.COEMPRUNTEUR}
                    </>
                  )}
                </BlockEmprunteurHead>

                {blockValue?.borrowerCoborrower?.totalInsuranceCost?.dimc
                  ?.toFixed && (
                  <div>
                    <p>
                      {messages.DIMC} + {messages.DIMC}
                    </p>
                    <p>
                      {blockValue?.borrowerCoborrower?.totalInsuranceCost?.dimc.toFixed(
                        2
                      )}
                      €
                    </p>
                  </div>
                )}

                {!blockValue?.borrowerCoborrower?.totalInsuranceCost?.dimc &&
                  blockValue?.borrower?.totalInsuranceCost?.dimc && (
                    <div>
                      <p>
                        {messages.DIMC} + {messages.D}
                      </p>
                      <p>{calculateTotalDimcD()}€</p>
                    </div>
                  )}

                {!blockValue?.borrowerCoborrower?.totalInsuranceCost?.dimc &&
                  !blockValue?.borrower?.totalInsuranceCost?.dimc &&
                  blockValue?.coborrower?.totalInsuranceCost?.dimc && (
                    <div>
                      <p>
                        {messages.D} + {messages.DIMC}
                      </p>
                      <p>{calculateTotalDDimc()}€</p>
                    </div>
                  )}

                {blockValue?.borrowerCoborrower?.totalInsuranceCost?.dim
                  ?.toFixed && (
                  <div>
                    <p>
                      {' '}
                      {messages.DIM} + {messages.DIM}
                    </p>
                    <p>
                      {blockValue?.borrowerCoborrower?.totalInsuranceCost?.dim.toFixed(
                        2
                      )}
                      €
                    </p>
                  </div>
                )}

                {!blockValue?.borrowerCoborrower?.totalInsuranceCost?.dim &&
                  blockValue?.borrower?.totalInsuranceCost?.dim && (
                    <div>
                      <p>
                        {' '}
                        {messages.DIM} + {messages.D}
                      </p>
                      <p>{calculateTotalDimD()}€</p>
                    </div>
                  )}

                {!blockValue?.borrowerCoborrower?.totalInsuranceCost?.dim &&
                  !blockValue?.borrower?.totalInsuranceCost?.dim &&
                  blockValue?.coborrower?.totalInsuranceCost?.dim && (
                    <div>
                      <p>
                        {' '}
                        {messages.D} + {messages.DIM}
                      </p>
                      <p>{calculateTotalDDim()}€</p>
                    </div>
                  )}

                <div>
                  <p>
                    {' '}
                    {messages.D} + {messages.D}
                  </p>
                  {blockValue?.borrowerCoborrower?.totalInsuranceCost?.d
                    ?.toFixed && (
                    <p>
                      {blockValue?.borrowerCoborrower?.totalInsuranceCost?.d?.toFixed(
                        2
                      )}
                      €
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </BlockEmprunteur>
    </>
  );
};

export default CoutTotalAssuranceFacultative;
