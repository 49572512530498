import styled from 'styled-components';

const MentionLegalWrapper = styled.div`
  font-family: Helvetica;
  color: #61676c;
  font-size: 1.1rem;
  line-height: 1.3rem;
  list-style-type: '-  ';
  letter-spacing: 0;
  text-align: justify;

  B {
    display: block;
    color: ${({ theme }) => theme.variables.colors.chartColors.black};
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  }

  .spacing {
    display: block;
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }

  A {
    text-decoration: underline;
    color: inherit;
  }
`;

export default MentionLegalWrapper;
