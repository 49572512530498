import { Card, Icons, ModalContent, Title, Tooltip } from '@components';
import styled from 'styled-components';

export const StyledCardWrapper = styled(Card.Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const WrapperElectronicVad = styled.div`
  max-width: 60rem;
`;

export const StyledTooltip = styled(Tooltip)`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledTitleTooltip: any = styled(Title)`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  line-height: 2rem;
`;

export const StyledTextTooltip = styled.div`
  display: flex;
`;

export const StyledModalContent = styled(ModalContent)`
  padding: 0;
`;

export const StyledSendIcon = styled(Icons.SendIcon)`
  width: 2.5rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;
