import {
  Steps,
  PastStep,
  CurrentStep,
  StyledCheckIcon,
  FuturStep,
  Wrapper,
  FullCircle,
  Circle,
  Label,
} from './styles';

interface Step {
  label: string;
}

export interface IBreadcrumbProps {
  steps: Step[];
  currentStep?: number;
  className?: string;
}

const Breadcrumb = ({
  steps,
  currentStep = 0,
  className = '',
}: IBreadcrumbProps): React.ReactElement => (
  <Steps className={'ph-breadcrumb ' + className}>
    {steps.map((step: Step, index: number) => {
      if (index < currentStep - 1) {
        return (
          <PastStep key={step.label}>
            <Wrapper>
              <FullCircle variant="past">
                <StyledCheckIcon />
              </FullCircle>
              <Label>{step.label}</Label>
            </Wrapper>
          </PastStep>
        );
      }
      if (index === currentStep - 1) {
        return (
          <CurrentStep key={step.label}>
            <Wrapper>
              <FullCircle>{currentStep}</FullCircle>
              <Label>{step.label}</Label>
            </Wrapper>
          </CurrentStep>
        );
      }

      return (
        <FuturStep key={step.label}>
          <Wrapper>
            <Circle />
            <Label>{step.label}</Label>
          </Wrapper>
        </FuturStep>
      );
    })}
  </Steps>
);

export default Breadcrumb;
