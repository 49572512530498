import { ResultsSimulationCard } from './ResultsSimulationCard/ResultsSimulationCard';
import AddSimulationButton from './AddSimulationButton';
import {
  ResultsSimulationWrapper,
  CardWrapperLeft,
  CardWrapperRight,
  DesktopWrapper,
  MobileWrapper,
  MobileSimuSwitchWrapper,
  MobileButtonSwitchWrapper,
  MobileButtonSwitch,
  StyledButton,
} from './styles';
import { useEffect, useState } from 'react';
import * as messages from './messages';
import {
  useAppSelector,
  selectSimulation,
  useAppDispatch,
  updateOffer,
  useSendCreateSouscriptionMutation,
  selectConfiguration,
  updateConfiguration,
  clearSimulationExceptBornesAmount,
} from '@store';
import { useLocation, useNavigate } from 'react-router-dom';
import { getURLParams } from '@utils';

export const ResultsSimulation: React.FC = () => {
  const dispatch = useAppDispatch();
  const simulationData = useAppSelector(selectSimulation);

  const navigate = useNavigate();
  const location = useLocation();
  const simulationId = location.state?.simulationId ?? undefined;
  const existsSimu2 = location.state?.existsSimu2 ? true : false;
  const [isSimu1Display, setIsSimu1Display] = useState<boolean>(
    !existsSimu2 || simulationId === 1
  );
  const [chosenSimulation, setChosenSimulation] = useState<number | null>(null);

  const changeSimu = (setDisplay: boolean) => {
    setIsSimu1Display(setDisplay);
  };

  const [triggerSendSouscription, souscriptionResponse] =
    useSendCreateSouscriptionMutation();

  const {
    vendorId,
    isInFormationMode,
    marqueBlanche,
    typeVente,
    requestId,
    souscriptionId,
    isSimu,
  } = useAppSelector(selectConfiguration);

  useEffect(() => {
    if (
      !souscriptionResponse?.data ||
      (
        getURLParams(souscriptionResponse?.data?.apply_url ?? '').get(
          'numeroSouscription'
        ) ?? ''
      ).length < 1
    ) {
      return;
    }
    dispatch(
      updateConfiguration({
        souscriptionId:
          getURLParams(souscriptionResponse?.data?.apply_url ?? '').get(
            'numeroSouscription'
          ) ?? '',
        isSimu: true,
      })
    );
  }, [souscriptionResponse?.data]);

  useEffect(() => {
    if (!isSimu || (souscriptionId ?? '').length < 1) {
      return;
    }
    navigate(`/offre`);
  }, [souscriptionId, isSimu]);

  const buildRequest = () => {
    return {
      souscriptionRequest: {
        numero_point_de_vente: vendorId,
        mode_formation: isInFormationMode,
        fetch_access_token: true,
        marque_blanche: marqueBlanche,
        type_vente: typeVente,
        request_id: requestId,
      },
    };
  };

  const onSubmit = (simulationId: 1 | 2 = 1) => {
    setChosenSimulation(simulationId);
    const simulation =
      simulationId === 1
        ? simulationData.simulation1
        : simulationData.simulation2;
    if (simulation) {
      dispatch(
        updateOffer({
          inputPurchaseAmount: simulation.enteredValue?.amount,
          inputTermNb:
            simulation.enteredValue?.duration ??
            simulation.calculatedValue?.duration,
          inputPricing: simulation.enteredValue?.rate,
          inputDeferment: simulation.enteredValue?.report,
          inputBorrowers:
            simulation.enteredValue?.coborrower !== '1' ? '2' : '1',
        })
      );
    }
    triggerSendSouscription(buildRequest());
  };

  return (
    <ResultsSimulationWrapper>
      <DesktopWrapper>
        <CardWrapperLeft>
          <ResultsSimulationCard simulationId={1} />
          <StyledButton
            onClick={() => onSubmit()}
            isLoading={
              souscriptionResponse?.isLoading && chosenSimulation === 1
            }
            isDisabled={
              souscriptionResponse?.isLoading && chosenSimulation !== 1
            }
          >
            {messages.CHOISIR_OFFRE}
          </StyledButton>
        </CardWrapperLeft>
        <CardWrapperRight>
          {simulationId === 2 || existsSimu2 ? (
            <>
              <ResultsSimulationCard simulationId={2} />
              <StyledButton
                onClick={() => onSubmit(2)}
                isLoading={
                  souscriptionResponse?.isLoading && chosenSimulation === 2
                }
                isDisabled={
                  souscriptionResponse?.isLoading && chosenSimulation !== 2
                }
              >
                {messages.CHOISIR_OFFRE}
              </StyledButton>
            </>
          ) : (
            <AddSimulationButton />
          )}
        </CardWrapperRight>
      </DesktopWrapper>

      <MobileWrapper>
        <MobileSimuSwitchWrapper>
          <MobileButtonSwitchWrapper>
            <MobileButtonSwitch
              onClick={() => changeSimu(true)}
              isDisplayedSimu={isSimu1Display}
            >
              {messages.SIMULATION + ' 1'}
            </MobileButtonSwitch>
          </MobileButtonSwitchWrapper>

          <MobileButtonSwitchWrapper>
            {simulationId === 2 || existsSimu2 ? (
              <MobileButtonSwitch
                onClick={() => changeSimu(false)}
                isDisplayedSimu={!isSimu1Display}
              >
                {messages.SIMULATION + ' 2'}
              </MobileButtonSwitch>
            ) : (
              <AddSimulationButton />
            )}
          </MobileButtonSwitchWrapper>
        </MobileSimuSwitchWrapper>

        <CardWrapperLeft>
          <ResultsSimulationCard simulationId={!isSimu1Display ? 2 : 1} />
          <StyledButton
            onClick={() => {
              dispatch(clearSimulationExceptBornesAmount());
              navigate(`/simulation${location.search}`);
            }}
          >
            {messages.REINIT}
          </StyledButton>
        </CardWrapperLeft>
      </MobileWrapper>
    </ResultsSimulationWrapper>
  );
};

export default ResultsSimulation;
