const SvgUser2: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.888 14.101c1.7.116 3.19.436 4.462.891A2.472 2.472 0 0123 17.32v.836C23 19.174 22.143 20 21.087 20h-3.935c.299-.286.553-.618.75-.984h3.185c.493 0 .892-.385.892-.861v-.836c0-.634-.394-1.193-.984-1.404a14.846 14.846 0 00-3.17-.735 4.017 4.017 0 00-.937-1.079zm-7.783-.47c2.474 0 4.59.385 6.328 1.003a2.65 2.65 0 011.778 2.493v.896c0 1.092-.923 1.977-2.061 1.977H3.06C1.924 20 1 19.115 1 18.023v-.896a2.65 2.65 0 011.777-2.492c1.738-.619 3.854-1.005 6.328-1.005zm0 1.054c-2.34 0-4.327.362-5.946.938a1.593 1.593 0 00-1.06 1.504v.896c0 .51.43.923.962.923H15.15c.531 0 .962-.413.962-.923v-.896c0-.678-.425-1.278-1.061-1.504-1.619-.576-3.606-.938-5.946-.938zm6.369-10.552c2.222 0 4.018 1.778 4.018 3.965v.992c0 2.187-1.796 3.965-4.018 3.965a4.033 4.033 0 01-2.689-1.018c.266-.191.512-.424.732-.69a2.99 2.99 0 001.957.724 2.99 2.99 0 002.997-2.98v-.993a2.99 2.99 0 00-2.997-2.98 3 3 0 00-1.303.295 4.816 4.816 0 00-.501-.859 4.04 4.04 0 011.804-.42zM9.105 3c2.393 0 4.328 1.905 4.328 4.248V8.31c0 2.343-1.935 4.248-4.328 4.248-2.393 0-4.327-1.905-4.327-4.248V7.248C4.778 4.905 6.712 3 9.105 3zm0 1.054c-1.78 0-3.228 1.427-3.228 3.194V8.31c0 1.767 1.449 3.193 3.228 3.193 1.78 0 3.229-1.426 3.229-3.193V7.248c0-1.767-1.449-3.194-3.229-3.194z"
    />
  </svg>
);

export default SvgUser2;
