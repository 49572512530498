const SvgAssurance: React.FC<{
  className?: string;
  width?: number;
  height?: number;
}> = ({ className, width = 22, height = 20 }) => (
  <svg
    width={width + 'px'}
    height={height + 'px'}
    viewBox={'0 0 ' + (width + 2) + ' ' + (height + 2)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <title>Icon/24px/User Interface/User 2</title>
    <g
      id="2--bis---VAD---Signature-à-distance"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="14---Récap-contrat-emprunteur-"
        transform="translate(-278.000000, -1306.000000)"
        fill="#292C2E"
      >
        <g id="Group-2" transform="translate(277.000000, 1302.018775)">
          <g id="🎨-Color" transform="translate(1.318120, 4.299407)">
            <path
              d="M10.6818802,0 C16.2099531,0 20.8346701,4.16224531 21.360551,9.6163816 C21.3991722,10.0169381 21.0842943,10.3636364 20.6818802,10.3636364 L11.363,10.363 L11.3636984,15.9818182 C11.3636984,17.0948868 12.2779542,18 13.4091529,18 C14.5403517,18 15.4546075,17.0948868 15.4546075,15.9818182 C15.4546075,15.6052604 15.7598679,15.3 16.1364257,15.3 C16.5129834,15.3 16.8182438,15.6052604 16.8182438,15.9818182 C16.8182438,17.8510872 15.2904164,19.3636364 13.4091529,19.3636364 C11.5905982,19.3636364 10.1023099,17.9502432 10.0051113,16.1675132 L10.000062,15.9818182 L10,10.363 L0.681880207,10.3636364 C0.313000619,10.3636364 0.0176757696,10.0723135 0.000764744947,9.71522921 L0.0032093831,9.6163816 C0.529090292,4.16224531 5.15380731,0 10.6818802,0 Z M10.6818802,1.36363636 C6.12181419,1.36363636 2.26952213,4.60269591 1.46955875,8.96601686 L1.46288021,8.99981818 L19.8998802,8.99981818 L19.8413842,8.70008157 C18.9546894,4.55637015 15.2992897,1.48851275 10.9492854,1.36735636 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgAssurance;
