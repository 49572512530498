/*
 * Messages
 */

export const CONTAINER_TITLE = 'Situation de logement';
export const LABEL_IN_THE_ACCOMODATION_SINCE = 'Dans le logement depuis';
export const PLACEHOLDER_IN_THE_ACCOMODATION_SINCE = 'MM / AAAA';
export const ACCOMODATION_SITUATION =
  'Sélectionnez la situation de l’emprunteur';
export const ERROR_ACCOMODATION_DATE = "La date n'est pas cohérente";
