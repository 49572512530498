/*
 * Messages
 */
export const LABEL_ACCOUNT_OWNER =
  'Compte sur lequel sera prélevée la mensualité du crédit';
export const LABEL_IBAN = 'IBAN';
export const LABEL_BIC = 'BIC';
export const LABEL_OPENING_DATE = 'Compte bancaire principal ouvert depuis';
export const PLACEHOLDER_OPENING_DATE = 'MM / AAAA';
export const ERROR_IBAN = "L'IBAN est invalide";
export const ERROR_BIC = 'Le BIC est invalide';
export const ERROR_SEPA = 'Le RIB est hors zone SEPA';
export const ERROR_OPENING_DATE =
  "La date n'est pas cohérente avec la date de naissance ou la date du jour";
