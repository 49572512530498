import { StyledBody } from './styles';

interface IBlockBodyProps {
  children?: React.ReactNode;
  isCollapsed: boolean;
  className?: string;
}

const Body = ({
  children,
  isCollapsed,
  className = '',
}: IBlockBodyProps): React.ReactElement => (
  <StyledBody isCollapsed={!isCollapsed} className={className}>
    {children}
  </StyledBody>
);

export default Body;
