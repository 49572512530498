import { Icons, Modal } from '@components';
import { useState, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { Leaving } from '../ModalContents';
import {
  WrapperDropdown,
  AuthenHeadUserIcon,
  WrapperAuthenHeadUserIcon,
  AuthenHeadInfosUser,
  WrapperInfosUser,
  AuthenHeadInfosUserName,
  AuthenHeadInfosUserNumber,
  AuthenHeadMenuIcon,
  WrapperChevronDownIcon,
  Dropdown,
  DropdownList,
  DropdownIconStyled,
  HitboxForDropdown,
} from './styles';
import DropdownItem from './DropdownItem';
import { LABEL_HOMEPAGE, LABEL_LOGOUT } from './messages';
import { useLocation } from 'react-router-dom';

export interface Props {
  name: string;
  vendorId?: string;
  redirigerAccueil: () => void;
  logout: () => void;
}

const DropdownMenu = ({
  name,
  vendorId,
  redirigerAccueil,
  logout,
}: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalReturnHomeActive, setIsModalReturnHomeActive] = useState(false);
  const [isModalLogOutActive, setIsModalLogOutActive] = useState(false);
  const location = useLocation();
  const theme = useTheme() as {
    vendor: { colors: { light: string; primary: string } };
    variables: {
      colors: { chartColors: { grey_200: string; grey_500: string } };
    };
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location?.state?.isModalOpen) {
      setIsModalReturnHomeActive(true);
    }
  }, []);

  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        event.target &&
        !ref.current.contains(event.target as HTMLElement)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mouseup', onClickOutside);
    return () => {
      document.removeEventListener('mouseup', onClickOutside);
    };
  }, [ref]);

  const actionOpenLogOut = () => {
    setIsModalLogOutActive(true);
  };

  const actionCloseLogOut = () => {
    setIsModalLogOutActive(false);
  };

  const actionLogOut = () => {
    logout();
  };

  const actionOpenReturnHome = () => {
    setIsModalReturnHomeActive(true);
  };

  const actionCloseReturnHome = () => {
    setIsModalReturnHomeActive(false);
  };

  return (
    <WrapperDropdown className="ph-dropdown-menu">
      <HitboxForDropdown ref={ref} onMouseDown={() => setIsOpen(!isOpen)}>
        <AuthenHeadUserIcon>
          <WrapperAuthenHeadUserIcon>
            <Icons.ProfilIcon
              mainColor={theme.variables.colors.chartColors.grey_500}
              backgroundColor={'white'}
            />
          </WrapperAuthenHeadUserIcon>
        </AuthenHeadUserIcon>
        <AuthenHeadInfosUser>
          <WrapperInfosUser>
            <AuthenHeadInfosUserName>{name}</AuthenHeadInfosUserName>
            <AuthenHeadInfosUserNumber>{vendorId}</AuthenHeadInfosUserNumber>
          </WrapperInfosUser>
        </AuthenHeadInfosUser>
        <AuthenHeadMenuIcon>
          <WrapperChevronDownIcon>
            <Icons.ChevronDownIcon />
          </WrapperChevronDownIcon>
        </AuthenHeadMenuIcon>
      </HitboxForDropdown>
      <Dropdown isOpen={isOpen}>
        <DropdownList>
          <DropdownItem label={LABEL_HOMEPAGE} action={actionOpenReturnHome}>
            <DropdownIconStyled>
              <Icons.HomeIcon />
            </DropdownIconStyled>
          </DropdownItem>
          <DropdownItem label={LABEL_LOGOUT} action={actionOpenLogOut}>
            <DropdownIconStyled>
              <Icons.LogOutIcon />
            </DropdownIconStyled>
          </DropdownItem>
        </DropdownList>
      </Dropdown>
      <Modal
        isVisible={isModalReturnHomeActive}
        isClosable
        handleCloseModal={() => setIsModalReturnHomeActive(false)}
      >
        <Leaving
          onClickCancel={actionCloseReturnHome}
          onClickConfirm={redirigerAccueil}
        />
      </Modal>
      <Modal
        isVisible={isModalLogOutActive}
        isClosable
        handleCloseModal={() => setIsModalLogOutActive(false)}
      >
        <Leaving
          onClickCancel={actionCloseLogOut}
          onClickConfirm={actionLogOut}
        />
      </Modal>
    </WrapperDropdown>
  );
};

export default DropdownMenu;
