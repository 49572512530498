import { EvidenceSubType } from '../types';
import dayjs from 'dayjs';
import { evidenceNumberRegExp, evidenceNumberTDSERegExp } from './regExps';
import isBetween from 'dayjs/plugin/isBetween';
import {
  MONACO_COUNTRY_CODE,
  CEE_COUNTRIES,
  ALLOWED_CNI_PASS_COUNTRIES,
  FRANCE_COUNTRY_CODE,
} from './constants/formFieldValidation';
import { EvidenceType } from '@types';
import { personTypes } from '@store';

dayjs.extend(isBetween);

type ValidateEvidenceNumberProps = {
  value: string;
  evidenceSubTypeJDID: string;
};

/**
 * @param value the value of the field to validate
 */
export const validateEvidenceNumber = (
  props: ValidateEvidenceNumberProps
): boolean => {
  const { value, evidenceSubTypeJDID } = props;

  if (!value) {
    return false;
  }
  const regex =
    evidenceSubTypeJDID === 'TITRE_DE_SEJOUR'
      ? evidenceNumberTDSERegExp
      : evidenceNumberRegExp;
  return !!value.match(regex);
};

export const validateNationalityEvidence = ({
  nationalityCd = '',
  evidenceSubTypeJDID = '',
}): boolean => {
  if (nationalityCd === '' || evidenceSubTypeJDID === '') {
    return true;
  }
  const isFrench = nationalityCd === FRANCE_COUNTRY_CODE;
  const isMonegasque = nationalityCd === MONACO_COUNTRY_CODE;
  const belongsToCeeCountry = CEE_COUNTRIES.includes(nationalityCd);
  const isCNIPASSAllowed = ALLOWED_CNI_PASS_COUNTRIES.includes(nationalityCd);
  const isTDSE = evidenceSubTypeJDID === EvidenceSubType.TITRE_DE_SEJOUR;
  const isCNIV =
    evidenceSubTypeJDID === EvidenceSubType.CARTE_IDENTITE_NATIONALE;
  const isPASS = evidenceSubTypeJDID === EvidenceSubType.PASSEPORT;

  if (
    ((isFrench || isMonegasque) && isTDSE) ||
    (!isFrench &&
      !belongsToCeeCountry &&
      (isCNIV || isPASS) &&
      !isCNIPASSAllowed)
  ) {
    return false;
  }
  return true;
};

export const validatePricing = (value: string): boolean => {
  return (
    Number.isInteger(Number(value)) &&
    Number.parseInt(value, 10) >= 0 &&
    Number(value) < 100000
  );
};

export const validateBirthDate = (value: string): boolean => {
  if (!value) {
    return false;
  }

  const birthDate = dayjs(
    value.toString().replace(/ /g, ''),
    'DD/MM/YYYY',
    true
  );
  const age = dayjs().diff(birthDate, 'years');
  return age >= 18 && age <= 100;
};

export const isPersonFieldDisabled = (
  value: string | undefined,
  person: personTypes.IPerson,
  ocrDocumentType: EvidenceType
): boolean => {
  return (
    !!value &&
    !!person?.documents?.find(
      (document) => document?.evidenceTypeCd === ocrDocumentType
    )
  );
};
