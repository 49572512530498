const SvgCallcenter: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2c4.631 0 8.433 3.616 8.731 8.173A1.669 1.669 0 0122 11.792v4.583a1.67 1.67 0 01-1.27 1.619 3.546 3.546 0 01-3.522 3.173h-3.02a1.356 1.356 0 01-1.25.833h-1.042a1.356 1.356 0 01-1.354-1.354c0-.747.607-1.354 1.354-1.354h1.041c.64 0 1.177.445 1.318 1.041h2.953a2.713 2.713 0 002.685-2.35 1.67 1.67 0 01-1.226-1.608v-4.583a1.67 1.67 0 011.228-1.608c-.29-4.103-3.72-7.35-7.895-7.35-4.175 0-7.605 3.247-7.897 7.35a1.67 1.67 0 011.23 1.608v4.583c0 .919-.747 1.667-1.666 1.667A1.669 1.669 0 012 16.375v-4.583c0-.782.541-1.44 1.27-1.62C3.566 5.617 7.368 2 12 2zm.938 18.125h-1.042a.521.521 0 000 1.042h1.041a.521.521 0 000-1.042zm-9.271-9.167a.834.834 0 00-.834.834v4.583a.834.834 0 001.667 0v-4.583a.834.834 0 00-.833-.834zm16.666 0a.834.834 0 00-.833.834v4.583a.834.834 0 001.667 0v-4.583a.834.834 0 00-.834-.834z"
    />
  </svg>
);

export default SvgCallcenter;
