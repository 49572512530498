import { selectConfiguration, useAppSelector } from '@store';

const useParcoursType = () => {
  const { isDebranchementQRCode, isQrActivated } =
    useAppSelector(selectConfiguration);

  const isParcoursSwitchDevice = () => {
    return !!(isDebranchementQRCode && isQrActivated);
  };

  const isParcoursNominal = () => !isParcoursSwitchDevice();

  return {
    isParcoursNominal,
    isParcoursSwitchDevice,
  };
};

export default useParcoursType;
