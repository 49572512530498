export const SIMULATION = 'Simulation';
export const MONTANT_CREDIT = 'Montant du crédit';
export const MENSUALITE = 'Mensualités';
export const MENSUALITE_EMPRUNTEUR =
  'Mensualités avec assurance emprunteur facultative';
export const MENSUALITE_MONO_EMPRUNTEUR =
  'Mensualités avec assurance mono-emprunteur facultative';
export const MENSUALITE_COEMPRUNTEUR =
  'Mensualités avec assurance co-emprunteur facultative';
export const MENSUALITE_TOTAL_EMP_CO_EMPRUNTEUR =
  'Mensualités avec assurance emprunteur et co-emprunteur facultative';
export const TARIFICATION = 'Tarification';
export const TAEG = 'TAEG fixe';
export const HORS_ASSURANCE = 'Hors assurance';
export const DIMC = 'DIMC';
export const DIM = 'DIM';
export const D = 'D';
export const DIMC2 = 'DIMC + DIMC';
export const DIM2 = 'DIM + DIM';
export const D2 = 'D + D';
export const DIMC_D = 'DIMC + D';
export const DIM_D = 'DIM + D';
export const D_DIMC = 'D + DIMC';
export const D_DIM = 'D + DIM';
export const DETAIL_GARANTIE = 'Voir le détail des garanties';
export const VOIR_DETAIL = 'Voir la simulation détaillée';
export const MODIFIER_SIMULATION = 'Modifier la simulation';
