import { MAX_WIDTH_SIZE } from '@utils';
import styled from 'styled-components';

export const BlockSEPA = styled.div`
  width: 100%;
`;

export const WrapperIcon = styled.div`
  height: 3.8rem;
  width: 4.3rem;
`;

export const StyledWrapperList = styled.ul`
  margin: 0;
  padding: 0;
  column-count: 3;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    column-count: 2;
  }
`;
export const StyledWrapperText = styled.div`
  text-align: left;
`;
export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h2};
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-bottom: 0;
    align-items: start;
  }
`;
export const StyledSubTitle = styled.div`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  font-weight: bold;
  text-align: left;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    margin-top: 0;
  }
`;
export const StyledText = styled.div<{
  isAddMarginBottom?: boolean;
}>`
  margin-bottom: ${({ theme, isAddMarginBottom }) =>
    isAddMarginBottom
      ? theme.variables.layout.spacing.spacing_m
      : theme.variables.layout.spacing.spacing_xs};
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  }
`;
export const StyledListCountries = styled.li`
  list-style-type: none;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
`;
