import {
  BlockText,
  BlockTitle,
  CheckCircleIcon,
  GridWrapper,
  IconWrapper,
  TextWrapper,
  TitleWrapper,
} from './styles';

export interface IProps {
  title: string;
  text: string;
}

export const GarantiesBlock: React.FC<IProps> = ({ title, text }) => {
  return (
    <GridWrapper>
      <IconWrapper>
        <CheckCircleIcon />
      </IconWrapper>
      <TitleWrapper>
        <BlockTitle>{title}</BlockTitle>
      </TitleWrapper>
      <TextWrapper>
        <BlockText>{text}</BlockText>
      </TextWrapper>
    </GridWrapper>
  );
};

export default GarantiesBlock;
