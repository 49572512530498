import dayjs from 'dayjs';
import { FORMAT_NUMBER_PATTERN } from './regExps';

export const formatNumber = (
  num: number | string | undefined
): string | undefined => {
  if (num === undefined) {
    return undefined;
  }
  const parsed = Number.parseFloat(num.toString());
  if (Number.isNaN(parsed)) {
    return undefined;
  }

  if (parsed === 0) {
    return parsed.toString();
  }
  if (parsed % 1 === 0) {
    // pas de décimales
    return parsed.toFixed(0).replace(FORMAT_NUMBER_PATTERN, '$1 '); // use ' ' as a separator
  }
  return parsed
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(FORMAT_NUMBER_PATTERN, '$1 '); // use ' ' as a separator
};

export const removeSpace = (dataStr: string): string => {
  return dataStr?.toString().replace(/ /g, '');
};

export const formatAsNumber = (dataStr: string): number => {
  return dataStr ? parseFloat(removeSpace(dataStr).replace(/,/, '.')) : 0;
};

// convert 'EXAMPLE' or 'example' to 'EXAMPLE'
export const wholeWordCapitalize = (phrasing: string) => {
  return phrasing.toUpperCase();
};

// convert 'éxèmple' to 'exemple' or 'ÉXÈMPLE' to 'EXEMPLE'
export const convertAccents = (phrasing: string) => {
  return phrasing.normalize('NFD').replace(/\p{Diacritic}/gu, '');
};

export const ibanFormatter = (iban: string) => {
  return wholeWordCapitalize(convertAccents(iban.toString()))
    .replace(/ /g, '')
    .match(/.{1,4}/g)
    ?.join(' ');
};

export const bicFormatter = (bic: string) => {
  return wholeWordCapitalize(convertAccents(bic));
};

// convert 'yyyy-mm-dd' to 'dd/mm/yyyy'
export const convertDate = (inputFormat: string): string => {
  let checkDate;
  try {
    checkDate = new Date(inputFormat).toISOString();
  } catch (e) {
    throw new Error(`not a real date ${checkDate}`);
  }
  return inputFormat.split('-').reverse().join('/');
};

// convert 'EXAMPLE' or 'example' to 'Example'
export const capitalize = (phrasing: string) => {
  return phrasing
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// convert 'dd/mm/yyyy' to 'yyyy-mm-dd' or 'mm/yyyy' to 'yyyy-mm-dd'
export const convertDateReverse = (inputFormat: string, withDay: boolean) => {
  if (!withDay) {
    return dayjs(
      inputFormat.toString().replace(/ /g, ''),
      'MM/YYYY',
      true
    ).format('YYYY-MM-DD');
  }
  return dayjs(
    inputFormat.toString().replace(/ /g, ''),
    'DD/MM/YYYY',
    true
  ).format('YYYY-MM-DD');
};
