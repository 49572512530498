import { Title } from '@components';
import { Legend, StyledInsuranceBlock, Wrapper, WrapperBlock } from '../styles';
import { Insurance } from '@types';
import { useMemo } from 'react';
import { NO_INSURANCE_KEY } from '../constant';

export interface IProps {
  title: string;
  insurances?: Insurance[];
  selectedInsuranceCd?: string;
  setSelectedInsuranceCd: (code: string) => void;
  setSelectedInsuranceCodeBareme: (codeBareme: string) => void;
  setIsCollapsed: (
    insurance: Insurance,
    setSelectedInsuranceCd: (code: string) => void,
    setSelectedInsuranceCodeBareme: (codeBareme: string) => void,
    selectedInsuranceCd?: string
  ) => void;
}

const InsuranceBorrower: React.FC<IProps> = ({
  title,
  insurances,
  selectedInsuranceCd,
  setSelectedInsuranceCd,
  setSelectedInsuranceCodeBareme,
  setIsCollapsed,
}) => {
  const bestInsurance = useMemo(
    () =>
      insurances
        ?.map((insurance) => insurance.code ?? '')
        .sort()
        .reduce(
          (prev, current) =>
            prev.length > current.length || current === NO_INSURANCE_KEY
              ? prev
              : current,
          ''
        ),
    [insurances]
  );

  const getInsurancesDetailsLabel = (): string => {
    let insurancesDetailsLabel = '';
    {
      insurances?.map((insurance, index) => {
        if (insurance?.code !== NO_INSURANCE_KEY) {
          insurancesDetailsLabel =
            insurancesDetailsLabel +
            (index === 0 ? '*' : '') +
            insurance?.code +
            ' : ' +
            insurance.label +
            (insurances && index === insurances.length - 2 ? '.' : ', ');
        }
      });
    }
    return insurancesDetailsLabel;
  };

  return (
    <WrapperBlock>
      <Title level={2}>{title}</Title>
      <Wrapper>
        {insurances?.map((insurance) => {
          return (
            <StyledInsuranceBlock
              key={insurance.code + '-' + insurance.code_bareme}
              isCollapsed={insurance.code !== selectedInsuranceCd}
              isBestInsurance={
                !!insurance?.code && insurance?.code === bestInsurance
              }
              setIsCollapsed={() => {
                setIsCollapsed(
                  insurance,
                  setSelectedInsuranceCd,
                  setSelectedInsuranceCodeBareme,
                  selectedInsuranceCd
                );
              }}
              insuranceSelected={insurance}
            />
          );
        })}
      </Wrapper>
      <Legend className="ph-legende">{getInsurancesDetailsLabel()}</Legend>
    </WrapperBlock>
  );
};

export default InsuranceBorrower;
