import { Card, Icons, Title, Tooltip } from '@components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing40px};
  margin-top: 0;
`;

export const WrapperBadge = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing40px};
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const StyledTitle = styled(Title)`
  margin: 0;
`;

export const TextWrapper = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  text-align: center;
  padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  padding-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperCards = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${({ theme }) => theme.variables.layout.spaces.medium};
`;

export const StyledCardWrapper = styled(Card.Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1 1 0;
  cursor: pointer;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const StyledCheckCircleIcon = styled(Icons.CheckCircleIcon)`
  width: 3rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  color: ${({ theme }) => theme.variables.colors.chartColors.green_700};
`;

export const StyledSearchIcon = styled(Icons.SearchIcon)`
  width: 3rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  color: ${({ theme }) => theme.variables.colors.interaction.warning_700};
`;

export const StyledCrossCircleOutlinesIcon = styled(
  Icons.CrossCircleOutlinesIcon
)`
  width: 3rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  color: ${({ theme }) => theme.variables.colors.chartColors.red};
`;

export const StyledTooltip = styled(Tooltip)`
  max-width: 60rem;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_m} 0;
`;
