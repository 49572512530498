import {
  CardBadge,
  CardTitles,
  StyledCard,
  CardSectionWrapper,
  AmountWrapper,
  HighlightInfo,
  TextWrapper,
  ModalOpener,
  StyledDetailButton,
  StyledEditIcon,
} from './styles';
import * as messages from './messages';
import { useNavigate } from '@hooks';
import { useState } from 'react';
import { DetailGaranties } from '@layouts';
import { Modal } from '@components';
import { selectSimulation, updateSimulation, useAppSelector } from '@store';
interface IProps {
  simulationId?: number;
}

export const ResultsSimulationCard: React.FC<IProps> = ({
  simulationId = 1,
}) => {
  const { navigate } = useNavigate();
  const [isGarantiesModal, setIsGarantiesModal] = useState(false);
  const simulation = useAppSelector(selectSimulation);
  const simulationData =
    simulationId === 1 ? simulation?.simulation1 : simulation?.simulation2;

  const calculateTotalDimcD = () => {
    return (
      (simulationData?.calculatedValue?.insurance?.borrower
        ?.withInsuranceMonthlyPayment?.dimc ?? 0) +
      (simulationData?.calculatedValue?.insurance?.coborrower
        ?.monthlyInsuranceCost?.d ?? 0)
    ).toFixed(2);
  };

  const calculateTotalDimD = () => {
    return (
      (simulationData?.calculatedValue?.insurance?.borrower
        ?.withInsuranceMonthlyPayment?.dim ?? 0) +
      (simulationData?.calculatedValue?.insurance?.coborrower
        ?.monthlyInsuranceCost?.d ?? 0)
    ).toFixed(2);
  };

  const calculateTotalDDimc = () => {
    return (
      (simulationData?.calculatedValue?.insurance?.coborrower
        ?.withInsuranceMonthlyPayment?.dimc ?? 0) +
      (simulationData?.calculatedValue?.insurance?.borrower
        ?.monthlyInsuranceCost?.d ?? 0)
    ).toFixed(2);
  };

  const calculateTotalDDim = () => {
    return (
      (simulationData?.calculatedValue?.insurance?.coborrower
        ?.withInsuranceMonthlyPayment?.dim ?? 0) +
      (simulationData?.calculatedValue?.insurance?.borrower
        ?.monthlyInsuranceCost?.d ?? 0)
    ).toFixed(2);
  };

  return (
    <StyledCard>
      <CardBadge>
        {messages.SIMULATION} {simulationId}
      </CardBadge>

      <CardSectionWrapper>
        <CardTitles>{messages.MONTANT_CREDIT}</CardTitles>

        <AmountWrapper>
          <HighlightInfo>
            {simulationData?.calculatedValue?.amount?.toFixed(2)}€
          </HighlightInfo>{' '}
          sur{' '}
          <HighlightInfo>
            {simulationData?.calculatedValue?.duration} mois
          </HighlightInfo>
        </AmountWrapper>

        <div>
          <TextWrapper $marginRight>{messages.TARIFICATION}</TextWrapper>
          <TextWrapper>
            {simulationData?.enteredValue?.libelleeTarification}
          </TextWrapper>
        </div>

        <hr />

        <div>
          <TextWrapper>
            <HighlightInfo>{messages.TAEG}</HighlightInfo>
          </TextWrapper>
          <TextWrapper>
            <HighlightInfo>
              {simulationData?.calculatedValue?.taeg?.toFixed(2)}%
            </HighlightInfo>
          </TextWrapper>
        </div>
      </CardSectionWrapper>

      <CardSectionWrapper>
        <CardTitles>{messages.MENSUALITE}</CardTitles>

        <div>
          <TextWrapper>{messages.HORS_ASSURANCE}</TextWrapper>
          <TextWrapper>
            {simulationData?.calculatedValue?.withoutInsurance?.toFixed(2)}€
          </TextWrapper>
        </div>
      </CardSectionWrapper>

      {/* 
      Valeurs possibles pour borrower et coborrower et leurs explications: 
      Si borrower === '1' => age < 65 ans 
      Si borrower === '2' => age > 65 ans

      Si coborrower === '1' => sans coemprunteur
      Si coborrower === '2' => age < 65 ans
      Si coborrower === '3' => age > 65 ans

      Pour voir la source de ces options -> choixEmprunteur et choixCoEmprunteur dans src\containers\CreateSimulation\EmprunteurForm\EmprunteurForm.tsx
      */}

      {/* Bloc emprunteur */}
      <CardSectionWrapper>
        {/* Cas mono-emprunteur     */}
        {simulationData?.enteredValue?.coborrower === '1' && (
          <CardTitles>{messages.MENSUALITE_EMPRUNTEUR}</CardTitles>
        )}

        {/* Cas 2 emp*/}
        {((simulationData?.enteredValue?.borrower === '1' &&
          simulationData?.enteredValue?.coborrower === '2') ||
          (simulationData?.enteredValue?.borrower === '2' &&
            simulationData?.enteredValue?.coborrower === '3') ||
          (simulationData?.enteredValue?.borrower === '2' &&
            simulationData?.enteredValue?.coborrower === '2') ||
          (simulationData?.enteredValue?.borrower === '1' &&
            simulationData?.enteredValue?.coborrower === '3')) && (
          <CardTitles>{messages.MENSUALITE_MONO_EMPRUNTEUR}</CardTitles>
        )}

        {simulationData?.calculatedValue?.insurance?.borrower
          ?.withInsuranceMonthlyPayment?.dimc && (
          <>
            <div>
              <TextWrapper>{messages.DIMC}</TextWrapper>
              <TextWrapper>
                {simulationData?.calculatedValue?.insurance?.borrower?.withInsuranceMonthlyPayment?.dimc.toFixed(
                  2
                )}
                €
              </TextWrapper>
            </div>
            <hr />
          </>
        )}

        {simulationData?.calculatedValue?.insurance?.borrower
          ?.withInsuranceMonthlyPayment.dim && (
          <>
            <div>
              <TextWrapper>{messages.DIM}</TextWrapper>
              <TextWrapper>
                {simulationData?.calculatedValue?.insurance?.borrower?.withInsuranceMonthlyPayment.dim.toFixed(
                  2
                )}
                €
              </TextWrapper>
            </div>
            <hr />
          </>
        )}

        {simulationData?.calculatedValue?.insurance?.borrower
          ?.withInsuranceMonthlyPayment.d && (
          <div>
            <TextWrapper>{messages.D}</TextWrapper>
            <TextWrapper>
              {simulationData?.calculatedValue?.insurance?.borrower?.withInsuranceMonthlyPayment.d.toFixed(
                2
              )}
              €
            </TextWrapper>
          </div>
        )}
      </CardSectionWrapper>

      {/* Bloc co-emprunteur (si ages differentes)*/}
      {(simulationData?.calculatedValue?.insurance?.coborrower
        ?.withInsuranceMonthlyPayment?.dimc ||
        simulationData?.calculatedValue?.insurance?.coborrower
          ?.withInsuranceMonthlyPayment?.dim ||
        simulationData?.calculatedValue?.insurance?.coborrower
          ?.withInsuranceMonthlyPayment?.d) &&
        ((simulationData?.enteredValue?.borrower === '2' &&
          simulationData?.enteredValue?.coborrower === '2') ||
          (simulationData?.enteredValue?.borrower === '1' &&
            simulationData?.enteredValue?.coborrower === '3')) && (
          <CardSectionWrapper>
            {<CardTitles>{messages.MENSUALITE_COEMPRUNTEUR}</CardTitles>}

            {simulationData?.calculatedValue?.insurance?.coborrower
              ?.withInsuranceMonthlyPayment?.dimc && (
              <>
                <div>
                  <TextWrapper>{messages.DIMC}</TextWrapper>
                  <TextWrapper>
                    {simulationData?.calculatedValue?.insurance?.coborrower?.withInsuranceMonthlyPayment?.dimc.toFixed(
                      2
                    )}
                    €
                  </TextWrapper>
                </div>
                <hr />
              </>
            )}

            {simulationData?.calculatedValue?.insurance?.coborrower
              ?.withInsuranceMonthlyPayment.dim && (
              <>
                <div>
                  <TextWrapper>{messages.DIM}</TextWrapper>
                  <TextWrapper>
                    {simulationData?.calculatedValue?.insurance?.coborrower?.withInsuranceMonthlyPayment.dim.toFixed(
                      2
                    )}
                    €
                  </TextWrapper>
                </div>
                <hr />
              </>
            )}

            {simulationData?.calculatedValue?.insurance?.coborrower
              ?.withInsuranceMonthlyPayment.d && (
              <div>
                <TextWrapper>{messages.D}</TextWrapper>
                <TextWrapper>
                  {simulationData?.calculatedValue?.insurance?.coborrower?.withInsuranceMonthlyPayment.d.toFixed(
                    2
                  )}
                  €
                </TextWrapper>
              </div>
            )}
          </CardSectionWrapper>
        )}

      {/* Bloc emprunteur et co-emprunteur (si co-emprunteur existe)*/}
      {(simulationData?.calculatedValue?.insurance?.coborrower
        ?.withInsuranceMonthlyPayment?.dimc ||
        simulationData?.calculatedValue?.insurance?.coborrower
          ?.withInsuranceMonthlyPayment?.dim ||
        simulationData?.calculatedValue?.insurance?.coborrower
          ?.withInsuranceMonthlyPayment?.d) && (
        <CardSectionWrapper>
          {
            <CardTitles>
              {messages.MENSUALITE_TOTAL_EMP_CO_EMPRUNTEUR}
            </CardTitles>
          }

          {simulationData?.calculatedValue?.insurance?.borrowerCoborrower
            ?.withInsuranceMonthlyPayment?.dimc && (
            <>
              <div>
                <TextWrapper>{messages.DIMC2}</TextWrapper>
                <TextWrapper>
                  {simulationData?.calculatedValue?.insurance?.borrowerCoborrower?.withInsuranceMonthlyPayment.dimc.toFixed(
                    2
                  )}
                  €
                </TextWrapper>
              </div>
              <hr />
            </>
          )}

          {!simulationData?.calculatedValue?.insurance?.borrowerCoborrower
            ?.withInsuranceMonthlyPayment?.dimc &&
            simulationData?.calculatedValue?.insurance?.borrower
              ?.withInsuranceMonthlyPayment?.dimc && (
              <>
                <div>
                  <TextWrapper>{messages.DIMC_D}</TextWrapper>
                  <TextWrapper>{calculateTotalDimcD()}€</TextWrapper>
                </div>
                <hr />
              </>
            )}

          {!simulationData?.calculatedValue?.insurance?.borrowerCoborrower
            ?.withInsuranceMonthlyPayment?.dimc &&
            !simulationData?.calculatedValue?.insurance?.borrower
              ?.withInsuranceMonthlyPayment?.dimc &&
            simulationData?.calculatedValue?.insurance?.coborrower
              ?.withInsuranceMonthlyPayment?.dimc && (
              <>
                <div>
                  <TextWrapper>{messages.D_DIMC}</TextWrapper>
                  <TextWrapper>{calculateTotalDDimc()}€</TextWrapper>
                </div>
                <hr />
              </>
            )}

          {simulationData?.calculatedValue?.insurance?.borrowerCoborrower
            ?.withInsuranceMonthlyPayment?.dim && (
            <>
              <div>
                <TextWrapper>{messages.DIM2}</TextWrapper>
                <TextWrapper>
                  {simulationData?.calculatedValue?.insurance?.borrowerCoborrower?.withInsuranceMonthlyPayment.dim?.toFixed(
                    2
                  )}
                  €
                </TextWrapper>
              </div>
              <hr />
            </>
          )}

          {!simulationData?.calculatedValue?.insurance?.borrowerCoborrower
            ?.withInsuranceMonthlyPayment?.dim &&
            simulationData?.calculatedValue?.insurance?.borrower
              ?.withInsuranceMonthlyPayment?.dim && (
              <>
                <div>
                  <TextWrapper>{messages.DIM_D}</TextWrapper>
                  <TextWrapper>{calculateTotalDimD()}€</TextWrapper>
                </div>
                <hr />
              </>
            )}

          {!simulationData?.calculatedValue?.insurance?.borrowerCoborrower
            ?.withInsuranceMonthlyPayment?.dim &&
            !simulationData?.calculatedValue?.insurance?.borrower
              ?.withInsuranceMonthlyPayment?.dim &&
            simulationData?.calculatedValue?.insurance?.coborrower
              ?.withInsuranceMonthlyPayment?.dim && (
              <>
                <div>
                  <TextWrapper>{messages.D_DIM}</TextWrapper>
                  <TextWrapper>{calculateTotalDDim()}€</TextWrapper>
                </div>
                <hr />
              </>
            )}

          <div>
            <TextWrapper>{messages.D2}</TextWrapper>
            <TextWrapper>
              {simulationData?.calculatedValue?.insurance?.borrowerCoborrower
                ?.withInsuranceMonthlyPayment.d
                ? simulationData?.calculatedValue?.insurance?.borrowerCoborrower?.withInsuranceMonthlyPayment.d.toFixed(
                    2
                  )
                : '-'}
              €
            </TextWrapper>
          </div>
        </CardSectionWrapper>
      )}

      <ModalOpener
        onClick={() => {
          setIsGarantiesModal(true);
        }}
      >
        {messages.DETAIL_GARANTIE}
      </ModalOpener>
      {isGarantiesModal && (
        <Modal
          isVisible={true}
          isClosable={true}
          handleCloseModal={() => {
            setIsGarantiesModal(false);
          }}
        >
          <DetailGaranties />
        </Modal>
      )}

      <StyledDetailButton
        variant="secondary"
        displayBorder={true}
        onClick={() =>
          navigate(`/simulation-result-details`, {
            state: {
              detailedData: simulationData?.calculatedValue,
              enteredData: simulationData?.enteredValue,
              simulationId: simulationId,
            },
          })
        }
      >
        {messages.VOIR_DETAIL}
      </StyledDetailButton>
      <StyledDetailButton
        variant="secondary"
        displayBorder={false}
        onClick={() =>
          navigate(`/simulation`, {
            state: {
              previouslyEnteredValues: simulationData?.enteredValue,
              simulationId: simulationId,
            },
          })
        }
      >
        <StyledEditIcon /> {messages.MODIFIER_SIMULATION}
      </StyledDetailButton>
    </StyledCard>
  );
};
