import React from 'react';
import { AnalyseDocument } from 'types/document';
import FormOcrIdentity from './FormOcrIdentity';
import FormOcrRib from './FormOcrRib';
import FormOcrDom from './FormOcrDom';
import { EvidenceType } from '@types';

export interface IProps {
  onValidate?: () => void;
  ocrData?: AnalyseDocument;
  borrowerType?: string;
  isMaxReuploadAttempt?: boolean;
  onReupload: () => void;
  ribOwner?: string;
}

export const ModalOcrContent: React.FC<IProps> = ({
  onValidate = () => undefined,
  ocrData,
  borrowerType,
  isMaxReuploadAttempt = false,
  onReupload,
  ribOwner,
}) => {
  let FormOcr;
  switch (ocrData?.type_piece) {
    case EvidenceType.JDID:
      FormOcr = FormOcrIdentity;
      break;
    case EvidenceType.JBAN:
      FormOcr = FormOcrRib;
      break;
    case EvidenceType.JDDO:
      FormOcr = FormOcrDom;
      break;
    default:
      return <></>;
  }

  return (
    <>
      <FormOcr
        onValidate={onValidate}
        ocrData={ocrData}
        borrowerType={borrowerType}
        isMaxReuploadAttempt={isMaxReuploadAttempt}
        onReupload={onReupload}
        ribOwner={ribOwner}
      />
    </>
  );
};
