const SvgQRCode: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <title>{'Icon/24px/Document/GR'}</title>
    <path
      fill="#4B4F54"
      fillRule="nonzero"
      d="M2.642 6.545a.642.642 0 0 0 .642-.641v-2.62h2.62a.642.642 0 0 0 0-1.284H2.642A.642.642 0 0 0 2 2.642v3.262c0 .354.287.641.642.641ZM21.358 2h-3.262a.642.642 0 0 0 0 1.284h2.62v2.62a.642.642 0 0 0 1.284 0V2.642A.642.642 0 0 0 21.358 2ZM5.904 20.716h-2.62v-2.62a.642.642 0 0 0-1.284 0v3.262c0 .355.287.642.642.642h3.262a.642.642 0 0 0 0-1.284Zm15.454-3.261a.642.642 0 0 0-.642.641v2.62h-2.62a.642.642 0 0 0 0 1.284h3.262a.642.642 0 0 0 .642-.642v-3.262a.642.642 0 0 0-.642-.641ZM10.506 5.636H6.221a.584.584 0 0 0-.585.585v4.285c0 .323.262.585.585.585h4.285a.584.584 0 0 0 .585-.585V6.221a.584.584 0 0 0-.585-.585Zm-.324 4.546H6.545V6.545h3.637v3.637Zm.324 2.727H6.221a.584.584 0 0 0-.585.585v4.285c0 .323.262.585.585.585h4.285a.584.584 0 0 0 .585-.585v-4.285a.584.584 0 0 0-.585-.585Zm-.324 4.546H6.545v-3.637h3.637v3.637Zm3.312-6.364h4.285a.584.584 0 0 0 .585-.585V6.221a.584.584 0 0 0-.585-.585h-4.285a.584.584 0 0 0-.585.585v4.285c0 .323.262.585.585.585Zm.324-4.546h3.637v3.637h-3.637V6.545Zm-.335 9.091c.317 0 .574-.305.574-.682v-.68h1.005c.317 0 .574-.306.574-.683 0-.376-.257-.682-.574-.682h-1.579c-.317 0-.574.306-.574.682v1.363c0 .18.06.354.168.482.108.128.254.2.406.2Zm4.506 2.995a.642.642 0 0 0 1.284 0v-2.353a.642.642 0 0 0-.642-.642h-2.353a.642.642 0 0 0 0 1.284h1.711v1.711Zm-3.02-1.176h-1.392c-.369 0-.668.203-.668.454s.3.455.668.455h1.391c.37 0 .668-.204.668-.455 0-.25-.299-.454-.668-.454ZM17.908 12c-.25 0-.454.328-.454.732v1.264c0 .404.203.731.454.731s.455-.327.455-.731V12.73c0-.403-.204-.731-.455-.731Zm-5.04 3.906a.454.454 0 1 0-.83.37.454.454 0 0 0 .83-.37Zm.003-3.632a.455.455 0 1 0-.835.362.455.455 0 0 0 .835-.362Z"
    />
  </svg>
);
export default SvgQRCode;
