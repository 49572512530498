import styled from 'styled-components';

// Navigation
export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const WrapperChoiceButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperBtnNext = styled.div`
  margin-left: auto;
`;
