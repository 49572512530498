const Tablet2Icon: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 32 23" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.2,22.4 C1.61333333,22.4 1.1,22.18 0.66,21.74 C0.22,21.3 0,20.7866667 0,20.2 L0,2.2 C0,1.61333333 0.22,1.1 0.66,0.66 C1.1,0.22 1.61333333,0 2.2,0 L29.8,0 C30.3866667,0 30.9,0.22 31.34,0.66 C31.78,1.1 32,1.61333333 32,2.2 L32,20.2 C32,20.7866667 31.78,21.3 31.34,21.74 C30.9,22.18 30.3866667,22.4 29.8,22.4 L2.2,22.4 Z M4.68,21.16 L27.32,21.16 L27.32,1.24 L4.68,1.24 L4.68,21.16 Z M3.44,1.24 L2.2,1.24 C1.93333333,1.24 1.70666667,1.33333333 1.52,1.52 C1.33333333,1.70666667 1.24,1.93333333 1.24,2.2 L1.24,20.2 C1.24,20.4666667 1.33333333,20.6933333 1.52,20.88 C1.70666667,21.0666667 1.93333333,21.16 2.2,21.16 L3.44,21.16 L3.44,1.24 Z M28.56,1.24 L28.56,21.16 L29.8,21.16 C30.0666667,21.16 30.2933333,21.0666667 30.48,20.88 C30.6666667,20.6933333 30.76,20.4666667 30.76,20.2 L30.76,2.2 C30.76,1.93333333 30.6666667,1.70666667 30.48,1.52 C30.2933333,1.33333333 30.0666667,1.24 29.8,1.24 L28.56,1.24 Z M30.76,1.24 L28.56,1.24 L30.76,1.24 Z M1.24,1.24 L3.44,1.24 L1.24,1.24 L1.24,1.24 Z"
    ></path>
  </svg>
);

export default Tablet2Icon;
