/*
 * Messages Modal Document
 *
 *
 */

export const COUNTRY_CODE_NO_SEPA = 'Le code pays est hors zone SEPA';
export const ERROR_BIC_IBAN = 'Les codes pays BIC et IBAN sont incohérents';
export const TITLE_MODAL = 'Informations récupérées';
export const SUCCESS_SCAN = 'Pièce scannée avec succès !';
export const INCOMPLETE_SCAN =
  "Toutes les informations n'ont pas pu être récupérées, veuillez les compléter.";
export const WARNING_RIBA =
  'Le RIB présenté ne peut servir de justificatif de domicile. Vous pouvez néanmoins le valider en tant que RIB sans adresse et présenter un autre justificatif de domicile.';
export const ERROR_RIBA =
  "Toutes les informations n'ont pas pu être récupérées, veuillez les compléter. Le RIB présenté ne peut servir de justificatif de domicile. Vous pouvez néanmoins le valider en tant que RIB sans adresse et présenter un autre justificatif de domicile.";

export const CHECK_INFO =
  "Merci de vérifier les informations ci-dessous avec votre client en tournant l'écran vers lui.";
export const SUB_TITLE_IDENTITY = 'Identité';
export const SUB_TITLE_CONTACT_INFO = 'Coordonnées';
export const DOCUMENT_TYPE = 'Type de pièce';
export const EVIDENCE_NUMBER = 'Numéro de pièce';
export const CIVILITY = 'Civilité';
export const BIRTH_NAME = 'Nom de naissance';
export const FIRSTNAME = 'Prénom';
export const LASTNAME = 'Nom';
export const BIRTH_DATE = 'Date de naissance';
export const BIRTH_CITY = 'Ville de naissance';
export const NATIONALITY = 'Nationalité';
export const SUB_TITLE_RIB = "Relevé d'Identité Bancaire SEPA (RIB)";
export const BIC = 'BIC';
export const IBAN = 'IBAN';
export const ADDRESS = 'Adresse';
export const ZIPCD = 'Code postal';
export const CITY = 'Ville';
export const PLACEHOLDER_CITY = 'Versailles';
export const PROOF_BUTTON = 'RECHARGER LE JUSTIFICATIF';
export const TOSEND_BUTTON = 'ENVOYER';
export const NATIONALITY_ISO_LABEL = 'Nationalité';
export const ERROR_JDID_NATIONALITY =
  'Incohérence entre la Nationalité et le Type de pièce d’identité. Merci de modifier ce champ ou charger un autre Type de pièce';

export enum VibiEnum {
  PD000F00002 = "Les codes pays du BIC et de l'IBAN ne sont pas en cohérence",
  PD000F00003 = "La taille de l'IBAN est incorrecte",
  PD000F00004 = 'Le code IBAN est invalide',
  PD000F00005 = 'Le référentiel des agences bancaires est indisponible',
  PD000F00006 = 'Code BIC non trouvé dans le référentiel des agences bancaires',
  PD000F00007 = 'Couple BIC / IBAN non trouvé dans le réfrentiel des agences bancaires',
}
