import { Centering, Title } from '@components';
import WrapperInformations from './styles';
import * as messages from './messages';
import { Informations as InformationsContainer } from '@containers';
import { useBorrowers } from '@hooks';

export const Informations: React.FC = () => {
  const { hasCoborrower } = useBorrowers();
  return (
    <WrapperInformations>
      <Centering>
        <Title>
          {hasCoborrower()
            ? messages.LAYOUT_TITLE_COEMP
            : messages.LAYOUT_TITLE_MONO}
        </Title>
      </Centering>

      <InformationsContainer />
    </WrapperInformations>
  );
};

export default Informations;
