const SvgRead: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.45 4.45v12.433H9.3c.881 0 1.678.358 2.25.936V7.556c0-1.714-1.41-3.106-3.15-3.106H3.45zm4.95-.9c1.567 0 2.928.88 3.6 2.17a4.05 4.05 0 013.6-2.17H21a.45.45 0 01.45.45v13.333a.45.45 0 01-.45.45h-6.3c-1.19 0-2.162.91-2.244 2.059l-.006.132V20a.425.425 0 01-.332.436l-.078.012h-.08a.43.43 0 01-.41-.448c0-1.223-1.006-2.217-2.25-2.217H3a.45.45 0 01-.45-.45V4A.45.45 0 013 3.55h5.4zm12.15.9H15.6c-1.74 0-3.15 1.392-3.15 3.106v10.263a3.158 3.158 0 012.25-.936h5.85V4.45z"
    />
  </svg>
);

export default SvgRead;
