import * as messages from './messages';
import { useFormContext } from 'react-hook-form';
import { OnInputChangeFunction } from '@components/InputWithRightButton/InputWithRightButton';
import { InputWithRightButton } from '@components';
import useInformationsForm from '../useInformationsForm';
import { MobileKeyboardType } from '@types';

interface IProps {
  name: string;
  label: string;
  role: string;
  onChange?: OnInputChangeFunction;
  validate?: (value: string | number | boolean) => boolean;
  isButtonCheckDisabled?: any;
  onButtonCheckClick?: any;
}

const InputWithRightButtonPrice = ({
  name,
  label,
  role,
  onChange,
  isButtonCheckDisabled = () => false,
  validate,
  onButtonCheckClick,
}: IProps) => {
  const { control } = useFormContext();
  const { onAmountChange, onNoneButtonClick, validatePricing } =
    useInformationsForm({ role });
  const PRICE_MAX_LENGTH = 6;

  return (
    <InputWithRightButton
      name={name}
      label={label}
      control={control}
      maxLength={PRICE_MAX_LENGTH}
      onChange={onChange ?? onAmountChange}
      onButtonCheckClick={onButtonCheckClick ?? onNoneButtonClick}
      placeholder={messages.PLACEHOLDER_TOTAL_SUM}
      after={messages.SYMBOL_EUROS_AND_MONTH}
      validate={validate ?? validatePricing}
      labelRightButton={messages.LABEL_BUTTON_NONE}
      isButtonCheckDisabled={isButtonCheckDisabled ?? (() => false)}
      styledInput="number"
      inputMode={MobileKeyboardType.NUMERIC}
    />
  );
};

export default InputWithRightButtonPrice;
