import { StyledHeader } from './styles';

interface IBlockHeaderProps {
  children?: React.ReactNode;
  isActive: boolean;
  onClick?: () => void;
  className?: string;
}

const Header = ({
  children,
  className = '',
  isActive,
  onClick,
}: IBlockHeaderProps): React.ReactElement => (
  <StyledHeader
    className={className}
    isActive={isActive}
    onClick={onClick}
    isClickable={!!onClick}
  >
    {children}
  </StyledHeader>
);

export default Header;
