import { Centering, Title } from '@components';
import WrapperSelectOffer from './styles';
import * as messages from './messages';
import { OfferDescription } from '@containers';

export const SelectOffer: React.FC = () => {
  return (
    <WrapperSelectOffer>
      <Centering>
        <Title>{messages.LAYOUT_TITLE}</Title>
      </Centering>

      <OfferDescription />
    </WrapperSelectOffer>
  );
};

export default SelectOffer;
