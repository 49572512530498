import { Choice as ChoiceContainer } from '@containers';
import { WrapperChoice } from './styles';

const Choice: React.FC = () => {
  return (
    <WrapperChoice>
      <ChoiceContainer />
    </WrapperChoice>
  );
};

export default Choice;
