export enum ImageStatus {
  DELETED = 'deleted',
  VISIBLE = 'visible',
}
export interface IImagesFile {
  images: HTMLImageElement[];
  id: string;
  status: ImageStatus;
  file: File;
  fileType: string;
}
