import { Icons, ModalContent } from '@components';
import * as messages from './messages';
import { StyledText, StyledWrapperText, WrapperPrompt } from './styles';

interface UploadFailedProps {
  onButtonClick: () => void;
}

export const UploadFailed = ({
  onButtonClick = () => undefined,
}: UploadFailedProps): React.ReactElement => {
  const body = (
    <StyledWrapperText>
      <StyledText>
        <>
          {messages.TEXT_START_UPLOAD_FAILED}{' '}
          <strong>{messages.TEXT_BOLD_UPLOAD_FAILED}</strong>
        </>
      </StyledText>
    </StyledWrapperText>
  );

  return (
    <WrapperPrompt>
      <ModalContent
        icon={<Icons.UploadKoIcon />}
        title={messages.TITLE_UPLOAD_FAILED}
        text={body}
        tertiaryButtonLabel={messages.RELOAD_UPLOAD_FAILED}
        onTertiaryButtonClick={onButtonClick}
      />
    </WrapperPrompt>
  );
};

export default UploadFailed;
