import styled from 'styled-components';
import { Input } from '@components';
import { lighten } from 'polished';

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const GreenLine = styled.div<{
  isMarginRight16px?: boolean;
}>`
  display: block;
  width: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  background-color: ${({ theme }) => lighten(0.3, theme.vendor.colors.primary)};
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_l};
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;
