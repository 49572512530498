export const TITLE = 'RIB de la zone SEPA';

export const TEXT_1 = 'Merci de ';
export const TEXT_2 = 'décocher cette case ';
export const TEXT_3 = 'si : ';

export const TEXT_4 = '• Le client ';
export const TEXT_5 = "n'a pas de RIB sur lui ";
export const TEXT_6 = 'à la souscription';
export const TEXT_7 = "• Le RIB provient d'un ";
export const TEXT_8 = 'pays européen de la zone SEPA, hors France et Monaco';
export const TEXT_9 =
  "Si vous décochez la case parce que le RIB provient d'un pays européen de la ";
export const TEXT_10 = 'zone SEPA ';
export const TEXT_11 = "(hors France et Monaco), merci d' ";
export const TEXT_12 = 'imprimer le RIB ';
export const TEXT_13 = 'afin de le joindre au dossier. ';
export const TITLE_2 = "Comment reconnaître la provenance d'un RIB ?";
export const TEXT_14 =
  "Pour reconnaître la provenance d'un RIB, il faut identifier le code pays.";
export const TEXT_15 = "Il sagit des 2 premiers caractères de l'IBAN.";
export const TEXT_16 = 'Exemple : France = FR, Monaco = MC.';
export const TITLE_3 = 'Liste des pays de la zone SEPA :';

export const BUTTON_HOME = "Retourner à l'accueil";
export const BUTTON_UPLOAD_RIB = 'CHARGER UN AUTRE RIB';
export const RIB_NO_SEPA = 'RIB hors zone SEPA';
