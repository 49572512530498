const SvgMaximize: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.697 13.303a.75.75 0 010 1.06L5.56 19.5h3.69a.75.75 0 110 1.5H3.722a.754.754 0 01-.046-.004L3.75 21a.754.754 0 01-.599-.298l.069.078a.754.754 0 01-.22-.53v-5.5a.75.75 0 111.5 0v3.688l5.136-5.135a.75.75 0 011.061 0zm3.583 0l5.22 5.22V14.75a.75.75 0 111.5 0v5.5a.75.75 0 01-.75.75h-5.5a.75.75 0 110-1.5h3.606l-5.136-5.136a.75.75 0 011.06-1.061zM9.25 3a.75.75 0 010 1.5H5.477l5.22 5.22a.75.75 0 01-1.06 1.06L4.5 5.644V9.25a.75.75 0 01-1.5 0v-5.5A.75.75 0 013.75 3h5.5zm11 0h.017c.02 0 .04.002.06.004L20.25 3a.754.754 0 01.599.298l-.069-.078a.754.754 0 01.22.53v5.5a.75.75 0 11-1.5 0V5.56l-5.136 5.137a.75.75 0 01-1.061-1.06L18.438 4.5H14.75a.75.75 0 110-1.5h5.5z"
    />
  </svg>
);

export default SvgMaximize;
