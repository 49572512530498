import { SimulationCardBlock } from './SimulationCardBlock/SimulationCardBlock';
import { StyledCard } from './styles';
import * as messages from './messages';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  selectSimulation,
  updateSimulation,
  useAppDispatch,
  useAppSelector,
} from '@store';

export const DetailResultSimulationCard: React.FC = () => {
  const [simulationData, setSimulationData] = useState<any>();
  const [enteredSimulationData, setEnteredSimulationData] = useState<any>();

  const location = useLocation();
  const { selectedSimulationDetails } = useAppSelector(selectSimulation);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // TODO Refacto : à vérifier pourquoi passer toute la simu par le state de location
    // => la simulationId ne suffit pas ?
    setSimulationData(
      location?.state?.detailedData ?? selectedSimulationDetails?.detailedData
    );
    setEnteredSimulationData(
      location?.state?.enteredData ?? selectedSimulationDetails?.enteredData
    );

    if (location?.state?.simulationId) {
      dispatch(
        updateSimulation({
          selectedSimulationDetails: {
            detailedData: location?.state?.detailedData,
            enteredData: location?.state?.enteredData,
            simulationId: location?.state?.simulationId,
          },
        })
      );
    }
  }, []);

  const simulationDataToLoad = [
    {
      blockNumber: 1,
      blockLabel: messages.MONTANT_CREDIT,
      blockValue: simulationData?.amount?.toFixed
        ? `${simulationData?.amount?.toFixed(2)}€`
        : '-',
    },
    {
      blockNumber: 2,
      blockLabel: messages.TAUX_ANNUEL,
      blockValue: simulationData?.taeg?.toFixed
        ? `${simulationData?.taeg?.toFixed(2)}%`
        : '-',
    },
    {
      blockNumber: 3,
      blockLabel: messages.MONTANT_TOTAL,
      blockValue: simulationData?.totalAmount?.toFixed
        ? `${simulationData?.totalAmount?.toFixed(2)}€`
        : '-',
    },
    {
      blockNumber: 4,
      blockLabel: messages.PREMIERE_ECHEANCE,
      blockValue: simulationData?.firstDeadLine?.toFixed
        ? `${simulationData?.firstDeadline} jours`
        : '-',
    },
    {
      blockNumber: 5,
      blockLabel: messages.DUREE_TOTALE,
      blockValue:
        simulationData?.duration?.toFixed &&
        simulationData?.firstDeadline?.toFixed
          ? `${
              simulationData?.duration + simulationData?.firstDeadline / 30 - 1
            } mois` //durée + (durée du report en jour /30)
          : '-',
    },
    {
      blockNumber: 6,
      blockLabel: messages.TAUX_DEBITEUR,
      blockValue: simulationData?.fixDebtorRate?.toFixed
        ? `${simulationData?.fixDebtorRate?.toFixed(2)}%`
        : '-',
    },
    {
      blockNumber: 7,
      blockLabel: messages.FRAIS_DOSSIER,
      blockValue: simulationData?.applicationFees?.toFixed
        ? `${simulationData?.applicationFees?.toFixed(2)}€`
        : '-',
    },
    {
      blockNumber: 8,
      blockLabel: messages.PREMIERE_ECHEANCE,
      blockValue: simulationData?.firstDeadline?.toFixed
        ? `${simulationData?.firstDeadline} jours`
        : '-',
    },
    {
      blockNumber: 9,
      blockLabel: messages.NOMBRE_ECHEANCES,
      blockValue: simulationData?.deadlineNumber?.toFixed
        ? `${simulationData?.deadlineNumber} `
        : '-',
    },
    {
      blockLabel: messages.MONTANT_ECHEANCE_HORS_ASSURANCE,
      blockValue: simulationData?.deadlineAmount?.toFixed
        ? `${simulationData?.deadlineAmount?.toFixed(2)}€`
        : '-',
    },
    {
      blockNumber: 10,
      blockLabel: messages.MONTANT_TOTAL_HORS_ASSURANCE,
      blockValue: simulationData?.totalDueAmount?.toFixed
        ? `${simulationData?.totalDueAmount?.toFixed(2)}€`
        : '-',
    },
    {
      blockNumber: 11,
      blockLabel: messages.COUT_TOTAL_ASSURANCE_FACULTATIVE,
      blockValue: simulationData?.insurance,
    },
    {
      blockNumber: 12,
      blockLabel: messages.COUT_MENSUEL_ASSURANCE_FACULTATIVE,
      blockValue: simulationData?.insurance,
    },
    {
      blockNumber: 13,
      blockLabel: messages.TAEA_GLOBAL,
      blockValue: simulationData?.globalTaea?.toFixed
        ? `${simulationData?.globalTaea?.toFixed(2)}%`
        : '-',
      blockDesc: messages.TAEA_DESC,
    },
    {
      blockNumber: 14,
      blockLabel: messages.RETENUE_VENDEUR,
      blockValue: simulationData?.vendorDeduction?.toFixed
        ? `${simulationData?.vendorDeduction?.toFixed(2) ?? '0.00'}€`
        : null,
    },
  ];

  return (
    <StyledCard>
      {simulationDataToLoad.map((simulationData, index) => {
        return (
          simulationData.blockValue !== null && (
            <SimulationCardBlock
              key={index}
              blockNumber={simulationData.blockNumber}
              blockLabel={simulationData.blockLabel}
              blockValue={simulationData.blockValue}
              blockDesc={simulationData.blockDesc}
              ageBorrower={enteredSimulationData?.borrower === '1' ? 25 : 70}
              ageCoBorrower={
                enteredSimulationData?.coborrower === '1'
                  ? 0
                  : enteredSimulationData?.coborrower === '2'
                  ? 25
                  : 70
              }
            />
          )
        );
      })}
    </StyledCard>
  );
};
