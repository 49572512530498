import { Button } from '@components';
import styled from 'styled-components';

export const ResultsSimulationWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const CardWrapperLeft = styled.div`
  width: 50%;
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: 100%;
  }
`;

export const CardWrapperRight = styled.div`
  width: 50%;
  height: auto;
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const DesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    display: none;
  }
`;

export const DesktopWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 3rem 0 4rem 0;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    display: block;
    width: 100%;
  }
`;

export const MobileSimuSwitchWrapper = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    display: flex;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.vendor.colors.primary};
    border-radius: 4rem;
    padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s}

`;

export const MobileButtonSwitchWrapper = styled.div`
  width: 50%;
  height: 32px;
`;

export const MobileButtonSwitch = styled.button.attrs(
  (props: { isDisplayedSimu: boolean }) => props
)`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    ({ theme }) =>
      props.isDisplayedSimu
        ? theme.vendor.colors.primary
        : theme.variables.colors.chartColors.white};
  border-radius: 2rem;
  color: ${(props) =>
    ({ theme }) =>
      props.isDisplayedSimu
        ? theme.variables.colors.chartColors.white
        : theme.vendor.colors.primary};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  font-weight: ${(props) => props.isDisplayedSimu && 'bold'};
`;

export const StyledButton = styled(Button)`
  height: 48px;
`;
