import { Icons, ModalContent } from '@components';
import * as propertiesMessages from './messages';
import {
  StyledText,
  StyledWrapperText,
  WrapperPrompt,
  WrapperTextSwitchDevice,
} from './styles';

interface IUnavailableServiceProps {
  handleUnavailableService: () => void;
  isSwitchDevice?: boolean;
}

export const UnavailableService: React.FC<IUnavailableServiceProps> = ({
  handleUnavailableService = () => undefined,
  isSwitchDevice,
}) => {
  const tertiaryButtonLabel = !isSwitchDevice
    ? propertiesMessages.BUTTON_UNAVAILABLE_SERVICE
    : '';
  const body = (
    <StyledWrapperText>
      <StyledText>
        <>
          {propertiesMessages.TEXT_START_UNAVAILABLE_SERVICE}{' '}
          <strong>{propertiesMessages.TEXT_BOLD_UNAVAILABLE_SERVICE}</strong>
        </>
        {isSwitchDevice && (
          <WrapperTextSwitchDevice>
            {propertiesMessages.TEXT_SERVICE_UNAVIABLE_SWITCH_DEVICE}
            <strong>
              {propertiesMessages.TEXT_SERVICE_UNAVIABLE_SWITCH_DEVICE_STRONG}
            </strong>
          </WrapperTextSwitchDevice>
        )}
      </StyledText>
    </StyledWrapperText>
  );

  return (
    <WrapperPrompt>
      <ModalContent
        icon={<Icons.CloudIcon />}
        title={propertiesMessages.TITLE_UNAVAILABLE_SERVICE}
        text={body}
        tertiaryButtonLabel={tertiaryButtonLabel}
        onTertiaryButtonClick={handleUnavailableService}
      />
    </WrapperPrompt>
  );
};

export default UnavailableService;
