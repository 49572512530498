const SvgPhone: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.396 15.98v-.016a1.002 1.002 0 00-.859-1.017 10.953 10.953 0 01-2.394-.595 1.007 1.007 0 00-1.059.224l-1.022 1.02a.603.603 0 01-.724.097 13.462 13.462 0 01-5.053-5.043.603.603 0 01.098-.726l1.02-1.017a1 1 0 00.225-1.059 10.882 10.882 0 01-.596-2.382c-.07-.498-.5-.868-1.011-.863H5.608a1.007 1.007 0 00-1.001 1.084 15.271 15.271 0 002.378 6.7 15.075 15.075 0 004.646 4.635 15.328 15.328 0 006.669 2.37 1.008 1.008 0 001.096-1.004v-2.409zm1.207 2.405a2.208 2.208 0 01-2.422 2.208 16.538 16.538 0 01-7.201-2.555 16.269 16.269 0 01-5.01-4.998 16.48 16.48 0 01-2.564-7.235 2.207 2.207 0 012.201-2.408h2.408a2.213 2.213 0 012.213 1.905c.096.726.274 1.438.53 2.122.304.808.11 1.719-.5 2.334l-.69.688c.999 1.61 2.36 2.968 3.973 3.965l.693-.692a2.214 2.214 0 012.332-.497 9.756 9.756 0 002.134.53 2.21 2.21 0 011.903 2.234v2.4z"
    />
  </svg>
);

export default SvgPhone;
