import styled from 'styled-components';

export const StyledTextBold = styled.div`
  font-weight: ${({ theme }) => theme.variables.fonts.types.bold};
`;

export const BlockCopy = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: solid;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
  border-color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
  border-width: ${({ theme }) => theme.variables.layout.border.xs};
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const BlockTextCopy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const TextCopy = styled.div<{
  isCopy?: boolean;
}>`
  background-color: ${({ theme, isCopy }) =>
    isCopy ? theme.vendor.colors.light : ''};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
`;

export const BlockBtnCopy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextCopyBtn = styled.div<{
  isCopy?: boolean;
}>`
  color: ${({ theme, isCopy }) => (isCopy ? '' : theme.vendor.colors.primary)};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h6};
`;

export const Btn = styled.div`
  background-color: ${({ theme }) => theme.vendor.colors.primary};
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs};
`;

export default StyledTextBold;
