import { Button } from '@components';
import * as messages from './messages';
import { WrapperButtons, WrapperBtnNext } from './styles';

export interface IButtonBlockFooterProps {
  isNextDisabled?: boolean;
  onClickNext?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  nextLabel?: string;
  isLoading?: boolean;
  className?: string;
}

export const ButtonBlockFooter: React.FC<IButtonBlockFooterProps> = ({
  isNextDisabled = false,
  onClickNext = () => {},
  nextLabel,
  isLoading = false,
  className = '',
}: IButtonBlockFooterProps) => {
  return (
    <WrapperButtons className={'ph-button-block-footer ' + className}>
      <WrapperBtnNext>
        <Button
          variant="primary"
          onClick={onClickNext}
          isDisabled={isNextDisabled}
          isLoading={isLoading}
          type="submit"
        >
          {nextLabel ?? messages.NEXT_BUTTON}
        </Button>
      </WrapperBtnNext>
    </WrapperButtons>
  );
};

export default ButtonBlockFooter;
