const SvgGalerie: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.412 3C20.289 3 21 3.711 21 4.588v14.824c0 .877-.711 1.588-1.588 1.588H4.588A1.588 1.588 0 013 19.412V4.588C3 3.711 3.711 3 4.588 3h14.824zm0 1.059H4.588a.53.53 0 00-.53.53v14.823c0 .292.238.53.53.53l.688-.001 10.14-10.774a.525.525 0 01.694-.066l.074.066 3.757 3.992v-8.57a.53.53 0 00-.53-.53zM15.8 10.332l-9.044 9.609h12.656a.53.53 0 00.53-.53l-.001-4.679-4.141-4.4zM8 6a2 2 0 110 4 2 2 0 010-4zm0 1.143a.857.857 0 100 1.714.857.857 0 000-1.714z"
    />
  </svg>
);

export default SvgGalerie;
