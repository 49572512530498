const SvgFilter1: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 16a1 1 0 010 2H7a1 1 0 010-2h10zm2-5a1 1 0 010 2H5a1 1 0 010-2h14zm2-5a1 1 0 010 2H3a1 1 0 110-2h18z"
    />
  </svg>
);

export default SvgFilter1;
