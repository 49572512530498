/*
 * Messages modal d'erreur
 *
 *
 */

export const TITLE = 'Une erreur technique est survenue';
export const REFUSE_CONTACT =
  'Merci de contacter votre centre de relation clientèle.';
export const REQUESTID_HANDLER = 'Code erreur : ';
export const BACK_BUTTON = "Retourner à l'accueil";
export const REFUSE_CONTACT_TABLET =
  'Merci de contacter votre centre de relation clientèle.';
export const UNREGISTER_MESSAGE = "Le dossier n'est pas enregistré.";
export const GO_BACK_MESSAGE =
  'Vous pouvez retourner sur le PC et fermer cet onglet.';
export const CODE_HTTP = 'Code http : ';
export const MESSAGE_ERROR = ' Message : ';
export const SERVICE = 'Service : ';
export const TEXT_COPY_PAST =
  'Vous pouvez copier/coller ces informations afin de les envoyer par mail';
export const TEXT_COPY_PAST_NEXT = 'à votre contact support.';
