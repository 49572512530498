import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { StyledButton } from '../Button/styles';

const getVariantCss = (isChecked: boolean, isValid: boolean) => {
  if (!isValid) {
    return css`
      border-color: ${({ theme }) => theme.variables.colors.chartColors.red};
      background-color: transparent;
    `;
  }

  if (!isChecked) {
    return css`
      border-color: ${({ theme }) =>
        theme.variables.colors.chartColors.grey_300};
      color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
      background-color: transparent;

      &:focus {
        border-color: ${({ theme }) =>
          theme.variables.colors.interaction.emphasis_700};
      }
      &:disabled {
        color: ${({ theme }) => theme.variables.colors.chartColors.grey_200};
        border-color: ${({ theme }) =>
          theme.variables.colors.chartColors.grey_200};
      }
    `;
  }

  return css`
    color: ${({ theme }) => theme.vendor.colors.primary};
    border-color: ${({ theme }) => theme.vendor.colors.primary};
    background-color: ${({ theme }) => theme.vendor.colors.light};

    &:not(:disabled):hover,
    &:active {
      color: ${({ theme }) => theme.vendor.colors.primary};
      border-color: ${({ theme }) => theme.vendor.colors.primary};
      background-color: ${({ theme }) => theme.vendor.colors.light};
    }

    &:disabled,
    &:disabled:hover {
      color: ${({ theme }) => lighten(0.3, theme.vendor.colors.primary)};
      border-color: ${({ theme }) => lighten(0.3, theme.vendor.colors.primary)};
      background-color: ${({ theme }) => theme.vendor.colors.lightest};
    }
  `;
};

const StyledButtonCheck = styled(StyledButton)<{
  isBig: boolean;
  isGrid: boolean;
  isChecked: boolean;
  isWide: boolean;
  isValid: boolean;
}>`
  font-weight: ${({ theme }) => theme.variables.fonts.types.normal};
  border-width: 1px;
  padding: ${({ isBig, isGrid, isWide, theme }) => {
    if (isBig && !isGrid) {
      return `1.2rem ${theme.variables.layout.spacing.spacing_s}`;
    }
    if (isGrid) {
      return `0 ${theme.variables.layout.spacing.spacing_s}`;
    }
    if (isWide) {
      return `${theme.variables.layout.spacing.spacing_xs} ${theme.variables.layout.spacing.spacing_l}`;
    }
    return `${theme.variables.layout.spacing.spacing_xs} ${theme.variables.layout.spacing.spacing_s}`;
  }};

  ${({ isChecked, isValid }) => getVariantCss(isChecked, isValid)}

  min-height: ${({ isGrid, isBig }) => (isGrid || isBig ? '4.8rem' : 'auto')};
  min-width: ${({ isGrid }) => (!isGrid ? '5.4rem' : 'auto')};
`;

export default StyledButtonCheck;
