import styled from 'styled-components';

export const WrapperResultsSimulation = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColoredTitle = styled.span`
  color: #007a75;
`;
