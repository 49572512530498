import { Card, DescriptionList } from '@components';
import styled from 'styled-components';

export const StyledCard = styled(Card.Wrapper)`
  padding-bottom: 2.9rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const StyledWrapperTitle = styled.div`
  display: flex;
  flex-direction: row;

  & h2 {
    margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  }
`;

export const StyledWrapperIcon = styled.div`
  line-height: 2.6rem;
  & svg {
    margin-right: 1rem;
  }
`;

export const StyledWrapperBadge = styled.div`
  display: inline-block;
  padding-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const StyledTextBadge = styled.span`
  font-weight: bold;
`;

export const StyledDescriptionList = styled(DescriptionList)`
  padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};

  & dt {
    padding-right: 50px;
  }
`;
