import * as messages from './messages';
import { Input, Select } from '@components';
import {
  StyledCard,
  WrapperInputMonthlyPayment,
  WrapperMonthlyText,
  WrapperInputCreateSimulation,
  WrapperSelectCreateSimulation,
  WrapperRateCreateSimulation,
} from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { IOption, MobileKeyboardType } from '@types';
import { formatNumber, formatAsNumber } from '@utils';
import { useEffect, useRef, useState } from 'react';
import {
  selectConfiguration,
  selectSimulation,
  updateSimulation,
  useAppDispatch,
  useAppSelector,
  useSendSimulationSimpleMutation,
} from '@store';
import useOfferForm from 'containers/OfferDescription/useOfferForm';

export interface CreditFormProps {
  simulationId?: number;
  setIsPageReady: React.Dispatch<React.SetStateAction<boolean>>;
}

enum INPUT {
  loanAmount = 'loanAmount',
  mouthlyAmountNumber = 'mouthlyAmountNumber',
  selectRate = 'selectRate',
  selectReport = 'selectReport',
}

const inputToControllersName: Record<INPUT, string[]> = {
  loanAmount: ['inputSimulationLoanAmount'],
  mouthlyAmountNumber: [
    'inputSimulationMonthlyAmount',
    'inputSimulationMonthlyNumber',
  ],
  selectRate: ['inputSimulationSelectRate'],
  selectReport: ['inputSimulationSelectReport'],
};

type InputsInfos = {
  [key in INPUT]: { constraintsReceived: boolean; isValid: boolean };
};

const CreditForm: React.FC<CreditFormProps> = ({
  simulationId,
  setIsPageReady,
}) => {
  const { onChangeInputPrice, onFocusInputPrice, onBlurInputPrice } =
    useOfferForm();

  const configuration = useAppSelector(selectConfiguration);
  const { vendorId } = {
    ...configuration,
  };

  const simulation1 = useAppSelector(selectSimulation).simulation1;

  const simulationPreviouslyEnteredValues =
    simulationId === 1
      ? useAppSelector(selectSimulation).simulation1?.enteredValue
      : useAppSelector(selectSimulation).simulation2?.enteredValue;

  const bornesMontant = simulation1?.calculatedValue;

  const [inputsInfos, setInputsInfos] = useState<InputsInfos>({
    loanAmount: {
      isValid:
        simulationPreviouslyEnteredValues?.amount !== undefined ? true : false,
      constraintsReceived:
        simulationPreviouslyEnteredValues?.amount !== undefined ? true : false,
    },
    mouthlyAmountNumber: {
      isValid:
        simulationPreviouslyEnteredValues?.monthAmount !== undefined ||
        simulationPreviouslyEnteredValues?.duration !== undefined
          ? true
          : false,
      constraintsReceived:
        simulationPreviouslyEnteredValues?.monthAmount !== undefined ||
        simulationPreviouslyEnteredValues?.duration !== undefined
          ? true
          : false,
    },
    selectRate: {
      isValid:
        simulationPreviouslyEnteredValues?.rate !== undefined ? true : false,
      constraintsReceived:
        simulationPreviouslyEnteredValues?.rate !== undefined ? true : false,
    },
    selectReport: {
      isValid:
        simulationPreviouslyEnteredValues?.report !== undefined ? true : false,
      constraintsReceived:
        simulationPreviouslyEnteredValues?.report !== undefined ? true : false,
    },
  });

  const [triggerSimulationSimple, simulationSimpleResult] =
    useSendSimulationSimpleMutation();

  const { control, trigger, getValues, setValue, resetField } =
    useFormContext();

  const [
    flagActivationChampsDureeMensualite,
    setFlagActivationChampsDureeMensualite,
  ] = useState<string>('MD');

  //Valeurs
  const [optionsTarifs, setOptionsTarifs] = useState<IOption[] | undefined>(
    simulationPreviouslyEnteredValues
      ? simulationPreviouslyEnteredValues.rates
      : []
  );
  const [optionsReports, setOptionsReports] = useState<IOption[] | undefined>(
    simulationPreviouslyEnteredValues
      ? simulationPreviouslyEnteredValues.reports
      : []
  );
  const [enteredValueStore, setEnteredValueStore] = useState<any>({
    amount: simulationPreviouslyEnteredValues?.amount,
    duration: simulationPreviouslyEnteredValues?.duration,
    durationMin: simulationPreviouslyEnteredValues?.durationMin,
    durationMax: simulationPreviouslyEnteredValues?.durationMax,
    monthAmount: simulationPreviouslyEnteredValues?.monthAmount,
    monthlyPaymentMin: simulationPreviouslyEnteredValues?.monthlyPaymentMin,
    monthlyPaymentMax: simulationPreviouslyEnteredValues?.monthlyPaymentMax,
    rate: simulationPreviouslyEnteredValues?.rate,
    rates: simulationPreviouslyEnteredValues?.rates,
    report: simulationPreviouslyEnteredValues?.report,
    reports: simulationPreviouslyEnteredValues?.reports,
    libelleeTarification:
      simulationPreviouslyEnteredValues?.libelleeTarification,
    tarifs: simulationPreviouslyEnteredValues?.tarifs,
    borrower: simulationPreviouslyEnteredValues?.borrower,
    coborrower: simulationPreviouslyEnteredValues?.coborrower,
  });

  //Limites
  const [amountMin, setAmountMin] = useState<number>(
    bornesMontant?.minAmount ?? 0
  );
  const [amountMax, setAmountMax] = useState<number>(
    bornesMontant?.maxAmount ?? 0
  );
  const [durationMin, setDurationMin] = useState<number>(
    simulationPreviouslyEnteredValues?.durationMin! ?? undefined
  );
  const [durationMax, setDurationMax] = useState<number>(
    simulationPreviouslyEnteredValues?.durationMax! ?? undefined
  );
  const [monthlyPaymentMin, setMonthlyPaymentMin] = useState<number>(
    simulationPreviouslyEnteredValues?.monthlyPaymentMin! ?? undefined
  );
  const [monthlyPaymentMax, setMonthlyPaymentMax] = useState<number>(
    simulationPreviouslyEnteredValues?.monthlyPaymentMax! ?? undefined
  );

  //Erreurs
  const [errorLoanAmount, setErrorLoanAmount] = useState('');

  const [needFocusMonthlyAmount, setNeedFocusMonthlyAmount] = useState(false);
  const [needFocusMonthlyNumber, setNeedFocusMonthlyNumber] = useState(false);

  useEffect(() => {
    if (needFocusMonthlyAmount) {
      monthlyAmountRef.current?.focus();
      monthlyAmountRef.current?.click();
      setNeedFocusMonthlyAmount(false);
    } else if (needFocusMonthlyNumber) {
      monthlyNumberRef.current?.focus();
      monthlyNumberRef.current?.click();
      setNeedFocusMonthlyNumber(false);
    }
  }, [needFocusMonthlyAmount, needFocusMonthlyNumber]);

  const dispatch = useAppDispatch();

  const [simulationLoanAmount, setSimulationLoanAmount] = useState(false);
  const [simulationMonthlyAmount, setSimulationMonthlyAmount] = useState(false);
  const [simulationMonthlyNumber, setSimulationMonthlyNumber] = useState(false);
  useEffect(() => {
    setSimulationMonthlyNumber(false);
    setSimulationLoanAmount(false);
    setSimulationMonthlyAmount(false);
  }, []);

  useEffect(() => {
    setValue(
      'inputSimulationLoanAmount',
      simulationPreviouslyEnteredValues?.amount?.toString().replace('.', ',') ??
        ''
    );
    setValue(
      'inputSimulationMonthlyAmount',
      simulationPreviouslyEnteredValues?.monthAmount
        ?.toString()
        .replace('.', ',') ?? ''
    );
    setValue(
      'inputSimulationMonthlyNumber',
      simulationPreviouslyEnteredValues?.duration?.toString() ?? ''
    );
    setValue(
      'inputSimulationSelectRate',
      simulationPreviouslyEnteredValues?.rate?.toString() ?? ''
    );
    setValue(
      'inputSimulationSelectReport',
      simulationPreviouslyEnteredValues?.report?.toString() ?? ''
    );

    if (simulationPreviouslyEnteredValues?.monthAmount) {
      setFlagActivationChampsDureeMensualite('M');
    } else if (simulationPreviouslyEnteredValues?.duration) {
      setFlagActivationChampsDureeMensualite('D');
    }

    setAmountMin(bornesMontant?.minAmount!);
    setAmountMax(bornesMontant?.maxAmount!);
    setMonthlyPaymentMin(simulationPreviouslyEnteredValues?.monthlyPaymentMin!);
    setMonthlyPaymentMax(simulationPreviouslyEnteredValues?.monthlyPaymentMax!);
    setDurationMin(simulationPreviouslyEnteredValues?.durationMin!);
    setDurationMax(simulationPreviouslyEnteredValues?.durationMax!);
  }, []);

  useEffect(() => {
    setIsPageReady(inputsInfos?.loanAmount?.constraintsReceived);
  }, [inputsInfos?.loanAmount?.constraintsReceived]);

  useEffect(() => {
    if (getValues('inputSimulationLoanAmount')) {
      trigger('inputSimulationLoanAmount');
    }
    if (getValues('inputSimulationMonthlyAmount')) {
      trigger('inputSimulationMonthlyAmount');
    }
    if (getValues('inputSimulationMonthlyNumber')) {
      trigger('inputSimulationMonthlyNumber');
    }
    if (getValues('inputSimulationSelectRate')) {
      trigger('inputSimulationSelectRate');
    }
    if (getValues('inputSimulationSelectReport')) {
      trigger('inputSimulationSelectReport');
    }
  }, [simulationPreviouslyEnteredValues]);

  useEffect(() => {
    setAmountMin(bornesMontant?.minAmount!);
    setAmountMax(bornesMontant?.maxAmount!);
    setInputsInfos({
      ...inputsInfos,
      loanAmount: {
        ...inputsInfos.loanAmount,
        constraintsReceived: !!(
          bornesMontant?.minAmount && bornesMontant?.maxAmount
        ),
      },
    });
  }, [bornesMontant]);

  useEffect(() => {
    if (simulationId === 1) {
      dispatch(
        updateSimulation({
          simulation1: {
            ...simulation1,
            enteredValue: enteredValueStore!,
          },
        })
      );
    } else {
      dispatch(
        updateSimulation({
          simulation2: {
            enteredValue: enteredValueStore!,
          },
        })
      );
    }
  }, [enteredValueStore]);

  useEffect(() => {
    if (
      getValues('inputSimulationMonthlyAmount') ||
      simulationPreviouslyEnteredValues?.monthAmount ===
        getValues('inputSimulationMonthlyAmount')
    ) {
      setFlagActivationChampsDureeMensualite('M');
    }

    if (
      getValues('inputSimulationMonthlyNumber') ||
      simulationPreviouslyEnteredValues?.duration ===
        getValues('inputSimulationMonthlyNumber')
    ) {
      setFlagActivationChampsDureeMensualite('D');
    }

    if (
      !getValues('inputSimulationMonthlyAmount') &&
      !getValues('inputSimulationMonthlyNumber')
    ) {
      setFlagActivationChampsDureeMensualite('MD');
    }
  }, [
    getValues('inputSimulationMonthlyAmount'),
    getValues('inputSimulationMonthlyNumber'),
  ]);

  // passe a true isValid pour le champ passé en paramètre (dans le state inputsInfos)
  const onValidateInput = (inputValidated: INPUT) => {
    setInputsInfos((prevInputsInfos) => ({
      ...prevInputsInfos,
      [inputValidated]: {
        ...prevInputsInfos[inputValidated],
        isValid: true,
      },
    }));
  };

  // passe tous les inputs en dessous de inputChanged à isValid:false, dans le but de les désactiver
  const onChangeInput = (inputChanged: INPUT) => {
    const inputsOrder: INPUT[] = [
      INPUT.loanAmount,
      INPUT.mouthlyAmountNumber,
      INPUT.selectRate,
      INPUT.selectReport,
    ];
    while (inputsOrder.length) {
      if (inputsOrder.includes(inputChanged)) {
        // On vérifie dans le while qu'il reste un élément, le ! est donc tolérable
        const inputToInvalidate: INPUT = inputsOrder.pop()!;
        setInputsInfos((prevInputsInfos) => ({
          ...prevInputsInfos,
          [inputToInvalidate]: {
            ...prevInputsInfos[inputToInvalidate],
            isValid: false,
          },
        }));
        // On vide les champs du dessous (mais pas le champ en cours de changement)
        if (inputToInvalidate !== inputChanged) {
          let controllerName: string;
          for (controllerName of inputToControllersName[inputToInvalidate]) {
            resetField(controllerName, { defaultValue: '' });
            // Ne pas afficher les tarifs précédents avant retour nouveau calcul simple
            if (controllerName === 'inputSimulationSelectRate') {
              setOptionsTarifs(undefined);
            }
          }
        }
      } else {
        break;
      }
    }
  };

  const buildRequest = () => {
    const numberFormattedMonthlyAmountValue = formatAsNumber(
      getValues('inputSimulationMonthlyAmount')
    );
    const numberFormattedMonthlyNumberValue = formatAsNumber(
      getValues('inputSimulationMonthlyNumber')
    );
    return {
      montant:
        getValues('inputSimulationLoanAmount') === ''
          ? ''
          : formatAsNumber(getValues('inputSimulationLoanAmount')),
      mensualite:
        getValues('inputSimulationMonthlyAmount') === ''
          ? ''
          : monthlyPaymentMin <= numberFormattedMonthlyAmountValue &&
            numberFormattedMonthlyAmountValue <= monthlyPaymentMax
          ? numberFormattedMonthlyAmountValue
          : '',
      duree:
        getValues('inputSimulationMonthlyNumber') === ''
          ? ''
          : durationMin <= numberFormattedMonthlyNumberValue &&
            numberFormattedMonthlyNumberValue <= durationMax
          ? numberFormattedMonthlyNumberValue
          : '',
      numero_point_de_vente: vendorId!,
      age_emprunteur: 25,
    };
  };

  const getSimulationSimple = async () => {
    const bodyRequest = buildRequest();
    const param = {
      simulationRequest: bodyRequest,
    };
    await triggerSimulationSimple(param);
  };

  useEffect(() => {
    const tarifs: IOption[] = [];
    if (simulationSimpleResult?.data) {
      simulationSimpleResult.data?.tarifs?.forEach((tarifSimulations) => {
        tarifs.push({
          id: tarifSimulations.id_tarification,
          label: tarifSimulations.libelle_tarification,
        });
      });

      setOptionsTarifs(tarifs);

      setEnteredValueStore({
        ...enteredValueStore,
        durationMin: simulationSimpleResult?.data?.duree_min,
        durationMax: simulationSimpleResult?.data?.duree_max,
        monthlyPaymentMin: simulationSimpleResult?.data?.mensualite_min,
        monthlyPaymentMax: simulationSimpleResult?.data?.mensualite_max,
        rates: tarifs,
        tarifs: simulationSimpleResult.data?.tarifs,
      });

      setDurationMin(simulationSimpleResult?.data?.duree_min);
      setDurationMax(simulationSimpleResult?.data?.duree_max);
      setMonthlyPaymentMin(simulationSimpleResult?.data?.mensualite_min);
      setMonthlyPaymentMax(simulationSimpleResult?.data?.mensualite_max);

      const isDurationConstReceived = !!(
        simulationSimpleResult?.data?.duree_min &&
        simulationSimpleResult?.data?.duree_max
      );
      const isMonthlyAmountConstReceived = !!(
        simulationSimpleResult?.data?.mensualite_min &&
        simulationSimpleResult?.data?.mensualite_max
      );

      setInputsInfos((prevInputsInfos) => ({
        ...prevInputsInfos,
        mouthlyAmountNumber: {
          ...prevInputsInfos.mouthlyAmountNumber,
          constraintsReceived:
            isDurationConstReceived && isMonthlyAmountConstReceived,
        },
      }));

      const isRatesAndReportsReceived = !!tarifs.length;
      setInputsInfos((prevInputsInfos) => ({
        ...prevInputsInfos,
        selectRate: {
          ...prevInputsInfos.selectRate,
          constraintsReceived: isRatesAndReportsReceived,
        },
        selectReport: {
          ...prevInputsInfos.selectReport,
          constraintsReceived: isRatesAndReportsReceived,
        },
      }));
    }
  }, [simulationSimpleResult?.data]);

  // Permet un seul click sur input tarification pour afficher les choix
  useEffect(() => {
    if (document.activeElement?.id === 'inputSimulationSelectRate') {
      document.getElementById('inputSimulationSelectRate')?.click();
    }
  }, [optionsTarifs]);

  const onValidateInputLoanAmount = (value: string) => {
    value = value?.toString().replaceAll(' ', '').replace(',', '.');
    return (
      (amountMin ?? 0) <= Number(value)! && Number(value)! <= (amountMax ?? 0)
    );
  };

  const createInputLoanAmountError = (value: string) => {
    let errorLoanAmount = '';
    value = value?.toString().replaceAll(' ', '').replace(',', '.');
    const isValid =
      (amountMin ?? 0) <= Number(value)! && Number(value)! <= (amountMax ?? 0);
    if (isValid || value === '') {
      errorLoanAmount = '';
    } else {
      if (errorLoanAmount !== '') {
        errorLoanAmount = messages.ERROR_LOAN_AMOUNT_KO;
      } else {
        errorLoanAmount =
          messages.ERROR_LOAN_AMOUNT +
          formatNumber(amountMin) +
          messages.AND +
          formatNumber(amountMax) +
          messages.EURO;
      }
    }

    return errorLoanAmount;
  };

  const onValidateInputMonthlyAmount = (value: string) => {
    value = value?.toString().replaceAll(' ', '').replace(',', '.');
    const isValid =
      ((monthlyPaymentMin ?? 0) <= Number(value) &&
        Number(value) <= (monthlyPaymentMax ?? 0) &&
        errorLoanAmount === '' &&
        !getValues('inputSimulationMonthlyNumber')) ||
      flagActivationChampsDureeMensualite !== 'M';
    return !!isValid;
  };

  const createInputMonthlyAmountError = (value: string) => {
    let errorMonthlyPayment = '';
    value = value?.toString().replaceAll(' ', '').replace(',', '.');
    const isValid =
      ((monthlyPaymentMin ?? 0) <= Number(value) &&
        Number(value) <= (monthlyPaymentMax ?? 0) &&
        errorLoanAmount === '' &&
        !getValues('inputSimulationMonthlyNumber')) ||
      flagActivationChampsDureeMensualite !== 'M';
    if (isValid || value === '') {
      errorMonthlyPayment = '';
    } else {
      if (errorLoanAmount !== '') {
        errorMonthlyPayment = messages.ERROR_LOAN_AMOUNT_KO;
      } else {
        errorMonthlyPayment =
          messages.ERROR_MONTHLY_AMOUNT +
          formatNumber(monthlyPaymentMin) +
          messages.AND +
          formatNumber(monthlyPaymentMax) +
          messages.EURO;
      }
    }

    return errorMonthlyPayment;
  };

  const onValidateInputMonthlyNumber = (value: string) => {
    value = value?.toString().replaceAll(' ', '');
    const isValid =
      ((durationMin ?? 0) <= Number(value) &&
        Number(value) <= (durationMax ?? 0) &&
        errorLoanAmount === '' &&
        !getValues('inputSimulationMonthlyAmount')) ||
      flagActivationChampsDureeMensualite !== 'D';

    return !!isValid;
  };

  const createInputMonthlyNumberError = (value: string) => {
    let errorDuration = '';
    value = value?.toString().replaceAll(' ', '');
    const isValid =
      ((durationMin ?? 0) <= Number(value) &&
        Number(value) <= (durationMax ?? 0) &&
        errorLoanAmount === '' &&
        !getValues('inputSimulationMonthlyAmount')) ||
      flagActivationChampsDureeMensualite !== 'D';

    if (isValid || value === '') {
      errorDuration = '';
    } else {
      if (errorLoanAmount !== '') {
        errorDuration = messages.ERROR_LOAN_AMOUNT_KO;
      } else {
        errorDuration =
          messages.ERROR_MONTHLY_NUMBER +
          formatNumber(durationMin) +
          messages.AND +
          formatNumber(durationMax) +
          messages.MONTHS;
      }
    }

    return errorDuration;
  };

  const setReportsList = (value: string) => {
    const reports: IOption[] = [];

    const resultSimu = simulationSimpleResult.isUninitialized
      ? simulationPreviouslyEnteredValues?.tarifs
      : simulationSimpleResult.data?.tarifs;

    resultSimu?.forEach(
      (tarifSimulations: {
        libelle_tarification: string;
        id_tarification: string;
        reports: any[];
      }) => {
        if (tarifSimulations.id_tarification === value) {
          tarifSimulations.reports.forEach((report) => {
            reports.push({
              id: report.toString(),
              label: report.toString(),
            });
          });
        }
      }
    );

    setOptionsReports(reports);
  };

  useEffect(() => {
    setEnteredValueStore({
      ...enteredValueStore,
      reports: optionsReports,
    });
  }, [optionsReports]);

  const findLibelleTarification = (value: string) => {
    const resultSimu = simulationSimpleResult.isUninitialized
      ? simulationPreviouslyEnteredValues?.tarifs
      : simulationSimpleResult.data?.tarifs;

    resultSimu?.forEach(
      (tarifSimulations: {
        libelle_tarification: string;
        id_tarification: string;
        reports: any[];
      }) => {
        if (tarifSimulations.id_tarification === value) {
          setEnteredValueStore({
            ...enteredValueStore,
            libelleeTarification: tarifSimulations.libelle_tarification,
            rate: value,
          });
        }
      }
    );
  };
  const monthlyAmountRef = useRef<HTMLFormElement | null>(null);
  const monthlyNumberRef = useRef<HTMLFormElement | null>(null);
  return (
    <StyledCard>
      <WrapperInputCreateSimulation>
        <Controller
          control={control}
          name={'inputSimulationLoanAmount'}
          defaultValue={
            simulationPreviouslyEnteredValues?.amount
              ? simulationPreviouslyEnteredValues.amount
              : ''
          }
          rules={{
            required: true,
            validate: onValidateInputLoanAmount,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              inputMode={MobileKeyboardType.DECIMAL}
              suffixText={messages.EURO}
              name={name}
              label={messages.INPUT_LOAN_AMOUNT}
              value={value}
              placeholder={messages.PRICE_PLACEHOLDER}
              isTouched={isTouched}
              isValid={!invalid}
              onFocus={() => {
                onFocusInputPrice(value, onChange);
              }}
              onBlur={async () => {
                if (value !== '' && !invalid && simulationLoanAmount) {
                  await getSimulationSimple();
                  onValidateInput(INPUT.loanAmount);
                  if (document.activeElement === monthlyAmountRef.current) {
                    monthlyAmountRef.current?.blur();
                    resetField('inputSimulationMonthlyAmount', {
                      defaultValue: '',
                    });
                    setNeedFocusMonthlyAmount(true);
                  } else if (
                    document.activeElement === monthlyNumberRef.current
                  ) {
                    monthlyNumberRef.current?.blur();
                    resetField('inputSimulationMonthlyNumber', {
                      defaultValue: '',
                    });
                    setNeedFocusMonthlyNumber(true);
                  }
                }
                onBlurInputPrice(value, onChange);
                onBlur();
              }}
              inputRef={ref}
              onChange={(newValue: string | number) => {
                setSimulationLoanAmount(true);
                newValue = onChangeInputPrice(
                  value,
                  newValue,
                  onChange
                ).replace(',', '.');
                onChangeInput(INPUT.loanAmount);
                setEnteredValueStore({
                  ...enteredValueStore,
                  amount: Number(newValue),
                  duration: undefined,
                  monthAmount: undefined,
                });
                setFlagActivationChampsDureeMensualite('MD');
              }}
              isSubmitted={isSubmitted}
              maxLength={16}
              error={createInputLoanAmountError(value)}
              isDisabled={!inputsInfos.loanAmount.constraintsReceived}
              valueWithoutSpaces={false}
            />
          )}
        />
      </WrapperInputCreateSimulation>
      <WrapperInputMonthlyPayment>
        <WrapperInputCreateSimulation>
          <Controller
            control={control}
            name={'inputSimulationMonthlyAmount'}
            defaultValue={
              simulationPreviouslyEnteredValues?.monthAmount &&
              !simulationPreviouslyEnteredValues?.duration
                ? simulationPreviouslyEnteredValues.monthAmount
                : ''
            }
            rules={{
              required: flagActivationChampsDureeMensualite === 'M',
              validate: onValidateInputMonthlyAmount,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Input
                inputMode={MobileKeyboardType.DECIMAL}
                suffixText={messages.EURO}
                name={name}
                label={messages.INPUT_MONTHLY_AMOUNT}
                value={value}
                placeholder={messages.PRICE_PLACEHOLDER}
                isTouched={isTouched}
                isValid={!invalid && !(isTouched && value == '')}
                onBlur={() => {
                  if (value !== '' && !invalid && simulationMonthlyAmount) {
                    getSimulationSimple();
                    onValidateInput(INPUT.mouthlyAmountNumber);
                  }
                  onBlurInputPrice(value, onChange);
                  onBlur();
                }}
                inputRef={(e: any) => {
                  ref(e);
                  monthlyAmountRef.current = e;
                }}
                onChange={(newValue: string | number) => {
                  setSimulationMonthlyAmount(true);
                  newValue = onChangeInputPrice(
                    value,
                    newValue,
                    onChange
                  ).replace(',', '.');
                  onChangeInput(INPUT.mouthlyAmountNumber);
                  setEnteredValueStore({
                    ...enteredValueStore,
                    monthAmount: Number(newValue),
                    duration: undefined,
                  });
                  resetField('inputSimulationMonthlyNumber', {
                    defaultValue: '',
                  });
                }}
                onFocus={() => {
                  onFocusInputPrice(value, onChange);
                }}
                isSubmitted={isSubmitted}
                maxLength={16}
                error={createInputMonthlyAmountError(value)}
                isDisabled={
                  (flagActivationChampsDureeMensualite !== 'M' &&
                    flagActivationChampsDureeMensualite !== 'MD') ||
                  !inputsInfos.loanAmount.isValid ||
                  !inputsInfos.mouthlyAmountNumber.constraintsReceived
                }
                valueWithoutSpaces={false}
                isLoading={
                  simulationSimpleResult?.isLoading &&
                  !inputsInfos?.mouthlyAmountNumber?.isValid
                }
              />
            )}
          />
        </WrapperInputCreateSimulation>
        <WrapperMonthlyText>
          <hr />
          <p>{messages.MONTHLY_TEXT_OR}</p>
          <hr />
        </WrapperMonthlyText>
        <WrapperInputCreateSimulation>
          <Controller
            control={control}
            name={'inputSimulationMonthlyNumber'}
            defaultValue={
              simulationPreviouslyEnteredValues?.duration &&
              !simulationPreviouslyEnteredValues?.monthAmount
                ? simulationPreviouslyEnteredValues.duration
                : ''
            }
            rules={{
              required: flagActivationChampsDureeMensualite === 'D',
              validate: onValidateInputMonthlyNumber,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Input
                inputMode={MobileKeyboardType.NUMERIC}
                suffixText={messages.MONTHS}
                name={name}
                label={messages.INPUT_MONTHLY_NUMBER}
                value={value}
                placeholder={messages.MOUNTH_PLACEHOLDER}
                isTouched={isTouched}
                isValid={!invalid && !(isTouched && value == '')}
                onBlur={() => {
                  if (value !== '' && !invalid && simulationMonthlyNumber) {
                    getSimulationSimple();
                    onValidateInput(INPUT.mouthlyAmountNumber);
                  }
                  onBlur();
                }}
                inputRef={(e: any) => {
                  ref(e);
                  monthlyNumberRef.current = e;
                }}
                onChange={(newValue: string | number) => {
                  setSimulationMonthlyNumber(true);
                  if (newValue.toString().match(/\D/)) {
                    return;
                  }
                  onChangeInput(INPUT.mouthlyAmountNumber);
                  setEnteredValueStore({
                    ...enteredValueStore,
                    duration: Number(newValue),
                    monthAmount: undefined,
                  });
                  onChange(newValue);
                  resetField('inputSimulationMonthlyAmount', {
                    defaultValue: '',
                  });
                }}
                isSubmitted={isSubmitted}
                maxLength={8}
                error={createInputMonthlyNumberError(value)}
                isDisabled={
                  (flagActivationChampsDureeMensualite !== 'D' &&
                    flagActivationChampsDureeMensualite !== 'MD') ||
                  !inputsInfos.loanAmount.isValid ||
                  !inputsInfos.mouthlyAmountNumber.constraintsReceived
                }
                valueWithoutSpaces={false}
                isLoading={
                  simulationSimpleResult?.isLoading &&
                  !inputsInfos?.mouthlyAmountNumber?.isValid
                }
              />
            )}
          />
        </WrapperInputCreateSimulation>
      </WrapperInputMonthlyPayment>
      <WrapperRateCreateSimulation>
        <Controller
          control={control}
          name={'inputSimulationSelectRate'}
          defaultValue={
            simulationPreviouslyEnteredValues
              ? simulationPreviouslyEnteredValues.rate
              : ''
          }
          rules={{ required: true }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Select
              label={messages.SELECT_RATE}
              name={name}
              placeholder={messages.SELECT_OPTION_DEFAULT}
              options={optionsTarifs || []}
              value={value}
              isTouched={isTouched}
              onChange={(value) => {
                setReportsList(value);
                onChangeInput(INPUT.selectRate);
                onValidateInput(INPUT.selectRate);
                findLibelleTarification(value);
                onChange(value);
              }}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              isSubmitted={isSubmitted}
              error={isTouched && invalid ? messages.ERROR_SELECT : ''}
              isDisabled={
                !inputsInfos.mouthlyAmountNumber.isValid ||
                !inputsInfos.selectRate.constraintsReceived ||
                optionsTarifs === undefined
              }
              isLoading={
                simulationSimpleResult?.isLoading &&
                inputsInfos?.mouthlyAmountNumber?.isValid
              }
            />
          )}
        />
      </WrapperRateCreateSimulation>
      <WrapperSelectCreateSimulation>
        <Controller
          control={control}
          name={'inputSimulationSelectReport'}
          defaultValue={
            simulationPreviouslyEnteredValues
              ? simulationPreviouslyEnteredValues.report
              : ''
          }
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Select
              label={messages.SELECT_REPORT}
              placeholder={messages.SELECT_REPORT_DEFAULT}
              name={name}
              onChange={(value) => {
                setEnteredValueStore({ ...enteredValueStore, report: value });
                onChange(value);
              }}
              options={optionsReports || []}
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              isSubmitted={isSubmitted}
              error={isTouched && invalid ? messages.ERROR_SELECT : ''}
              isDisabled={
                !inputsInfos.selectRate.isValid ||
                !inputsInfos.selectReport.constraintsReceived
              }
            />
          )}
        />
      </WrapperSelectCreateSimulation>
    </StyledCard>
  );
};

export default CreditForm;
