import { Loader } from '@components';
import { IDescriptionListProps, DescriptionListVariant } from '@types';
import {
  Wrapper,
  StyledList,
  Item,
  Title,
  SubTitle,
  Description,
  StyledSubTitle,
  IconWrapper,
  LoaderWrapper,
} from './styles';

const DescriptionList = ({
  list,
  className = '',
  variant = DescriptionListVariant.FILLED_BY_DOTS,
}: IDescriptionListProps): React.ReactElement => (
  <StyledList className={'ph-description-list ' + className}>
    {list.map(
      ({
        title,
        subTitle,
        description,
        fieldName,
        isLineBold = false,
        isVisible = true,
        isSubtitleStyled = false,
        isValueBold = false,
        isLoading = false,
      }) => (
        <Item key={fieldName} isLineBold={isLineBold} variant={variant}>
          {isVisible && (
            <>
              <Wrapper>
                <Title variant={variant}>{title}</Title>
                {!isLoading && (
                  <Description className={fieldName} isValueBold={isValueBold}>
                    {description}
                  </Description>
                )}
                {isLoading && (
                  <IconWrapper>
                    <LoaderWrapper>
                      <Loader />
                    </LoaderWrapper>
                  </IconWrapper>
                )}
              </Wrapper>
              {subTitle && (
                <Wrapper>
                  {isSubtitleStyled ? (
                    <StyledSubTitle>{subTitle}</StyledSubTitle>
                  ) : (
                    <SubTitle>{subTitle}</SubTitle>
                  )}
                </Wrapper>
              )}
            </>
          )}
        </Item>
      )
    )}
  </StyledList>
);

export default DescriptionList;
