import {
  StyledCardWrapper,
  StyledContractIcon,
  StyledModalContent,
  StyledText,
  StyledTextBold,
} from './styles';
import * as messages from './messages';
import { useRedirection } from '@hooks';

const Signature: React.FC = () => {
  const { redirigerAccueil } = useRedirection();

  return (
    <StyledCardWrapper>
      <StyledModalContent
        icon={<StyledContractIcon />}
        text={
          <>
            <StyledText>
              {messages.FIRST_LINE}
              <br />
              {messages.SECOND_LINE}
            </StyledText>
            <StyledTextBold>{messages.THIRD_LINE}</StyledTextBold>
          </>
        }
        primaryButtonLabel={messages.BUTTON}
        onPrimaryButtonClick={redirigerAccueil}
      ></StyledModalContent>
    </StyledCardWrapper>
  );
};

export default Signature;
