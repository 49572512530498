import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './simulationTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

//Initial State
export const initialState = getInitialState();

const simulationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    setSimulation(simulationState, action: PayloadAction<IState>) {
      simulationState.state = { ...action.payload };
    },
    updateSimulation(simulationState, action: PayloadAction<Partial<IState>>) {
      simulationState.state = {
        ...simulationState?.state,
        ...action.payload,
      };
    },
    clear(simulationState) {
      simulationState.state = getInitialState().state;
    },
    clearExceptBornesAmount(simulationState) {
      const minAmount =
        simulationState.state.simulation1?.calculatedValue?.minAmount;
      const maxAmount =
        simulationState.state.simulation1?.calculatedValue?.maxAmount;
      simulationState.state = {
        ...getInitialState().state,
        simulation1: {
          calculatedValue: {
            minAmount,
            maxAmount,
          },
        },
      };
    },
  },
});

export const selectSimulation = (simulationState: {
  simulation: { state: IState };
}) => simulationState.simulation.state;

export const {
  setSimulation,
  updateSimulation,
  clear,
  clearExceptBornesAmount,
} = simulationSlice.actions;

export default simulationSlice.reducer;
