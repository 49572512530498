import { Signature as SignatureContainer } from '@containers';
import { WrapperSignature } from './styles';

export const Signature: React.FC = () => {
  return (
    <WrapperSignature>
      <SignatureContainer />
    </WrapperSignature>
  );
};

export default Signature;
