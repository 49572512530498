import {
  StyledHeader,
  StyledHeaderWrapper,
  StyledRightIconContainer,
} from './styles';

interface ICardHeaderProps {
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  RightIcon?: React.FC;
  LeftIcon?: React.FC;
}

const WrapperContentHeader = ({
  children,
  RightIcon,
}: {
  children?: React.ReactNode;
  RightIcon?: React.FC;
}): React.ReactElement =>
  RightIcon ? <StyledHeader>{children}</StyledHeader> : <>{children}</>;

const Header = ({
  children,
  onClick,
  className = '',
  RightIcon,
  LeftIcon,
}: ICardHeaderProps): React.ReactElement => (
  <StyledHeaderWrapper
    className={className}
    onClick={onClick}
    isClickable={!!onClick}
  >
    {LeftIcon && (
      <StyledRightIconContainer>
        <LeftIcon />
      </StyledRightIconContainer>
    )}
    <WrapperContentHeader>{children}</WrapperContentHeader>
    {RightIcon && (
      <StyledRightIconContainer>
        <RightIcon />
      </StyledRightIconContainer>
    )}
  </StyledHeaderWrapper>
);
export default Header;
