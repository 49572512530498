import Cookies, { CookieSetOptions } from 'universal-cookie';

export const setCookie = ({
  name = '',
  originUri = '',
  maxAge = 0,
  domain = '',
}) => {
  removeCookie(name);
  new Cookies().set(name, originUri, { maxAge, domain });
};

export const removeCookie = (
  name: string,
  options?: CookieSetOptions | undefined
) => {
  new Cookies().remove(name, options ?? undefined);
};
