import * as messages from './messages';
import { Input, Title } from '@components';
import { StyledCard, WrapperPurchaseOrder } from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import { selectOffer, useAppSelector } from '@store';
import { purchaseOrderNumberRegExp } from '@utils';
import useOfferForm from '../useOfferForm';
import { IOfferFormProps } from '../types';
import { useEffect } from 'react';

const PurchaseOrder: React.FC<IOfferFormProps> = ({
  onShouldNotSubmitForm,
  isSubmitted = false,
}) => {
  const { control, trigger } = useFormContext();
  // On récupère les données de l'offre stockées dans le store
  const offer = useAppSelector(selectOffer);
  useOfferForm();

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [isSubmitted]);

  return (
    <StyledCard>
      <Title level={2}>{messages.LABEL_PURCHASE_ORDER}</Title>
      <WrapperPurchaseOrder>
        <Controller
          control={control}
          name={'purchaseOrderNumber'}
          defaultValue={offer?.purchaseOrderNumber}
          rules={{
            required: false,
            pattern: purchaseOrderNumberRegExp,
            minLength: 1,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              name={name}
              label={messages.LABEL_PURCHASE_ORDER_NUMBER}
              afterLabel={messages.FACULTATIF}
              placeholder={messages.LABEL_PURCHASE_ORDER_NUMBER}
              value={value}
              onChange={(value) => {
                onShouldNotSubmitForm(false);
                let displayValue = value.toString().toUpperCase();
                if ((displayValue ?? '').length > 13) {
                  displayValue = displayValue.substring(0, 13);
                }
                onChange(displayValue);
              }}
              autoComplete="one-time-code"
              isTouched={isTouched && value?.length > 0}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              inputWidth="51rem"
              isSubmitted={isSubmitted}
              maxLength={13}
            />
          )}
        />
      </WrapperPurchaseOrder>
    </StyledCard>
  );
};

export default PurchaseOrder;
