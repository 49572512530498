export const TITLE_MODAL_UNREADABLE_EVIDENCE = 'Pièce non reconnue';
export const ERROR_UNREADABLE_EVIDENCE =
  "Nous n'avons pas réussi à reconnaître la pièce de votre client.";
export const ERROR_UNREADABLE_EVIDENCE_2 =
  'Le type de pièce sélectionné correspond-il à la pièce chargée ?';
export const ERROR_UNREADABLE_EVIDENCE_3 =
  'La pièce chargée est-elle lisible et bien cadrée ?';
export const TITLE_MODAL_INCONSISTENCY_TYPE = 'Mauvais type de document';
export const PROOF_BUTTON = 'CHARGER À NOUVEAU';
export const TO_SEND_BUTTON = 'ACCEPTER LA PIÈCE';
