import styled from 'styled-components';

export const StyledWrapperText = styled.div`
  max-width: 44rem;
`;

export const StyledText = styled.div`
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const WrapperPrompt = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const WrapperPromptFinishUpload = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  margin-bottom: -0.8rem;
`;

export const StyledTitle = styled.div`
  font-weight: ${({ theme }) => theme.variables.fonts.types.bold};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h2};
  color: ${({ theme }) => theme.vendor.colors.primary};
  margin-bottom: -0.8rem;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const WrapperDownloadIcon = styled.div`
  width: 4rem;
  align-self: center;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;
export const WrapperTextSwitchDevice = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;
