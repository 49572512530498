import { ReactNode, useEffect, useState } from 'react';
import { Wrapper, StyledButton, StyledLoader, StyledTooltip } from './styles';

export type VariantType = 'primary' | 'secondary' | 'dark' | 'link' | 'light';

export interface IButtonProps {
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  variant?: VariantType;
  value?: string | number;
  isLoading?: boolean;
  isDisplayingInfoBubble?: boolean;
  infoBubbleMsgNode?: ReactNode;
  actionAfterLoading?: () => void;
  requestsToWait?: {
    isLoading: boolean;
    isUninitialized: boolean;
    data?: any;
  }[];
  isReadyFromParent?: boolean;
  isActionValid?: boolean;
}

const Button = ({
  children,
  type = 'button',
  isDisabled = false,
  onClick = () => null,
  className = '',
  variant = 'primary',
  value,
  isLoading = false,
  isDisplayingInfoBubble = false,
  infoBubbleMsgNode = null,
  actionAfterLoading,
  requestsToWait,
  isReadyFromParent = !requestsToWait,
  isActionValid = true,
}: IButtonProps): React.ReactElement => {
  const [isReady, setIsReady] = useState(!!isReadyFromParent);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isReady && isClicked && actionAfterLoading) {
      setIsClicked(false);
      actionAfterLoading();
    }
  }, [isClicked, isReady]);

  useEffect(() => {
    if (!isActionValid) {
      setIsClicked(false);
    }
  }, [isClicked]);

  // Controler le loader à partir du parent
  useEffect(() => {
    setIsReady(!!isReadyFromParent);
  }, [isReadyFromParent]);

  useEffect(() => {
    if (!isLoading) {
      setIsClicked(false);
    }
  }, [isLoading]);

  // Controler le loader à partir de Button
  useEffect(() => {
    if (!requestsToWait) {
      return;
    }

    let ready = true;
    requestsToWait.forEach((request) => {
      if (
        request?.isLoading ||
        request?.isUninitialized ||
        request?.data === undefined
      ) {
        ready = false;
      }
    });

    setIsReady(ready);
  }, [requestsToWait]);

  const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (actionAfterLoading) {
      setIsClicked(true);
    }
    if (onClick) {
      onClick(event);
    }
    if (
      !requestsToWait &&
      isReadyFromParent === undefined &&
      actionAfterLoading
    ) {
      actionAfterLoading();
    }
  };

  // Par défaut l'action est en attente si : cliqué et pas prêt et l'action est valide
  const isDefaultLoading = !isReady && isClicked && isActionValid;

  return (
    <Wrapper>
      {isDisplayingInfoBubble && (
        <StyledTooltip
          isVisible={isDisplayingInfoBubble && !!infoBubbleMsgNode}
        >
          {infoBubbleMsgNode}
        </StyledTooltip>
      )}
      <StyledButton
        type={type}
        disabled={isDisabled || isLoading || isDefaultLoading}
        isLoading={isLoading || isDefaultLoading}
        variant={variant}
        className={'ph-button ' + className}
        value={value}
        onClick={onClickButton}
      >
        <StyledLoader
          isLoading={isLoading || isDefaultLoading}
          isHideBackground={false}
        >
          {children}
        </StyledLoader>
      </StyledButton>
    </Wrapper>
  );
};

export default Button;
