import { Loader, Subtitle } from '@components';
import styled from 'styled-components';

export const StyledSubtitle = styled(Subtitle)`
  display: flex;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const TextParagraph = styled.p`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  letter-spacing: 0;
  margin: 0;
  padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  padding-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;
export const TextBold = styled.a`
  font-weight: bold;
`;
export const StyledH2 = styled.h2`
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h2};
  font-weight: bold;
  letter-spacing: 0;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  line-height: 2.8rem;
`;

export const WrapperTitle = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;
export const WrapperIconText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const StyledLoader = styled(Loader)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;
