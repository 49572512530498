const SvgSearch: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.053 3a8.053 8.053 0 016.019 13.402l3.79 3.79a.474.474 0 11-.67.67l-3.79-3.79A8.053 8.053 0 1111.052 3zm0 .947a7.105 7.105 0 104.966 12.187l.051-.064c.02-.02.041-.037.063-.052a7.105 7.105 0 00-5.08-12.07z"
    />
  </svg>
);

export default SvgSearch;
