import styled from 'styled-components';
import { MAX_WIDTH_SIZE } from '@utils';

export const StyledLabel = styled.p`
  margin: 0 0 ${({ theme }) => theme.variables.layout.spacing.spacing_xxs} 0;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
`;

export const StyledSubText = styled.p<{
  isMobileDevice: boolean;
}>`
  margin: 0 0
    ${({ isMobileDevice, theme }) =>
      !isMobileDevice
        ? theme.variables.layout.spacing.spacing_s
        : theme.variables.layout.spaces.medium}
    0;
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
`;

export const WrapperUploadZone = styled.div<{
  isMobileDevice: boolean;
}>`
  margin: ${({ theme, isMobileDevice }) =>
      isMobileDevice
        ? theme.variables.layout.spaces.medium
        : theme.variables.layout.spacing.spacing_s}
    0 0 0;
`;

export const WrapperUploadZoneIcon = styled.div`
  display: block;
`;

export const WrapperUploadZoneLabel = styled.span`
  vertical-align: middle;
`;

export const WrapperUploadZoneLabelBis = styled.span`
  vertical-align: middle;
  text-decoration: underline;
  font-weight: bold;
`;

export const WrapperUploadFileProgress = styled.div<{
  isMobileDevice: boolean;
}>`
  display: block;
  margin-top: ${({ isMobileDevice, theme }) =>
    isMobileDevice ? '0.3rem' : theme.variables.layout.spacing.spacing_xs};
`;

export const WrapperTablet = styled.div`
  margin: 18px;
  ${WrapperUploadZoneIcon} {
    display: inline-block;
    width: ${({ theme }) => theme.variables.fonts.sizes.h3};
    height: ${({ theme }) => theme.variables.fonts.sizes.h3};
    margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;

export const WrapperDesktop = styled.div<{
  isDisabled: boolean;
}>`
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  ${WrapperUploadZoneIcon} {
    width: 7.3rem;
    height: 6rem;
    margin: 0 auto ${({ theme }) => theme.variables.layout.spacing.spacing_xs}
      auto;
    color: ${({ isDisabled, theme }) =>
      isDisabled ? 'inherit' : theme.vendor.colors.primary};
  }
`;

export const WrapperDestopLabel = styled.div`
  margin: auto;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
  ${WrapperUploadZoneLabel} {
    font-weight: ${({ theme }) => theme.variables.fonts.types.normal};
  }
`;

export const StyledUploadFileProgress = styled.div`
  padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const WrapperProgress = styled.div`
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xs} 0 0;
`;

export const StyledUploadedFile = styled.div`
  display: flex;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: 1rem;
  gap: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

// min-width: 0; pour que le text-overflow marche bien
export const WrapperFileName = styled.div`
  flex: 1;
  min-width: 0;
`;

export const StyledFileName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
`;

export const WrapperOptions = styled.div`
  flex: 1;
  max-width: fit-content;
  padding-top: 3px;
`;

export const StyledOptions = styled.div`
  float: right;
`;

export const StyledIcons = styled.div`
  display: inline-block;
  width: ${({ theme }) => theme.variables.fonts.sizes.h4};
  height: ${({ theme }) => theme.variables.fonts.sizes.h4};
  cursor: pointer;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    width: ${({ theme }) => theme.variables.fonts.sizes.h2};
    height: ${({ theme }) => theme.variables.fonts.sizes.h2};
  }
`;

export const StyledCheck = styled.div`
  display: inline-block;
  width: ${({ theme }) => theme.variables.fonts.sizes.h4};
  height: ${({ theme }) => theme.variables.fonts.sizes.h4};
  color: ${({ theme }) => theme.vendor.colors.primary};
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const StyledUploadZone = styled.div<{
  isDisabled: boolean;
  isOver: boolean;
  isUploadMultiple: boolean;
  maxWidth?: string;
}>`
  border: 2px dashed
    ${({ isDisabled, isOver, theme }) =>
      isOver && !isDisabled
        ? theme.vendor.colors.primary
        : theme.variables.colors.chartColors.grey_300};
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_l};
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? theme.variables.colors.chartColors.grey_300
      : theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  font-weight: bold;
  text-align: center;
  width: ${({ isUploadMultiple }) => (isUploadMultiple ? '100%' : 'inherit')};
  max-width: ${({ isUploadMultiple }) =>
    isUploadMultiple ? '48rem' : 'inherit'};
  height: inherit;
  background-color: ${({ isDisabled, isOver, theme }) => {
    if (isDisabled) {
      return theme.variables.colors.chartColors.grey_50;
    }
    if (isOver) {
      return theme.vendor.colors.lightest;
    }
    return 'transparent';
  }};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  &:hover {
    ${({ isDisabled, theme }) =>
      !isDisabled && `background-color: ${theme.vendor.colors.lightest};`};
    ${({ isDisabled, theme }) =>
      !isDisabled && `border: 2px dashed ${theme.vendor.colors.primary};`};
  }
`;

export const StyledInputFile = styled.input`
  display: none;
`;

export const WrapperUpload = styled.div`
  flex: 1;
  min-width: 0;
  display: inline-block;
  width: 100%;
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.variables.colors.chartColors.red};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
`;

export const StyledFileViewer = styled.div`
  border: 0.1rem solid
    ${({ theme }) => theme.variables.colors.chartColors.grey_300};
  border-bottom: 0rem;
  border-radius: ${({ theme }) =>
    `${theme.variables.layout.borderRadius.radius_l} ${theme.variables.layout.borderRadius.radius_l} 0rem 0rem`};
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  font-weight: bold;
  text-align: center;
  background-color: ${({ theme }) =>
    theme.variables.colors.chartColors.grey_50};
  cursor: 'pointer';
  height: 14rem;
  overflow: hidden;
  &:hover {
    ${({ theme }) => `background-color: ${theme.vendor.colors.lightest};`};
  }
`;
export const StyledImage = styled.img`
  max-width: 100%;
  position: relative;
`;

export const StyledMultipleUploads = styled.div<{
  isMultiple: boolean;
  isLast: boolean;
}>`
  width: ${({ isMultiple }) => (isMultiple ? '100%' : 'inherit')};
  max-width: ${({ isMultiple }) => (isMultiple ? '48rem' : 'inherit')};
  display: ${({ isMultiple }) => (isMultiple ? 'inline-block' : 'inherit')};
  margin-bottom: ${({ isLast, theme }) =>
    isLast ? '0' : theme.variables.layout.spacing.spacing_m};
`;
