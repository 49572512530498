import { Icons, Subtitle } from '@components';
import styled from 'styled-components';

export const TextParagraph = styled.div`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  letter-spacing: 0;
  margin: 0;
  padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  padding-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  text-align: center;
`;
export const StyledSubtitle = styled(Subtitle)`
  display: flex;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  display: inline-block;
`;
export const WrapperSubtitle = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;
export const WrapperIcon = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;
export const WrapperText = styled.div`
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;
export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xxl};
  min-height: 100%;
  padding-bottom: 11.2rem;
`;
export const StyledSentIcon = styled(Icons.CheckCircleIcon)`
  color: ${({ theme }) => theme.variables.colors.chartColors.green_700};
  width: ${({ theme }) => theme.variables.layout.spacing.spacing_xxl};
`;
export const StyledBr = styled.br`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;
