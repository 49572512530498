export enum FileStatus {
  PENDING = 'pending',
  RENAMING = 'renaming',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
  DELETING = 'deleting',
}

export enum EvidenceFace {
  RECTO = 'Recto',
  VERSO = 'Verso',
}

export default interface IFile {
  id: string;
  name: string;
  file: File;
  type?: string;
  progress: number;
  error?: string;
  status?: FileStatus;
}
