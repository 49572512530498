const SvgMinimize: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.167 13.083h.029l.047.004-.076-.004a.755.755 0 01.6.3l-.07-.08a.755.755 0 01.22.53v5.5a.75.75 0 01-1.5 0l-.001-3.69L4.28 20.78a.75.75 0 01-1.06-1.06l5.136-5.137h-3.69a.75.75 0 110-1.5h5.5zm9.166 0a.75.75 0 010 1.5h-3.69l5.137 5.137a.75.75 0 01-1.06 1.06l-5.137-5.137v3.69a.75.75 0 11-1.5 0v-5.529l.004-.047-.004.076a.755.755 0 01.3-.6l-.08.07a.755.755 0 01.53-.22h5.5zM4.28 3.22l5.136 5.136v-3.69a.75.75 0 111.5 0v5.53a.754.754 0 01-.003.047l.004-.076a.755.755 0 01-.3.6l.08-.07a.755.755 0 01-.53.22h-5.5a.75.75 0 010-1.5l3.689-.001L3.22 4.28a.75.75 0 011.06-1.06zm16.5 0a.75.75 0 010 1.06l-5.137 5.136h3.69a.75.75 0 110 1.5h-5.529a.754.754 0 01-.047-.003l.076.004a.755.755 0 01-.6-.3l.07.08a.755.755 0 01-.22-.53v-5.5a.75.75 0 011.5 0v3.689L19.72 3.22a.75.75 0 011.06 0z"
    />
  </svg>
);

export default SvgMinimize;
