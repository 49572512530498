const SvgList: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 17.25a.75.75 0 01.102 1.493L21 18.75H8a.75.75 0 01-.102-1.493L8 17.25h13zm-17.99 0a.75.75 0 01.102 1.493L3 18.75a.75.75 0 01-.102-1.493l.112-.007zm17.99-6a.75.75 0 01.102 1.493L21 12.75H8a.75.75 0 01-.102-1.493L8 11.25h13zm-17.99 0a.75.75 0 01.102 1.493L3 12.75a.75.75 0 01-.102-1.493l.112-.007zm17.99-6a.75.75 0 01.102 1.493L21 6.75H8a.75.75 0 01-.102-1.493L8 5.25h13zm-17.99 0a.75.75 0 01.102 1.493L3 6.75a.75.75 0 01-.102-1.493l.112-.007z"
    />
  </svg>
);

export default SvgList;
