import {
  selectOffer,
  selectPerson,
  selectSynchronizePerson,
  synchronizePersonCoEmprunteurReset,
  synchronizePersonCoEmprunteurResponse,
  synchronizePersonEmprunteurReset,
  synchronizePersonEmprunteurResponse,
  useAppDispatch,
  useAppSelector,
  useSendCoEmprunteurMutation,
  useSendEmprunteurMutation,
} from '@store';
import {
  ICoEmprunteurRequest,
  IEmprunteurRequest,
  IPerson,
  RoleCd,
} from '@types';
import { buildEmprunteurBackDataToStoreUtils } from 'containers/Informations/InformationsUtils';
import { useEffect } from 'react';
import {
  ICoEmprunteur,
  IEmprunteur,
} from 'store/souscriptionApi/souscriptionApiTypes';

const useBorrowers = () => {
  const NUMBER_MAX_OF_SYNCHRONIZE_ATTEMPTS = 5;

  const offer = useAppSelector(selectOffer);
  const [patchEmprunteur, responseEmprunteur] = useSendEmprunteurMutation();
  const [patchCoEmprunteur, responseCoEmprunteur] =
    useSendCoEmprunteurMutation();
  const {
    emprunteur: synchronizedEmprunteur,
    coemprunteur: synchronizedCoemprunteur,
  } = useAppSelector(selectSynchronizePerson);
  const { emprunteur, coemprunteur } = useAppSelector(selectPerson);

  const dispatch = useAppDispatch();

  //Comparaison de la requête et de la réponse
  const isPersonLastAttemptSynchronizedSuccess = ({
    role = RoleCd.BORROWER,
    personFromResponse = {},
  }: {
    role: RoleCd;
    personFromResponse?: Partial<IPerson>;
  }): boolean => {
    const personFromRequest =
      role === RoleCd.BORROWER
        ? buildEmprunteurBackDataToStoreUtils(
            (synchronizedEmprunteur?.request as IEmprunteurRequest)?.emprunteur
          ) ?? {}
        : buildEmprunteurBackDataToStoreUtils(
            (synchronizedCoemprunteur?.request as ICoEmprunteurRequest)
              ?.coemprunteur
          ) ?? {};
    return (
      (!personFromRequest.identity?.firstName ||
        personFromRequest.identity?.firstName ===
          personFromResponse.identity?.firstName) &&
      (!personFromRequest.identity?.lastName ||
        personFromRequest.identity?.lastName ===
          personFromResponse.identity?.lastName) &&
      (!personFromRequest.billingAddressInformation?.city ||
        personFromRequest.billingAddressInformation?.city ===
          personFromResponse.billingAddressInformation?.city)
    );
  };

  const synchronizeWithReattempts = ({
    role = RoleCd.BORROWER,
    responsePersonData,
  }: {
    role: RoleCd;
    responsePersonData?: IEmprunteur | ICoEmprunteur;
  }) => {
    if (!responsePersonData) {
      return;
    }
    const synchronizePersonResponse =
      role === RoleCd.BORROWER
        ? synchronizePersonEmprunteurResponse
        : synchronizePersonCoEmprunteurResponse;
    dispatch(
      synchronizePersonResponse(
        buildEmprunteurBackDataToStoreUtils(responsePersonData)
      )
    );
    const synchronizedPerson =
      role === RoleCd.BORROWER
        ? synchronizedEmprunteur
        : synchronizedCoemprunteur;

    if (
      !isPersonLastAttemptSynchronizedSuccess({
        role,
        personFromResponse:
          buildEmprunteurBackDataToStoreUtils(responsePersonData),
      }) &&
      synchronizedPerson?.request
    ) {
      if (
        (synchronizedPerson.attemptCount ?? 0) <
        NUMBER_MAX_OF_SYNCHRONIZE_ATTEMPTS
      ) {
        const sendPerson = (
          role === RoleCd.BORROWER ? sendEmprunteur : sendCoEmprunteur
        ) as (personRequest: IEmprunteurRequest | ICoEmprunteurRequest) => void;
        sendPerson(synchronizedPerson?.request);
      } else {
        dispatch(
          synchronizePersonResponse({
            isAttempting: false,
            attemptCount: 0,
          })
        );
      }
    } else {
      dispatch(
        synchronizePersonResponse({
          isSuccess: true,
          isAttempting: false,
          attemptCount: 0,
        })
      );
    }
  };

  useEffect(() => {
    synchronizeWithReattempts({
      role: RoleCd?.BORROWER,
      responsePersonData: responseEmprunteur?.data,
    });
  }, [responseEmprunteur]);

  useEffect(() => {
    synchronizeWithReattempts({
      role: RoleCd?.COBORROWER,
      responsePersonData: responseCoEmprunteur?.data,
    });
  }, [responseCoEmprunteur]);

  const hasCoborrower = () => offer?.inputBorrowers === '2';

  const sendEmprunteur = (emprunteurRequest: IEmprunteurRequest) => {
    dispatch(
      synchronizePersonEmprunteurReset({
        request: emprunteurRequest,
        emprunteur: buildEmprunteurBackDataToStoreUtils(emprunteurRequest),
      })
    );
    patchEmprunteur(emprunteurRequest);
  };

  const sendCoEmprunteur = (coEmprunteurRequest: ICoEmprunteurRequest) => {
    dispatch(
      synchronizePersonCoEmprunteurReset({
        request: coEmprunteurRequest,
        coEmprunteur: buildEmprunteurBackDataToStoreUtils(coEmprunteurRequest),
      })
    );
    patchCoEmprunteur(coEmprunteurRequest);
  };

  const refreshSynchroBorrowers = () => {
    if (!synchronizedEmprunteur?.isSuccess && synchronizedEmprunteur?.request) {
      sendEmprunteur(synchronizedEmprunteur?.request as IEmprunteurRequest);
    }
    if (
      !synchronizedCoemprunteur?.isSuccess &&
      synchronizedCoemprunteur?.request
    ) {
      sendCoEmprunteur(
        synchronizedCoemprunteur?.request as ICoEmprunteurRequest
      );
    }
  };

  const validateBorrower = (role: RoleCd = RoleCd.BORROWER): boolean => {
    const person = role === RoleCd.BORROWER ? emprunteur : coemprunteur;
    const synchronizedPerson =
      role === RoleCd.BORROWER
        ? synchronizedEmprunteur
        : synchronizedCoemprunteur;
    if (synchronizedPerson?.isAttempting || !synchronizedPerson?.isSuccess) {
      return false;
    }
    return (
      person?.identity?.firstName === synchronizedPerson?.identity?.firstName &&
      person?.identity?.lastName === synchronizedPerson?.identity?.lastName &&
      person?.identity?.birthName === synchronizedPerson?.identity?.birthName &&
      person?.contactInformation?.email ===
        synchronizedPerson?.contactInformation?.email &&
      person?.contactInformation?.mobilePhoneNumber ===
        synchronizedPerson?.contactInformation?.mobilePhoneNumber &&
      person?.billingAddressInformation?.city ===
        synchronizedPerson?.billingAddressInformation?.city &&
      person?.familySituation?.childrenNb ==
        synchronizedPerson?.familySituation?.childrenNb
    );
  };

  return {
    hasCoborrower,
    sendEmprunteur,
    sendCoEmprunteur,
    responseEmprunteur,
    responseCoEmprunteur,
    refreshSynchroBorrowers,
    validateBorrower,
  };
};

export default useBorrowers;
