import * as messages from './messages';
import { Title } from '@components';
import { FormWrapper, TitleWrapper } from './styles';
import useInformationsForm from '../useInformationsForm';
import InputWithRightButtonPrice from './InputWithRightButtonPrice';
import { personTypes } from '@store';

interface IProps {
  role: string;
}

const FinancialSituation: React.FC<IProps> = ({ role }) => {
  const {
    onMonthlyRentChange,
    onMonthlyRentButtonCheckClick,
    isMonthlyRentButtonCheckDisabled,
    validateMonthlyRentAmount,
  } = useInformationsForm({ role });

  const suffixRole = '_' + role;

  return (
    <FormWrapper>
      <InputWithRightButtonPrice
        name={'monthlyIncome' + suffixRole}
        label={messages.LABEL_OTHER_INCOME_HOUSEHOLD}
        role={role}
      />
      {role === personTypes.RoleCd.BORROWER && (
        <>
          <TitleWrapper>
            <Title level={2}>{messages.HOUSEHOLD_RENT}</Title>
          </TitleWrapper>
          <InputWithRightButtonPrice
            name={'otherIncome' + suffixRole}
            label={messages.LABEL_OTHER_INCOME}
            role={role}
          />

          <TitleWrapper>
            <Title level={2}>{messages.LABEL_HOUSEHOLD_CHARGES}</Title>
          </TitleWrapper>
          <InputWithRightButtonPrice
            name={'monthlyRentAmount' + suffixRole}
            label={messages.LABEL_MONTHLY_RENT_AMOUNT}
            role={role}
            validate={(newValue) => validateMonthlyRentAmount(newValue)}
            onChange={onMonthlyRentChange}
            onButtonCheckClick={onMonthlyRentButtonCheckClick}
            isButtonCheckDisabled={isMonthlyRentButtonCheckDisabled}
          />
          <InputWithRightButtonPrice
            name={'otherOngoingLoans' + suffixRole}
            label={messages.LABEL_OTHER_CREDITS}
            role={role}
          />
          <InputWithRightButtonPrice
            name={'alimonyPaid' + suffixRole}
            label={messages.LABEL_ALIMONY_PAID}
            role={role}
          />
          <InputWithRightButtonPrice
            name={'otherCharges' + suffixRole}
            label={messages.LABEL_OTHER_CHARGES}
            role={role}
          />
        </>
      )}
    </FormWrapper>
  );
};

export default FinancialSituation;
