import styled from 'styled-components';
import { Button, Tooltip } from '@components';

export const StyledButtonQRCode = styled(Button)`
  border: none;
`;
export const StyledLabelButton = styled.h6`
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h5};
`;
export const WrapperButtonQRCode = styled.div`
  display: flex;
  flex-direction: row;
  width: 15%;
  justify-content: right;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  max-width: 60rem;
  gap: 0;
`;
export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const TooltipWrapperWithButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledTooltip = styled(Tooltip)`
  max-width: 60rem;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xl} 0;
`;

export const StyledTooltipInfo = styled(Tooltip)`
  text-align: left;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const StyledMessage = styled.div`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
`;

export const StyledTextTooltip = styled.span`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
`;

export const StyledTextBold = styled.span`
  font-weight: bold;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
`;

export const WrapperTooltipButtonQRCode = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const WrapperIconQRCode = styled.div`
  width: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
  height: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;
