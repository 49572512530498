import React from 'react';
import { Icons, ModalContent } from '@components';
import * as propertiesMessage from './messages';
import { StyledText, StyledWrapperText, WrapperPrompt } from './styles';

interface WrongIdOnEvidenceProps {
  onReupload: () => void;
  onAccepted: () => void;
  isMaxReuploadAttempt: boolean;
}

export const WrongIdOnEvidence = ({
  isMaxReuploadAttempt,
  onReupload = () => undefined,
  onAccepted = () => undefined,
}: WrongIdOnEvidenceProps): React.ReactElement => {
  const body = (
    <StyledWrapperText>
      <StyledText>
        {propertiesMessage.ERROR_WRONG_IDON_EVIDENCE_MESSAGE}
      </StyledText>
    </StyledWrapperText>
  );
  return (
    <WrapperPrompt>
      <ModalContent
        icon={<Icons.WarningIcon />}
        title={propertiesMessage.TITLE_MODAL_WRONG_IDON_EVIDENCE}
        text={body}
        primaryButtonLabel={propertiesMessage.TO_SEND_BUTTON}
        onPrimaryButtonClick={onAccepted}
        secondaryButtonLabel={propertiesMessage.PROOF_BUTTON}
        onSecondaryButtonClick={onReupload}
        isSecondaryButtonDisabled={isMaxReuploadAttempt}
      />
    </WrapperPrompt>
  );
};

export default WrongIdOnEvidence;
