const SvgTrash: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.321 7.8a.75.75 0 111.5 0v12c0 1.089-.922 1.95-2.035 1.95H6.214c-1.113 0-2.035-.861-2.035-1.95v-12a.75.75 0 011.5 0v12c0 .236.228.45.535.45h11.572c.307 0 .535-.214.535-.45v-12zm-7.071 2.7a.75.75 0 111.5 0v6.6a.75.75 0 11-1.5 0v-6.6zm-3.214 0a.75.75 0 111.5 0v6.6a.75.75 0 11-1.5 0v-6.6zm7.178-.75a.75.75 0 01.75.75v6.6a.75.75 0 11-1.5 0v-6.6a.75.75 0 01.75-.75zM3 6.75a.75.75 0 010-1.5h5.035V4c0-.966.784-1.75 1.75-1.75h4.43c.966 0 1.75.784 1.75 1.75l-.001 1.25H21a.75.75 0 110 1.5H3zm11.214-3H9.786a.25.25 0 00-.25.25l-.001 1.25h4.929V4a.25.25 0 00-.25-.25z"
    />
  </svg>
);

export default SvgTrash;
