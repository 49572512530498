import { Centering, Title } from '@components';
import { DetailResultSimulation as DetailResultSimulationContainer } from '@containers';
import { WrapperDetailResulsSimulation } from './styles';
import * as messages from './messages';

export const DetailResultSimulation: React.FC = () => {
  return (
    <WrapperDetailResulsSimulation>
      <Centering>
        <Title>{messages.LAYOUT_TITLE}</Title>
      </Centering>

      <DetailResultSimulationContainer />
    </WrapperDetailResulsSimulation>
  );
};

export default DetailResultSimulation;
