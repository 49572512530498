export const CEE_COUNTRIES = [
  'DE',
  'AT',
  'BE',
  'BG',
  'CY',
  'HR',
  'DK',
  'ES',
  'EE',
  'FI',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'CZ',
  'RO',
  'GB',
  'SK',
  'SI',
  'SE',
];

export const MONACO_COUNTRY_CODE = 'MC';
export const UK_COUNTRY_CODE = 'GB';
export const SWITZERLAND_COUNTRY_CODE = 'CH';
export const FRANCE_COUNTRY_CODE = 'FR';

export const ALLOWED_CNI_PASS_COUNTRIES = [
  MONACO_COUNTRY_CODE,
  UK_COUNTRY_CODE,
  SWITZERLAND_COUNTRY_CODE,
];

export const LOCATAIRE = 'LOCATAIRE';
export const PROPRIETAIRE_AVEC_PRET = 'PROPRIETAIRE_AVEC_PRET';
export const AUTRES = 'AUTRES';
export const LOGE_PAR_FAMILLE = 'LOGE_PAR_FAMILLE';
