import {
  IBankInformations,
  ICoEmprunteur,
  IEmprunteur,
} from 'store/souscriptionApi/souscriptionApiTypes';
import dayjs from 'dayjs';
import { personTypes } from '@store';

const DATE_FORMAT_FRONT = 'DD/MM/YYYY';
const DATE_FORMAT_BACK = 'YYYY-MM-DD';

export const getformattedCityName = (cityName = '') =>
  cityName.substring(0, 30);

export const buildEmprunteurRequestUtils = (
  submitData: any,
  addressCity: any
): IEmprunteur => {
  return {
    civilite: submitData?.inputCivility_TIT,
    prenom: submitData?.inputFirstName_TIT,
    nom: submitData?.inputLastName_TIT,
    nom_naissance: submitData?.inputBirthName_TIT,
    date_naissance: dayjs(
      submitData?.inputBirthDate_TIT?.toString().replace(/ /g, ''),
      DATE_FORMAT_FRONT
    ).format(DATE_FORMAT_BACK),
    pays_naissance: submitData?.inputBirthCountry_TIT,
    departement_naissance: submitData?.inputBirthDepartment_TIT || undefined,
    ville_naissance: getformattedCityName(
      submitData?.inputBirthCityLabel_TIT ?? ''
    ),
    code_insee_naissance: submitData?.inputBirthCityInsee_TIT || undefined,
    nationalite: submitData?.inputNationality_TIT,
    type_piece_identite: submitData?.evidenceSubTypeJDID_TIT,
    numero_piece_identite: submitData?.evidenceNb_TIT,
    adresse_postale: submitData?.inputAddressLineOne_TIT,
    complement_adresse: submitData?.inputAddressLineTwo_TIT || undefined,
    code_postal: submitData?.inputZipCd_TIT,
    ville: getformattedCityName(addressCity),
    email: submitData?.inputEmail_TIT,
    telephone_mobile:
      submitData?.inputMobilePhoneNumber_TIT?.toString().replace(/ /g, '') ||
      undefined,
    telephone_fixe:
      submitData?.inputFixPhoneNumber_TIT?.toString().replace(/ /g, '') ||
      undefined,
    situation_familiale: submitData?.inputMaritalStatus_TIT,
    nombre_enfants: submitData?.childrenNb_TIT,
    situation_logement: submitData?.housingCd_TIT,
    date_situation_logement: dayjs(
      '01/'.concat(
        submitData?.inputAccomodationDate_TIT?.toString().replace(/ /g, '')
      ),
      DATE_FORMAT_FRONT
    ).format(DATE_FORMAT_BACK),
    revenus_mensuels: submitData?.monthlyIncome_TIT,
    autres_revenus: submitData?.otherIncome_TIT,
    charges_residence_principale: submitData?.monthlyRentAmount_TIT,
    autres_credits: submitData?.otherOngoingLoans_TIT,
    pensions_alimentaires: submitData?.alimonyPaid_TIT,
    autres_charges: submitData?.otherCharges_TIT,
    situation_professionnelle: {
      secteur_activite_id: submitData?.inputEmployerActivitySector_TIT,
      profession_ou_situation_id: submitData?.inputProfessionOrSituation_TIT,
      nom_employeur: submitData?.inputEmployerName_TIT || undefined,
      code_postal_professionnel:
        submitData?.inputEmployerZipCode_TIT || undefined,
      nature_activite: submitData?.inputActivityType_TIT || undefined,
      numero_SIREN: submitData?.inputSIREN_TIT || undefined,
      nombre_employes: submitData?.inputEmployeesNumber_TIT || undefined,
      statut_occupation_locaux:
        submitData?.inputWorkplaceStatusCd_TIT || undefined,
      statut_professionnel:
        submitData?.inputProfessionnalStatusCd_TIT || undefined,
      date_debut: dayjs(
        '01/'.concat(
          submitData?.inputEmployerDate_TIT?.toString().replace(/ /g, '')
        ),
        DATE_FORMAT_FRONT
      ).format(DATE_FORMAT_BACK),
      type_contrat_id: submitData?.inputContractType_TIT,
    },
  };
};

export const buildCoEmprunteurRequestUtils = (
  submitData: any
): ICoEmprunteur => {
  return {
    civilite: submitData?.inputCivility_CTI,
    prenom: submitData?.inputFirstName_CTI,
    nom: submitData?.inputLastName_CTI,
    nom_naissance: submitData?.inputBirthName_CTI,
    date_naissance: dayjs(
      submitData?.inputBirthDate_CTI?.toString().replace(/ /g, ''),
      DATE_FORMAT_FRONT
    ).format(DATE_FORMAT_BACK),
    pays_naissance: submitData?.inputBirthCountry_CTI,
    departement_naissance: submitData?.inputBirthDepartment_CTI || undefined,
    ville_naissance: getformattedCityName(submitData?.inputBirthCityLabel_CTI),
    code_insee_naissance: submitData?.inputBirthCityInsee_CTI || undefined,
    nationalite: submitData?.inputNationality_CTI,
    type_piece_identite: submitData?.evidenceSubTypeJDID_CTI,
    numero_piece_identite: submitData?.evidenceNb_CTI,
    email: submitData?.inputEmail_CTI,
    telephone_mobile:
      submitData?.inputMobilePhoneNumber_CTI?.toString().replace(/ /g, '') ||
      undefined,
    revenus_mensuels: submitData?.monthlyIncome_CTI,
    situation_professionnelle: {
      nom_employeur: submitData?.inputEmployerName_CTI || undefined,
      code_postal_professionnel:
        submitData?.inputEmployerZipCode_CTI || undefined,
      nature_activite: submitData?.inputActivityType_CTI || undefined,
      numero_SIREN: submitData?.inputSIREN_CTI || undefined,
      nombre_employes: submitData?.inputEmployeesNumber_CTI || undefined,
      statut_occupation_locaux:
        submitData?.inputWorkplaceStatusCd_CTI || undefined,
      statut_professionnel:
        submitData?.inputProfessionnalStatusCd_CTI || undefined,
      secteur_activite_id: submitData?.inputEmployerActivitySector_CTI,
      profession_ou_situation_id: submitData?.inputProfessionOrSituation_CTI,
      date_debut: dayjs(
        '01/'.concat(
          submitData?.inputEmployerDate_CTI?.toString().replace(/ /g, '')
        ),
        DATE_FORMAT_FRONT
      ).format(DATE_FORMAT_BACK),
      type_contrat_id: submitData?.inputContractType_CTI,
    },
  };
};

export const buildEmprunteurStoreUtils = (
  request: any
): Partial<personTypes.IPerson> => {
  return {
    identity: {
      birthCity: request?.emprunteur?.ville_naissance,
      birthCityInseeCd: request?.emprunteur?.code_insee_naissance,
      birthCountry: request?.emprunteur?.pays_naissance,
      birthDepartmentCd: request?.emprunteur?.departement_naissance,
      birthDt: request?.emprunteur?.date_naissance,
      birthName: request?.emprunteur?.nom_naissance,
      firstName: request?.emprunteur?.prenom,
      lastName: request?.emprunteur?.nom,
      nationalityIsoCd: request?.emprunteur?.nationalite,
      titleCd: request?.emprunteur?.civilite,
      evidenceSubTypeCd: request?.emprunteur?.type_piece_identite,
      evidenceNb: request?.emprunteur?.numero_piece_identite,
    },
    billingAddressInformation: {
      additionalAddress: request?.emprunteur?.complement_adresse,
      city: request?.emprunteur?.ville,
      streetAddress: request?.emprunteur?.adresse_postale,
      zipCd: request?.emprunteur?.code_postal.toString(),
    },
    contactInformation: {
      mobilePhoneNumber: request?.emprunteur?.telephone_mobile?.toString(),
      fixPhoneNumber: request?.emprunteur?.telephone_fixe?.toString(),
      email: request?.emprunteur?.email,
    },
    professionalSituation: {
      employerName:
        request?.emprunteur?.situation_professionnelle?.nom_employeur,
      employerZipCode:
        request?.emprunteur?.situation_professionnelle?.code_postal_employeur,
      activitySector:
        request?.emprunteur?.situation_professionnelle?.secteur_activite_id,
      profession:
        request?.emprunteur?.situation_professionnelle
          ?.profession_ou_situation_id,
      beginningEmployerDate:
        request?.emprunteur?.situation_professionnelle?.date_debut,
      contractType:
        request?.emprunteur?.situation_professionnelle?.type_contrat_id,
      activityType:
        request?.emprunteur?.situation_professionnelle?.nature_activite,
      siren: request?.emprunteur?.situation_professionnelle?.numero_siren,
      employeesNumber:
        request?.emprunteur?.situation_professionnelle?.nombre_employes,
      workplaceStatusCd:
        request?.emprunteur?.situation_professionnelle
          ?.statut_occupation_locaux,
      professionnalStatusCd:
        request?.emprunteur?.situation_professionnelle?.statut_professionnel,
    },
    familySituation: {
      maritalStatus: request?.emprunteur?.situation_familiale,
      childrenNb: request?.emprunteur?.nombre_enfants,
    },
    housingSituation: {
      housingStatus: request?.emprunteur?.situation_logement,
      housingEntryDate: request?.emprunteur?.date_situation_logement,
    },
  };
};

export const buildCoEmprunteurStoreUtils = (
  request: any
): Partial<personTypes.IPerson> => {
  return {
    identity: {
      birthCity: request?.coemprunteur?.ville_naissance,
      birthCityInseeCd: request?.coemprunteur?.code_insee_naissance,
      birthCountry: request?.coemprunteur?.pays_naissance,
      birthDepartmentCd: request?.coemprunteur?.departement_naissance,
      birthDt: request?.coemprunteur?.date_naissance,
      birthName: request?.coemprunteur?.nom_naissance,
      firstName: request?.coemprunteur?.prenom,
      lastName: request?.coemprunteur?.nom,
      nationalityIsoCd: request?.coemprunteur?.nationalite,
      titleCd: request?.coemprunteur?.civilite,
      evidenceSubTypeCd: request?.coemprunteur?.type_piece_identite,
      evidenceNb: request?.coemprunteur?.numero_piece_identite,
    },
    contactInformation: {
      mobilePhoneNumber: request?.coemprunteur?.telephone_mobile?.toString(),
      email: request?.coemprunteur?.email,
    },
    professionalSituation: {
      employerName:
        request?.coemprunteur?.situation_professionnelle?.nom_employeur,
      employerZipCode:
        request?.coemprunteur?.situation_professionnelle?.code_postal_employeur,
      activitySector:
        request?.coemprunteur?.situation_professionnelle?.secteur_activite_id,
      profession:
        request?.coemprunteur?.situation_professionnelle
          ?.profession_ou_situation_id,
      beginningEmployerDate:
        request?.coemprunteur?.situation_professionnelle?.date_debut,
      contractType:
        request?.coemprunteur?.situation_professionnelle?.type_contrat_id,
      activityType:
        request?.coemprunteur?.situation_professionnelle?.nature_activite,
      siren: request?.coemprunteur?.situation_professionnelle?.numero_siren,
      employeesNumber:
        request?.coemprunteur?.situation_professionnelle?.nombre_employes,
      workplaceStatusCd:
        request?.coemprunteur?.situation_professionnelle
          ?.statut_occupation_locaux,
      professionnalStatusCd:
        request?.coemprunteur?.situation_professionnelle?.statut_professionnel,
    },
    familySituation: {
      maritalStatus: request?.emprunteur?.situation_familiale,
      childrenNb: request?.emprunteur?.nombre_enfants,
    },
    housingSituation: {
      housingStatus: request?.emprunteur?.situation_logement,
      housingEntryDate: request?.emprunteur?.date_situation_logement,
    },
  };
};
export const buildEmprunteurBackDataToStoreUtils = (
  request: any
): Partial<personTypes.IPerson> => {
  return {
    identity: {
      birthCity: request?.ville_naissance,
      birthCityInseeCd: request?.code_insee_naissance,
      birthCountry: request?.pays_naissance,
      birthDepartmentCd: request?.departement_naissance,
      birthDt: request?.date_naissance,
      birthName: request?.nom_naissance,
      firstName: request?.prenom,
      lastName: request?.nom,
      nationalityIsoCd: request?.nationalite,
      titleCd: request?.civilite,
      evidenceSubTypeCd: request?.type_piece_identite,
      evidenceNb: request?.numero_piece_identite,
    },
    billingAddressInformation: {
      additionalAddress: request?.complement_adresse,
      city: request?.ville,
      streetAddress: request?.adresse_postale,
      zipCd: request?.code_postal,
    },
    contactInformation: {
      mobilePhoneNumber: request?.telephone_mobile,
      fixPhoneNumber: request?.telephone_fixe,
      email: request?.email,
    },
    professionalSituation: {
      employerName: request?.situation_professionnelle?.nom_employeur,
      employerZipCode:
        request?.situation_professionnelle?.code_postal_employeur,
      activitySector: request?.situation_professionnelle?.secteur_activite_id,
      profession:
        request?.situation_professionnelle?.profession_ou_situation_id,
      beginningEmployerDate: request?.situation_professionnelle?.date_debut,
      contractType: request?.situation_professionnelle?.type_contrat_id,
      activityType: request?.situation_professionnelle?.nature_activite,
      siren: request?.situation_professionnelle?.numero_siren,
      employeesNumber: request?.situation_professionnelle?.nombre_employes,
      workplaceStatusCd:
        request?.situation_professionnelle?.statut_occupation_locaux,
      professionnalStatusCd:
        request?.situation_professionnelle?.statut_professionnel,
    },
    familySituation: {
      maritalStatus: request?.situation_familiale,
      childrenNb: request?.nombre_enfants,
    },
    housingSituation: {
      housingStatus: request?.situation_logement,
      housingEntryDate: request?.date_situation_logement,
    },
  };
};

export const buildFullBankInformationsRequestUtils = (
  submitData: any
): IBankInformations => {
  return {
    iban: submitData?.iban?.trim().replace(/ /g, ''),
    bic: submitData?.bic.trim(),
    date_ouverture_compte: dayjs(
      '01/'.concat(submitData?.accountOpeningDt?.toString().replace(/ /g, '')),
      DATE_FORMAT_FRONT
    ).format(DATE_FORMAT_BACK),
    proprietaire_compte: submitData?.inputAccountOwner,
  };
};

export const buildPartialBankInformationsRequestUtils = (
  submitData: any
): IBankInformations => {
  return {
    date_ouverture_compte: dayjs(
      '01/'.concat(submitData?.accountOpeningDt?.toString().replace(/ /g, '')),
      DATE_FORMAT_FRONT
    ).format(DATE_FORMAT_BACK),
  };
};
