import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './bankDetailsTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const bankDetailsSlice = createSlice({
  name: 'bankDetails',
  initialState,
  reducers: {
    setBankDetails(bankDetailsState, action: PayloadAction<IState>) {
      bankDetailsState.state = { ...action.payload };
    },
    updateBankDetails(
      bankDetailsState,
      action: PayloadAction<Partial<IState>>
    ) {
      bankDetailsState.state = {
        ...bankDetailsState?.state,
        ...action.payload,
      };
    },
    clear(bankDetailsState) {
      bankDetailsState.state = getInitialState().state;
    },
  },
});

export const selectBankDetails = (bankDetailsState: {
  bankDetails: { state: IState };
}) => bankDetailsState.bankDetails.state;

export const { setBankDetails, updateBankDetails, clear } =
  bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;
