const SvgEye: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M12 4.5c-2.943 0-5.534 1.43-7.751 3.794a18.224 18.224 0 00-2.403 3.223l-.241.432-.052.1a.5.5 0 000 .447l.18.334a18.224 18.224 0 002.516 3.42c2.217 2.366 4.808 3.795 7.751 3.795 2.943 0 5.534-1.43 7.751-3.794a18.224 18.224 0 002.403-3.223l.241-.432c.022-.04.04-.074.052-.1a.5.5 0 000-.447l-.18-.334a18.224 18.224 0 00-2.516-3.42C17.534 5.93 14.943 4.5 12 4.5zm0 1c2.625 0 4.977 1.297 7.022 3.478.723.772 1.355 1.601 1.89 2.43l.136.213c.087.139.167.272.241.4l.144.252-.037.067a17.235 17.235 0 01-2.374 3.227c-2.045 2.181-4.397 3.478-7.022 3.478s-4.977-1.297-7.022-3.478a17.235 17.235 0 01-1.89-2.43l-.136-.212a15.41 15.41 0 01-.241-.4l-.145-.252.038-.068a17.235 17.235 0 012.374-3.227C7.023 6.798 9.375 5.5 12 5.5z" />
      <path d="M12 9.045a3.227 3.227 0 100 6.455 3.227 3.227 0 000-6.455zm0 1a2.227 2.227 0 110 4.455 2.227 2.227 0 010-4.455z" />
    </g>
  </svg>
);

export default SvgEye;
