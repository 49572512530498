const SvgLogOut: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.23 3a.692.692 0 110 1.385H5.539c-.637 0-1.153.516-1.153 1.153v12.924c0 .637.516 1.153 1.153 1.153h3.693a.692.692 0 110 1.385H5.538A2.538 2.538 0 013 18.462V5.538A2.538 2.538 0 015.538 3h3.693zm6.952 3.895l4.615 4.615c.024.024.046.05.067.077l-.067-.077a.696.696 0 01.14.78.674.674 0 01-.074.123l-.007.01-.059.067-4.615 4.615a.692.692 0 01-.98-.98l3.434-3.433H9.231a.692.692 0 110-1.384l9.404-.001-3.432-3.433a.692.692 0 01.979-.979z"
    />
  </svg>
);

export default SvgLogOut;
