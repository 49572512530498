import {
  StyledEnlargeCard,
  StyledMaximizeIcon,
  StyledEnlargeText,
  StyledWrapper,
} from './styles';

interface IEnlargeFileProps {
  file: File;
  fileType: string;
}
const EnlargeFile = ({ file, fileType }: IEnlargeFileProps) => {
  const handleClick = () => {
    // We generate the blob using the file type
    const blob = new Blob(Array.of(file), { type: `${file.type};base64` });

    // We create the file URL from the Blob
    const pdfObjectUrl = URL.createObjectURL(blob);

    // We open the url in a new window
    window.open(pdfObjectUrl);
  };
  return (
    <StyledEnlargeCard onClick={handleClick}>
      <StyledWrapper>
        <StyledEnlargeText>Agrandir</StyledEnlargeText>
        <StyledMaximizeIcon />
      </StyledWrapper>
    </StyledEnlargeCard>
  );
};

export default EnlargeFile;
