const SvgCrosscircleglyph: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm3.284 6.716a.698.698 0 00-.987 0L12 11.013 9.703 8.716a.698.698 0 00-.9-.074l-.087.074a.698.698 0 000 .987L11.013 12l-2.297 2.297a.698.698 0 00-.074.9l.074.087a.698.698 0 00.987 0L12 12.987l2.297 2.297c.246.245.628.27.9.074l.087-.074a.698.698 0 000-.987L12.987 12l2.297-2.297a.698.698 0 00.074-.9z"
    />
  </svg>
);

export default SvgCrosscircleglyph;
