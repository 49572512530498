import { render } from '@testing-library/react';
import { ThemeProvider } from 'styled-components';
import { getTheme } from '../styles';
import 'sanitize.css/sanitize.css';
import { configureStore } from '@reduxjs/toolkit';
import { configuration, person } from '@store';
import { Provider, useDispatch } from 'react-redux';
import { ReactElement, JSXElementConstructor } from 'react';

console.log('ENV', process.env.NODE_ENV);
/*
jest.mock('@components', () => ({
  Icons: {
    FileIcon: () => <div />,
    Illustration2Icon: () => <div />,
    CheckCircleIcon: () => <div data-testid="checkCircleIconTestId" />,
    AdviceIcon: () => <div />,
    ChronoIcon: () => <div data-testid="chronoIconIconTestId" />,
    InfoOutlinesIcon: () => <div />,
    ChevronLeftIcon: () => <div />,
    EditIcon: () => <div />,
    CloudIcon: () => <div data-testid="cloudIconTestId" />,
    LogOutIcon: () => <div data-testid="LogoutIconTestId" />,
    ProfilIcon: () => <div />,
    ChevronDownIcon: () => <div />,
    UploadKoIcon: () => <div data-testid="uploadKoIconTestId" />,
    CloseIcon: () => <div data-testid="closeIconTestId" />,
    MaximizeIcon: () => <div />,
    PrintIcon: () => <div />,
    CrossCircleOutlinesIcon: () => (
      <div data-testid="crossCircleOutlinesIconTestId" />
    ),
    Illustration1Icon: () => <div />,
    TrashIcon: () => <div />,
    WarningIcon: () => <div data-testid="checkSvgWarningTestId" />,
    CrossCircleGlyphIcon: () => <div />,
    CrossCircle: () => <div />,
    ResponseOKIcon: () => <div data-testid="responseOkTestId" />,
    HomeIcon: () => <div />,
  },
}));
*/
let currentStore: any;
export const useAppDispatch = () => useDispatch<typeof currentStore.dispatch>();

const preloadedState = {};
export const customRender = (
  ui: ReactElement,
  {
    preloadedState: any = {},
    store = configureStore({
      reducer: { person, configuration },
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({}),
      preloadedState,
    }),
    ...renderOptions
  } = {}
) => {
  const AllTheProviders: React.FC<{ children: any }> = ({ children }) => (
    <Provider store={store}>
      <ThemeProvider theme={getTheme('conforama')}>{children}</ThemeProvider>
    </Provider>
  );
  currentStore = store;
  render(ui, { wrapper: AllTheProviders, ...renderOptions });
};

// re-export everything
export * from '@testing-library/react';

// override render method
export { customRender as render };
