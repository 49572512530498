import { StyledWrapper } from './styles';

interface IBlockWrapperProps {
  children?: React.ReactNode;
  id?: string;
  className?: string;
}

const Wrapper = ({
  children,
  id,
  className = '',
}: IBlockWrapperProps): React.ReactElement => (
  <StyledWrapper id={id} className={'ph-block ' + className}>
    {children}
  </StyledWrapper>
);

export default Wrapper;
