import {
  WrapperCheckBoxLabel,
  WrapperCheckBox,
  StyledCheckBox,
  StyledCheckmark,
  StyledCheckBoxLabel,
  StyledCheckIcon,
} from './styles';

export interface ICheckBoxProps {
  children?: React.ReactNode;
  id: string;
  name: string;
  value?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  className?: string;
}

const CheckBox = ({
  id,
  name,
  value,
  isChecked = false,
  isDisabled = false,
  children,
  onClick = () => null,
  className = 'ph-checkbox',
}: ICheckBoxProps): React.ReactElement => (
  <WrapperCheckBoxLabel
    isChecked={isChecked}
    isDisabled={isDisabled}
    className={className}
  >
    <WrapperCheckBox>
      <StyledCheckBox
        type="checkbox"
        id={id}
        name={name}
        value={value}
        defaultChecked={isChecked}
        disabled={isDisabled}
        onClick={onClick}
      />
      <StyledCheckmark isChecked={isChecked} isDisabled={isDisabled}>
        <StyledCheckIcon isVisible={isChecked} />
      </StyledCheckmark>
    </WrapperCheckBox>
    <StyledCheckBoxLabel isDisabled={isDisabled}>
      {children}
    </StyledCheckBoxLabel>
  </WrapperCheckBoxLabel>
);

export default CheckBox;
