import { Centering, Modal, Logo } from '@components';
import {
  WrapperSimulationHeader,
  StyledHeaderButton,
  StyledLogOutIcon,
  StyledArrowLeftCircleIcon,
  LogoWrapper,
  WrapperHeaderContent,
  WrapperHeaderButtonLeft,
  WrapperHeaderButtonRight,
  DisplayMobile,
  DisplayDesktop,
} from './styles';
import { useNavigate, useRedirection } from '@hooks';
import { useState } from 'react';
import * as messages from './messages';
import LeavingMobile from '../LeavingMobile';
import { Leaving } from '@containers';
import { useLocation } from 'react-router';

const SimulationHeader: React.FC<{
  vendorName: string;
}> = ({ vendorName = '' }) => {
  const { navigateBack } = useNavigate();
  const { pathname: locationPathname } = useLocation();
  const { logout } = useRedirection({});
  const { redirigerAccueil } = useRedirection();
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const [isDeconnectionOuAccueil, setIsDeconnectionOuAccueil] = useState('');

  const confirmDeconnection = () => {
    setIsDisplayModal(false);
    logout();
  };

  const confirmAccueil = () => {
    setIsDisplayModal(false);
    redirigerAccueil();
  };

  const onBackAction = () => {
    if (locationPathname === '/simulation') {
      setIsDisplayModal(true);
      setIsDeconnectionOuAccueil('accueil');
    } else {
      navigateBack();
    }
  };

  const onLogoutAction = () => {
    setIsDisplayModal(true);
    setIsDeconnectionOuAccueil('deconnection');
  };

  return (
    <WrapperSimulationHeader>
      <Centering>
        <WrapperHeaderContent>
          <WrapperHeaderButtonLeft>
            <StyledHeaderButton variant="link" onClick={onBackAction}>
              <StyledArrowLeftCircleIcon />
              <span>{messages.RETOUR}</span>
            </StyledHeaderButton>
          </WrapperHeaderButtonLeft>
          <LogoWrapper>{vendorName && <Logo name={vendorName} />}</LogoWrapper>
          <WrapperHeaderButtonRight>
            <StyledHeaderButton variant="link" onClick={onLogoutAction}>
              <StyledLogOutIcon />
              <span>{messages.DECONNECTER}</span>
            </StyledHeaderButton>
          </WrapperHeaderButtonRight>
          {isDisplayModal && (
            <>
              <Modal
                isVisible={true}
                isClosable={true}
                handleCloseModal={() => {
                  setIsDisplayModal(false);
                }}
              >
                <DisplayMobile>
                  <LeavingMobile
                    onClickCancel={() => {
                      setIsDisplayModal(false);
                    }}
                    onClickConfirm={() => {
                      if (isDeconnectionOuAccueil === 'deconnection') {
                        confirmDeconnection();
                      } else if (isDeconnectionOuAccueil === 'accueil') {
                        confirmAccueil();
                      }
                    }}
                  />
                </DisplayMobile>
                <DisplayDesktop>
                  <Leaving
                    onClickCancel={() => {
                      setIsDisplayModal(false);
                    }}
                    onClickConfirm={() => {
                      if (isDeconnectionOuAccueil === 'deconnection') {
                        confirmDeconnection();
                      } else if (isDeconnectionOuAccueil === 'accueil') {
                        confirmAccueil();
                      }
                    }}
                    text={messages.TEXT_DECONNECTION}
                  />
                </DisplayDesktop>
              </Modal>
            </>
          )}
        </WrapperHeaderContent>
      </Centering>
    </WrapperSimulationHeader>
  );
};

export default SimulationHeader;
