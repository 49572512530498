import { Icons } from '@components';
import {
  StyledUploadedFile,
  WrapperFileName,
  StyledFileName,
  WrapperOptions,
  StyledOptions,
  StyledIcons,
  StyledCheck,
} from './styles';

export interface IUploadFileProps {
  fileName: string;
  isCancellable?: boolean;
  isDeletable?: boolean;
  isLoaded?: boolean;
  isUploaded?: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
}

const UploadedFile = ({
  fileName,
  isCancellable = false,
  isDeletable = false,
  isLoaded = false,
  isUploaded,
  onCancel,
  onDelete,
}: IUploadFileProps): React.ReactElement => {
  return (
    <StyledUploadedFile>
      <WrapperFileName>
        <StyledFileName>{fileName}</StyledFileName>
      </WrapperFileName>
      <WrapperOptions>
        <StyledOptions>
          {isCancellable && (
            <StyledIcons onClick={onCancel}>
              <Icons.CloseIcon />
            </StyledIcons>
          )}
          {isDeletable && isUploaded && (
            <StyledIcons onClick={onDelete}>
              <Icons.TrashIcon />
            </StyledIcons>
          )}
          {isLoaded && isUploaded && (
            <StyledCheck>
              <Icons.CheckCircleIcon />
            </StyledCheck>
          )}
        </StyledOptions>
      </WrapperOptions>
    </StyledUploadedFile>
  );
};

export default UploadedFile;
