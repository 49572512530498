import { Button } from '@components';
import styled from 'styled-components';
import { Icons } from '@components';

export const WrapperSimulationHeader = styled.header`
  width: 100%;
  height: ${({ theme }) => theme.variables.layout.spacing.spacing_xxl};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  box-shadow: ${({ theme }) => theme.variables.colors.shadows[15]};

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    span {
      display: none;
    }
  }
`;

export const WrapperHeaderButtonLeft = styled.div`
  width: 33%;
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: auto;
  }
`;

export const WrapperHeaderButtonRight = styled.div`
  width: 33%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    width: auto;
  }
`;

export const WrapperHeaderContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

export const StyledHeaderButton = styled(Button)`
  font-weight: bold;
  color: ${({ theme }) => theme.vendor.colors.primary};
  font-size: 16px;
  min-width: 80px;

  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    min-width: auto;
  }
`;

export const StyledLogOutIcon = styled(Icons.LogOutIcon)`
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  height: 20px;
`;

export const StyledArrowLeftCircleIcon = styled(Icons.ArrowLeftCircleIcon)`
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  height: 20px;
`;

export const LogoWrapper = styled.div`
  height: ${({ theme }) => theme.variables.layout.spacing.spacing_xxl};

  img {
    width: 100%;
    height: 100%;
    padding-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
    padding-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;

export const DisplayMobile = styled.div`
  @media (min-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    display: none;
  }
`;

export const DisplayDesktop = styled.div`
  @media (max-width: ${({ theme }) => theme.variables.layout.breakpoints.m}) {
    display: none;
  }
`;
