const SvgUploadDocument: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 73 60" className={className}>
    <defs>
      <linearGradient
        id="UploadDocument_svg__a"
        x1="0%"
        x2="100%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="gray" stopOpacity={0.55} />
        <stop offset="54%" stopColor="gray" stopOpacity={0.12} />
        <stop offset="100%" stopColor="gray" stopOpacity={0.42} />
      </linearGradient>
      <linearGradient
        id="UploadDocument_svg__b"
        x1="42.527%"
        x2="57.311%"
        y1="99.166%"
        y2=".587%"
      >
        <stop offset="0%" stopColor="gray" stopOpacity={0.25} />
        <stop offset="54%" stopColor="gray" stopOpacity={0.12} />
        <stop offset="100%" stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="UploadDocument_svg__c"
        x1="50%"
        x2="50%"
        y1="100%"
        y2="0%"
      >
        <stop offset="0%" stopColor="gray" stopOpacity={0.25} />
        <stop offset="54%" stopColor="gray" stopOpacity={0.12} />
        <stop offset="100%" stopColor="gray" stopOpacity={0.42} />
      </linearGradient>
      <linearGradient
        id="UploadDocument_svg__d"
        x1="58.091%"
        x2="41.941%"
        y1="99.375%"
        y2=".63%"
      >
        <stop offset="0%" stopColor="gray" stopOpacity={0.25} />
        <stop offset="54%" stopColor="gray" stopOpacity={0.12} />
        <stop offset="100%" stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="UploadDocument_svg__e"
        x1="50%"
        x2="50%"
        y1="100%"
        y2=".317%"
      >
        <stop offset="0%" stopColor="gray" stopOpacity={0.25} />
        <stop offset="54%" stopColor="gray" stopOpacity={0.12} />
        <stop offset="100%" stopColor="gray" stopOpacity={0.5} />
      </linearGradient>
      <linearGradient
        id="UploadDocument_svg__f"
        x1="50%"
        x2="50%"
        y1="100%"
        y2="0%"
      >
        <stop offset="0%" stopColor="gray" stopOpacity={0.25} />
        <stop offset="54%" stopColor="gray" stopOpacity={0.12} />
        <stop offset="100%" stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
      <linearGradient
        id="UploadDocument_svg__g"
        x1="50.004%"
        x2="50.004%"
        y1="100%"
        y2="0%"
      >
        <stop offset="0%" stopColor="gray" stopOpacity={0.25} />
        <stop offset="54%" stopColor="gray" stopOpacity={0.12} />
        <stop offset="100%" stopColor="gray" stopOpacity={0.1} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        opacity={0.15}
        d="M22.796 13.985c2.443-2.21 6.278-2.66 9.768-2.484 10.963.553 20.859 5.92 29.419 11.99 3.099 2.197 6.15 4.568 8.163 7.592 4.095 6.16 2.88 14.696-2.813 19.77-1.938 1.728-4.286 3.051-6.697 4.225-4.287 2.087-8.911 3.761-13.777 4.238-3.47.34-6.98.063-10.432-.41-9.637-1.326-19.096-4.256-27.025-9.27-3.479-2.2-6.756-4.934-8.19-8.493-1.433-3.56-.516-8.065 3.035-10.171 1.469-.871 4.48-2.356 10.783-6.302 6.303-3.945 5.327-8.483 7.766-10.685z"
      />
      <path
        fill="url(#UploadDocument_svg__a)"
        fillRule="nonzero"
        d="M-2.597 7.197h38.575v27.918H-2.597z"
        opacity={0.5}
        transform="rotate(-81.42 31.25 4.236)"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M29.563 37.636l5.628-37.3 26.904 4.06-5.628 37.3z"
      />
      <path
        fill="#E0E0E0"
        fillRule="nonzero"
        d="M36.333 17.052l19.418 2.93-.178 1.177-19.418-2.93zM35.89 19.994l19.417 2.93-.178 1.177-19.417-2.93zM47.168 9.058l10.003 1.51-.178 1.176-10.003-1.51z"
      />
      <path
        fill="#B2B2B2"
        fillRule="nonzero"
        d="M47.607 12.134l8.238 1.243-.178 1.177-8.238-1.243z"
      />
      <path
        fill="#E0E0E0"
        fillRule="nonzero"
        d="M35.445 22.936l17.064 2.575-.177 1.177-17.065-2.575zM35 25.879l14.711 2.22-.177 1.176-14.71-2.22zM34.557 28.82l12.945 1.954-.177 1.177-12.946-1.953zM34.113 31.763l16.476 2.486-.178 1.177-16.476-2.486z"
      />
      <path
        fill="#6C63FF"
        fillRule="nonzero"
        d="M33.67 34.705l6.472.976-.178 1.177-6.472-.976z"
      />
      <path
        fill="url(#UploadDocument_svg__b)"
        fillRule="nonzero"
        d="M7.91 4.616a3.972 3.972 0 10-6.611 2.337v.018c.11.142.246.262.402.352l.008-.017c.16.185.896.555 1.786.686h.02c.564.086 1.19.079 1.772-.131l.017-.029c.146-.043.284-.11.409-.198l.012-.043A3.968 3.968 0 007.91 4.616z"
        opacity={0.5}
        transform="translate(37.362 6.06)"
      />
      <path
        fill="url(#UploadDocument_svg__c)"
        fillRule="nonzero"
        d="M2.93 2.041h27.998v38.685H2.93z"
        opacity={0.5}
        transform="rotate(-9.181 51.152 18.76)"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M1.254 10.107l26.85-4.402 6.103 37.226-26.85 4.401z"
      />
      <path
        fill="#E0E0E0"
        fillRule="nonzero"
        d="M7.48 25.66l19.38-3.176.192 1.175-19.379 3.177zM7.962 28.597l19.379-3.177.193 1.175-19.38 3.177zM15.337 14.725l9.983-1.637.193 1.175-9.984 1.637z"
      />
      <path
        fill="#B2B2B2"
        fillRule="nonzero"
        d="M16.699 17.517l8.221-1.348.193 1.175-8.222 1.348z"
      />
      <path
        fill="#E0E0E0"
        fillRule="nonzero"
        d="M8.444 31.534l17.03-2.792.192 1.174-17.03 2.792zM8.925 34.47l14.68-2.408.193 1.175-14.68 2.407zM9.406 37.406l12.92-2.118.192 1.175-12.92 2.117zM9.888 40.341l16.442-2.695.193 1.175-16.443 2.695z"
      />
      <path
        fill="#B2B2B2"
        fillRule="nonzero"
        d="M10.37 43.278l6.459-1.059.192 1.175-6.46 1.059z"
      />
      <path
        fill="url(#UploadDocument_svg__d)"
        fillRule="nonzero"
        d="M8.178 3.486a4.083 4.083 0 10-5.73 4.373l.006.018c.152.104.324.178.504.215v-.019c.215.132 1.053.26 1.965.108h.02c.579-.093 1.189-.3 1.691-.688 0 0 0-.012.007-.033.13-.088.244-.197.338-.322V7.09a4.079 4.079 0 001.199-3.605z"
        opacity={0.5}
        transform="translate(6.213 13.108)"
      />
      <path
        fill="url(#UploadDocument_svg__e)"
        fillRule="nonzero"
        d="M.044.033h27.919v38.575H.044z"
        opacity={0.5}
        transform="translate(20.73 11.628)"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M21.13 11.945h27.208v37.722H21.13z"
      />
      <g fillRule="nonzero" transform="translate(24.536 15.081)">
        <path fill="url(#UploadDocument_svg__f)" d="M0 0h21.129v12.001H0z" />
        <path fill="#FFF" d="M.226 1.747h20.622v9.973H.226z" />
        <path
          fill="url(#UploadDocument_svg__g)"
          d="M1.684 2.693h7.155v7.55H1.684z"
        />
        <path fill="#B2B2B2" d="M1.859 2.761H8.62v7.212H1.859z" opacity={0.8} />
        <path fill="#B2B2B2" d="M.226.226h20.622v1.521H.226z" opacity={0.2} />
        <path fill="#E0E0E0" d="M11.381 4.874h7.832v1h-7.832z" />
        <path fill="#F5F5F5" d="M11.381 7.015h7.832v1h-7.832z" />
        <path
          fill="#B2B2B2"
          d="M11.325 5.944h4.62v1h-4.62zM11.325 8.085h3.155v1h-3.155z"
          opacity={0.2}
        />
        <path fill="#B2B2B2" d="M15.044 8.085h3.155v1h-3.155z" />
        <path
          fill="#FFF"
          d="M5.626 7.786V7.53a1.615 1.615 0 10-.807 0v.256a1.57 1.57 0 00-1.16 1.51v.707h3.128v-.706a1.57 1.57 0 00-1.16-1.51z"
        />
        <circle cx={5.222} cy={5.613} r={1.716} fill="#FFF" />
      </g>
      <g fillRule="nonzero">
        <path
          fill="url(#UploadDocument_svg__f)"
          d="M0 0h21.129v12.001H0z"
          transform="matrix(-1 0 0 1 45.678 28.683)"
        />
        <path fill="#FFF" d="M45.452 30.43H24.83v9.973h20.622z" />
        <path
          fill="#B2B2B2"
          d="M45.452 28.909H24.83v1.52h20.622z"
          opacity={0.2}
        />
        <path fill="#E0E0E0" d="M34.332 33.546H26.5v1h7.832z" />
        <path fill="#F5F5F5" d="M34.332 35.73H26.5v1h7.832z" />
        <path
          fill="#B2B2B2"
          d="M31.09 34.603h-4.62v1h4.62zM29.681 36.787h-3.155v1h3.155z"
          opacity={0.2}
        />
        <path fill="#B2B2B2" d="M33.627 36.787h-3.155v1h3.155z" />
      </g>
      <path
        fill="currentColor"
        d="M53.443 43.756c-.6-2.957-3.172-5.185-6.3-5.185a6.424 6.424 0 00-5.7 3.47c-2.572.258-4.586 2.443-4.586 5.102 0 2.828 2.314 5.143 5.143 5.143h11.143A4.299 4.299 0 0057.429 48a4.234 4.234 0 00-3.986-4.244zm-4.929 1.77v3.331h-2.743v-3.332h-2.914l4.286-4.382 4.286 4.384-2.915-.002z"
      />
    </g>
  </svg>
);

export default SvgUploadDocument;
