import { ReactNode, useEffect, useRef } from 'react';
import { IPerson, MobileKeyboardType, RoleCd } from '@types';
import {
  StyledFormContainer,
  StyledInfoCardContainer,
  StyledInfoCard,
  StyledFormField,
} from './styles';
import * as propertiesMessage from './messages';
import { mobilePhoneRegExp, phoneMask } from '@utils';
import { Input } from '@components';
import { personTypes } from '@store';
import { useForm, useFormContext } from 'react-hook-form';
import { IEmailValidationState, useEmailControle } from '@hooks';

const PersonInformationsCard = ({
  onChangePhoneNumber,
  onChangeEmail,
  headerNode,
  person,
  role = personTypes.RoleCd.BORROWER,
  setEmpEmailValidationState = () => null,
  setCoEmpEmailValidationState = () => null,
}: {
  onChangePhoneNumber: (role: personTypes.RoleCd) => void;
  onChangeEmail: (role: personTypes.RoleCd) => void;
  headerNode?: ReactNode;
  person: IPerson;
  role?: personTypes.RoleCd;
  setEmpEmailValidationState?: React.Dispatch<
    React.SetStateAction<IEmailValidationState | null>
  >;
  setCoEmpEmailValidationState?: React.Dispatch<
    React.SetStateAction<IEmailValidationState | null>
  >;
}) => {
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const suffixRole = '_' + role;

  const phoneNumber = methods.watch('phoneNumber' + suffixRole);
  const email = methods.watch('email') + suffixRole;

  const emailInputRef = useRef<HTMLInputElement>(null);
  const { trigger } = useFormContext();

  const {
    checkEmailValideAndControleEmail,
    isEmailValideOrToBeCorrected,
    ModalEmailValidation,
    getIsEmailFieldPending,
    setIsEmailFieldPending,
    getValidationState,
    getIsToBeCorrected,
  } = useEmailControle({ emailInputRef, role });

  useEffect(() => {
    if (role === RoleCd.BORROWER) {
      setEmpEmailValidationState(getValidationState());
    } else {
      setCoEmpEmailValidationState(getValidationState());
    }
  }, [
    getValidationState()?.isLoading,
    getValidationState()?.isValid,
    getValidationState()?.isForeground,
  ]);

  useEffect(() => {
    methods.trigger('phoneNumber' + suffixRole);
  }, [phoneNumber]);

  useEffect(() => {
    methods.trigger('email' + suffixRole);
  }, [email]);

  const formatDisplayPhoneNumber = (phoneNumber = '') => {
    return phoneNumber.replaceAll(' ', '').replace(/([0-9]{2})/g, '$1 ');
  };

  return (
    <StyledInfoCardContainer>
      <StyledInfoCard headerNode={headerNode ?? null}>
        <StyledFormContainer withHeaderNode={!!headerNode}>
          <StyledFormField
            label={propertiesMessage.LASTNAME_FIELD_LABEL}
            name={'lastName' + suffixRole}
            defaultValue={person?.identity?.lastName}
            isReadOnly
          />
          <StyledFormField
            label={propertiesMessage.FIRSTNAME_FIELD_LABEL}
            name={'firstName' + suffixRole}
            defaultValue={person?.identity?.firstName}
            isReadOnly
          />
          <StyledFormField
            label={propertiesMessage.MOBILE_FIELD_LABEL}
            name={'phoneNumber' + suffixRole}
            defaultValue={formatDisplayPhoneNumber(
              person?.contactInformation?.mobilePhoneNumber
            )}
            validate={(newValue: string) =>
              mobilePhoneRegExp.test((newValue ?? '').replaceAll(' ', ''))
            }
            isReadOnly={false}
            render={(
              { onChange, value, ref, onBlur, name },
              { invalid, isTouched }
            ) => (
              <Input
                inputMode={MobileKeyboardType.TEL}
                name={name}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                onChange={(newValue) => {
                  onChange(formatDisplayPhoneNumber(newValue + ''));
                  onChangePhoneNumber(role);
                }}
                mask={phoneMask}
                error={
                  isTouched && invalid
                    ? propertiesMessage.ERROR_MOBILE_PHONE_NUMBER
                    : ''
                }
              />
            )}
          />
          <StyledFormField
            label={propertiesMessage.EMAIL_FIELD_LABEL}
            name={'email' + suffixRole}
            validate={isEmailValideOrToBeCorrected}
            isReadOnly={false}
            defaultValue={person?.contactInformation?.email}
            render={(
              { onChange, value, onBlur, name },
              { invalid, isTouched }
            ) => (
              <>
                <Input
                  inputMode={MobileKeyboardType.EMAIL}
                  name={name}
                  value={value}
                  isTouched={isTouched}
                  isValid={!invalid}
                  onBlur={() => {
                    onBlur();
                    checkEmailValideAndControleEmail({
                      mailToTest: value,
                    });
                    setIsEmailFieldPending(false);
                  }}
                  inputRef={emailInputRef}
                  onChange={(newValue) => {
                    onChange(newValue.toString());
                    onChangeEmail(role);
                  }}
                  maxLength={50}
                  error={
                    isTouched && invalid
                      ? getIsToBeCorrected()
                        ? propertiesMessage.ERROR_CONTROLE_EMAIL
                        : propertiesMessage.ERROR_EMAIL
                      : ''
                  }
                  onFocus={() => {
                    setIsEmailFieldPending(true);
                    trigger(name);
                  }}
                />
                <ModalEmailValidation />
              </>
            )}
          />
        </StyledFormContainer>
      </StyledInfoCard>
    </StyledInfoCardContainer>
  );
};

export default PersonInformationsCard;
