const SvgMessage1: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.788 19.212l3.948-1.316a.692.692 0 01.53.039c.99.5 2.085.76 3.196.756a7.154 7.154 0 006.397-3.958c.5-.99.76-2.084.756-3.193l.002-.425c-.2-3.632-3.1-6.531-6.693-6.73h-.464a7.046 7.046 0 00-3.196.757A7.154 7.154 0 005.31 11.54a7.043 7.043 0 00.756 3.193.692.692 0 01.039.531l-1.316 3.948zm15.308-3.857a8.537 8.537 0 01-7.632 4.72 8.427 8.427 0 01-3.557-.777l-4.995 1.665a.692.692 0 01-.875-.875l1.665-4.995a8.425 8.425 0 01-.778-3.555 8.537 8.537 0 014.719-7.633A8.426 8.426 0 0112.463 3l.499.001c4.336.24 7.798 3.701 8.038 8.075v.461a8.424 8.424 0 01-.904 3.818z"
    />
  </svg>
);

export default SvgMessage1;
