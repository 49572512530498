import styled from 'styled-components';
import { Icons } from '@components';

// TODO add 0.6rem in spacing theme
const BadgeWrapper = styled.div<{ isSelected: boolean; isActive: boolean }>`
  color: ${({ theme, isActive }) =>
    isActive
      ? theme.variables.colors.chartColors.grey_500
      : theme.variables.colors.chartColors.grey_200};
  background-color: ${({ theme, isSelected, isActive }) =>
    isSelected || !isActive
      ? theme.variables.colors.chartColors.white
      : theme.vendor.colors.lightest};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  border-radius: ${({ theme }) =>
    `${theme.variables.layout.borderRadius.radius_s} ${theme.variables.layout.borderRadius.radius_s} ${theme.variables.layout.borderRadius.radius_s} ${theme.variables.layout.borderRadius.radius_s}`};
  padding: ${({ theme }) =>
    `0.6rem ${theme.variables.layout.spacing.spacing_xs} 0.6rem ${theme.variables.layout.spacing.spacing_xs}`};
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.vendor.colors.lightest};
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

const StyledCheckCircle = styled(Icons.CheckCircleIcon)`
  color: ${({ theme }) => theme.vendor.colors.primary};
  height: 1.5rem;
  margin-right: 0.95rem;
`;

const StyledCrossCircleOutlines = styled(Icons.CrossCircleOutlinesIcon)`
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_100};
  height: 1.5rem;
  margin-right: 0.95rem;
`;

export default { BadgeWrapper, StyledCheckCircle, StyledCrossCircleOutlines };
