import FooterWrapper from './styles';
import * as messages from './messages';
import { useRedirection } from '@hooks';
import { Centering } from '@components';

interface IFooterProps {
  returnUrl?: string;
}

const Footer: React.FC<IFooterProps> = () => {
  const { getUrlMentionsLegales } = useRedirection();

  return (
    <Centering>
      <FooterWrapper>
        <p className="links">
          <a target="_blank" href={getUrlMentionsLegales()} rel="noreferrer">
            {messages.LINK_LEGAL_MENTIONS_LABEL}
          </a>
        </p>
      </FooterWrapper>
    </Centering>
  );
};

export default Footer;
