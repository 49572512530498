import styled from 'styled-components';
import { lighten } from 'polished';

export const WrapperDropdown = styled.div`
  position: relative;
`;

export const AuthenHeadUserIcon = styled.div`
  display: flex;
  align-items: center;
`;
export const WrapperAuthenHeadUserIcon = styled.div`
  width: 4rem;
  height: 4rem;
  color: ${({ theme }) => lighten(0.3, theme.vendor.colors['primary'])};
`;

export const AuthenHeadInfosUser = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0.9rem 0.5rem 0.4rem;
  flex-grow: 1;
  align-items: center;
`;
export const WrapperInfosUser = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AuthenHeadInfosUserName = styled.span`
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
`;
export const AuthenHeadInfosUserNumber = styled.span`
  display: inline-flex;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;

export const AuthenHeadMenuIcon = styled.div`
  display: flex;
  align-items: center;
`;
export const WrapperChevronDownIcon = styled.div`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  width: 1.4rem;
`;

interface IStyleProps {
  isOpen: boolean;
}

// padding to extend the hitbox
export const HitboxForDropdown = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

// to be evolved if the height of the dropdown changes
const dropDownHeight = 9.6;

// margin-right to compensate the padding-right of the hitbox
export const Dropdown = styled.div<IStyleProps>`
  position: absolute;
  z-index: 4;
  top: calc(100% + ${({ theme }) => theme.variables.layout.spacing.spacing_xs});
  right: 0;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_s};
  box-shadow: ${({ theme }) => theme.variables.colors.shadows[15]};
  background: ${({ theme }) => theme.variables.colors.chartColors.white};
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  padding: ${({ isOpen, theme }) =>
      isOpen ? theme.variables.layout.spacing.spacing_xs : '0'}
    0;
  max-height: ${({ isOpen }) => (isOpen ? `${dropDownHeight}rem` : '0')};
  transition: max-height 0.2s;
  overflow: hidden !important;
`;

export const AuthenError = styled.div``;
export const AuthenHeadInfosUserOgarnizationId = styled.div``;

export const DropdownItemStyled = styled.li`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xs}
    ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const DropdownList = styled.ul`
  list-style: none;
  padding-left: 0rem;
  margin: 0rem;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const DropdownLabel = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  letter-spacing: 0;
  line-height: 20px;
`;

export const DropdownIconStyled = styled.div`
  display: flex;
  width: 2.4rem;
  min-width: 2.4rem;
  height: 2.4rem;
  min-height: 2.4rem;
  align-self: center;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;
