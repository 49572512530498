const SvgPhoto: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.727 4l1.818 2.727h3.637c1.004 0 1.818.814 1.818 1.818v10a1.818 1.818 0 01-1.818 1.819H3.818A1.818 1.818 0 012 18.545v-10c0-1.004.814-1.818 1.818-1.818h3.637L9.273 4h5.454zm-.535 1H9.808L8.287 7.282a1 1 0 01-.832.445H3.818A.818.818 0 003 8.545v10c0 .452.366.819.818.819h16.364a.818.818 0 00.818-.819v-10a.818.818 0 00-.818-.818h-3.637a1 1 0 01-.832-.445L14.193 5zM12 8.955a4.136 4.136 0 110 8.272 4.136 4.136 0 010-8.272zm0 1a3.136 3.136 0 100 6.272 3.136 3.136 0 000-6.272z"
    />
  </svg>
);

export default SvgPhoto;
