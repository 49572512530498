export const PATH_SIMULATION = '/simulation';

export const getURLParams = (url = window.location?.hash ?? '') => {
  let hashUrl = url;
  if (hashUrl) {
    hashUrl = hashUrl.replace(/^http[^#]+(#\/.*)$/, '$1');
  }
  return new URLSearchParams('?' + ((hashUrl ?? '').split('?')[1] ?? ''));
};
export const getUrlParamsAsString = (url = window.location?.hash ?? '') =>
  url.split('?')[1] ?? '';

export const isParcourSimulation = (location: string): boolean => {
  return location.startsWith(PATH_SIMULATION);
};
