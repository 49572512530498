import { IFile, IImagesFile, ImageStatus, EvidenceFace } from '@types';

export const containsRecto = (files: IFile[]): boolean => {
  return files.some((file) => file.type === EvidenceFace.RECTO);
};

export const containsDeletedImage = (images: IImagesFile[]): number => {
  return images.findIndex((image) => {
    return image.status === ImageStatus.DELETED;
  });
};

export const addImage = (
  images: IImagesFile[],
  newImage: IImagesFile
): IImagesFile[] => {
  const newImages = images.slice(0);
  const firstDeletedImage = containsDeletedImage(images);
  if (firstDeletedImage >= 0) {
    newImages[firstDeletedImage] = newImage;
    return newImages;
  }
  newImages.push(newImage);
  return newImages;
};

export const findCorrespondingImage = (
  file: IFile,
  processedImages: IImagesFile[] | undefined
): IImagesFile | undefined => {
  return processedImages?.find((image) => image.id === file.id);
};
