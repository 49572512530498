const SvgChevronDown: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.963 15.768l8.809-9.058a.684.684 0 01.986 0 .732.732 0 010 1.015l-9.302 9.565a.684.684 0 01-.987 0L2.167 7.725a.732.732 0 010-1.015.684.684 0 01.987 0l8.809 9.058z"
    />
  </svg>
);

export default SvgChevronDown;
