import { Centering, Title } from '@components';
import WrapperSimulation from './styles';
import * as messages from './messages';
import { CreateSimulation as Simulation } from '@containers';

export const CreateSimulation: React.FC = () => {
  /**
   * Create Simulation page
   */
  return (
    <WrapperSimulation>
      <Centering>
        <Title>{messages.LAYOUT_TITLE}</Title>
      </Centering>

      <Simulation />
    </WrapperSimulation>
  );
};

export default CreateSimulation;
