import Styled, { css } from 'styled-components';
import { BorderColorType } from '@types';
import { MAX_WIDTH_SIZE } from '@utils';

export const StyledWrapper = Styled.div<{
  hasBorder: boolean;
  isDisabled: boolean;
  borderColor?: BorderColorType;
}>`
  box-shadow: ${({ theme }) => theme.variables.colors.shadows[15]};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_l};
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  background-color: ${({ theme }) => theme.variables.colors.chartColors.white};
  border-left-width: ${({ theme }) =>
    theme.variables.layout.spacing.spacing_xs};
  border-right: ${({ theme }) =>
    theme.variables.layout.spacing.spacing_xs} solid transparent;
  border-left-style: solid;
  border-left-color: ${({ theme, hasBorder, isDisabled, borderColor }) => {
    if (hasBorder && !isDisabled) {
      if (borderColor === 'grey') {
        return theme.variables.colors.chartColors.grey_500;
      }
      return theme.variables.colors.chartColors.green_300;
    }
    if (hasBorder && isDisabled) {
      return theme.variables.colors.chartColors.grey_100;
    }
    return 'transparent';
  }};
  color: ${({ theme, isDisabled }) =>
    isDisabled && theme.variables.colors.chartColors.grey_200};
  transition: color 0.2s, border-left-color 0.2s;

  * {
    color: ${({ theme, isDisabled }) =>
      isDisabled && theme.variables.colors.chartColors.grey_200} !important;
  }
  
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const StyledHeader = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledBody = Styled.div<{
  isCollapsed: boolean;
}>`
  display: flex;
  padding-top: ${({ isCollapsed, theme }) =>
    isCollapsed ? theme.variables.layout.spacing.spacing_s : '0'};
  margin-top: ${({ isCollapsed, theme }) =>
    isCollapsed ? theme.variables.layout.spacing.spacing_s : '0'};
  height: ${({ isCollapsed }) => (isCollapsed ? 'auto' : '0')};
  overflow: ${({ isCollapsed }) => (isCollapsed ? 'visible' : 'hidden')};
  transition: padding 0.2s, border-color 0.1s, margin 0.2s;
  flex-direction: column;
  border-top: solid 0.1rem ${({ isCollapsed, theme }) =>
    isCollapsed ? theme.variables.colors.chartColors.grey_50 : 'transparent'};

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;

export const StyledRightIconContainer = Styled.div`
  width: 2.4rem;
  height: 2.4rem;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
    margin-left: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;

export const StyledHeaderWrapper = Styled.div<{
  isClickable: boolean;
}>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
