import { MAX_WIDTH_SIZE } from '@utils';

const useDetectMobileDevice = () => {
  const isMobileScreenSize = () => screen?.width <= parseInt(MAX_WIDTH_SIZE);

  return {
    isMobileScreenSize,
  };
};

export default useDetectMobileDevice;
