import { Button, Icons } from '@components';
import { MIN_WIDTH_SIZE, MAX_WIDTH_SIZE } from '@utils';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: calc(
    ${() => MIN_WIDTH_SIZE + 'px'} -
      (${({ theme }) => theme.variables.layout.spacing.spacing_xl} * 2)
  );

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    text-align: justify;
    text-justify: inter-word;
    max-height: calc(100vh - 2.8rem);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
`;

export const Column = styled.div`
  width: 100%;
`;

export const WrapperGlobalTitle = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    justify-content: left;
  }
`;

export const StyledFileIcon = styled(Icons.FileIcon)`
  height: 2.4rem;
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    display: none;
  }
`;

export const StyledTitle = styled.h1`
  margin: 0;
  display: flex;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.titleModalDocument};
  text-transform: uppercase;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
  }
`;

export const WrapperSuccessScan = styled.div`
  display: flex;
  height: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
  background: ${({ theme }) => theme.variables.colors.chartColors.green_700};
  justify-content: center;
`;
export const WrapperIncompleteScan = styled(WrapperSuccessScan)`
  background: ${({ theme }) => theme.variables.colors.chartColors.orange_10};
`;

export const IconContainer = styled(Icons.Illustration2Icon)`
  width: 14.7rem;
  align-self: center;
  margin-right: 1.2rem;
  color: ${({ theme }) => theme.vendor.colors.primary};

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    display: none;
  }
`;

export const StyledText = styled.p`
  margin: 0;
  display: flex;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
`;

export const WrapperSubTitle = styled.div`
  display: flex;
  justify-content: left;
  border-bottom: 0.1rem solid #b2b2b2;
  margin-bottom: 2.6rem;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const WrapperText = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-top: 2.4rem;
  }
`;

export const StyledCheckCircleIcon = styled(Icons.CheckCircleIcon)`
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-right: 1rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.white};
`;
export const StyledAdviceIcon = styled(Icons.AdviceIcon)`
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-right: 1rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const StyledWarningIcon = styled(Icons.WarningIcon)`
  width: 2rem;
  height: 2rem;
  align-self: center;
  margin-right: 1rem;
  color: ${({ theme }) => theme.variables.colors.interaction.warning_700};
`;

export const StyledSubTitle = styled.p`
  margin: 0.6rem 0rem 0.6rem 0rem;
  display: flex;
  color: ${({ theme }) => theme.variables.colors.chartColors.white};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
`;
export const StyledSubTitleIncomplete = styled(StyledSubTitle)`
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_m}
    ${({ theme }) => theme.variables.layout.spacing.spacing_xl};

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin: 0;
  }
`;

export const WrapperBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const WrapperFieldValue = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  min-width: 70%;
  align-items: start;
`;

export const WrapperDefaultValue = styled.div`
  display: block;
`;

export const StyledLabel = styled.span<{
  isErrorMsgVisible: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: ${({ isErrorMsgVisible }) =>
    isErrorMsgVisible ? 'start' : 'center'};
  margin-top: ${({ isErrorMsgVisible }) =>
    isErrorMsgVisible ? '0.9rem' : '0'};
`;

export const WrapperBodyOCR = styled.div`
  display: block;
  width: 100%;
  min-height: 4rem;
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
  font-weight: bold;
  align-items: center;
`;

export const WrapperIcon = styled.div``;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 2.4rem;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const StyledProofButton = styled(Button)`
  margin-right: 1.6rem;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-right: 0;
    &:not(last-child) {
      margin-bottom: 0.8rem;
    }
  }
`;

export const EditButton = styled.button`
  width: 100%;
  color: ${({ theme }) => theme.vendor.colors.primary};
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  width: 2.2rem;
  padding: 0;
  margin-left: 8px;
`;

export const OcrValue = styled.span<{
  isInEditMode: boolean;
}>`
  display: ${({ isInEditMode }) => (isInEditMode ? 'none' : 'block')};
`;

export const OcrField = styled.div<{
  isInEditMode: boolean;
}>`
  display: ${({ isInEditMode }) => (isInEditMode ? 'block' : 'none')};
  width: 100%;
`;

export const WrapperIncompleteScanRIBA = styled.div`
  height: 7.2rem;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.variables.colors.chartColors.orange_10};
`;

export const StyledWarningIconRIBA = styled(Icons.WarningIcon)`
  width: 2rem;
  height: 2rem;
  align-self: top;
  margin: 0.7rem 1rem 0rem 0rem;
  color: ${({ theme }) => theme.variables.colors.interaction.warning_700};
`;

export const StyledSubTitleIncompleteRIBA = styled(StyledSubTitle)`
  width: 56.8rem;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  line-height: 20px;
`;

export const WrapperScan = styled.div<{
  background: string;
}>`
  display: flex;
  height: auto;
  background: ${({ theme, background }) =>
    theme.variables.colors.chartColors[background]};
  justify-content: center;
`;

export const StyledGeneralSubTitle = styled.p<{
  color: string;
}>`
  margin: 0.6rem 0rem 0.6rem 0rem;
  display: flex;
  max-width: 56.8rem;
  line-height: 20px;
  color: ${({ theme, color }) => theme.variables.colors.chartColors[color]};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h4};
`;
