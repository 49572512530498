export interface IDescriptionList {
  title: string;
  subTitle?: string;
  description?: string;
  fieldName: string;
  isLineBold?: boolean;
  isVisible?: boolean;
  isSubtitleStyled?: boolean;
  isValueBold?: boolean;
  isLoading?: boolean;
}

export enum DescriptionListVariant {
  FILLED_BY_DOTS = 'filledByDots',
  SPLIT_BY_LINES = 'splitByLines',
}

export interface IDescriptionListProps {
  list: IDescriptionList[];
  className?: string;
  variant?: DescriptionListVariant;
}
