import { Icons, Modal } from '@components';
import * as messages from './messages';
import { TypeDeContrat, ScoreLightType, TypeVente } from '@types';
import {
  StyledButtonHome,
  StyledCardWrapper,
  StyledCheckCircle2Icon,
  StyledCloseIcon,
  StyledSearchIcon,
  StyledText,
  StyledTextBold,
  StyledTextTooltip,
  StyledTitle,
  StyledTitleTooltip,
  StyledTooltip,
  ShowDecisionWrapper,
  StyledSignWrapper,
  StyledButtonFooter,
} from './styles';
import { Leaving } from '../../ModalContents';
import { useEffect, useState } from 'react';
import {
  selectConfiguration,
  selectPerson,
  updateConfiguration,
  useAppDispatch,
  useAppSelector,
  useSendContratPapierMutation,
} from '@store';
import { openPdfFile } from '@utils';
import { useNavigate, useRedirection } from '@hooks';

export interface ShowDecisionProps {
  scoreLight?: ScoreLightType;
}

const ShowDecision: React.FC<ShowDecisionProps> = ({ scoreLight }) => {
  const { souscriptionId, typeVente } = useAppSelector(selectConfiguration);
  const { emprunteur } = useAppSelector(selectPerson);
  const [isModalReturnHomeActive, setIsModalReturnHomeActive] = useState(false);

  const [isVAD] = useState(typeVente === TypeVente.VENTE_A_DISTANCE);

  const { redirigerAccueil } = useRedirection();

  const [sendContrat, responseContrat] = useSendContratPapierMutation();

  const dispatch = useAppDispatch();

  const actionOpenReturnHome = () => {
    setIsModalReturnHomeActive(true);
  };

  const actionCloseReturnHome = () => {
    setIsModalReturnHomeActive(false);
  };

  useEffect(() => {
    if (!responseContrat?.data?.contenu) {
      return;
    }
    openPdfFile(responseContrat.data.contenu);
  }, [responseContrat?.data?.contenu]);

  const onClickSign = (type: TypeDeContrat) => {
    dispatch(updateConfiguration({ typeContrat: type }));
    sendContrat({ numero_souscription: souscriptionId ?? '' });
  };

  return (
    <ShowDecisionWrapper>
      {scoreLight && (
        <StyledCardWrapper>
          {ScoreLightType.VERT === scoreLight && (
            <>
              <StyledCheckCircle2Icon />
              <StyledTitle>{messages.TITLE_GREEN}</StyledTitle>
              <StyledText>{messages.TEXT_GREEN}</StyledText>
            </>
          )}
          {ScoreLightType.ORANGE === scoreLight && (
            <>
              <StyledSearchIcon />
              <StyledTitle>{messages.TITLE_ORANGE}</StyledTitle>
              <StyledText>{messages.TEXT_ORANGE}</StyledText>
            </>
          )}
          {ScoreLightType.ROUGE === scoreLight && (
            <>
              <StyledCloseIcon />
              <StyledTitle>{messages.TITLE_RED}</StyledTitle>
              <StyledText>{messages.TEXT_RED}</StyledText>
            </>
          )}
          <StyledTextBold>{messages.TEXT_PAPER}</StyledTextBold>
          <StyledSignWrapper>
            {emprunteur?.contactInformation?.mobilePhoneNumber && (
              <StyledButtonFooter
                nextLabel={
                  isVAD
                    ? messages.BUTTON_ELECTRONIC_VAD
                    : messages.BUTTON_ELECTRONIC
                }
                isBackVisible={false}
                isNextDisabled={responseContrat?.isLoading}
              />
            )}
            <StyledButtonFooter
              nextStep="/signature"
              nextVariant="secondary"
              nextLabel={
                isVAD ? messages.BUTTON_PAPER_VAD : messages.BUTTON_PAPER
              }
              isBackVisible={false}
              onClickNext={() => onClickSign(TypeDeContrat.PAPIER)}
              requestsToWait={[responseContrat]}
            />
          </StyledSignWrapper>
          {ScoreLightType.ROUGE === scoreLight && (
            <>
              <Modal
                isVisible={isModalReturnHomeActive}
                isClosable
                handleCloseModal={() => setIsModalReturnHomeActive(false)}
              >
                <Leaving
                  onClickCancel={actionCloseReturnHome}
                  onClickConfirm={redirigerAccueil}
                />
              </Modal>
              <StyledButtonHome variant="link" onClick={actionOpenReturnHome}>
                {messages.BUTTON_LINK_HOME}
              </StyledButtonHome>
            </>
          )}
        </StyledCardWrapper>
      )}
      <StyledTooltip
        skin="warning"
        isVisible
        hasArrowDown={false}
        icon={Icons.WarningIcon}
      >
        {
          <>
            <strong>{messages.TOOLTIP_FILE_SUBMITTED_STRONG}</strong>
            {messages.TOOLTIP_FILE_SUBMITTED}
          </>
        }
      </StyledTooltip>
      <StyledTooltip
        skin="advice"
        hasArrowDown={false}
        icon={Icons.InfoOutlinesIcon}
        isVisible={true}
      >
        <StyledTitleTooltip level={2}>
          {messages.TITLE_TOOLTIP}
        </StyledTitleTooltip>
        <StyledTextTooltip>{messages.TEXT_TOOLTIP}</StyledTextTooltip>
      </StyledTooltip>
    </ShowDecisionWrapper>
  );
};

export default ShowDecision;
