import {
  selectConfiguration,
  selectOffer,
  useAppSelector,
  IBlockAttendu,
  IDocumentsAttendus,
  selectDocumentsSynchronization,
} from '@store';
import {
  IBlock,
  EIsExpected,
  IOcr,
  IBlockState,
  IPieceJustificative,
} from '@types';
import { useBorrowers } from '@hooks';
import { useEffect, useState } from 'react';

export enum ETypeEmprunteur {
  emprunteur_principal = 'EMPRUNTEUR_PRINCIPAL',
  co_emprunteur = 'CO_EMPRUNTEUR',
  no_emprunteur_type = 'NO_EMPRUNTEUR_TYPE',
}
export enum ETypeEmprunteurUploaded {
  BORROWER = 'TIT',
  COBORROWER = 'CTI',
}
export enum EEvidenceBlocksTypeNames {
  BLOCK_COMMANDE = 'blocksCommande',
  BLOCK_COBORROWER = 'blocksCoborrower',
  BLOCK_BORROWER = 'blocksBorrower',
  BLOCK_BANQUE = 'blocksBanque',
}

const useBlocksState = () => {
  const { hasCoborrower } = useBorrowers();
  const offer = useAppSelector(selectOffer);
  const ocrParameters =
    useAppSelector(selectConfiguration).parametres_ocr?.parametres;

  const { synchronizedFiles } = useAppSelector(selectDocumentsSynchronization);
  const blocks = ocrParameters?.blocks ?? {};
  const [isAllPiecesJustificativesValidated, setIsAllPiecesJointesValidated] =
    useState(false);

  const getBlockModel = ({
    block,
    blockName,
    ocrParameters,
  }: {
    block: any;
    blockName: string;
    ocrParameters: IOcr;
  }): IBlockState => {
    let isBlockVisible = false;
    // synchronisation des pièces justificatives avec le service uploaded
    if (synchronizedFiles && Object.keys(synchronizedFiles)?.length > 0) {
      const isBlockComandeAttendue =
        synchronizedFiles?.bon_de_commande?.attendu;
      isBlockVisible = !(
        (blockName === EEvidenceBlocksTypeNames.BLOCK_COBORROWER &&
          !hasCoborrower()) ||
        (isBlockComandeAttendue === EIsExpected.NON_ATTENDU &&
          blockName === EEvidenceBlocksTypeNames.BLOCK_COMMANDE)
      );
      // si pas de synchro et on connaît l'offre, le front est capable de déduire
    } else if (offer?.inputLoanAmount) {
      isBlockVisible = !(
        (blockName === EEvidenceBlocksTypeNames.BLOCK_COBORROWER &&
          !hasCoborrower()) ||
        (offer?.inputLoanAmount <= ocrParameters?.overdraftThresholdBDC &&
          blockName === EEvidenceBlocksTypeNames.BLOCK_COMMANDE)
      );
    }

    const blockState: IBlockState = {
      name: blockName,
      title: block?.title,
      isVisible: isBlockVisible,
      pieceJustificatives: [],
    };
    if (block?.description) {
      blockState.description = block.description;
    }
    return blockState;
  };

  const getPieceJustificativeModel = ({
    block,
    pieceJustificativeName,
    blockState,
    ocrParameters,
  }: {
    block: IBlock;
    pieceJustificativeName: string;
    blockState: IBlockState;
    ocrParameters: IOcr;
  }): IPieceJustificative => {
    const pieceJustificative = block?.evidenceBlocks[pieceJustificativeName];
    let isVisible = false;
    let isValidated = false;

    // Si on a déjà le résultat de synchro
    if (synchronizedFiles) {
      const documentsUploaded: IDocumentsAttendus | {} =
        synchronizedFiles ?? {};
      isVisible = false;
      for (const [, documentUploaded] of Object.entries(documentsUploaded)) {
        const document: IBlockAttendu = documentUploaded;
        if (
          document?.typeDePiece === pieceJustificativeName &&
          document?.typeBlockDocument === blockState?.name
        ) {
          switch (document.attendu) {
            case EIsExpected.OBLIGATOIRE:
            case EIsExpected.FACULTATIF:
              isVisible = true;
              isValidated = document.estUpload;
              break;
            case EIsExpected.NON_ATTENDU:
              break;
          }
          break;
        }
      }
      // Sinon, avec l'offre, le front est capable de déduire
    } else if (offer?.inputLoanAmount) {
      isVisible = !(
        pieceJustificative.hideUnderThreshold &&
        ((offer.inputLoanAmount <= ocrParameters?.overdraftThresholdBDC &&
          pieceJustificativeName === EEvidenceBlocksTypeNames.BLOCK_COMMANDE) ||
          (offer.inputLoanAmount <= ocrParameters?.overdraftThreshold &&
            pieceJustificativeName !== EEvidenceBlocksTypeNames.BLOCK_COMMANDE))
      );
    }

    const pieceJustificativeState: IPieceJustificative = {
      name: pieceJustificativeName,
      title: pieceJustificative?.name,
      isVisible: isVisible && blockState.isVisible,
      isMandatory: pieceJustificative.mandatory,
      types: pieceJustificative?.evidenceTypes,
      maxMultipleFiles: pieceJustificative?.maxMultipleFiles ?? 4,
      maxFileSize: ocrParameters?.maxFileSize,
      maxPdfSize: block?.maxPdfSize ?? ocrParameters?.maxPdfSize,
      isValidated,
    };
    return pieceJustificativeState;
  };

  const getBlocksModel = (): {
    blocks: IBlockState[];
    isAllValidated: boolean;
  } => {
    if (!ocrParameters) {
      return { blocks: [], isAllValidated: false };
    }
    const blocksModel: IBlockState[] = [];
    let isAllPiecesValidated = true;
    Object.keys(blocks).forEach((blockName: string) => {
      const block = blocks[blockName];
      const blockModel = getBlockModel({
        block,
        blockName,
        ocrParameters,
      });
      blocksModel.push(blockModel);

      Object.keys(block?.evidenceBlocks ?? {}).forEach(
        (pieceJustificativeName: string) => {
          const pieceJustificative = getPieceJustificativeModel({
            block,
            pieceJustificativeName,
            blockState: blockModel,
            ocrParameters,
          });
          blockModel.pieceJustificatives.push(pieceJustificative);
          if (!pieceJustificative?.isValidated) {
            isAllPiecesValidated = false;
          }
        }
      );
    });
    return {
      blocks: blocksModel,
      isAllValidated: isAllPiecesValidated,
    };
  };

  const [blocksState, setBlocksState] = useState<IBlockState[]>(
    getBlocksModel()?.blocks
  );

  useEffect(() => {
    if (!ocrParameters && !synchronizedFiles) {
      return;
    }
    const blocksModel = getBlocksModel();
    setBlocksState(blocksModel?.blocks);
    setIsAllPiecesJointesValidated(blocksModel?.isAllValidated);
  }, [ocrParameters, synchronizedFiles]);

  return {
    blocksState,
    isAllPiecesJustificativesValidated,
  };
};

export default useBlocksState;
