import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Tooltip from '../Tooltip';
import { StyledHeaderContainer, StyledCard } from './styles';

interface IInfoCardProps {
  children?: React.ReactNode;
  className?: string;
  headerNode?: ReactNode;
}

const InfoCard = ({
  headerNode,
  children,
  className = '',
}: IInfoCardProps): React.ReactElement => {
  const ToolTipContainerRef = useRef<HTMLDivElement>(null);
  const [toolTipHeight, setToolTipHeight] = useState(0);

  const updateToolTipHeight = () => {
    if (ToolTipContainerRef && ToolTipContainerRef.current) {
      setToolTipHeight(ToolTipContainerRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    if (!headerNode) {
      return;
    }
    updateToolTipHeight();
  }, [ToolTipContainerRef, headerNode]);

  /* Ce code permet de mettre à jour la hauteur lorsque le composant devient visible
     Réponse trouvé ici : https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom */
  /* Début du code en question */
  useEffect(() => {
    if (!headerNode) {
      return;
    }
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        updateToolTipHeight();
      }
    });
    if (ToolTipContainerRef && ToolTipContainerRef.current) {
      observer.observe(ToolTipContainerRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ToolTipContainerRef]);
  /* Fin du code en question */

  return (
    <StyledCard
      toolTipHeight={toolTipHeight}
      className={'ph-info-card ' + className}
    >
      {headerNode && (
        <StyledHeaderContainer
          ref={ToolTipContainerRef}
          toolTipHeight={toolTipHeight}
        >
          <Tooltip isVisible hasArrowDown={false} skin="info">
            {headerNode}
          </Tooltip>
        </StyledHeaderContainer>
      )}
      {children}
    </StyledCard>
  );
};

export default InfoCard;
