const MIME_TYPE_JPEG = 'image/jpeg';

const editFileExtension = (fileName: string, newExtension: string): string =>
  `${fileName.split('.').slice(0, -1).join('.')}.${newExtension}`;

function setDPI(
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  dpi: number
) {
  // Set up CSS size.
  canvas.style.width = canvas.style.width || `${canvas.width}px`;
  canvas.style.height = canvas.style.height || `${canvas.height}px`;

  // Resize canvas and scale future draws.
  const scaleFactor = dpi / 96; // 96 is the default dpi for Canvas API
  canvas.width = Math.ceil(canvas.width * scaleFactor);
  canvas.height = Math.ceil(canvas.height * scaleFactor);
}

const calculateNewSize = (
  maxPixelSize: number,
  width: number,
  height: number
) => {
  let newHeight = height;
  let newWidth = width;

  if (width > height) {
    if (width > maxPixelSize) {
      newHeight *= maxPixelSize / width;
      newWidth = maxPixelSize;
    }
  } else if (height > maxPixelSize) {
    newWidth *= maxPixelSize / height;
    newHeight = maxPixelSize;
  }

  return {
    width: newWidth,
    height: newHeight,
  };
};

const compressImage = (
  file: File,
  callback: (newFile: File | null) => void,
  options = {
    compressionSizeThreshold: 100000, // Max size in octets that triggers the compression
    compressionLevel: 0.85, // Compression level 0 is ultra compressed, 1 is not compressed at all
    dpiThreshold: 1000, // below 1000 pixels we increase the DPI
    dpiOCR: 250, // DPI for OCR
    maxPixelSize: 2000, // Max size ( Width and Height ) when compressing
  },
  setImage: (image: HTMLImageElement) => void
): void => {
  const reader = new FileReader();

  const image = new Image();

  reader.onload = (event) => {
    const canvas = document.createElement('canvas');
    const context2d = canvas.getContext('2d');

    const imageSrc = event?.target?.result?.toString();

    image.onload = () => {
      const { name, size } = file;
      const { width, height } = image;

      canvas.width = width;
      canvas.height = height;

      // Adjust DPI if resolution too low (< 1000 pixels)
      const dpiThreashold = width > height ? width : height;
      if (dpiThreashold <= options.dpiThreshold) {
        setDPI(canvas, image, options.dpiOCR);
      } else if (size > options.compressionSizeThreshold) {
        // Resize if file too big
        const newSize = calculateNewSize(options.maxPixelSize, width, height);
        canvas.width = newSize.width;
        canvas.height = newSize.height;
      }
      if (context2d) {
        context2d.drawImage(image, 0, 0, canvas.width, canvas.height);
      }

      // Convert to JPEG
      canvas.toBlob(
        (blob: Blob | null) => {
          if (blob) {
            const compressFileBlob = new File(
              [blob],
              editFileExtension(name, 'jpg'),
              {
                type: MIME_TYPE_JPEG,
              }
            );
            callback(compressFileBlob);
          }
        },
        MIME_TYPE_JPEG,
        options.compressionLevel
      );
    };

    if (imageSrc) {
      image.src = imageSrc;
    }
    setImage(image);
  };
  reader.readAsDataURL(file);
};

export default compressImage;
