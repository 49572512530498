import {
  StyledCardWrapper,
  StyledModalContent,
  WrapperElectronicVad,
  StyledSendIcon,
  StyledTooltip,
  StyledTitleTooltip,
  StyledTextTooltip,
} from './styles';
import * as messages from './messages';
import { useRedirection } from '@hooks';
import { Icons } from '@components';

const PopInFinElectroniqueVAD: React.FC = () => {
  const { redirigerAccueil } = useRedirection();

  return (
    <WrapperElectronicVad>
      <StyledCardWrapper>
        <StyledModalContent
          icon={<StyledSendIcon />}
          title={messages.TITLE_FINISH_VAD}
          text={messages.TEXT_ELECTRONIC_VAD}
          primaryButtonLabel={messages.BUTTON_FINISH_VAD}
          onPrimaryButtonClick={redirigerAccueil}
        />
      </StyledCardWrapper>
      <StyledTooltip
        skin="advice"
        hasArrowDown={false}
        icon={Icons.InfoOutlinesIcon}
        isVisible={true}
      >
        <StyledTitleTooltip level={2}>
          {messages.TOOLTIP_TITLE_VAD}
        </StyledTitleTooltip>
        <StyledTextTooltip>{messages.TOOLTIP_TEXT_VAD}</StyledTextTooltip>
      </StyledTooltip>
    </WrapperElectronicVad>
  );
};

export default PopInFinElectroniqueVAD;
