import { Input, Select } from '@components';
import styled from 'styled-components';

export const WrapperInput = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;

export const SelectCity = styled(Select)<{
  isFranceSelected: boolean;
}>`
  display: ${({ isFranceSelected }) => (isFranceSelected ? 'flex' : 'none')};
`;

export const InputCity = styled(Input)<{
  isFranceSelected: boolean;
}>`
  display: ${({ isFranceSelected }) => (isFranceSelected ? 'none' : 'flex')};
`;
