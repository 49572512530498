const SvgAward2: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.364 1.5a6.864 6.864 0 013.982 12.455l1.059 7.98a.5.5 0 01-.753.494l-4.288-2.573-4.289 2.573a.5.5 0 01-.752-.495l1.06-7.979A6.864 6.864 0 0112.363 1.5zm0 13.727a6.835 6.835 0 01-3.048-.712l-.866 6.523 3.656-2.194a.5.5 0 01.515 0l3.656 2.194-.865-6.523a6.835 6.835 0 01-3.048.712zm0-12.727a5.864 5.864 0 103.133 10.82.4.4 0 01.088-.058A5.864 5.864 0 0012.364 2.5z"
    />
  </svg>
);

export default SvgAward2;
