import { Icons, ModalContent } from '@components';
import * as messages from './messages';

export interface IInterruptedUpload {
  withScroll?: false;
}

export const InterruptedUpload: React.FC<IInterruptedUpload> = ({
  withScroll,
}) => (
  <ModalContent
    withScroll={withScroll}
    icon={<Icons.WarningIcon />}
    title={messages.TITLE_MODAL_TELECHARGEMENT_INTERROMPU}
    text={
      <>
        {messages.TEXT_MODAL_TELECHARGEMENT_INTERROMPU}
        <strong>{messages.TEXT_MODAL_TELECHARGEMENT_INTERROMPU_STRONG}</strong>
      </>
    }
  />
);

export default InterruptedUpload;
