import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Insurance } from '@types';
import { IInsuranceState, IState } from './insuranceTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const getAssuranceSelectedToUpdate = (
  insurancesStatePerson: IInsuranceState,
  partialUpdateAssurance: Partial<Insurance>
): IInsuranceState => {
  if (!partialUpdateAssurance?.code) {
    return insurancesStatePerson;
  }
  const updateAssurance: Insurance = (
    insurancesStatePerson?.insurances ?? []
  ).find((assurance) => assurance?.code === partialUpdateAssurance?.code) ?? {
    code: partialUpdateAssurance?.code,
    code_bareme: '',
    label: '',
    cout_mensuel: '',
    cout_total: '',
    taea: '',
    mensualite_credit: '',
    montant_total: '',
  };
  return {
    ...insurancesStatePerson,
    insuranceSelected: partialUpdateAssurance?.code,
    insurances: [
      ...(insurancesStatePerson?.insurances ?? []).filter(
        (assurance) => assurance?.code !== partialUpdateAssurance?.code
      ),
      { ...updateAssurance, ...partialUpdateAssurance },
    ],
  };
};

const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {
    setInsurances(insurancesState, action: PayloadAction<IState>) {
      insurancesState.state = {
        ...(action?.payload ?? getInitialState().state),
      };
    },
    updateInsurances(insurancesState, action: PayloadAction<Partial<IState>>) {
      insurancesState.state = { ...insurancesState.state, ...action.payload };
    },
    updateInsuranceSelectedEmprunteur(
      insurancesState,
      action: PayloadAction<Partial<Insurance>>
    ) {
      insurancesState.state = {
        ...insurancesState.state,
        emprunteur: getAssuranceSelectedToUpdate(
          { ...insurancesState.state.emprunteur },
          action?.payload
        ),
      };
    },
    updateInsuranceSelectedCoemprunteur(
      insurancesState,
      action: PayloadAction<Partial<Insurance>>
    ) {
      insurancesState.state = {
        ...insurancesState.state,
        coemprunteur: getAssuranceSelectedToUpdate(
          { ...insurancesState.state.coemprunteur },
          action?.payload
        ),
      };
    },
    clearInsurances(insurancesState) {
      insurancesState.state = getInitialState().state;
    },
    clearInsuranceCoEmprunteur(insurancesState) {
      insurancesState.state.coemprunteur = getInitialState().state.coemprunteur;
    },
  },
});

export const selectInsurances = (insurancesState: {
  insurance: { state: IState };
}) => insurancesState.insurance.state;

export const {
  setInsurances,
  updateInsurances,
  updateInsuranceSelectedEmprunteur,
  updateInsuranceSelectedCoemprunteur,
  clearInsurances,
  clearInsuranceCoEmprunteur,
} = insurancesSlice.actions;

export default insurancesSlice.reducer;
