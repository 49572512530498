import { FunctionComponent, useEffect } from 'react';
import { ModalContent, Modal, Icons, InformationsBlock } from '@components';
import { useComponentVisible } from '@hooks';
import * as messages from './messages';
import { StyledTooltip, TextParagraph, TextBold } from './styles';

export interface IProps {
  isJobless: boolean;
  withInsurance: boolean;
  isCR: boolean;
  onClose: () => void;
}
const InsuranceInformations: FunctionComponent<IProps> = ({
  isJobless,
  isCR,
  onClose,
  withInsurance,
}) => {
  const { ref, isComponentVisible } = useComponentVisible(true);

  useEffect(() => {
    if (!isComponentVisible) {
      onClose();
    }
  }, [isComponentVisible]);

  const withInsuranceTooltipBody = (
    <>
      <TextParagraph>{messages.WITH_INSURANCE_TEXT_2}</TextParagraph>
      <InformationsBlock
        isVisible
        skin="info"
        icon={Icons.CrossCircleIcon}
        hasArrowDown={false}
        title={messages.ADDITIONAL_INFORMATIONS_TITLE}
        titleColor="primary"
      >
        <TextParagraph>
          <TextBold>
            {messages.ADDITIONAL_INFORMATIONS_TEXT_3_WITH_INUSURANCE}
          </TextBold>
          {messages.ADDITIONAL_INFORMATIONS_TEXT_VAT_1_WITH_INUSURANCE}
        </TextParagraph>
        <TextParagraph>
          <TextBold>
            {messages.ADDITIONAL_INFORMATIONS_TEXT_5_WITH_INUSURANCE}
          </TextBold>
          {isJobless
            ? messages.ADDITIONAL_INFORMATIONS_TEXT_6
            : messages.ADDITIONAL_INFORMATIONS_TEXT_7}
        </TextParagraph>
      </InformationsBlock>
    </>
  );

  const withoutInsuranceTooltipBody = (
    <>
      {messages.SUB_TITLE}
      <StyledTooltip isVisible skin="advice" hasArrowDown={false}>
        {messages.GUIDANCE_BLOCK_TEXT_1}
        <TextBold>{messages.GUIDANCE_BLOCK_TEXT_2}</TextBold>
        {messages.GUIDANCE_BLOCK_TEXT_3}
        <TextBold>{messages.GUIDANCE_BLOCK_TEXT_4}</TextBold>
        {messages.GUIDANCE_BLOCK_TEXT_5}
      </StyledTooltip>

      <InformationsBlock
        isVisible
        skin="info"
        icon={Icons.CrossCircleIcon}
        hasArrowDown={false}
        title={messages.ADDITIONAL_INFORMATIONS_TITLE}
        titleColor="primary"
      >
        {isCR ? (
          <TextParagraph>
            {messages.ADDITIONAL_INFORMATIONS_TEXT_CR_1}{' '}
            <TextBold>{messages.ADDITIONAL_INFORMATIONS_TEXT_CR_2}</TextBold>{' '}
          </TextParagraph>
        ) : (
          <TextParagraph>
            <TextBold>{messages.ADDITIONAL_INFORMATIONS_TEXT_VAT_1}</TextBold>{' '}
            {messages.ADDITIONAL_INFORMATIONS_TEXT_VAT_2}
          </TextParagraph>
        )}
        <TextParagraph>
          <TextBold> {messages.ADDITIONAL_INFORMATIONS_TEXT_5}</TextBold>{' '}
          {isJobless
            ? messages.ADDITIONAL_INFORMATIONS_TEXT_6
            : messages.ADDITIONAL_INFORMATIONS_TEXT_7}
        </TextParagraph>
      </InformationsBlock>

      <InformationsBlock
        isVisible
        skin="caution"
        hasArrowDown={false}
        icon={Icons.CrossCircleOutlinesIcon}
        title={messages.WARNING_TITLE}
      >
        <TextParagraph>
          <TextBold> {messages.WARNING_TEXT_1}</TextBold>
          {messages.WARNING_TEXT_2}
        </TextParagraph>
        <TextParagraph>
          <TextBold>
            {isJobless ? messages.WARNING_TEXT_3 : messages.WARNING_TEXT_5}
          </TextBold>
          {messages.WARNING_TEXT_4}
        </TextParagraph>
      </InformationsBlock>
    </>
  );

  const modalTitle = withInsurance
    ? messages.WITH_INSURANCE_TEXT_1
    : messages.TITLE;

  const modalText = withInsurance
    ? withInsuranceTooltipBody
    : withoutInsuranceTooltipBody;

  return (
    <Modal isVisible isClosable>
      <div ref={ref}>
        <ModalContent
          title={modalTitle}
          text={modalText}
          primaryButtonLabel={messages.CLOSE}
          withScroll={false}
          onPrimaryButtonClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default InsuranceInformations;
