const SvgUser: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 18.62v1.054C21 20.96 19.976 22 18.712 22H5.288C4.024 22 3 20.959 3 19.674V18.62c0-1.312.784-2.484 1.973-2.932 1.93-.728 4.28-1.182 7.027-1.182s5.097.454 7.026 1.182c1.19.448 1.974 1.62 1.974 2.932zm-1.22 0c0-.798-.472-1.504-1.178-1.77-1.797-.677-4.004-1.103-6.602-1.103-2.598 0-4.805.426-6.602 1.104-.706.265-1.178.97-1.178 1.77v1.053c0 .6.478 1.086 1.068 1.086h13.424c.59 0 1.068-.486 1.068-1.086V18.62zM12 12.005c1.976 0 3.585-1.678 3.585-3.757v-1.25c0-2.08-1.609-3.758-3.585-3.758S8.415 4.92 8.415 6.997v1.251c0 2.079 1.609 3.757 3.585 3.757zm0 1.24c-2.657 0-4.805-2.24-4.805-4.997v-1.25C7.195 4.24 9.343 2 12 2c2.657 0 4.805 2.241 4.805 4.997v1.251c0 2.756-2.148 4.997-4.805 4.997z"
    />
  </svg>
);

export default SvgUser;
