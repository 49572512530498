import styled from 'styled-components';
import DropdownMenu from '../DropdownMenu';

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  justify-content: flex-end;
`;

export const HeaderLink = styled.div`
  display: flex;
  outline: 0;
  flex-grow: 1;
`;
