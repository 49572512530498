export const resetAuthenticationRequired = () => {
  sessionStorage.setItem('need-redirect-authentication', 'false');
};

export const setAuthenticationRequired = () => {
  sessionStorage.setItem('need-redirect-authentication', 'true');
};

export const isAuthenticationRequired = () =>
  sessionStorage.getItem('need-redirect-authentication') === 'true';

export const setAuthenticationEnabled = (authenticationEnabled = true) => {
  sessionStorage.setItem(
    'authentication-enabled',
    authenticationEnabled ? 'true' : 'false'
  );
};

export const isAuthenticationEnabled = () =>
  sessionStorage.getItem('authentication-enabled') !== 'false';
