import ProgressBar from '../ProgressBar';
import { IFile, FileStatus } from '@types';
import UploadedFile from './UploadedFile';
import {
  StyledUploadFileProgress,
  WrapperProgress,
  ErrorMessage,
} from './styles';

export interface IUploadFileProgressProps {
  file: IFile;
  isUploaded: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
}

const UploadFileProgress = ({
  file,
  isUploaded,
  onCancel = () => ({}),
  onDelete = () => ({}),
}: IUploadFileProgressProps): React.ReactElement => {
  const { error: errorMessage, status } = file;
  const hasError = status === FileStatus.FAILED;

  return (
    <StyledUploadFileProgress>
      <UploadedFile
        isCancellable={hasError}
        isDeletable={file.progress === 100 && !hasError}
        isLoaded={file.progress === 100 && !hasError}
        isUploaded={isUploaded}
        fileName={file.name}
        onCancel={onCancel}
        onDelete={onDelete}
      />
      <WrapperProgress>
        <ProgressBar progress={file.progress} hasFailed={hasError} />
      </WrapperProgress>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </StyledUploadFileProgress>
  );
};

export default UploadFileProgress;
