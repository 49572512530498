import { useRef } from 'react';
import UploadZoneTablet from './UploadZoneTablet';
import UploadZoneDesktop from './UploadZoneDesktop';
import { StyledUploadZone, StyledInputFile } from './styles';
import messages from './messages';

export interface IUploadZoneProps {
  label?: string;
  name?: string;
  isMobileDevice: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  isOver?: boolean;
  onChange: React.FormEventHandler<HTMLInputElement>;
}

const UploadZone = ({
  name = '',
  isMobileDevice,
  isDisabled = false,
  isMultiple = false,
  isOver = false,
  onChange,
}: IUploadZoneProps): React.ReactElement => {
  const inputFile = useRef<HTMLInputElement>(null);

  const handleClick = (e: any) => {
    e.preventDefault();
    if (!isDisabled && inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  return (
    <form>
      <StyledUploadZone
        id={`upload-zone-${name}`}
        isDisabled={isDisabled}
        isOver={isOver}
        onClick={handleClick}
        isUploadMultiple={isMultiple}
      >
        {isMobileDevice ? (
          <UploadZoneTablet label={messages.tabletLabel} />
        ) : (
          <UploadZoneDesktop
            isDisabled={isDisabled}
            label={
              isMultiple ? messages.desktopLabelMultiple : messages.desktopLabel
            }
            labelBis={
              isMultiple
                ? messages.desktopLabelMultipleBis
                : messages.desktopLabelBis
            }
          />
        )}
      </StyledUploadZone>
      <StyledInputFile
        id={`upload-input-${name}`}
        ref={inputFile}
        type="file"
        multiple={isMultiple}
        onChange={onChange}
      />
    </form>
  );
};

export default UploadZone;
