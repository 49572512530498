/*
 * Messages ServiceErrorsOcr
 */
export const BUTTON_UNAVAILABLE_SERVICE = 'PASSER CETTE ÉTAPE';
export const TEXT_START_UNAVAILABLE_SERVICE =
  'La liaison avec les serveurs a été perdue et le téléchargement n’a pas pu aboutir.';
export const TEXT_BOLD_UNAVAILABLE_SERVICE =
  'Vous aurez la possibilité de télécharger les pièces ultérieurement lors de la souscription.';
export const TITLE_UNAVAILABLE_SERVICE = 'Service indisponible';
export const TEXT_GO_BACK_PC = 'Vous pouvez';
export const TEXT_BOLD_GO_BACK_PC =
  ' reprendre le dossier sur le PC et fermer cet onglet.';
export const TEXT_SERVICE_UNAVIABLE_SWITCH_DEVICE = 'Vous pouvez ';

export const TEXT_SERVICE_UNAVIABLE_SWITCH_DEVICE_STRONG =
  'reprendre le dossier sur le PC et fermer cet onglet.';
