import { ButtonCheck, Card } from '@components';
import styled from 'styled-components';

export const StyledCard = styled(Card.Wrapper)`
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
  padding-bottom: 0;
`;

export const StyledWrapper = styled.div`
  padding-bottom: 0.1rem;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyleTitleIconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  & > h2 {
    padding-left: 1.6rem;
  }
`;

export const StyledButtonCheck = styled(ButtonCheck)`
  max-width: 10rem;
  margin-top: -0.6rem;
  color: ${({ theme }) => theme.vendor.colors.primary};
  border-color: ${({ theme }) => theme.vendor.colors.primary};
`;
