import React from 'react';
import { StyledButton, WrapperButton } from './styles';

export interface MobileProps {
  btnLabel?: string;
  goToResult?: () => void;
  isDisabled: boolean;
  isLoading?: boolean;
}

const SimulationButton: React.FC<MobileProps> = ({
  btnLabel,
  goToResult = () => {},
  isDisabled,
  isLoading = false,
}) => {
  return (
    <React.Fragment>
      <WrapperButton>
        {btnLabel && (
          <StyledButton
            variant="primary"
            onClick={goToResult}
            isDisabled={isDisabled}
            isLoading={isLoading}
          >
            {btnLabel}
          </StyledButton>
        )}
      </WrapperButton>
    </React.Fragment>
  );
};

export default SimulationButton;
