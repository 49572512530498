import { useState } from 'react';
import { Icons, ModalContent } from '@components';
import dayjs from 'dayjs';
import * as messages from './messages';
import StyledTextBold, {
  BlockBtnCopy,
  BlockCopy,
  BlockTextCopy,
  Btn,
  TextCopy,
  TextCopyBtn,
} from './styles';

interface IErrorInformations {
  code?: string;
  message?: string;
  service?: string;
  requestId?: string;
  vendorId?: string;
  installmentId?: string;
  firstName?: string;
  lastName?: string;
}
interface GenericErrorProps {
  redirectToPopHome: () => void;
  requestId?: string;
  errorInformations?: IErrorInformations;
  willDispatchError?: boolean;
}
const GenericError = ({
  redirectToPopHome,
  requestId = '',
  errorInformations,
  willDispatchError = true,
}: GenericErrorProps): React.ReactElement => {
  const usedRequestId = requestId ?? 'unknown';
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const dateNow = dayjs(new Date()).toDate().toString();

  const copyError = () => {
    let copy;
    if (errorInformations) {
      copy = { ...errorInformations, date: dateNow };
    } else {
      copy = { error: usedRequestId, date: dateNow };
    }
    navigator.clipboard.writeText(JSON.stringify(copy).replace(/,/gi, '\n'));
    setIsCopy(true);
  };

  return (
    <ModalContent
      icon={<Icons.CloudIcon />}
      title={messages.TITLE}
      text={
        <>
          {messages.REFUSE_CONTACT}
          <StyledTextBold>{messages.TEXT_COPY_PAST}</StyledTextBold>
          <StyledTextBold>{messages.TEXT_COPY_PAST_NEXT}</StyledTextBold>
          <BlockCopy onClick={() => copyError()}>
            <BlockTextCopy>
              <TextCopy isCopy={isCopy}>
                {messages.REQUESTID_HANDLER}
                {usedRequestId}
              </TextCopy>
              {errorInformations && (
                <>
                  <TextCopy isCopy={isCopy}>
                    {messages.CODE_HTTP}
                    {errorInformations?.code}
                    {messages.MESSAGE_ERROR}
                    {errorInformations?.message}
                  </TextCopy>
                  <TextCopy isCopy={isCopy}>
                    {messages.SERVICE}
                    {errorInformations?.service}
                  </TextCopy>
                </>
              )}
            </BlockTextCopy>
            <BlockBtnCopy>
              <TextCopyBtn isCopy={isCopy}>Copier</TextCopyBtn>
              <Btn>
                <Icons.CopyFile />
              </Btn>
            </BlockBtnCopy>
          </BlockCopy>
        </>
      }
      primaryButtonLabel={messages.BACK_BUTTON}
      onPrimaryButtonClick={redirectToPopHome}
    />
  );
};

export default GenericError;
