const SvgPictureIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#4B4F54"
      fillRule="evenodd"
      d="M3.875 5C2.839 5 2 5.84 2 6.875v11.25C2 19.16 2.84 20 3.875 20h16.25C21.16 20 22 19.16 22 18.125V6.875C22 5.839 21.16 5 20.125 5H3.875Zm16.25 1.25c.345 0 .625.28.625.625v7.5l-4.719-2.434a.625.625 0 0 0-.721.116l-4.638 4.638-3.325-2.215a.625.625 0 0 0-.787.077L3.253 17.5v.675a.632.632 0 0 1-.003-.05V6.875c0-.345.28-.625.625-.625h16.25Zm-12.497 5a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictureIcon;
