import { ButtonCheck } from '@components';
import { IOption } from '@types';
import { Wrapper, Label, List, HiddenInput } from './styles';

export interface IRadioListProps {
  name: string;
  label?: string;
  isColorLabelForm?: boolean;
  isDisabled?: boolean;
  options: IOption[];
  value?: string;
  valueList: string[];
  onChange: (value: string) => void;
  isBig?: boolean;
  isBold?: boolean;
  isGrid?: boolean;
  className?: string;
  isValid?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

const RadioList = ({
  name,
  label,
  options,
  value,
  valueList,
  isDisabled,
  onChange = () => null,
  isBig = false,
  isBold = false,
  isGrid = false,
  className = '',
  isValid,
  inputRef = undefined,
}: IRadioListProps): React.ReactElement => {
  const handleClick = (buttonValue: string) => {
    onChange(buttonValue);
  };

  return (
    <Wrapper className={'ph-radio-list ' + className}>
      {label && <Label isBold={isBold}>{label}</Label>}
      <HiddenInput name={name} defaultValue={value} ref={inputRef} readOnly />
      <List isGrid={isGrid} role="list">
        {options.map((option) => (
          <li key={option.id}>
            <ButtonCheck
              name={option.id}
              onClick={handleClick}
              value={option.id}
              isChecked={option.id === value}
              isBig={isBig}
              isGrid={isGrid}
              isDisabled={
                isDisabled ||
                (valueList &&
                  (valueList.length === 1 || !valueList.includes(option.id)))
              }
              isValid={isValid}
            >
              {option.label}
            </ButtonCheck>
          </li>
        ))}
      </List>
    </Wrapper>
  );
};

export default RadioList;
