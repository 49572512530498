import { Icons, Input } from '@components';
import { useEffect, useState } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import {
  EditButton,
  OcrField,
  OcrValue,
  StyledLabel,
  WrapperBodyOCR,
  WrapperDefaultValue,
  WrapperFieldValue,
  WrapperItem,
} from './styles';

interface IProps {
  label: string;
  defaultValue?: string;
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  render?: (
    {
      value,
      onChange,
      label,
      onBlur,
      ref,
    }: {
      value: string;
      onChange: (newValue: string) => void;
      label: string;
      onBlur: () => void;
      ref: React.Ref<HTMLInputElement>;
      name: string;
    },
    {
      isTouched,
      invalid,
    }: {
      isTouched: boolean;
      invalid: boolean;
    }
  ) => React.ReactElement;
  renderPlaceholder?: (value: string) => React.ReactNode;
  isReadOnly?: boolean;
  isEditMode?: boolean;
  validate?: (newValue: string) => boolean;
  pattern?: RegExp;
  mask?: string | undefined;
  minLength?: number;
  maxLength?: number;
  error?: string;
  reloadField?: number;
  onEdit?: () => void;
  onFocus?: () => void;
  onChange?: (newValue: string | number) => void;
  iban?: boolean;
}

const FormEditableField: React.FC<IProps> = ({
  label,
  name,
  render: renderField,
  defaultValue = '',
  renderPlaceholder,
  isReadOnly = false,
  validate,
  pattern,
  mask,
  minLength = 0,
  maxLength,
  error,
  isEditMode = false,
  reloadField = 0,
  onEdit,
  onFocus,
  onChange,
  iban,
}) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(true);
  const [isErrorMsgVisible, setIsErrorMsgVisible] = useState(false);
  const { control, setValue } = useFormContext();
  const {
    field: { value, ref, onChange: handleChange, onBlur },
    fieldState: { invalid, isTouched },
  } = useController({
    name,
    control,
    rules: {
      required: true,
      validate,
      pattern,
      minLength,
    },
    defaultValue,
  });

  const setEditMode = () => {
    setIsInEditMode(true);
  };

  const onClickEdit = () => {
    setEditMode();
    if (onEdit) {
      onEdit();
    }
  };

  useEffect(() => {
    if (invalid) {
      setEditMode();

      // On enlève la validation si le champ est vide
      if (defaultValue === '') {
        setIsErrorVisible(false);
      }
      if (error) {
        setIsErrorMsgVisible(true);
      }
    } else {
      setIsErrorMsgVisible(false);
    }
  }, [invalid]);

  useEffect(() => {
    if (isEditMode) {
      setEditMode();
    }
  }, [isEditMode]);

  /**
   * Le champ est complètement mis à jour s'il s'agit d'un nouvel affichage avec une nouvelle valeur
   *  => réouverture de la popin OCR avec de nouvelles données océrisées par exemple
   */
  useEffect(() => {
    setIsInEditMode(!defaultValue);
    setValue(name, defaultValue, { shouldValidate: true });
  }, [defaultValue, reloadField]);

  const getValue = (newValue: string) =>
    renderPlaceholder ? renderPlaceholder(newValue) : defaultValue;
  // TODO : Refacto au niveau du label pour qu'il soit utilisé depuis le Input
  return (
    <WrapperItem className={'ph-editable-field '}>
      <WrapperDefaultValue>
        <StyledLabel isErrorMsgVisible={isErrorMsgVisible}>{label}</StyledLabel>
      </WrapperDefaultValue>
      <WrapperFieldValue>
        <WrapperBodyOCR>
          <OcrValue isInEditMode={isInEditMode}>
            {iban
              ? defaultValue.replace(/(.{4})(?=.)/g, '$1 ')
              : getValue(defaultValue)}
          </OcrValue>
          <OcrField isInEditMode={isInEditMode}>
            {renderField ? (
              renderField(
                {
                  onChange: handleChange,
                  value,
                  name,
                  onBlur,
                  ref,
                  label,
                },
                { invalid, isTouched: isErrorVisible || isTouched }
              )
            ) : (
              <Input
                value={value}
                name={name}
                onChange={(newValue) => {
                  if (onChange) {
                    onChange(newValue);
                  }
                  handleChange(newValue);
                }}
                isTouched={isErrorVisible || isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                mask={mask}
                maskChar=""
                maxLength={maxLength}
                error={error}
                onFocus={onFocus}
              />
            )}
          </OcrField>
        </WrapperBodyOCR>
        {!isInEditMode && !isReadOnly && (
          <EditButton id={`edit-${name}`} onClick={onClickEdit}>
            <Icons.EditIcon />
          </EditButton>
        )}
      </WrapperFieldValue>
    </WrapperItem>
  );
};

export default FormEditableField;
