import { ButtonCheck } from '@components';
import { Controller, Control } from 'react-hook-form';
import { MobileKeyboardType } from '@types';
import { StyledInput, GreenLine, FlexWrapper } from './styles';

export type OnInputChangeFunction = (
  onChange: (...event: any[]) => void,
  btnLabel: string,
  value: string | number
) => void;

export interface IProps {
  name: string;
  label?: string;
  control: Control<Record<string, string | number | boolean>>;
  maxLength: number;
  defaultValue?: string;
  showGreenLine?: boolean;
  informationToolTipMessage?: string;
  validate?: (value: string | number | boolean) => boolean;
  onChange?: OnInputChangeFunction;
  onNoneButtonClick?: (
    fieldName: string,
    onChange: (...event: unknown[]) => void
  ) => void;
  onButtonCheckClick?: (...event: any[]) => void;
  isButtonCheckDisabled?: () => boolean | undefined;
  placeholder?: string;
  after: string;
  labelRightButton: string;
  inputMode?:
    | 'search'
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
  styledInput?: string;
  className?: string;
}

const InputWithRightButton: React.FC<IProps> = ({
  name,
  label,
  control,
  maxLength,
  defaultValue,
  showGreenLine,
  informationToolTipMessage,
  labelRightButton,
  validate = () => true,
  onChange: onInputChange = () => null,
  onButtonCheckClick = () => null,
  isButtonCheckDisabled = () => false,
  placeholder = '',
  after = '',
  inputMode = MobileKeyboardType.NUMERIC,
  styledInput = 'price',
  className = '',
}) => {
  const buttonName = `${name}Btn`;

  return (
    <FlexWrapper>
      {showGreenLine && <GreenLine />}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={{ validate }}
        render={({
          field: { onChange, value, name, onBlur, ref },
          fieldState: { isTouched, invalid },
          formState: { isSubmitted },
        }) => (
          <StyledInput
            inputMode={inputMode}
            name={name}
            label={label}
            placeholder={placeholder}
            type={styledInput}
            value={value as string | number}
            onChange={(newValue) =>
              onInputChange(onChange, buttonName, newValue)
            }
            isTouched={isTouched}
            isValid={!invalid}
            onBlur={onBlur}
            inputRef={ref}
            after={after}
            isSymbolVisible
            maxLength={maxLength}
            informationToolTipMessage={informationToolTipMessage}
            isSubmitted={isSubmitted}
            className={'ph-input-right-button ' + className}
          >
            <Controller
              control={control}
              name={buttonName}
              defaultValue={false}
              rules={{
                required: false,
              }}
              render={({
                field: { onChange: onButtonChange, value: buttonValue },
              }) => (
                <ButtonCheck
                  name="0"
                  onClick={() => {
                    onButtonCheckClick(name, onButtonChange);
                  }}
                  isDisabled={isButtonCheckDisabled()}
                  isChecked={!!buttonValue}
                  isWide
                >
                  {labelRightButton}
                </ButtonCheck>
              )}
            />
          </StyledInput>
        )}
      />
    </FlexWrapper>
  );
};

export default InputWithRightButton;
