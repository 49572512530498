const SvgSmartphone: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 2a2 2 0 012 2v16a2 2 0 01-2 2H8a2 2 0 01-2-2V4a2 2 0 012-2h8zm1 16.174H7V20a1 1 0 001 1h8a1 1 0 001-1v-1.826zm-4.993.942c.253 0 .458.212.458.475a.467.467 0 01-.458.475.467.467 0 01-.459-.475c0-.263.206-.475.459-.475zM17 6.974H7v10.2h10v-10.2zM16 3H8a1 1 0 00-1 1v1.974h10V4a1 1 0 00-1-1z"
    />
  </svg>
);

export default SvgSmartphone;
