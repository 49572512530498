/*
 * Messages
 */
export const CONTAINER_TITLE = 'Coordonnées';
export const INPUT_EMAIL = 'Adresse e-mail';
export const INPUT_MOBILE_PHONE_NUMBER = 'Numéro de mobile';
export const PLACEHOLDER_EMAIL = 'exemple@exemple.com';
export const PLACEHOLDER_MOBILE_PHONE_NUMBER = '06 00 00 00 00';
export const ERROR_EMAIL = 'Saisie non conforme';
export const ERROR_MOBILE_PHONE_NUMBER =
  'Numéro de mobile non conforme, doit commencer par 06 ou 07';
export const TOOLTIP_CONTACT_TITLE = 'Informations contact';
export const TOOLTIP_CONTACT_NOTE =
  'Ces informations sont indispensables pour la signature électronique du dossier.';
export const ERROR_CONTROLE_EMAIL = "L'e-mail semble non valide";
