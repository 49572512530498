/*
 * Messages ServiceErrorsOcr
 *
 *
 */
export const TITLE_UPLOAD_FAILED = 'Échec du téléchargement';
export const TEXT_START_UPLOAD_FAILED =
  'La liaison avec les serveurs a été perdue et le téléchargement n’a pas pu aboutir.';
export const TEXT_BOLD_UPLOAD_FAILED =
  'Merci de réessayer de charger la pièce à nouveau.';
export const RELOAD_UPLOAD_FAILED = 'CHARGER À NOUVEAU';
