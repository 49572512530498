import useFirstRender from './useFirstRender';
import { useEffect } from 'react';
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

const usePersistingForm = (
    formName: string,
    watch: UseFormWatch<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    options?: { exclude: string[] }
): Record<string, string> => {
    const formData = watch();
    const { firstRender } = useFirstRender();
    const localStore = sessionStorage.getItem(formName);
    const parsedLocalStore = localStore ? JSON.parse(localStore) : null;
    if (!firstRender) {
        sessionStorage.setItem(formName, formData ? JSON.stringify(formData) : '');
    }

    useEffect(() => {
        if (parsedLocalStore) {
            Object.entries(parsedLocalStore).forEach(([key, value]) => {
                if (!options?.exclude.includes(key)) {
                    setValue(key, value, { shouldValidate: true });
                }
            });
        }
    }, []);
    return parsedLocalStore;
};
export default usePersistingForm;
