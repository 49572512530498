import { StyledContent } from './styles';

interface IBlockContentProps {
  children?: React.ReactNode;
  className?: string;
}

const Content = ({
  children,
  className = '',
}: IBlockContentProps): React.ReactElement => (
  <StyledContent className={className}>{children}</StyledContent>
);
export default Content;
