const SvgFinance: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.58 7.83972H17.85C20.14 7.83972 22 9.70409 22 11.9872V16.8625C22 19.1456 20.14 21 17.85 21H6.15C3.86 21 2 19.1456 2 16.8625V11.9872C2 9.70409 3.86 7.83972 6.15 7.83972H6.28C6.2855 7.83058 6.291 7.8211 6.29662 7.81142C6.32167 7.76827 6.34917 7.72091 6.39 7.6802L11.02 3.1738C11.24 2.95446 11.58 2.94449 11.82 3.13392L14.02 4.91853L15.5 4.04118C15.66 3.95145 15.85 3.94148 16.02 3.99133C16.19 4.05115 16.32 4.19073 16.38 4.36022L17.58 7.83972ZM12.93 5.56658L11.48 4.4001L7.94 7.83972H9.29C9.34 7.74999 9.4 7.66026 9.51 7.60044L12.93 5.56658ZM15.48 5.44694L16.31 7.83972H11.43L15.48 5.44694ZM17.85 19.7936C19.48 19.7936 20.8 18.4776 20.8 16.8525V11.9772C20.8 10.3521 19.48 9.03611 17.85 9.03611H6.15C4.52 9.03611 3.2 10.3521 3.2 11.9772V16.8525C3.2 18.4776 4.52 19.7936 6.15 19.7936H17.85Z"
      fill="#292C2E"
    />
    <path
      d="M13.8201 12.0072C14.1501 12.0072 14.4101 11.748 14.4201 11.419C14.4201 11.2594 14.3701 11.1099 14.2601 10.9903C14.1501 10.8706 14.0001 10.8108 13.8401 10.8008C13.7701 10.7909 12.1601 10.7609 11.0301 11.8576C10.6801 12.1966 10.4201 12.5954 10.2501 13.054H9.22012C8.89012 13.054 8.62012 13.3232 8.62012 13.6622C8.62012 13.9912 8.89012 14.2604 9.22012 14.2604H10.0001C9.99012 14.39 9.97012 14.5096 9.97012 14.6492C9.97012 14.7888 9.99012 14.9184 10.0001 15.058H9.25012C8.92012 15.058 8.64012 15.3272 8.64012 15.6661C8.64012 15.9952 8.91012 16.2643 9.25012 16.2643H10.2301C10.4101 16.7429 10.6801 17.1517 11.0401 17.4906C12.0301 18.4378 13.3701 18.5076 13.7501 18.5076H13.8401C14.1701 18.4876 14.4301 18.2085 14.4101 17.8795C14.4001 17.7199 14.3301 17.5704 14.2101 17.4607C14.1001 17.361 13.9501 17.3012 13.8001 17.3012C13.7901 17.3012 13.7801 17.3012 13.7701 17.3012C13.6401 17.3012 12.5701 17.3012 11.8501 16.6133C11.7401 16.5136 11.6501 16.3939 11.5701 16.2643H13.2701C13.6001 16.2643 13.8701 15.9952 13.8701 15.6661C13.8701 15.3371 13.6001 15.058 13.2701 15.058H11.1901C11.1801 14.9284 11.1601 14.7988 11.1601 14.6492C11.1601 14.5096 11.1801 14.39 11.1901 14.2604H13.2601C13.5901 14.2604 13.8701 13.9912 13.8701 13.6622C13.8701 13.3332 13.6001 13.054 13.2601 13.054H11.6001C11.6801 12.9344 11.7601 12.8247 11.8601 12.725C12.5601 12.0471 13.6001 12.0072 13.8201 12.0072Z"
      fill="#292C2E"
    />
  </svg>
);

export default SvgFinance;
