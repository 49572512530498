// mobile phone mask
export const phoneMask = '## ## ## ## ##';

export const zipCodeMask = '#####';

export const dateMask = '## / ## / ####';

export const dateMonthYearMask = '## / ####';

export const sirenMask = '#########';

export const employeesNumberMask = '####';
