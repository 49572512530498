import { Icons } from '@components';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
  align-self: center;
  justify-self: start;
`;
export const BlockTitle = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.variables.colors.chartColors.green_500};
  margin: 0;
`;
export const GridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 5rem auto;
`;
export const BlockText = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const TextWrapper = styled.div`
  grid-column: 2;
`;

export const IconWrapper = styled.div`
  place-self: center;
`;
export const CheckCircleIcon = styled(Icons.CheckCircleIcon)`
  height: 20px;
  width: 21.5px;
  color: ${({ theme }) => theme.variables.colors.chartColors.green_500};
`;
