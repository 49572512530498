const SvgEdit: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.38 4.99a.75.75 0 110 1.5H5.64a.89.89 0 00-.89.89v11.48c0 .492.398.89.89.89h11.48a.89.89 0 00.89-.89v-5.74a.75.75 0 111.5 0v5.74a2.39 2.39 0 01-2.39 2.39H5.64a2.39 2.39 0 01-2.39-2.39V7.38a2.39 2.39 0 012.39-2.39h5.74zm8.08 1.45a.99.99 0 00-1.4-1.4l-7.643 7.643-.466 1.866 1.866-.466 7.643-7.644zm1.06-2.46a2.49 2.49 0 010 3.52l-7.79 7.79a.75.75 0 01-.348.197l-3.28.82a.75.75 0 01-.91-.91l.82-3.28a.75.75 0 01.198-.348L17 3.98a2.49 2.49 0 013.52 0z"
    />
  </svg>
);

export default SvgEdit;
