export const TITLE_MODAL_EXPIRED = 'Durée de validité de la pièce dépassée';
export const ERROR_EXPIRED_PJ_MESSAGE =
  "La pièce d'identité de votre client n'est plus en cours de validité. Merci d’en télécharger une autre.";
export const ERROR_EXPIRED_PJ_MESSAGE_2 =
  'Si vous acceptez cette pièce, le client devra ';
export const ERROR_EXPIRED_PJ_MESSAGE_2_BIS =
  'fournir obligatoirement son permis de conduire en plus.';
export const ERROR_EXPIRED_PJ_MESSAGE_2_TER =
  'L’intégralité des pièces justificatives sera à télécharger à une autre étape, avant la Signature électronique du dossier.';
export const ERROR_EXPIRED_PJ_MESSAGE_3 =
  'S’il ne dispose pas d’une nouvelle pièce, la demande de crédit de votre client ne peut aboutir. Merci de retourner à l’accueil. ';
export const PROOF_BUTTON = 'CHARGER À NOUVEAU';
export const TO_SEND_BUTTON = 'ACCEPTER LA PIÈCE';
export const STOP_PROCESS = "RETOUR A L'ACCUEIL";
export const PROOF_BUTTON_TABLET = 'CHARGER UNE NOUVELLE PIĖCE';
export const ERROR_EXPIRED_PJ_MESSAGE_3_TABLET =
  'S’il ne dispose pas d’une nouvelle pièce, la demande de crédit de votre client ne peut aboutir.';
export const UPLOAD = 'Charger à nouveau ou ';
export const ABANDON = 'abandonner le dossier';
export const CLOSE_TAB = ' en fermant cet onglet.';
export const IF_YOU_ACCEPT_PJ = 'Si vous acceptez cette pièce :';
export const FIRST_PROPOSITION_BOLD =
  'Le chargement sera terminé sur la tablette ';
export const FIRST_PROPOSITION = 'et vous devrez revenir sur le PC.';
export const SECOND_PROPOSITION_BOLD =
  'Le client devra fournir obligatoirement son permis de conduire ';
export const SECOND_PROPOSITION = 'en plus.';
export const THIRD_PROPOSITION_BOLD =
  'L’intégralité des pièces justificatives sera à télécharger à une autre étape, ';
export const THIRD_PROPOSITION = 'avant la signature du dossier.';

export const TITLE_MODAL = 'Téléchargemement des pièces justificatives réussi';
export const DOWNLOAD_SUCCESS =
  'Téléchargement des pièces terminé à cette étape';
export const ALL_PJ = "L'intégralité des pièces justificatives ";
export const CAN_BE_UPLOADED =
  'sera à télécharger à une autre étape y compris le permis de conduire.';
export const GO_BACK_PC =
  'Vous pouvez reprendre le dossier sur le PC et fermer cet onglet.';
export const PROOF_BUTTON_TABLET_OUTDATED_EVIDENCE = 'CHARGER A NOUVEAU';
export const ONE = '1. ';
export const TWO = '2. ';
export const THREE = '3. ';
export const PJ_NOT_VALID =
  "La pièce d'identité de votre client n'est plus en cours de validité.";
export const UPLOAD_ANOTHER_PJ = 'Merci d’en télécharger une autre.';
