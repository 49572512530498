const SvgIllustration1: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 284 138"
    className={className}
  >
    <defs>
      <filter
        id="Illustration1_svg__a"
        width="103.1%"
        height="103.2%"
        x="-1.6%"
        y="-1.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation={5}
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
      </filter>
      <filter
        id="Illustration1_svg__c"
        width="118.1%"
        height="176.7%"
        x="-7.5%"
        y="-31.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation={3.5}
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
      </filter>
      <filter
        id="Illustration1_svg__e"
        width="118.1%"
        height="176.7%"
        x="-7.5%"
        y="-31.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation={3.5}
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
      </filter>
      <filter
        id="Illustration1_svg__g"
        width="212.5%"
        height="210.2%"
        x="-56.2%"
        y="-55.1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx={6} dy={6} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation={11}
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.51372549 0 0 0 0 0.792156863 0 0 0 0 0.529411765 0 0 0 0.65 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <rect
        id="Illustration1_svg__d"
        width={127}
        height={30}
        x={0}
        y={0}
        rx={5}
      />
      <rect
        id="Illustration1_svg__f"
        width={127}
        height={30}
        x={0}
        y={0}
        rx={5}
      />
      <path
        id="Illustration1_svg__b"
        d="M24 8a8 8 0 018-8h315a8 8 0 018 8v74.337h669V994H0V82.337h24z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M-369-560H655V447H-369z" />
      <g transform="translate(-369 -119)">
        <use
          fill="#000"
          filter="url(#Illustration1_svg__a)"
          xlinkHref="#Illustration1_svg__b"
        />
        <use fill="#FFF" xlinkHref="#Illustration1_svg__b" />
      </g>
      <g transform="translate(-345 -7)">
        <rect width={976} height={228} fill="#FFF" fillOpacity={0.9} rx={5} />
        <path
          fill="#E8F5E9"
          fillRule="nonzero"
          d="M458.624 23.246c5.123-4.603 13.165-5.54 20.485-5.174 22.992 1.15 43.746 12.331 61.699 24.978 6.499 4.579 12.897 9.517 17.12 15.818 8.588 12.835 6.04 30.616-5.9 41.188-4.064 3.599-8.99 6.356-14.046 8.802-8.99 4.346-18.688 7.835-28.893 8.828-7.277.708-14.637.13-21.877-.855-20.21-2.761-40.05-8.865-56.678-19.313-7.296-4.581-14.17-10.277-17.176-17.692-3.006-7.416-1.082-16.803 6.365-21.19 3.08-1.815 9.396-4.908 22.614-13.129 13.218-8.22 11.17-17.672 16.287-22.261z"
        />
        <g transform="translate(361 18)">
          <use
            fill="#000"
            filter="url(#Illustration1_svg__c)"
            xlinkHref="#Illustration1_svg__d"
          />
          <use fill="#FFF" xlinkHref="#Illustration1_svg__d" />
          <circle cx={13.5} cy={15.5} r={5.5} fill="#FFF" stroke="#E5E5E5" />
          <path
            fill="#F2F2F2"
            d="M117 18a2 2 0 010 4H29a2 2 0 110-4h88zM69 9a2 2 0 110 4H29a2 2 0 110-4h40z"
          />
        </g>
        <g transform="translate(488 71)">
          <use
            fill="#000"
            filter="url(#Illustration1_svg__e)"
            xlinkHref="#Illustration1_svg__f"
          />
          <use fill="#FFF" xlinkHref="#Illustration1_svg__f" />
          <circle cx={13.5} cy={15.5} r={5.5} fill="#FFF" stroke="#E5E5E5" />
          <path
            fill="#F2F2F2"
            d="M117 18a2 2 0 010 4H29a2 2 0 110-4h88zM69 9a2 2 0 110 4H29a2 2 0 110-4h40z"
          />
        </g>
        <g
          filter="url(#Illustration1_svg__g)"
          transform="matrix(-1 0 0 1 521 27)"
        >
          <g fillRule="nonzero" transform="translate(43.384 45.928)">
            <rect
              width={16}
              height={56}
              x={18.17}
              y={-2}
              fill="#E3EAE5"
              opacity={0.633}
              rx={2}
              transform="rotate(-46 26.17 26)"
            />
            <path
              fill="#A6D8A9"
              d="M14.302.756l5.714 5.56c.498.498.686 1.188.565 1.831a2.085 2.085 0 012.029.537L50.004 36.08a2.09 2.09 0 010 2.956L41.58 47.46a2.09 2.09 0 01-2.956 0L11.229 20.066a2.09 2.09 0 01-.539-2.03 2.041 2.041 0 01-1.83-.564L3 11.922c3.26-2.228 5.53-3.983 6.811-5.265 1.282-1.281 2.779-3.248 4.49-5.901z"
            />
          </g>
          <path
            fill="#A6D8A9"
            fillRule="nonzero"
            d="M31 0c17.12 0 31 13.88 31 31 0 17.12-13.88 31-31 31C13.88 62 0 48.12 0 31 0 13.88 13.88 0 31 0zm0 10c-11.598 0-21 9.402-21 21s9.402 21 21 21 21-9.402 21-21-9.402-21-21-21z"
          />
          <circle cx={31} cy={31} r={21} fill="#FFF" opacity={0.5} />
        </g>
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M490.5 68a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm.684-25.998c4.694.098 8.56 3.966 8.803 8.806.13 2.576-.744 5.024-2.46 6.892a8.983 8.983 0 01-4.313 2.61v2.403c0 1.263-.991 2.287-2.214 2.287-1.223 0-2.214-1.024-2.214-2.287V58.31c0-1.263.991-2.287 2.214-2.287a4.463 4.463 0 003.316-1.473 4.782 4.782 0 001.25-3.506c-.123-2.457-2.087-4.42-4.47-4.47-2.312-.052-4.305 1.698-4.624 4.062-.03.218-.045.44-.045.662 0 1.263-.99 2.287-2.213 2.287S482 52.562 482 51.3c0-.433.03-.868.087-1.294.629-4.657 4.54-8.1 9.097-8.003z"
        />
      </g>
    </g>
  </svg>
);

export default SvgIllustration1;
