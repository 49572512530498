/*
 * Messages
 */

export const LABEL_OTHER_INCOME_HOUSEHOLD =
  "Montant des revenus mensuels nets (avant le prélèvement de l'impôt)";
export const LABEL_OTHER_INCOME =
  'Montant des autres revenus (hors revenu du co-emprunteur si existant)';
export const LABEL_MONTHLY_RENT_AMOUNT = 'Loyer ou prêt résidence principale';
export const LABEL_HOUSEHOLD_CHARGES = 'Charges du foyer';
export const LABEL_OTHER_CREDITS = 'Autres crédits et LLD (Auto, Carte, LOA…)';
export const LABEL_ALIMONY_PAID = 'Pensions alimentaires versées';
export const LABEL_OTHER_CHARGES =
  'Autres charges (loyer ou prêt résidence secondaire, frais de garde enfants…)';
export const HOUSEHOLD_RENT = 'Autres revenus du foyer';
export const PLACEHOLDER_TOTAL_SUM = 'Somme totale';
export const SYMBOL_EUROS_AND_MONTH = '€ / mois';
export const LABEL_BUTTON_NONE = 'Aucun';
