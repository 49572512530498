const SvgJob: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 21.5A2.5 2.5 0 011.5 19V9A2.5 2.5 0 014 6.5h3.5v-.611C7.5 4.563 8.625 3.5 10 3.5h4c1.375 0 2.5 1.063 2.5 2.389V6.5H20A2.5 2.5 0 0122.5 9v10a2.5 2.5 0 01-2.5 2.5H4zm3.5-14H4A1.5 1.5 0 002.5 9v10A1.5 1.5 0 004 20.5h3.5v-13zm8 0h-7v13h7v-13zm4.5 0h-3.5v13H20a1.5 1.5 0 001.5-1.5V9A1.5 1.5 0 0020 7.5zm-6-3h-4c-.835 0-1.5.628-1.5 1.389V6.5h7v-.611c0-.76-.665-1.389-1.5-1.389z"
    />
  </svg>
);

export default SvgJob;
