import styled, { css } from 'styled-components';
import { SkinType } from '@types';
import { AdviceIcon } from '../Icons';

export const Wrapper = styled.div<{
  isVisible: boolean;
}>`
  overflow: hidden;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  max-height: ${({ isVisible }) => (isVisible ? '999rem' : '0')};
  transition: opacity 0.2s, max-height 0.2s;
  max-width: 60rem;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_s} 0;
`;

const getSkinnedBackgroundColor = ({
  theme,
  skin,
}: {
  theme: any;
  skin: any;
}) => {
  switch (skin) {
    case 'help':
      return theme.variables.colors.chartColors.dark_700;
    case 'warning':
      return theme.variables.colors.chartColors.yellow_300;
    case 'info':
      return theme.vendor.colors.lightest;
    case 'deleteCustomerInfo':
      return theme.vendor.colors.lightest;
    case 'advice':
      return theme.variables.colors.chartColors.blue_100;
    case 'primary':
      return theme.variables.colors.chartColors.white;
    case 'caution':
      return theme.variables.colors.chartColors.grey_50;
    default:
      return theme.variables.colors.chartColors.white;
  }
};

const getSkinnedCss = ({ skin = 'advice' }) => {
  if (skin === 'help') {
    return css`
      color: ${({ theme }) => theme.variables.colors.chartColors.white};
      font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
      line-height: 1.6rem;
      text-align: center;
      padding: 1.2rem ${({ theme }) =>
        theme.variables.layout.spacing.spacing_xs}; 1.2rem ${({ theme }) =>
      theme.variables.layout.spacing.spacing_xs};;
    `;
  }
  if (skin === 'primary') {
    return css`
      color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
      font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
      line-height: 1.6rem;
      text-align: center;
      padding: 0.1rem 0.1rem 0.1rem 0.1rem;
    `;
  }
  if (skin === 'deleteCustomerInfo') {
    return css`
      color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
      font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
      line-height: 1.6rem;
      text-align: center;
      padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s}
        ${({ theme }) => theme.variables.layout.spacing.spacing_s};
    `;
  }

  return css`
    color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
    font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
    line-height: ${({ theme }) => theme.variables.layout.spaces.medium};
    padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s}
      ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  `;
};

export const StyledWrapperWarningMessage = styled.div<{
  skin: SkinType;
  hasArrowDown: boolean;
  isVisible: boolean;
}>`
  ${(skin) => getSkinnedCss(skin)}
  position: relative;
  background-color: ${getSkinnedBackgroundColor};
  border-radius: ${({ theme }) =>
    theme.variables.layout.borderRadius.radius_s};;
  justify-content: center;
  align-items: flex-start;
  ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  margin-bottom: ${({ hasArrowDown, theme }) =>
    hasArrowDown ? theme.variables.layout.spacing.spacing_xs : '0'};
  padding-top: ${({ isVisible }) => (isVisible ? 'auto' : '0')};
  padding-bottom: ${({ isVisible }) => (isVisible ? 'auto' : '0')};
  padding-left: ${({ theme }) => theme.variables.layout.spaces.medium};
  padding-right: ${({ theme }) => theme.variables.layout.spaces.medium};;
  transition: padding 0.2s;

  &::after {
    content: '';
    display: ${({ hasArrowDown }) => (hasArrowDown ? 'block' : 'none')};
    position: absolute;
    bottom: -0.7rem;
    transform: translateX(-50%);
    left: 50%;
    width: 0;
    height: 0;
    margin: auto;
    border-right: ${({ theme }) =>
      theme.variables.layout.spacing.spacing_xs}; solid transparent;
    border-left: ${({ theme }) =>
      theme.variables.layout.spacing.spacing_xs}; solid transparent;
    border-top: ${({ theme }) =>
      theme.variables.layout.spacing
        .spacing_xs}; solid ${getSkinnedBackgroundColor};
  }
`;

export const StyledWarningMessage = styled.div`
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  letter-spacing: 0;
  align-self: center;
  width: 100%;
`;

export const StyledAdviceIcon = styled(AdviceIcon)<{ skin: SkinType }>`
  color: ${({ theme, skin }) => {
    switch (skin) {
      case 'info':
        return theme.vendor.colors.primary;
      case 'advice':
        return theme.variables.colors.interaction.emphasis_700;
      case 'warning':
        return theme.variables.colors.interaction.warning_700;
      case 'primary':
        return theme.variables.colors.chartColors.green_700;
      default:
        return theme.variables.colors.chartColors.dark_700;
    }
  }};
  margin-top: -0.3rem;
  width: 2.5rem;
  min-width: 2.5rem;
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const Title = styled.p<{
  color?: string;
}>`
  color: ${({ theme, color }) =>
    color === 'primary'
      ? theme.vendor.colors.title
      : theme.variables.colors.chartColors.dark};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  font-weight: bold;
  letter-spacing: 0;
  margin: 0;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
`;
