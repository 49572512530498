const SvgSortZA: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 18.39a.5.5 0 00.492-.41l.008-.09V7.097l3.146 3.146a.5.5 0 00.638.058l.07-.058a.5.5 0 00.057-.638l-.057-.069-3.812-3.811a.767.767 0 00-.998-.075l-.086.075-3.812 3.811a.5.5 0 00.638.765l.07-.058L17.5 7.097V17.89a.5.5 0 00.5.5zM7.333 7.056a.5.5 0 00.09-.992l-.09-.008H2a.5.5 0 00-.09.992l.09.008h5.333zM10 12.39a.5.5 0 00.09-.992L10 11.39H2a.5.5 0 00-.09.991l.09.009h8zm2.667 5.333a.5.5 0 00.09-.992l-.09-.008H2a.5.5 0 00-.09.992l.09.008h10.667z"
    />
  </svg>
);

export default SvgSortZA;
