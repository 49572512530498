import { Icons } from '@components';
import {
  WrapperDesktop,
  WrapperUploadZoneIcon,
  WrapperDestopLabel,
  WrapperUploadZoneLabel,
  WrapperUploadZoneLabelBis,
} from './styles';

export interface IUploadZoneDesktopProps {
  label: string;
  labelBis: string;
  isDisabled: boolean;
}

const UploadZoneDesktop = ({
  label,
  labelBis,
  isDisabled = false,
}: IUploadZoneDesktopProps): React.ReactElement => {
  return (
    <WrapperDesktop isDisabled={isDisabled}>
      <WrapperUploadZoneIcon>
        <Icons.UploadDocumentIcon />
      </WrapperUploadZoneIcon>
      <WrapperDestopLabel>
        <WrapperUploadZoneLabel>{label}</WrapperUploadZoneLabel>
        <WrapperUploadZoneLabelBis>{labelBis}</WrapperUploadZoneLabelBis>
      </WrapperDestopLabel>
    </WrapperDesktop>
  );
};

export default UploadZoneDesktop;
