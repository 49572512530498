import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IState } from './errorTypes';

const getInitialState = () => {
  return { state: {} } as { state: IState };
};

// Initial State
export const initialState = getInitialState();

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(errorState, action: PayloadAction<IState>) {
      errorState.state = {
        ...(action?.payload ?? getInitialState().state),
      };
    },
    clearError(errorState) {
      errorState.state = getInitialState().state;
    },
  },
});

export const selectError = (errorState: { error: { state: IState } }) =>
  errorState.error.state;

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
