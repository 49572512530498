const SvgDownArrow: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 21a.869.869 0 01-.616-.255l-6.129-6.13a.871.871 0 111.232-1.23l4.642 4.641V3.871a.871.871 0 111.742 0l-.001 14.156 4.643-4.643a.871.871 0 111.232 1.232l-6.13 6.129-.007.007a.876.876 0 01-.065.058l.073-.065A.878.878 0 0112 21z"
    />
  </svg>
);

export default SvgDownArrow;
