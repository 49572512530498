import {
  StyledLink,
  StyledButton,
  StyledChevronLeftIcon,
  StyledChevronRightIcon,
  StyledDisabledLink,
} from './styles';

export interface ILinkProps {
  children?: React.ReactNode;
  className?: string;
  chevron?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  to?: string;
  isDisabled?: boolean;
}

const Link = ({
  children,
  className = '',
  chevron,
  onClick,
  to,
  isDisabled = false,
}: ILinkProps): React.ReactElement => {
  const content = (
    <>
      {chevron === 'left' && <StyledChevronLeftIcon />}
      {children}
      {chevron === 'right' && <StyledChevronRightIcon />}
    </>
  );
  if (isDisabled) {
    return (
      <StyledDisabledLink className={'ph-link ' + className} to="#">
        {content}
      </StyledDisabledLink>
    );
  }
  if (onClick) {
    return (
      <StyledButton
        type="button"
        className={'ph-link ' + className}
        onClick={onClick}
      >
        {content}
      </StyledButton>
    );
  }

  return (
    <StyledLink className={'ph-link ' + className} to={to ?? ''}>
      {content}
    </StyledLink>
  );
};

export default Link;
