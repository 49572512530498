import { StyledSentIconSwitchDevice, StyledSubtitle } from '../styles';
import {
  StyledBr,
  TextParagraph,
  WrapperContainer,
  WrapperIcon,
  WrapperSubtitle,
  WrapperText,
} from './styles';
import * as messages from './messages';

export interface IProps {}

const FinishUploadMobile: React.FC<IProps> = () => (
  <WrapperContainer>
    <WrapperIcon>
      <StyledSentIconSwitchDevice />
    </WrapperIcon>
    <WrapperSubtitle>
      <StyledSubtitle>{messages.TITLE_UPLOAD_FINISH}</StyledSubtitle>
    </WrapperSubtitle>
    <TextParagraph>
      <WrapperText>{messages.TEXT_UPLOAD_FINISH_PC}</WrapperText>
      <StyledBr />
      <WrapperText>{messages.TEXT_UPLOAD_FINISH_MOBILE}</WrapperText>
    </TextParagraph>
  </WrapperContainer>
);

export default FinishUploadMobile;
