import styled, { css } from 'styled-components';
import { CloseIcon, CrossSymphonyIcon } from '../Icons';
import { MAX_WIDTH_SIZE } from '@utils';

export const Background = styled.div<{
  isVisible: boolean;
}>`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  z-index: 6;
  padding: 10rem 0;
  overflow: auto;
`;

export const StyledWrapper = styled.div`
  display: flex;
  position: relative;
  width: 72.8rem;
  background: ${({ theme }) => theme.variables.colors.chartColors.white};
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
  flex-direction: column;
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_l};
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    padding: ${({ theme }) => theme.variables.layout.spacing.spacing_s} 2rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: fixed;
    bottom: 0px;
    max-height: 100vh;
  }
`;

export const ButtonClose = styled.button<{
  isDisabled?: boolean;
}>`
  display: flex;
  border: none;
  background-color: transparent;
  align-self: flex-end;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  position: absolute;
  top: 1.9rem;
  right: 1.9rem;
  padding: 0;
  z-index: 7;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    border-radius: 50%;
    box-shadow: -1.5px 9px 7px -1px #dddddd;
  }
`;

const applyCloseStyles = (isDisabled = false) => css`
  color: ${({ theme }) =>
    isDisabled
      ? theme.variables.colors.chartColors.grey_100
      : theme.variables.colors.chartColors.dark_700};
  width: 2.4rem;
  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    padding: 0.1rem;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)<{ isDisabled?: boolean }>`
  ${({ isDisabled }: { isDisabled?: boolean }) => applyCloseStyles(isDisabled)}
`;

export const StyledCloseMobileIcon = styled(CrossSymphonyIcon)<{
  isDisabled?: boolean;
}>`
  ${({ isDisabled }: { isDisabled?: boolean }) => applyCloseStyles(isDisabled)}
`;
