import { Title } from '@components';
import { WrapperResultsSimulation, ColoredTitle } from './styles';
import * as messages from './messages';
import { ResultsSimulation as ResultsSimulationContainer } from '@containers';
import { useLocation } from 'react-router-dom';

export const ResultsSimulation: React.FC = () => {
  const location = useLocation();
  const nbEmprunteurs = location.state?.nbEmprunteurs;

  return (
    <WrapperResultsSimulation>
      <Title>
        {messages.LAYOUT_TITLE}{' '}
        {nbEmprunteurs === 1 && (
          <ColoredTitle>{messages.LAYOUT_TITLE_COLORED}</ColoredTitle>
        )}
        {nbEmprunteurs === 2 && (
          <ColoredTitle>{messages.LAYOUT_TITLE_COLORED_2}</ColoredTitle>
        )}
      </Title>

      <ResultsSimulationContainer />
    </WrapperResultsSimulation>
  );
};

export default ResultsSimulation;
