import ShowDecision from './ShowDecision';
import { useEffect } from 'react';
import {
  selectConfiguration,
  updateConfiguration,
  useAppDispatch,
  useAppSelector,
  useLazyGetScoreQuery,
} from '@store';

const Choice: React.FC = () => {
  const dispatch = useAppDispatch();
  const [triggerGetScore, resultGetScore] = useLazyGetScoreQuery();
  const { scoreLight } = useAppSelector(selectConfiguration);

  useEffect(() => {
    if (!resultGetScore?.data) {
      return;
    }
    dispatch(
      updateConfiguration({
        scoreLight: resultGetScore.data?.score,
      })
    );
  }, [resultGetScore]);

  return <ShowDecision scoreLight={scoreLight} />;
};
export default Choice;
