import { useFormContext } from 'react-hook-form';

const useValidateInput = () => {
  const { setValue } = useFormContext();
  const shouldValidateInput = (name: string, value: any) => {
    setValue(name, value, {
      shouldValidate: true,
    });
  };

  return {
    shouldValidateInput,
  };
};

export default useValidateInput;
