import { Button } from '@components';
import styled from 'styled-components';

export const DetailResultSimulationWrapper = styled.div`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const StyledButton = styled(Button)`
  margin-top: 20px;
  height: 48px;
`;
