import StyledMessage from './styles';

interface IErrorMessageProps {
  children?: React.ReactNode;
  className?: string;
}

const ErrorMessage = ({
  children,
  className = '',
}: IErrorMessageProps): React.ReactElement => (
  <StyledMessage className={'ph-error-message ' + className}>
    {children}
  </StyledMessage>
);

export default ErrorMessage;
