import { DropdownLabel, DropdownItemStyled } from './styles';

export interface Props {
  label: string;
  action: () => void;
  children?: React.ReactNode;
}

const DropdownItem: React.FC<Props> = ({ label, action, children }) => (
  <DropdownItemStyled onClick={() => action()}>
    {children}
    <DropdownLabel>{label}</DropdownLabel>
  </DropdownItemStyled>
);

export default DropdownItem;
