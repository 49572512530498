const SvgCheckCircle: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.5 11.154a.75.75 0 011.5 0v.852a10 10 0 11-5.93-9.14.75.75 0 01-.61 1.37 8.5 8.5 0 105.04 7.77v-.852zm-8.5 1.65l8.72-8.728a.75.75 0 011.06 1.06l-9.25 9.258a.75.75 0 01-1.06 0L8.695 11.62a.75.75 0 011.06-1.06L12 12.802z"
    />
  </svg>
);

export default SvgCheckCircle;
