import * as messages from './messages';
import { ReserveText } from './styles';

export const Reserve: React.FC = () => {
  return (
    <>
      <ReserveText>{messages.RESERVE_ETUDE}</ReserveText>
    </>
  );
};

export default Reserve;
