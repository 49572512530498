import { Button } from '@components';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
  padding: 4.5rem 0 1.5rem 0;
  font-weight: bold;
  font-size: 1.25rem;
`;

export const TextWrapper = styled.div`
  font-size: 1rem;
  margin-bottom: 15rem;
`;

export const StyledConfirmButton = styled(Button)`
  height: 3rem;
  font-size: 1rem;
  background-color: #28883c;
  margin-bottom: 1rem;
`;

export const StyledCancelButton = styled(Button)`
  height: 3rem;
  font-size: 1rem;
  border-color: #28883c;
  color: #28883c;
`;
