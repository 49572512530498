import { useState } from 'react';
import { detectMobileDevice } from '@utils';
import Tooltip from '../Tooltip';
import {
  StyledWrapper,
  StyledInfoOutlinesIcon,
  TooltipContainer,
  StyledButton,
} from './styles';

export interface IInformationTooltipProps {
  children?: React.ReactNode;
  className?: string;
  iconSize?: number;
  tooltipWidth?: number;
  isToolTipDisabled?: boolean;
  onClickOpenModal?: () => void;
}

const InformationTooltip = ({
  children,
  className = '',
  iconSize = 1.6,
  tooltipWidth = 32,
  isToolTipDisabled,
  onClickOpenModal,
}: IInformationTooltipProps): React.ReactElement => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const isMobileDevice = detectMobileDevice();
  return (
    <StyledWrapper
      className={'ph-information-tooltip ' + className}
      onMouseLeave={() => {
        if (!isMobileDevice) {
          setTooltipVisible(false);
        }
      }}
    >
      <TooltipContainer width={tooltipWidth} iconSize={iconSize}>
        <Tooltip isVisible={isTooltipVisible} skin="help">
          {children}
        </Tooltip>
      </TooltipContainer>
      <StyledButton
        type="button"
        onClick={() => {
          if (isMobileDevice && !isToolTipDisabled) {
            setTooltipVisible(!isTooltipVisible);
          }
          if (isToolTipDisabled && onClickOpenModal) {
            onClickOpenModal();
          }
        }}
        onMouseEnter={() => {
          if (!isMobileDevice) {
            setTooltipVisible(true);
          }
          if (isToolTipDisabled) {
            setTooltipVisible(false);
          }
        }}
      >
        <StyledInfoOutlinesIcon iconSize={iconSize} />
      </StyledButton>
    </StyledWrapper>
  );
};

export default InformationTooltip;
