const SvgContract: React.FC<{
  className?: string;
  width?: number;
  height?: number;
}> = ({ className, width = 22, height = 22 }) => (
  <svg
    width={width + 'px'}
    height={height + 'px'}
    viewBox="0 0 24 32"
    className={className}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.3333333,0.8004002 C19.3333333,0.36018009 18.9750629,0 18.5371768,0 L6.01098147,0 C5.79867307,0 5.59963395,0.08004002 5.44040265,0.24012006 L0.238846946,5.46940137 C0.0796156486,5.6161414 0,5.82958146 0,6.04302151 L0,25.8662665 C0,26.3064866 0.358270419,26.6666667 0.796156486,26.6666667 L18.5239076,26.6666667 C18.9617936,26.6666667 19.3200641,26.3064866 19.3200641,25.8662665 L19.3200641,15.1409038 C19.3200641,15.0875438 19.3200641,15.0475238 19.3067948,15.0075038 L19.3333333,0.8004002 Z M17.7410204,17.3686843 L17.7410204,25.0658663 L1.60558225,25.0658663 L1.60558225,6.84342171 L5.891558,6.84342171 C6.32944406,6.84342171 6.68771448,6.48324162 6.68771448,6.04302151 L6.68771448,1.6008004 L17.7410204,1.6008004 L17.7277511,17.2086043 C17.7277511,17.2619643 17.7277511,17.3153243 17.7410204,17.3686843 Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.5165866,19.1295648 L4.81674674,19.1562448 C4.39212995,19.1562448 4.06039808,19.5164249 4.06039808,19.956645 C4.06039808,20.3968651 4.40539922,20.7570452 4.81674674,20.7570452 L14.5165866,20.7303652 C14.9412034,20.7303652 15.2729353,20.3701851 15.2729353,19.929965 C15.2862045,19.4764049 14.9412034,19.1295648 14.5165866,19.1295648 Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.84328529,16.2214441 L14.5165866,16.194764 C14.9279341,16.194764 15.2729353,15.834584 15.2729353,15.3943638 C15.2729353,14.9541437 14.9279341,14.5939636 14.5165866,14.5939636 L4.84328529,14.6206437 C4.43193777,14.6206437 4.08693663,14.9808237 4.08693663,15.4210439 C4.08693663,15.861264 4.4186685,16.2214441 4.84328529,16.2214441 Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.5298559,9.81824245 L4.84328529,9.81824245 C4.4186685,9.81824245 4.07366735,10.1784225 4.07366735,10.6186427 C4.07366735,11.0588628 4.4186685,11.4190429 4.84328529,11.4190429 L14.5165866,11.4190429 C14.9412034,11.4190429 15.2862045,11.0588628 15.2862045,10.6186427 C15.2862045,10.1784225 14.9412034,9.81824245 14.5298559,9.81824245 Z"
    />
  </svg>
);

export default SvgContract;
