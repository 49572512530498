import Styled from 'styled-components';

export const StyledContainer = Styled.div`
  width: 100%;
  margin-top: 0.6rem; /* borders width */
`;

export const StyledHeaderContainer = Styled.div<{
  toolTipHeight: number;
}>`
  z-index: 10;
  position: relative;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};

  margin-top: ${({ toolTipHeight }) => `-${toolTipHeight / 2}px`};
`;

export const StyledCard = Styled.div<{
  toolTipHeight: number;
}>`
  margin-top: ${({ toolTipHeight, theme }) =>
    `calc(${toolTipHeight / 2}px + ${
      theme.variables.layout.spacing.spacing_m
    })`};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  border-radius: ${({ theme }) => theme.variables.layout.borderRadius.radius_l};
  padding: ${({ theme }) =>
    `0 ${theme.variables.layout.spacing.spacing_s} ${theme.variables.layout.spacing.spacing_m} ${theme.variables.layout.spacing.spacing_s}`};
  z-index: 1;
  position: relative;
  border: 0.2rem solid ${({ theme }) => theme.vendor.colors.light};
  background-color: ${({ theme }) => theme.variables.colors.chartColors.white};
`;
