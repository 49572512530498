import { BlockEmprunteur, BlockEmprunteurHead } from './styles';

import * as messages from './messages';

export interface IProps {
  blockValue: any;
  ageBorrower: number;
  ageCoBorrower: number;
}

export const CoutMensuelAssuranceFacultative: React.FC<IProps> = ({
  blockValue,
  ageBorrower,
  ageCoBorrower,
}) => {
  const calculateTotalDimcD = () => {
    return (
      blockValue?.borrower?.monthlyInsuranceCost?.dimc +
      blockValue?.coborrower?.monthlyInsuranceCost?.d
    ).toFixed(2);
  };

  const calculateTotalDDimc = () => {
    return (
      blockValue?.coborrower?.monthlyInsuranceCost?.dimc +
      blockValue?.borrower?.monthlyInsuranceCost?.d
    ).toFixed(2);
  };

  const calculateTotalDimD = () => {
    return (
      blockValue?.borrower?.monthlyInsuranceCost?.dim +
      blockValue?.coborrower?.monthlyInsuranceCost?.d
    ).toFixed(2);
  };

  const calculateTotalDDim = () => {
    return (
      blockValue?.coborrower?.monthlyInsuranceCost?.dim +
      blockValue?.borrower?.monthlyInsuranceCost?.d
    ).toFixed(2);
  };

  return (
    <BlockEmprunteur>
      {
        <div>
          <BlockEmprunteurHead>{messages.POUR_EMPRUNTEUR}</BlockEmprunteurHead>
          {blockValue?.borrower?.monthlyInsuranceCost?.dimc && (
            <div>
              <p>{messages.DIMC}</p>
              <p>
                {blockValue?.borrower?.monthlyInsuranceCost?.dimc.toFixed(2)}€
              </p>
            </div>
          )}
          {blockValue?.borrower?.monthlyInsuranceCost?.dim && (
            <div>
              <p>{messages.DIM}</p>
              <p>
                {blockValue?.borrower?.monthlyInsuranceCost?.dim?.toFixed(2)}€
              </p>
            </div>
          )}
          <div>
            <p>{messages.D}</p>
            <p>{blockValue?.borrower?.monthlyInsuranceCost?.d?.toFixed(2)}€</p>
          </div>

          {(blockValue?.coborrower?.monthlyInsuranceCost?.dimc ||
            blockValue?.coborrower?.monthlyInsuranceCost?.dim ||
            blockValue?.coborrower?.monthlyInsuranceCost?.d) &&
            ageBorrower !== ageCoBorrower && (
              <>
                <BlockEmprunteurHead>
                  {messages.POUR_COEMPRUNTEUR}
                </BlockEmprunteurHead>

                {blockValue?.coborrower?.monthlyInsuranceCost?.dimc && (
                  <div>
                    <p>{messages.DIMC}</p>
                    <p>
                      {(
                        blockValue?.coborrower?.monthlyInsuranceCost?.dimc ?? 0
                      ).toFixed(2)}
                      €
                    </p>
                  </div>
                )}
                {blockValue?.coborrower?.monthlyInsuranceCost?.dim && (
                  <div>
                    <p>{messages.DIM}</p>
                    <p>
                      {blockValue?.coborrower?.monthlyInsuranceCost?.dim?.toFixed(
                        2
                      )}
                      €
                    </p>
                  </div>
                )}
                <div>
                  <p>{messages.D}</p>
                  <p>
                    {blockValue?.coborrower?.monthlyInsuranceCost?.d?.toFixed(
                      2
                    )}
                    €
                  </p>
                </div>
              </>
            )}

          {(blockValue?.coborrower?.monthlyInsuranceCost?.dimc ||
            blockValue?.coborrower?.monthlyInsuranceCost?.dim ||
            blockValue?.coborrower?.monthlyInsuranceCost?.d) && (
            <>
              <BlockEmprunteurHead>
                {messages.POUR_EMPRUNTEUR}{' '}
                {blockValue?.coborrower &&
                  (blockValue.coborrower?.monthlyInsuranceCost?.dimc ||
                    blockValue.coborrower?.monthlyInsuranceCost?.dim ||
                    blockValue.coborrower?.monthlyInsuranceCost?.d) && (
                    <>
                      {messages.ET} {messages.COEMPRUNTEUR}
                    </>
                  )}
              </BlockEmprunteurHead>

              {blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.dimc && (
                <div>
                  <p>
                    {' '}
                    {messages.DIMC} + {messages.DIMC}
                  </p>
                  <p>
                    {blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.dimc.toFixed(
                      2
                    )}
                    €
                  </p>
                </div>
              )}

              {!blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.dimc &&
                blockValue?.borrower?.monthlyInsuranceCost?.dimc && (
                  <div>
                    <p>
                      {' '}
                      {messages.DIMC} + {messages.D}
                    </p>
                    <p>{calculateTotalDimcD()}€</p>
                  </div>
                )}

              {!blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.dimc &&
                !blockValue?.borrower?.monthlyInsuranceCost?.dimc &&
                blockValue?.coborrower?.monthlyInsuranceCost?.dimc && (
                  <div>
                    <p>
                      {' '}
                      {messages.D} + {messages.DIMC}
                    </p>
                    <p>{calculateTotalDDimc()}€</p>
                  </div>
                )}

              {blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.dim && (
                <div>
                  <p>
                    {messages.DIM} + {messages.DIM}
                  </p>
                  <p>
                    {blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.dim.toFixed(
                      2
                    )}
                    €
                  </p>
                </div>
              )}

              {!blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.dim &&
                blockValue?.borrower?.monthlyInsuranceCost?.dim && (
                  <div>
                    <p>
                      {messages.DIM} + {messages.D}
                    </p>
                    <p>{calculateTotalDimD()}€</p>
                  </div>
                )}

              {!blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.dim &&
                !blockValue?.borrower?.monthlyInsuranceCost?.dim &&
                blockValue?.coborrower?.monthlyInsuranceCost?.dim && (
                  <div>
                    <p>
                      {messages.D} + {messages.DIM}
                    </p>
                    <p>{calculateTotalDDim()}€</p>
                  </div>
                )}

              <div>
                <p>
                  {' '}
                  {messages.D} + {messages.D}
                </p>
                <p>
                  {blockValue?.borrowerCoborrower?.monthlyInsuranceCost?.d?.toFixed(
                    2
                  )}
                  €
                </p>
              </div>
            </>
          )}
        </div>
      }
    </BlockEmprunteur>
  );
};

export default CoutMensuelAssuranceFacultative;
