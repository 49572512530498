/*
 * Messages Validation Modal
 *
 *
 */

export const TITLE = 'Signature électronique';
export const PROVISIONAL_CARD_NUMBER_FIELD_LABEL = 'N° de carte provisoire';
export const ELECTRONIC_SIGN = 'Signer';
export const INFOCARD_HEADER_FIRST_LINE =
  'Ces informations sont indispensables et ne pourront plus être modifiées.';
export const INFOCARD_HEADER_SECOND_LINE_BOLD =
  'Merci de les vérifier auprès de votre client ';
export const INFOCARD_HEADER_SECOND_LINE_END =
  'avant de passer à la signature.';
export const INFORMATION_TOOLTIP_MSG =
  'En cliquant sur « Signature papier », le dossier s’imprimera. En cliquant sur « Signature électronique », vous signerez via cet appareil.';
export const ELECTRONIC_SIGNATURE_TITLE =
  'Vous avez sélectionné la Signature électronique';
export const ELECTRONIC_SIGNATURE_TITLE_VAD =
  'Vous avez sélectionné la Signature électronique à distance';
export const ELECTRONIC_SIGNATURE_TEXT_1 =
  'Si la page de signature ne s’est pas ouverte automatiquement, merci de ';
export const ELECTRONIC_SIGNATURE_TEXT_1_VAD =
  "Si la page de confirmation de l'envoi du mail de signature électronique à distance ne s'est pas ouverte automatiquement, merci de ";
export const ELECTRONIC_SIGNATURE_BUTTON_RETRY = 'cliquer ici directement';
export const ELECTRONIC_SIGNATURE_TEXT_2 =
  ' pour l’afficher. Sinon, basculez en ';
export const ELECTRONIC_SIGNATURE_BUTTON_BACK_TO_MANUAL = 'signature papier.';
export const ELECTRONIC_SIGNATURE_BUTTON_BACK_TO_MANUAL_VAD =
  'signature papier à distance.';
export const ELECTRONIC_SIGNATURE_TEXT_3 =
  'Vous avez effectué la signature électronique avec succès ?';
export const ELECTRONIC_SIGNATURE_TEXT_4 = 'Vous pouvez ';
export const ELECTRONIC_SIGNATURE_TEXT_5 = 'retourner à l’accueil.';
