import { Badge, Title } from '@components';
import {
  StyledCard,
  StyledDescriptionList,
  StyledTextBadge,
  StyledWrapperBadge,
  StyledWrapperIcon,
  StyledWrapperTitle,
} from './styles';
import { DescriptionListVariant, IDescriptionList } from '@types';

export interface ISummaryCardProps {
  Icon: React.ReactElement;
  title: string;
  list: IDescriptionList[];
  texteBadge?: string;
  legendeBadge?: string;
}

const SummaryCard = ({
  Icon,
  title,
  list,
  texteBadge,
  legendeBadge,
}: ISummaryCardProps) => {
  (list ?? []).forEach((element) => {
    element.isValueBold = true;
  });

  return (
    <StyledCard>
      <StyledWrapperTitle>
        <StyledWrapperIcon>{Icon}</StyledWrapperIcon>
        <Title level={2}>{title ?? ''}</Title>
      </StyledWrapperTitle>
      {texteBadge && (
        <StyledWrapperBadge>
          <Badge variant="light">
            <StyledTextBadge>{texteBadge}</StyledTextBadge>
            {legendeBadge ?? ''}
          </Badge>
        </StyledWrapperBadge>
      )}
      <StyledDescriptionList
        list={list}
        variant={DescriptionListVariant.SPLIT_BY_LINES}
      />
    </StyledCard>
  );
};

export default SummaryCard;
