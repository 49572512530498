const SvgIconSmartphone: React.FC<{ className?: string }> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={28}>
    <title>{'icone tablette'}</title>
    <g fill="none" fillRule="nonzero">
      <path
        fill="#268038"
        d="M2.75 28c-.733 0-1.375-.275-1.925-.825C.275 26.625 0 25.983 0 25.25V2.75C0 2.017.275 1.375.825.825 1.375.275 2.017 0 2.75 0h34.5c.733 0 1.375.275 1.925.825.55.55.825 1.192.825 1.925v22.5c0 .733-.275 1.375-.825 1.925-.55.55-1.192.825-1.925.825H2.75Zm3.1-1.55h28.3V1.55H5.85v24.9ZM4.3 1.55H2.75c-.333 0-.617.117-.85.35-.233.233-.35.517-.35.85v22.5c0 .333.117.617.35.85.233.233.517.35.85.35H4.3V1.55Zm31.4 0v24.9h1.55c.333 0 .617-.117.85-.35.233-.233.35-.517.35-.85V2.75c0-.333-.117-.617-.35-.85a1.157 1.157 0 0 0-.85-.35H35.7Zm2.75 0H35.7h2.75Zm-36.9 0H4.3 1.55Z"
      />
      <g fill="#3A913F">
        <path d="M27.785 19.073c0 1.466-1.166 2.66-2.6 2.66h-11.37c-1.434 0-2.6-1.194-2.6-2.66v-6.785c0-1.466 1.166-2.66 2.6-2.66h1.627l.56-.397.46-1.292c.244-.448.681-.696 1.167-.696h3.669c.486 0 .948.273 1.19.745l.292.547 1.07-.596-.292-.547C23.096 6.522 22.246 6 21.298 6h-3.645c-.947 0-1.822.547-2.26 1.392L15.03 8.41h-1.214C11.7 8.41 10 10.15 10 12.313v6.785C10 21.26 11.7 23 13.815 23h11.37C27.3 23 29 21.26 29 19.098v-5.22h-1.215v5.195Z" />
        <path d="M19.5 11a4.512 4.512 0 0 0-4.5 4.5c0 2.476 2.024 4.5 4.5 4.5s4.5-2.024 4.5-4.5-2.024-4.5-4.5-4.5Zm0 7.81a3.302 3.302 0 0 1-3.31-3.31 3.302 3.302 0 0 1 3.31-3.31 3.302 3.302 0 0 1 3.31 3.31 3.302 3.302 0 0 1-3.31 3.31ZM28.615 9.395V7h-1.23v2.395H25v1.235h2.385V13h1.23v-2.37H31V9.395z" />
      </g>
    </g>
  </svg>
);
export default SvgIconSmartphone;
