import * as messages from './messages';
import { Input, Title, Select } from '@components';
import { FormWrapper, ItemWrapper, TitleWrapper } from './styles';
import { useFormContext, Controller } from 'react-hook-form';
import useInformationsForm from '../useInformationsForm';
import {
  selectConfiguration,
  selectPerson,
  useAppSelector,
  personTypes,
} from '@store';
import { isPersonFieldDisabled, validateNationalityEvidence } from '@utils';
import { EvidenceType } from '@types';
import { useValidateInput } from '@hooks';
import { useEffect } from 'react';

interface IProps {
  role: string;
}

const InformationsId: React.FC<IProps> = ({ role }) => {
  const configuration = useAppSelector(selectConfiguration);
  const { listTypePieceIdentite } = {
    ...configuration,
  };

  const { control, trigger, getValues } = useFormContext();
  const { shouldValidateInput } = useValidateInput();

  const persons = useAppSelector(selectPerson);
  const person =
    role === personTypes.RoleCd.BORROWER
      ? persons?.emprunteur
      : persons?.coemprunteur;
  const suffixRole = '_' + role;

  const { onValidateEvidenceNumber } = useInformationsForm({ role });
  useEffect(() => {
    if (!person?.identity) {
      return;
    }
    shouldValidateInput(
      'evidenceSubTypeJDID' + suffixRole,
      person?.identity?.evidenceSubTypeCd
    );
    shouldValidateInput(
      'evidenceNb' + suffixRole,
      person?.identity?.evidenceNb
    );
  }, [person]);

  return (
    <FormWrapper>
      {/* Type de pièce */}
      <TitleWrapper>
        <Title level={2}>{messages.CONTAINER_PROOF_OF_IDENTITY}</Title>
      </TitleWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          name={'evidenceSubTypeJDID' + suffixRole}
          defaultValue={person?.identity?.evidenceSubTypeCd}
          rules={{
            required: true,
            validate: (value: string) =>
              validateNationalityEvidence({
                nationalityCd: getValues('inputNationality' + suffixRole),
                evidenceSubTypeJDID: value,
              }),
          }}
          render={({
            field: { onChange, value, ref, onBlur, name },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Select
              label={messages.LABEL_TYPE_PIECE}
              name={name}
              onChange={(newValue) => {
                onChange(newValue);
                trigger('inputNationality' + suffixRole);
              }}
              value={value}
              placeholder={messages.LABEL_PLACEHOLDER}
              options={listTypePieceIdentite ?? []}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              isDisabled={isPersonFieldDisabled(
                person?.identity?.evidenceSubTypeCd,
                person,
                EvidenceType.JDID
              )}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </ItemWrapper>

      {/* Numéro pièce */}
      <ItemWrapper>
        <Controller
          control={control}
          name={'evidenceNb' + suffixRole}
          defaultValue={person?.identity?.evidenceNb}
          rules={{
            required: true,
            minLength: 4,
            validate: onValidateEvidenceNumber,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              name={name}
              label={messages.LABEL_NUMBER_USER}
              placeholder={messages.LABEL_NUMBER_USER}
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              isSubmitted={isSubmitted}
              onBlur={onBlur}
              inputRef={ref}
              onChange={onChange}
              maxLength={14}
              isDisabled={isPersonFieldDisabled(
                person?.identity?.evidenceNb,
                person,
                EvidenceType.JDID
              )}
            />
          )}
        />
      </ItemWrapper>
    </FormWrapper>
  );
};

export default InformationsId;
