import { createApi, FetchArgs } from '@reduxjs/toolkit/query/react';
import { getDynamicQueriesWithRetries, IEndPoints } from '../rtkQueryUtils';

const authGwApiSlice = createApi({
  reducerPath: 'authGwApi',
  baseQuery: getDynamicQueriesWithRetries(IEndPoints.authgw),
  endpoints: (builder) => ({
    getLogout: builder.query<void, void>({
      query: (): FetchArgs => {
        const theQuery: { url: string; headers?: any; credentials: any } = {
          url: `/logout`,
          credentials: 'include',
        };
        return theQuery;
      },
    }),
  }),
});
const endpointShouldGenerateError = (endpoint: string): boolean => {
  return !['getLogout'].includes(endpoint);
};

export const authgwEndpointsToNotCheck = [endpointShouldGenerateError];

export default authGwApiSlice;
export const { useLazyGetLogoutQuery } = authGwApiSlice;
export const authGwApiReducer = authGwApiSlice.reducer;
export const authGwApiMiddleware = authGwApiSlice.middleware;
