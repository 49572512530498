import * as messages from './messages';
import { TitleGaranties } from './styles';
import { DetailGaranties as DetailGarantiesContainer } from '@containers';

export const DetailGaranties: React.FC = () => {
  return (
    <>
      <TitleGaranties>{messages.DETAIL_PAGE_TITLE}</TitleGaranties>
      <DetailGarantiesContainer />
    </>
  );
};

export default DetailGaranties;
