import { Controller, useFormContext } from 'react-hook-form';
import * as messages from './messages';
import { Select, Input, Title } from '@components';
import {
  StyledCard,
  StyledCheckBox,
  WrapperInputDeferment,
  WrapperInputDepositAmount,
  WrapperInputCondition,
  WrapperInputLoanAmount,
  WrapperInputMaterialCategory,
  WrapperInputPricing,
  WrapperInputPurchaseAmount,
  WrapperInputTermNb,
  WrapperInputTypeLoan,
  WrapperPurchaseDeposit,
  WrapperInputBorrowers,
  StyledButtonCheck,
  IconPerson,
  IconPeople,
  WrapperBorrowers,
} from './styles';
import { CodesErreurTarification, MobileKeyboardType } from '@types';
import { IOfferFormProps } from './types';
import useOfferForm from './useOfferForm';
import { selectConfiguration, selectOffer, useAppSelector } from '@store';
import { useEffect, useState } from 'react';

const OfferForm: React.FC<IOfferFormProps> = ({
  onShouldNotSubmitForm,
  setMaterialCategoryLabel = () => {},
  errorPricing = null,
  setErrorPricing = () => {},
  isSubmitted = false,
}) => {
  const {
    control,
    listMaterials,
    listTypeLoan,
    listDeferments,
    listPricings,
    inputPurchaseDefaultValue,
    inputDepositDefaultValue,
    onValidateInputPurchase,
    onValidateInputDeposit,
    onValidateInputLoanAmount,
    onValidateInputTermNumber,
    onClickFreePricing,
    onChangeInputPrice,
    onChangeInputTermNb,
    getMaterialCategoryLabel,
    onFocusInputPrice,
    onBlurInputPrice,
    isConditionChoiceAvailable,
    isDefermentVisible,
    isErrorPricingVisible,
    errorLoanAmount,
    errorTermNb,
  } = useOfferForm();

  const offer = useAppSelector(selectOffer);
  const configuration = useAppSelector(selectConfiguration);
  const { setValue } = useFormContext();
  const [isPrefilled, setIsPreFilled] = useState(false);

  // Initialisation du passage de contexte
  useEffect(() => {
    if (isSubmitted || configuration?.isPassageDeContexte !== true) {
      return;
    }
    setIsPreFilled(true);
    if (offer?.inputMaterialCategory) {
      setValue('inputMaterialCategory', offer?.inputMaterialCategory, {
        shouldValidate: true,
      });
    }
    if (offer?.inputPurchaseAmount) {
      setValue('inputPurchaseAmount', offer?.inputPurchaseAmount, {
        shouldValidate: true,
      });
    }
    if (offer?.inputDepositAmount) {
      setValue('inputDepositAmount', offer?.inputDepositAmount, {
        shouldValidate: true,
      });
    }
    if (offer?.inputTermNb) {
      setValue('inputTermNb', offer?.inputTermNb, {
        shouldValidate: true,
      });
    }
    if (offer?.inputBorrowers) {
      setValue('inputBorrowers', offer?.inputBorrowers, {
        shouldValidate: true,
      });
    }
    if (offer?.inputPlace) {
      setValue('inputPlace', offer?.inputPlace, {
        shouldValidate: true,
      });
    }
    if (offer?.purchaseOrderNumber) {
      setValue('purchaseOrderNumber', offer?.purchaseOrderNumber, {
        shouldValidate: true,
      });
    }
  }, [offer, isSubmitted]);

  useEffect(() => {
    if (listMaterials?.length === 1) {
      setMaterialCategoryLabel(listMaterials[0]?.label ?? '');
    } else {
      setMaterialCategoryLabel('');
    }
  }, [listMaterials]);

  const getErrorPricing = (): string => {
    return isErrorPricingVisible && listPricings?.length === 0
      ? messages.ERROR_PRICING
      : errorPricing === CodesErreurTarification.CHRONIQUE_NON_TROUVEE
      ? messages.ERROR_CHRONICLE
      : '';
  };

  return (
    <StyledCard>
      <Title level={2}>{messages.CONTAINER_OFFER_TITLE}</Title>
      <WrapperInputTypeLoan>
        <Controller
          control={control}
          name={'inputTypeLoan'}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, ref, onBlur, name },
            fieldState: { isTouched, invalid },
          }) => (
            <Select
              label={messages.INPUT_TYPE_LOAN}
              placeholder={listTypeLoan ? messages.PLACEHOLDER_TYPE_LOAN : ''}
              name={name}
              onChange={(newTypeLoanId) => {
                onShouldNotSubmitForm(false);
                onChange(newTypeLoanId);
                setValue('inputMaterialCategory', '');
              }}
              value={value}
              options={listTypeLoan || []}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              isDisabled={!listTypeLoan || listTypeLoan?.length <= 1}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </WrapperInputTypeLoan>

      <WrapperInputMaterialCategory>
        <Controller
          control={control}
          name={'inputMaterialCategory'}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, ref, onBlur, name },
            fieldState: { isTouched, invalid },
          }) => (
            <Select
              label={messages.INPUT_MATERIAL_CATEGORY}
              placeholder={
                listMaterials ? messages.GENERIC_PLACHOLDER_LIST : ''
              }
              name={name}
              onChange={(newMaterialCategoryId) => {
                onShouldNotSubmitForm(false);
                onChange(newMaterialCategoryId);
                setMaterialCategoryLabel(
                  getMaterialCategoryLabel(newMaterialCategoryId)
                );
              }}
              value={value}
              options={listMaterials || []}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              hasSearch
              isDisabled={!listMaterials || listMaterials?.length <= 1}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </WrapperInputMaterialCategory>

      <WrapperPurchaseDeposit>
        <WrapperInputPurchaseAmount>
          <Controller
            control={control}
            name="inputPurchaseAmount"
            defaultValue={
              offer?.inputPurchaseAmount?.toString() ??
              inputPurchaseDefaultValue
            }
            rules={{
              required: true,
              validate: onValidateInputPurchase,
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, invalid },
            }) => (
              <Input
                type="number"
                inputMode={MobileKeyboardType.DECIMAL}
                suffixText="€"
                label={messages.INPUT_PURCHASE_AMOUNT}
                placeholder={messages.PLACEHOLDER_PURCHASE_AMOUNT}
                value={value}
                name={name}
                isTouched={
                  isTouched || (isPrefilled && offer?.inputPurchaseAmount > 0)
                }
                isValid={!invalid}
                onFocus={() => {
                  onFocusInputPrice(value, onChange);
                }}
                onChange={(newValue) => {
                  onShouldNotSubmitForm(false);
                  onChangeInputPrice(value, newValue, onChange);
                }}
                onBlur={() => {
                  onBlurInputPrice(value, onChange);
                  onBlur();
                }}
                inputRef={ref}
                isSymbolVisible
                isSubmitted={isSubmitted}
              />
            )}
          />
        </WrapperInputPurchaseAmount>

        <WrapperInputDepositAmount>
          <Controller
            control={control}
            name={'inputDepositAmount'}
            defaultValue={inputDepositDefaultValue}
            rules={{
              required: true,
              validate: onValidateInputDeposit,
            }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { isTouched, invalid },
            }) => (
              <Input
                type="number"
                inputMode={MobileKeyboardType.DECIMAL}
                label={messages.INPUT_DEPOSIT_AMOUNT}
                placeholder={messages.PLACEHOLDER_DEPOSIT_AMOUNT}
                value={value}
                name={name}
                suffixText="€"
                isTouched={
                  isTouched ||
                  (isPrefilled && Number(offer?.inputDepositAmount ?? 0) > 1)
                }
                isValid={!invalid}
                inputRef={ref}
                onFocus={() => {
                  onFocusInputPrice(value, onChange);
                }}
                onChange={(newValue) => {
                  onShouldNotSubmitForm(false);
                  onChangeInputPrice(value, newValue, onChange);
                }}
                onBlur={() => {
                  onBlurInputPrice(value, onChange);
                  onBlur();
                }}
                isSymbolVisible
                isSubmitted={isSubmitted}
                validateIfPrefilled={(value) => {
                  return Number(value ?? 0) > 0;
                }}
              />
            )}
          />
        </WrapperInputDepositAmount>
      </WrapperPurchaseDeposit>

      <WrapperInputLoanAmount>
        <Controller
          control={control}
          name={'inputLoanAmount'}
          defaultValue=""
          rules={{
            required: true,
            validate: onValidateInputLoanAmount,
          }}
          render={({
            field: { value, name, ref, onChange },
            fieldState: { invalid },
          }) => (
            <Input
              type="price"
              suffixText="€"
              label={messages.INPUT_LOAN_AMOUNT}
              placeholder={messages.PLACEHOLDER_LOAN_AMOUNT}
              value={value}
              name={name}
              onChange={(newPrice) => {
                onShouldNotSubmitForm(false);
                onChange(newPrice);
              }}
              isValid={!invalid}
              inputRef={ref}
              isDisabled
              error={errorLoanAmount}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </WrapperInputLoanAmount>

      <WrapperInputTermNb>
        <Controller
          control={control}
          name="inputTermNb"
          defaultValue={offer?.inputTermNb?.toString()}
          rules={{
            required: true,
            validate: onValidateInputTermNumber,
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { isTouched, invalid },
          }) => (
            <Input
              type="number"
              inputMode={MobileKeyboardType.NUMERIC}
              suffixText="mois"
              label={messages.INPUT_DESIRED_TERM_NB}
              value={value}
              name={name}
              isTouched={isTouched || (isPrefilled && offer?.inputTermNb > 0)}
              isValid={!invalid}
              inputRef={ref}
              isSymbolVisible
              onChange={(newValue) => {
                onShouldNotSubmitForm(false);
                onChangeInputTermNb(value, newValue, onChange);
              }}
              onBlur={onBlur}
              error={errorTermNb}
              maxLength={3}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </WrapperInputTermNb>

      <WrapperInputPricing>
        <Controller
          control={control}
          name="inputPricing"
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, ref, onBlur, name },
            fieldState: { isTouched, invalid },
          }) => (
            <Select
              label={messages.INPUT_PRICING}
              name={name}
              onChange={(newPricingId) => {
                setErrorPricing(null);
                onShouldNotSubmitForm(false);
                onChange(newPricingId);
              }}
              value={value}
              placeholder={listPricings ? '' : messages.GENERIC_PLACHOLDER_LIST}
              options={listPricings || []}
              isDisabled={!listPricings || listPricings.length <= 1}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              error={getErrorPricing()}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </WrapperInputPricing>

      <>
        {isConditionChoiceAvailable && (
          <WrapperInputCondition>
            <Controller
              control={control}
              name="inputCondition"
              defaultValue=""
              render={({ field: { value, name } }) => (
                <>
                  <StyledCheckBox
                    id="paid-pricing"
                    name={name}
                    isChecked={value.charAt(1) === 'C'}
                    onClick={() => {
                      onShouldNotSubmitForm(false);
                      onClickFreePricing();
                    }}
                  >
                    {messages.INPUT_PAID_PRICING}
                  </StyledCheckBox>

                  <StyledCheckBox
                    id="free-pricing"
                    name={name}
                    isChecked={value.charAt(1) === 'V'}
                    onClick={() => {
                      onShouldNotSubmitForm(false);
                      onClickFreePricing();
                    }}
                  >
                    {messages.INPUT_FREE_PRICING}
                  </StyledCheckBox>
                </>
              )}
            />
          </WrapperInputCondition>
        )}
      </>

      <>
        {isDefermentVisible && (
          <WrapperInputDeferment>
            <Controller
              control={control}
              name="inputDeferment"
              defaultValue={offer?.inputDeferment?.toString()}
              rules={{
                required: true,
                validate: (value) =>
                  !!listDeferments?.find((option) => option.id === value),
              }}
              render={({
                field: { onChange, value, ref, onBlur, name },
                fieldState: { isTouched, invalid },
              }) => (
                <Select
                  label={messages.INPUT_DEFERMENT}
                  name={name}
                  onChange={(newDefermentId) => {
                    onShouldNotSubmitForm(false);
                    onChange(newDefermentId);
                  }}
                  value={value}
                  options={listDeferments || []}
                  placeholder={
                    listDeferments ? messages.GENERIC_PLACHOLDER_LIST : ''
                  }
                  isTouched={isTouched || isSubmitted}
                  isValid={!invalid}
                  onBlur={onBlur}
                  inputRef={ref}
                  isDisabled={!listDeferments || listDeferments?.length <= 1}
                />
              )}
            />
          </WrapperInputDeferment>
        )}
      </>

      <WrapperBorrowers>
        <Title level={2}>{messages.BORROWERS_TITLE}</Title>
        <WrapperInputBorrowers>
          <Controller
            control={control}
            name="inputBorrowers"
            defaultValue={offer?.inputBorrowers}
            rules={{
              required: true,
              validate: (value) => ['1', '2'].includes(value),
            }}
            render={({
              field: { value, name, onChange },
              fieldState: { invalid },
            }) => (
              <>
                <StyledButtonCheck
                  name={name}
                  onClick={() => {
                    onShouldNotSubmitForm(false);
                    onChange('1');
                  }}
                  value={'1'}
                  isChecked={value === '1'}
                  isValid={!invalid}
                >
                  <IconPerson className="icon-person" />
                </StyledButtonCheck>

                <StyledButtonCheck
                  name={name}
                  onClick={() => {
                    onShouldNotSubmitForm(false);
                    onChange('2');
                  }}
                  value={'2'}
                  isChecked={value === '2'}
                  isValid={!invalid}
                >
                  <IconPeople className="icon-people" />
                </StyledButtonCheck>
              </>
            )}
          />
        </WrapperInputBorrowers>
      </WrapperBorrowers>
    </StyledCard>
  );
};

export default OfferForm;
