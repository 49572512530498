import styled from 'styled-components';
import { DescriptionList, Block, RadioButton } from '@components';

const StyledInlineBlockHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRadioButton = styled(RadioButton)`
  width: 14rem;
`;

const StyledInsuranceCode = styled.span<{ isCollapsed: boolean }>`
  color: ${({ theme, isCollapsed }) =>
    isCollapsed
      ? theme.variables.colors.chartColors.dark_700
      : theme.vendor.colors.primary};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  line-height: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  font-weight: ${({ theme }) => theme.variables.fonts.types.bold};
`;

const StyledBlockContent = styled(Block.Content)`
  display: flex;
  padding: 0 4.8rem 0 4.8rem;
  border: none;
`;

const StyledDescriptionList = styled(DescriptionList)`
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  &:first-child {
    margin-right: 8rem;
  }
  dd {
    font-weight: inherit;
    line-height: 2rem;
  }
  dt {
    font-weight: inherit;
    line-height: 2rem;
  }
`;

const StyledBadgeBestInsuranceWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: -4rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
`;
export default {
  StyledInlineBlockHeader,
  StyledRadioButton,
  StyledInsuranceCode,
  StyledBlockContent,
  StyledDescriptionList,
  StyledBadgeBestInsuranceWrapper,
};
