import React from 'react';
import { Icons, ModalContent } from '@components';
import * as propertiesMessage from './messages';
import { StyledText, StyledWrapperText, WrapperPrompt } from './styles';

interface UnreadableEvidenceProps {
  onReupload: () => void;
  onAccepted: () => void;
  isMaxReuploadAttempt: boolean;
  status?: string;
}

export const UnreadableEvidence = ({
  isMaxReuploadAttempt,
  onReupload = () => undefined,
  onAccepted = () => undefined,
  status = '',
}: UnreadableEvidenceProps): React.ReactElement => {
  const body = (
    <StyledWrapperText>
      <StyledText>
        <>
          {propertiesMessage.ERROR_UNREADABLE_EVIDENCE}
          <br />
          {propertiesMessage.ERROR_UNREADABLE_EVIDENCE_2}
          <br />
          {propertiesMessage.ERROR_UNREADABLE_EVIDENCE_3}
        </>
      </StyledText>
    </StyledWrapperText>
  );

  return (
    <WrapperPrompt>
      <ModalContent
        icon={<Icons.WarningIcon />}
        title={
          status === 'INCONSISTENCY_TYPE'
            ? propertiesMessage.TITLE_MODAL_INCONSISTENCY_TYPE
            : propertiesMessage.TITLE_MODAL_UNREADABLE_EVIDENCE
        }
        text={body}
        primaryButtonLabel={propertiesMessage.TO_SEND_BUTTON}
        onPrimaryButtonClick={onAccepted}
        secondaryButtonLabel={propertiesMessage.PROOF_BUTTON}
        onSecondaryButtonClick={onReupload}
        isSecondaryButtonDisabled={isMaxReuploadAttempt}
      />
    </WrapperPrompt>
  );
};

UnreadableEvidence.defaultProps = {
  status: '',
};

export default UnreadableEvidence;
