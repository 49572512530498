import styled from 'styled-components';
import { Icons } from '@components';
import InsuranceBlock from './InsuranceBlock';

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;

export const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xl};
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.variables.fonts.sizes.normalText};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const UnderText = styled(Text)`
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const StyledAdviceIcon = styled(Icons.AdviceIcon)`
  width: 2.6rem;
  margin-right: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  color: ${({ theme }) => theme.vendor.colors.primary};
`;

export const WrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;

export const StyledInsuranceBlock = styled(InsuranceBlock)`
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const h2 = styled.h2`
  display: flex;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  color: red;
`;

export const Legend = styled.div`
  display: inline;
  color: ${({ theme }) => theme.variables.colors.chartColors.grey_300};
  font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_l};
`;
