import {
  clearStore,
  selectConfiguration,
  setSimulation,
  updateBankDetails,
  updateConfiguration,
  updateOffer,
  updatePersonEmprunteur,
  useAppDispatch,
  useAppSelector,
  useLazyGetParametersQuery,
  useSendSimulationSimpleMutation,
  updatePersonCoEmprunteur,
} from '@store';
import {
  getCompanyName,
  getURLParams,
  getUrlParamsAsString,
  PATH_SIMULATION,
  getAuthenticationEnabled,
  getCommonPartDomain,
  setCookie,
  resetAuthenticationRequired,
  Company,
  setAuthenticationEnabled,
} from '@utils';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IOption, TypeVente } from '@types';
import { getTheme } from '@styles';
import { useParcoursType, usePersistingState } from '@hooks';

export interface IStep {
  label: string;
  paths: string[];
}

export enum EntiteFinanciere {
  CETELEM = 'PF',
  DOMOFINANCE = 'DOMO',
  CREDIT_MODERNE = 'CM',
}

const useInitApp = () => {
  const [theme, setTheme] =
    useState<null | {
      vendor: { logo: string };
      variables: any;
    }>(null);
  const configuration = useAppSelector(selectConfiguration);
  const location = useLocation();
  const [isFormation, setFormation] = useState(false);
  const [, setEntiteFinanciere] = usePersistingState('entite-financiere', '');

  const [vendorId, setVendorId] = useState('');
  const [souscriptionId, setSouscriptionId] = useState('');
  const [urlFinSouscription, setUrlFinSouscription] = useState('');
  const [isRoutingRedirectionReady, setIsRoutingRedirectionReady] =
    useState(true);
  const [marqueBlanche, setMarqueBlanche] = useState(false);
  const [typeVente, setTypeVente] = useState(TypeVente.FACE_A_FACE);

  const [triggerGetParameters, resultGetParameters] =
    useLazyGetParametersQuery();

  const [triggerSimulationSimple, simulationSimpleResult] =
    useSendSimulationSimpleMutation();

  const dispatch = useAppDispatch();

  const isRedirectable = () => isRoutingRedirectionReady !== false;
  const { isParcoursSwitchDevice } = useParcoursType();
  const [isInitializingByReauth, setIsInitializingByReauth] = useState(false);

  const clearUrlParams = (isSimu = false) => {
    window.history.replaceState(
      {},
      document.title,
      '/#' + (isSimu ? PATH_SIMULATION : location?.pathname)
    );
  };

  useEffect(() => {
    if (!getAuthenticationEnabled()) {
      return;
    }
    setEntiteFinanciere(getEntiteFinanciere());
  }, [resultGetParameters]);

  // Après un clear du store on initialise
  useEffect(() => {
    if (!configuration.isCleared && !isInitializingByReauth) {
      return;
    }
    const {
      vendorIdParam,
      souscriptionIdParam,
      returnUrlParam,
      marqueBlancheParam,
      formationParam,
      typeVenteParam,
      requestIdParam,
      isDebranchementQRCodeParam,
      switchDeviceIdParam,
    } = getParams();
    initFromParams();
    const isSimu = location.pathname.startsWith(PATH_SIMULATION);
    clearUrlParams(isSimu);
    dispatch(
      updateConfiguration({
        souscriptionId: souscriptionIdParam ?? undefined,
        vendorId: isSimu ? vendorId : vendorIdParam ?? undefined,
        marqueBlanche: marqueBlancheParam?.toLowerCase() === 'true',
        typeVente:
          typeVenteParam === TypeVente.VENTE_A_DISTANCE + ''
            ? TypeVente.VENTE_A_DISTANCE
            : TypeVente.FACE_A_FACE,
        urlFinSouscription: returnUrlParam,
        requestId: requestIdParam ?? '',
        isInFormationMode: !isSimu && formationParam === 'true',
        isDebranchementQRCode: isDebranchementQRCodeParam ?? false,
        switchDeviceId: switchDeviceIdParam ?? undefined,
        idAppareil: crypto
          .randomUUID()
          .toString()
          .replaceAll('-', '')
          .substring(0, 20),
      })
    );
    setIsInitializingByReauth(false);
  }, [configuration.isCleared, isInitializingByReauth]);

  useEffect(() => {
    if (!isParcoursSwitchDevice() || !location.pathname) {
      return;
    }
    if (location.pathname !== '/documents') {
      let url = window.location.hash;
      const urlTab = url.split('?');
      const param = urlTab[1] ? '?' + urlTab[1] : '';
      const redirectUrl = window.location.origin + '/#/documents' + param;

      window.location.replace(redirectUrl);
    }
  }, [, location.pathname, isParcoursSwitchDevice()]);

  useEffect(() => {
    const {
      vendorIdParam,
      souscriptionIdParam,
      returnUrlParam,
      requestIdParam,
      apimAccessTokenParam,
      authInitTokenParam,
      isDebranchementQRCodeParam,
      switchDeviceIdParam,
      isReAuthent,
    } = getParams();
    // Initialisation au chargement de la page
    initFromParams();
    // Les paramètres ci-dessous impliquent un nouveau parcours souscription (nouveau débranchement)
    // === '/simulation' à remplacer par .startWith('/simulation') ?
    const isSimu = location.pathname === PATH_SIMULATION;
    if (!isSimu) {
      if (
        vendorIdParam ||
        souscriptionIdParam ||
        returnUrlParam ||
        apimAccessTokenParam ||
        authInitTokenParam ||
        requestIdParam ||
        isDebranchementQRCodeParam ||
        switchDeviceIdParam
      ) {
        if (!isReAuthent) {
          clearStore();
          sessionStorage.clear();
        } else {
          setIsInitializingByReauth(true);
        }
        if (souscriptionIdParam || souscriptionId) {
          triggerGetParameters({
            numeroSouscription: souscriptionIdParam ?? souscriptionId,
            apimAccessToken: apimAccessTokenParam,
            authInitToken: authInitTokenParam,
          });
        }
      } else {
        if (configuration?.isSimu && configuration?.souscriptionId) {
          triggerGetParameters({
            numeroSouscription: configuration?.souscriptionId,
            apimAccessToken: apimAccessTokenParam,
            authInitToken: authInitTokenParam,
          });
          dispatch(updateConfiguration({ isSimu: false }));
        }
        // Sinon on reprend les informations du parcours (par exemple refresh)
        setFormation(configuration.isInFormationMode ?? false);
        setSouscriptionId(configuration.souscriptionId ?? '');
        setVendorId(configuration.vendorId ?? '');
        setUrlFinSouscription(configuration.urlFinSouscription ?? '');
        setTypeVente(configuration.typeVente ?? TypeVente.FACE_A_FACE);
        // Le thème est modifié uniquement si le store n'est pas vierge
        if (!configuration.isCleared) {
          setMarqueBlanche(configuration.marqueBlanche ?? false);
          setTheme(getTheme(getCompanyName(configuration.vendorId)));
        }
      }
    }

    // Les paramètres ci-dessous impliquent un nouveau parcours simulation (nouveau débranchement)
    //Permet d'avoir bornes au 1er chargement / avoir apimAccessToken ou authInitToken
    if (isSimu) {
      if (vendorIdParam || apimAccessTokenParam || authInitTokenParam) {
        if (!isReAuthent) {
          clearStore();
          sessionStorage.clear();
        } else {
          setIsInitializingByReauth(true);
        }
        const bodyRequest = {
          numero_point_de_vente: vendorIdParam!,
          age_emprunteur: 25,
        };
        const param = {
          simulationRequest: bodyRequest,
          apimAccessToken: apimAccessTokenParam,
          authInitToken: authInitTokenParam,
          requestId: requestIdParam ?? '',
        };
        triggerSimulationSimple(param);
      } else {
        // Sinon on reprend les informations du parcours (par exemple refresh)
        setVendorId(configuration.vendorId ?? '');
        setMarqueBlanche(configuration.marqueBlanche ?? false);
        setTheme(getTheme(getCompanyName(configuration.vendorId)));
      }
    }
  }, [location]);

  // Update favicon
  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']");
    link?.setAttribute(
      'href',
      process.env.PUBLIC_URL +
        '/favicons/favicon_' +
        (marqueBlanche
          ? 'marqueBlanche'
          : (getCompanyName(configuration.vendorId) ?? '').replace(
              /_.*$/, // suppression des sous groupes d'enseignes (_oi ...)
              ''
            )) +
        '.ico'
    );
  }, [configuration.vendorId, marqueBlanche]);

  useEffect(() => {
    if (!resultGetParameters?.data) {
      return;
    }
    dispatch(
      updateConfiguration({
        isQrActivated:
          resultGetParameters?.data?.parametres_switch_device
            ?.switch_device_enabled,
        parametres_ocr: resultGetParameters?.data?.parametres_ocr ?? {},
        products:
          resultGetParameters?.data?.parametres_offre_commerciale?.produits,
        listPlaces: (
          resultGetParameters?.data?.parametres_offre_commerciale
            ?.lieux_travaux ?? []
        ).map((lieu) => {
          return { id: lieu?.code, label: lieu?.label };
        }),
        listCivility: (
          resultGetParameters?.data?.parametres_emprunteur?.civilites ?? []
        ).map((civilite) => {
          return { id: civilite?.code, label: civilite?.label };
        }),
        souscriptionId: souscriptionId,
        vendorId: vendorId,
        isInFormationMode: isFormation,
        mentionLegaleOffreCommerciale:
          resultGetParameters?.data?.parametres_offre_commerciale
            ?.mention_legale,
        mentionLegaleEmprunteur:
          resultGetParameters?.data?.parametres_emprunteur?.mention_legale,
        mentionLegaleAssurance:
          resultGetParameters?.data?.parametres_assurance?.mention_legale,
        listTypePieceIdentite: (
          resultGetParameters?.data?.parametres_emprunteur
            ?.types_piece_identite ?? []
        ).map((type_piece_identite) => {
          return {
            id: type_piece_identite?.code,
            label: type_piece_identite?.label,
          };
        }),
        listSituationLogement: (
          resultGetParameters?.data?.parametres_emprunteur
            ?.situations_logements ?? []
        ).map((situations_logements) => {
          return {
            id: situations_logements?.code,
            label: situations_logements?.label,
          };
        }),
        listStatutsLocaux: (
          resultGetParameters?.data?.parametres_emprunteur
            ?.statuts_occupation_locaux ?? []
        ).map((statut) => {
          return {
            id: statut?.code,
            label: statut?.label,
          };
        }),
        listStatutsProfessionnels: (
          resultGetParameters?.data?.parametres_emprunteur
            ?.statuts_professionnels ?? []
        ).map((statut) => {
          return {
            id: statut?.code,
            label: statut?.label,
          };
        }),
        situationsFamilialeMonoEmprunteur: (
          resultGetParameters?.data?.parametres_emprunteur
            ?.situations_familiales_mono_emprunteur ?? []
        ).map((situation_familiale) => {
          return {
            id: situation_familiale?.code,
            label: situation_familiale?.label,
          };
        }),
        situationsFamilialeCoEmprunteur: (
          resultGetParameters?.data?.parametres_emprunteur
            ?.situations_familiales_co_emprunteur ?? []
        ).map((situation_familiale) => {
          return {
            id: situation_familiale?.code,
            label: situation_familiale?.label,
          };
        }),
        secteursActivite: (
          resultGetParameters?.data?.parametres_emprunteur
            ?.secteurs_activites ?? []
        ).map((secteur_activite) => {
          const secteur: IOption = {
            id: secteur_activite?.secteur.code,
            label: secteur_activite?.secteur.label,
          };

          const professions: IOption[] = [];
          (secteur_activite?.professions ?? []).forEach((value) => {
            const profession: IOption = {
              id: value?.code,
              label: value?.label,
            };
            professions.push(profession);
          });

          const typesContrat: IOption[] = [];
          (secteur_activite?.types_contrat ?? []).forEach((value) => {
            const typeContrat: IOption = {
              id: value?.code,
              label: value?.label,
            };
            typesContrat.push(typeContrat);
          });
          return {
            secteur,
            professions,
            typesContrat,
          };
        }),
        seuilMontantCreditPourAffichageInfosEmployeur:
          resultGetParameters?.data?.parametres_emprunteur
            ?.seuil_montant_credit_affichage_infos_employeur,
        urlFinSouscription,
      })
    );
    const souscriptionExterne =
      resultGetParameters?.data?.souscription_externe
        ?.subscription_informations;

    if (!souscriptionExterne) {
      return;
    }

    const offreCommerciale = souscriptionExterne?.project;
    const emprunteurPrincipal = souscriptionExterne?.borrower;
    const coEmprunteur = souscriptionExterne?.coborrower;
    const informationsBancaires = souscriptionExterne?.bank_details;
    let inputBorrowersSouscritionExterne = '1';
    if (coEmprunteur) {
      inputBorrowersSouscritionExterne = '2';
    }

    dispatch(
      updateConfiguration({
        isPassageDeContexte: true,
      })
    );
    dispatch(
      updateOffer({
        inputMaterialCategory: offreCommerciale?.goods_code,
        inputPurchaseAmount: offreCommerciale?.project_amount,
        inputDepositAmount: offreCommerciale?.deposit,
        inputTermNb: offreCommerciale?.term,
        inputPlace: offreCommerciale?.renovation_place,
        purchaseOrderNumber: offreCommerciale?.order_number,
        inputBorrowers: inputBorrowersSouscritionExterne,
      })
    );
    dispatch(
      updatePersonEmprunteur({
        identity: {
          firstName: emprunteurPrincipal?.personal_informations?.first_name,
          lastName: emprunteurPrincipal?.personal_informations?.last_name,
          birthDt: emprunteurPrincipal?.personal_informations?.birth_date,
          nationalityIsoCd:
            emprunteurPrincipal?.personal_informations?.nationality,
          titleCd: emprunteurPrincipal?.personal_informations?.title,
        },
        contactInformation: {
          mobilePhoneNumber: emprunteurPrincipal?.phone?.mobile_number,
          fixPhoneNumber: emprunteurPrincipal?.phone?.home_phone_number,
          email: emprunteurPrincipal?.email,
        },
        billingAddressInformation: {
          additionalAddress: emprunteurPrincipal?.address?.additional_address,
          city: emprunteurPrincipal?.address?.city,
          streetAddress: emprunteurPrincipal?.address?.street_address,
          zipCd: emprunteurPrincipal?.address?.zip_code,
        },
        familySituation: {
          maritalStatus: emprunteurPrincipal?.family?.marital_status,
          childrenNb: emprunteurPrincipal?.family?.children_nb,
        },
        professionalSituation: {
          profession: emprunteurPrincipal?.professional?.csp,
          activitySector: emprunteurPrincipal?.professional?.csp
            ? getActivitySectorByCsp(emprunteurPrincipal?.professional?.csp)
            : undefined,
          beginningEmployerDate:
            emprunteurPrincipal?.professional?.beginning_employment_date,
        },
        housingSituation: {
          housingStatus: emprunteurPrincipal?.housing?.housing_status,
          housingEntryDate: emprunteurPrincipal?.housing?.housing_entry_date,
        },
      })
    );
    dispatch(
      updatePersonCoEmprunteur({
        identity: {
          firstName: coEmprunteur?.personal_informations?.first_name,
          lastName: coEmprunteur?.personal_informations?.last_name,
          birthDt: coEmprunteur?.personal_informations?.birth_date,
          nationalityIsoCd: coEmprunteur?.personal_informations?.nationality,
          titleCd: coEmprunteur?.personal_informations?.title,
        },
        contactInformation: {
          mobilePhoneNumber: coEmprunteur?.phone?.mobile_number,
          fixPhoneNumber: coEmprunteur?.phone?.home_phone_number,
          email: coEmprunteur?.email,
        },
        billingAddressInformation: {
          additionalAddress: coEmprunteur?.address?.additional_address,
          city: coEmprunteur?.address?.city,
          streetAddress: coEmprunteur?.address?.street_address,
          zipCd: coEmprunteur?.address?.zip_code,
        },
        professionalSituation: {
          profession: coEmprunteur?.professional?.csp,
          activitySector: coEmprunteur?.professional?.csp
            ? getActivitySectorByCsp(coEmprunteur?.professional?.csp)
            : undefined,
          beginningEmployerDate:
            coEmprunteur?.professional?.beginning_employment_date,
        },
      })
    );
    dispatch(
      updateBankDetails({
        bic: informationsBancaires?.bic,
        iban: informationsBancaires?.iban,
        accountOwner: informationsBancaires?.account_holder,
      })
    );
  }, [resultGetParameters.data]);

  const getActivitySectorByCsp = (csp: string): string | undefined => {
    return (
      (
        resultGetParameters?.data?.parametres_emprunteur?.secteurs_activites ??
        []
      ).find((secteur_activite) => {
        return !!(secteur_activite?.professions ?? []).find((profession) => {
          return profession?.code === csp;
        });
      })?.secteur?.code ?? undefined
    );
  };

  useEffect(() => {
    if (!simulationSimpleResult?.data) {
      return;
    }

    dispatch(
      updateConfiguration({
        vendorId: vendorId,
      })
    );

    dispatch(
      setSimulation({
        simulation1: {
          calculatedValue: {
            minAmount: simulationSimpleResult.data.montant_min,
            maxAmount: simulationSimpleResult.data.montant_max,
          },
        },
      })
    );
  }, [simulationSimpleResult.data]);

  const initFromParams = () => {
    const initAppUrlParams = getUrlParamsAsString();
    if (initAppUrlParams) {
      dispatch(updateConfiguration({ initAppUrlParams }));
    }
    const {
      vendorIdParam,
      marqueBlancheParam,
      souscriptionIdParam,
      returnUrlParam,
      formationParam,
      typeVenteParam,
      apimAccessTokenParam,
      authInitTokenParam,
    } = getParams();
    setFormation(!!formationParam && formationParam !== 'false');
    setSouscriptionId(souscriptionIdParam ?? '');
    setVendorId(vendorIdParam ?? '');
    setUrlFinSouscription(returnUrlParam);
    setTypeVente(
      typeVenteParam === 'VENTE_A_DISTANCE'
        ? TypeVente.VENTE_A_DISTANCE
        : TypeVente.FACE_A_FACE
    );
    setIsRoutingRedirectionReady(false);
    resetAuthenticationRequired();

    // Le thème est modifié uniquement si le store n'est pas vierge
    if (!configuration.isCleared) {
      setTheme(getTheme(getCompanyName(vendorIdParam + '')));
      setMarqueBlanche(marqueBlancheParam === 'true');
    } else {
      setAuthenticationEnabled(!apimAccessTokenParam && !authInitTokenParam);
    }

    const originUri =
      window.location.origin +
      '/' +
      (window.location.hash ?? '').split('?')[0] +
      '?' +
      (initAppUrlParams && initAppUrlParams !== ''
        ? initAppUrlParams
        : configuration?.initAppUrlParams ?? '') +
      '&isReAuthent=true';
    setCookie({
      name: 'auth-request-origin',
      originUri,
      maxAge: 360000,
      domain: getCommonPartDomain(),
    });
  };

  const getParams = () => {
    const searchParams = getURLParams();
    const returnUrlParam = searchParams.get('returnUrl') ?? '';
    return {
      formationParam: searchParams.get('modeFormation'),
      vendorIdParam: searchParams.get('numeroPointDeVente'),
      souscriptionIdParam: searchParams.get('numeroSouscription'),
      marqueBlancheParam: searchParams.get('marqueBlanche'),
      typeVenteParam: searchParams.get('typeVente'),
      returnUrlParam: returnUrlParam.substring(
        0,
        returnUrlParam.lastIndexOf('/')
      ),
      apimAccessTokenParam: searchParams.get('apimAccessToken') ?? '',
      authInitTokenParam: searchParams.get('authInitToken') ?? '',
      requestIdParam: searchParams.get('requestId'),
      isDebranchementQRCodeParam:
        searchParams.get('isDebranchementQRCode') === 'true',
      switchDeviceIdParam: searchParams.get('switchDeviceIdParam'),
      isReAuthent: searchParams.get('isReAuthent') === 'true',
    };
  };

  const getEntiteFinanciere = (): EntiteFinanciere => {
    const company = getCompanyName(vendorId);
    switch (company) {
      case Company.CETELEM:
        return EntiteFinanciere.CETELEM;
      case Company.DOMOFINANCE:
        return EntiteFinanciere.DOMOFINANCE;
      default:
        break;
    }
    return EntiteFinanciere.CREDIT_MODERNE;
  };

  return {
    theme,
    isFormation,
    isSimu: location.pathname.startsWith(PATH_SIMULATION),
    vendorId,
    urlFinSouscription,
    marqueBlanche,
    typeVente,
    isRedirectable,
    resultGetParameters,
    isInitializingByReauth,
  };
};
export default useInitApp;
