import { IOption } from '@types';

export const getLabelFromList = (
  key: string,
  lists: IOption[]
): string | undefined => {
  const foundItem = lists.find((item) => item.id === key);
  return foundItem?.label;
};

export const getKeyFromList = (
  label: string,
  lists: IOption[]
): string | undefined => {
  const foundItem = lists.find((item) => item.label === label);
  return foundItem?.id;
};

export const getOptionFromList = (
  id: string,
  lists: IOption[] | null
): IOption | null => {
  return (lists ?? []).find((item) => item.id === id) ?? null;
};

export const getUniqueId = (): string => {
  const array = new Uint32Array(10);
  return crypto.getRandomValues(array).toString();
};
