import { Select } from '@components';
import { InputCity, SelectCity, WrapperInput } from './styles';
import * as messages from './messages';
import { Controller, useFormContext } from 'react-hook-form';
import useInformationsForm from '../useInformationsForm';
import { selectPerson, useAppSelector, personTypes } from '@store';
import {
  cityRegExp,
  isPersonFieldDisabled,
  validateNationalityEvidence,
} from '@utils';
import { EvidenceType } from '@types';
import { useValidateInput } from '@hooks';
import { useEffect } from 'react';

interface IProps {
  role: string;
}

const InformationsBirthLocation: React.FC<IProps> = ({ role }) => {
  const persons = useAppSelector(selectPerson);
  const { shouldValidateInput } = useValidateInput();
  const person =
    role === personTypes.RoleCd.BORROWER
      ? persons?.emprunteur
      : persons?.coemprunteur;

  useEffect(() => {
    if (!person?.identity) {
      return;
    }
    shouldValidateInput(
      'inputBirthCountry' + suffixRole,
      person?.identity?.birthCountry
    );
    shouldValidateInput(
      'inputBirthDepartment' + suffixRole,
      person?.identity?.birthDepartmentCd
    );
    shouldValidateInput(
      'inputBirthCityInsee' + suffixRole,
      person?.identity?.birthCityInseeCd
    );
    shouldValidateInput(
      'inputBirthCityLabel' + suffixRole,
      person?.identity?.birthCity
    );
    shouldValidateInput(
      'inputNationality' + suffixRole,
      person?.identity?.nationalityIsoCd
    );
  }, [person]);

  const suffixRole = '_' + role;

  const ocrBirthCity = person?.identity?.birthCity;

  const {
    control,
    listCountries,
    listDepartments,
    listCities,
    isFranceSelected,
    isCitiesReady,
    isDepartmentsLoading,
  } = useInformationsForm({ role, ocrBirthCity, shouldCheckCountry: true });

  const { trigger, getValues } = useFormContext();

  return (
    <>
      <WrapperInput>
        <Controller
          control={control}
          name={'inputBirthCountry' + suffixRole}
          defaultValue={person?.identity?.birthCountry}
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, ref, onBlur, name },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Select
              label={messages.INPUT_BIRTH_COUNTRY}
              name={name}
              onChange={onChange}
              value={value}
              placeholder={messages.PLACEHOLDER_LIST}
              hasSearch
              options={listCountries || []}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </WrapperInput>

      {isFranceSelected && (
        <WrapperInput>
          <Controller
            control={control}
            name={'inputBirthDepartment' + suffixRole}
            defaultValue={person?.identity?.birthDepartmentCd}
            rules={{
              required: isFranceSelected,
            }}
            render={({
              field: { onChange, value, ref, onBlur, name },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Select
                name={name}
                label={messages.INPUT_BIRTH_DEPARTMENT}
                onChange={onChange}
                value={value}
                hasSearch
                options={
                  listDepartments
                    ? listDepartments.map(({ id, label }) => ({
                        id,
                        label: `${id} - ${label}`,
                      }))
                    : []
                }
                placeholder={messages.PLACEHOLDER_LIST}
                inputRef={ref}
                onBlur={onBlur}
                isTouched={isTouched}
                isDisabled={isDepartmentsLoading && !listDepartments}
                isLoading={isDepartmentsLoading && !listDepartments}
                isValid={!invalid}
                isSubmitted={isSubmitted}
              />
            )}
          />
        </WrapperInput>
      )}

      <WrapperInput>
        <Controller
          control={control}
          name={'inputBirthCityInsee' + suffixRole}
          defaultValue={person?.identity?.birthCityInseeCd}
          rules={{
            required: isFranceSelected,
          }}
          render={({
            field: { onChange, value, ref, onBlur, name },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <SelectCity
              name={name}
              label={messages.INPUT_BIRTH_CITY}
              onChange={onChange}
              value={value}
              isDisabled={!isCitiesReady}
              options={listCities || []}
              hasSearch
              placeholder={messages.PLACEHOLDER_LIST}
              inputRef={ref}
              onBlur={onBlur}
              isTouched={isTouched}
              isValid={!invalid}
              isSubmitted={isSubmitted}
              isLoading={!isCitiesReady}
              isFranceSelected={isFranceSelected ?? false}
            />
          )}
        />
        <Controller
          control={control}
          name={'inputBirthCityLabel' + suffixRole}
          defaultValue={person?.identity?.birthCity}
          rules={{
            required: true,
            pattern: cityRegExp,
          }}
          render={({
            field: { onChange, value, ref, onBlur, name },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <InputCity
              name={name}
              label={messages.INPUT_BIRTH_CITY}
              onChange={onChange}
              value={value}
              inputRef={ref}
              onBlur={onBlur}
              isTouched={isTouched}
              isValid={!invalid}
              isSubmitted={isSubmitted}
              maxLength={30}
              isFranceSelected={isFranceSelected ?? false}
            />
          )}
        />
      </WrapperInput>

      <WrapperInput>
        <Controller
          control={control}
          name={'inputNationality' + suffixRole}
          defaultValue={person?.identity?.nationalityIsoCd}
          rules={{
            required: true,
            validate: (value: string) =>
              validateNationalityEvidence({
                nationalityCd: value,
                evidenceSubTypeJDID: getValues(
                  'evidenceSubTypeJDID' + suffixRole
                ),
              }),
          }}
          render={({
            field: { onChange, value, ref, onBlur, name },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Select
              label={messages.INPUT_NATIONALITY}
              name={name}
              onChange={(newValue) => {
                onChange(newValue);
                trigger('evidenceSubTypeJDID' + suffixRole);
              }}
              value={value as `${string}`}
              placeholder={messages.PLACEHOLDER_LIST}
              hasSearch
              options={listCountries || []}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              error={invalid && !!value ? messages.ERROR_JDID_NATIONALITY : ''}
              isSubmitted={isSubmitted}
              isDisabled={isPersonFieldDisabled(
                person?.identity?.nationalityIsoCd,
                person,
                EvidenceType.JDID
              )}
            />
          )}
        />
      </WrapperInput>
    </>
  );
};

export default InformationsBirthLocation;
