import { useBorrowers, usePersistingState, useRedirection } from '@hooks';
import {
  selectConfiguration,
  selectPerson,
  updatePersonCoEmprunteur,
  updatePersonEmprunteur,
  useAppDispatch,
  useAppSelector,
  useSendContratElectroniqueMutation,
  useSendModifyBorrowerWithoutErrorMutation,
} from '@store';
import { IModifyBorrowerRequest, RoleCd } from '@types';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

export enum fieldNames {
  email = 'email',
  mobile = 'telephoneMobile',
}
const useSendElectronicContract = (
  onElectronicSignResponse = (url: string) => {
    console.log(url);
  },
  methods: UseFormReturn
) => {
  const { souscriptionId } = useAppSelector(selectConfiguration);
  const { getUrlRetourAccueil } = useRedirection();
  const [hasInformationChangedOnce] = usePersistingState(
    'has-information-changed-once',
    {
      borrower: { email: false, telephoneMobile: false, isModifying: false },
      coborrower: { email: false, telephoneMobile: false, isModifying: false },
    }
  );

  const dispatch = useAppDispatch();
  const { hasCoborrower } = useBorrowers();
  const person = useAppSelector(selectPerson);
  const [isWaitingToSendContract, setIsWaitingToSendContract] = useState(false);
  const [triggerSendModifyBorrower, responseSendModifyBorrower] =
    useSendModifyBorrowerWithoutErrorMutation();
  const [triggerSendModifyCoBorrower, responseSendModifyCoBorrower] =
    useSendModifyBorrowerWithoutErrorMutation();
  const [sendContrat, responseContrat] = useSendContratElectroniqueMutation();

  // Récupération de l'url à la réponse de WS de signature électronique
  useEffect(() => {
    if (!responseContrat?.data) {
      return;
    }
    onElectronicSignResponse(responseContrat?.data?.url);
  }, [responseContrat?.data]);

  useEffect(() => {
    if (
      !responseSendModifyCoBorrower?.isError &&
      !responseSendModifyBorrower?.isError &&
      !responseContrat?.isError
    ) {
      return;
    }
    onElectronicSignResponse('');
  }, [
    responseSendModifyCoBorrower?.isError,
    responseSendModifyBorrower?.isError,
    responseContrat?.isError,
  ]);

  // Modification de l'emprunteur ok
  useEffect(() => {
    if (!responseSendModifyBorrower?.isSuccess) {
      return;
    }
    hasInformationChangedOnce.borrower = {
      email: false,
      telephoneMobile: false,
      isModifying: false,
    };
    if (
      !hasInformationChangedOnce.coborrower.isModifying &&
      isWaitingToSendContract
    ) {
      setIsWaitingToSendContract(false);
      sendElectronicContrat();
    }
  }, [responseSendModifyBorrower?.isSuccess]);
  // Modification du coemprunteur ok
  useEffect(() => {
    if (!responseSendModifyCoBorrower?.isSuccess) {
      return;
    }
    hasInformationChangedOnce.coborrower = {
      email: false,
      telephoneMobile: false,
      isModifying: false,
    };
    if (
      !hasInformationChangedOnce.borrower.isModifying &&
      isWaitingToSendContract
    ) {
      setIsWaitingToSendContract(false);
      sendElectronicContrat();
    }
  }, [responseSendModifyCoBorrower?.isSuccess]);

  // les champs email/mobile de l'emprunteur/coemprunteur sont en cours de modification
  const isModifyingField = ({
    fieldName,
    role,
  }: {
    fieldName: fieldNames;
    role: RoleCd;
  }) => {
    const borrower = role === RoleCd.BORROWER ? 'borrower' : 'coborrower';
    hasInformationChangedOnce[borrower][fieldName] = true;
  };

  // Formatte l'affichage champ téléphone mobile
  const formatContactValue = (value: string) => {
    return (value ?? '').trim().replaceAll(' ', '');
  };

  // Envoie la mise à jour des champs modifiés
  const updatePersonInformationsIfNeeded = (role: RoleCd) => {
    const hasInformationChanged =
      role === RoleCd.BORROWER
        ? hasInformationChangedOnce.borrower
        : hasInformationChangedOnce.coborrower;

    if (
      !hasInformationChanged.email &&
      !hasInformationChanged.telephoneMobile
    ) {
      hasInformationChanged.isModifying = false;
      return;
    }
    hasInformationChanged.isModifying = true;
    const updatePersonContactInformations: IModifyBorrowerRequest = {
      telephoneMobile: hasInformationChanged.telephoneMobile
        ? formatContactValue(methods.getValues('phoneNumber_' + role)) ??
          undefined
        : undefined,
      email: hasInformationChanged.email
        ? formatContactValue(methods.getValues('email_' + role)) ?? undefined
        : undefined,
    };
    const sendModify =
      role === RoleCd.BORROWER
        ? triggerSendModifyBorrower
        : triggerSendModifyCoBorrower;
    sendModify({
      numeroSouscription: souscriptionId ?? '',
      arg: {
        typeEmprunteur: role,
        etape: 'E_SIGNATURE',
      },
      modifyBorrowerRequest: updatePersonContactInformations,
    });
    const updateStore: { mobilePhoneNumber?: string; email?: string } = {};
    if (updatePersonContactInformations.telephoneMobile) {
      updateStore.mobilePhoneNumber =
        updatePersonContactInformations.telephoneMobile;
    }
    if (updatePersonContactInformations.email) {
      updateStore.email = updatePersonContactInformations.email;
    }

    const updatePerson =
      role === RoleCd.BORROWER
        ? updatePersonEmprunteur
        : updatePersonCoEmprunteur;
    const personIndex =
      role === RoleCd.BORROWER ? 'emprunteur' : 'coemprunteur';

    dispatch(
      updatePerson({
        contactInformation: {
          ...(person[personIndex]?.contactInformation ?? {}),
          ...updateStore,
        },
      })
    );
  };

  // Envoi du contrat
  const sendElectronicContrat = () => {
    sendContrat({
      numero_souscription: souscriptionId ?? '',
      urlRetour: getUrlRetourAccueil(),
    });
  };

  // Met à jour les champs puis envoie le contrat
  const updateInformationsIfNeededAndThenSign = () => {
    updatePersonInformationsIfNeeded(RoleCd.BORROWER);
    if (hasCoborrower()) {
      updatePersonInformationsIfNeeded(RoleCd.COBORROWER);
    }
    if (
      !hasInformationChangedOnce.borrower.isModifying &&
      !hasInformationChangedOnce.coborrower.isModifying
    ) {
      setIsWaitingToSendContract(false);
      sendElectronicContrat();
    } else {
      setIsWaitingToSendContract(true);
    }
  };

  return {
    isModifyingField,
    updateInformationsIfNeededAndThenSign,
  };
};

export default useSendElectronicContract;
