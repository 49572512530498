export const LABEL_BORROWER_PROFESSIONAL_SITUATION =
  "Situation professionnelle de l'emprunteur";
export const LABEL_COBORROWER_PROFESSIONAL_SITUATION =
  'Situation professionnelle du co-emprunteur';
export const LABEL_EMPLOYER_NAME = "Nom de l'employeur";
export const LABEL_EMPLOYER_ZIP_CODE = 'Code postal professionnel';
export const LABEL_ACTIVITY_TYPE = "Nature d'activité";
export const LABEL_SIREN = 'Numéro SIREN';
export const LABEL_EMPLOYEES_NUMBER = "Nombre d'employés";
export const LABEL_WORKPLACE_STATUS = 'Locaux';
export const LABEL_PROFESSIONNAL_STATUS = 'Fonction';
export const LABEL_BORROWER_SELECT_ACTIVITY_SECTOR =
  "Sélectionnez le secteur d'activité de l'emprunteur";
export const LABEL_COBORROWER_SELECT_ACTIVITY_SECTOR =
  "Sélectionnez le secteur d'activité du co-emprunteur";
export const LABEL_PROFESSION_OR_SITUATION = 'Profession ou situation';
export const LABEL_EMPLOYER_DATE =
  "Chez l'employeur actuel ou dans la même situation depuis";
export const LABEL_CONTRACT_TYPE = 'Type de contrat';
export const LABEL_ERROR_ZIP_CODE = 'Code postal non reconnu';
export const PLACEHOLDER_EMPLOYER_DATE = 'MM / AAAA';
export const ERROR_EMPLOYER_DATE =
  "La date n'est pas cohérente avec la date de naissance";
export const ERROR_SIREN_FORMAT = 'Format invalide';
export const ERROR_SIREN_INVALID = 'Numéro de SIREN incorrect';
export const ERROR_EMPLOYEE_NUMBER = "Nombre d'employés incorrect";
