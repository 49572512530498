import { RadioList, Title } from '@components';
import { StyledCard } from './styles';
import * as messages from './messages';
import useOfferForm from './useOfferForm';
import { IOption } from '@types';
import { IOfferFormProps } from './types';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { selectOffer, useAppSelector } from '@store';

const OfferPlaceForm: React.FC<IOfferFormProps> = ({
  onShouldNotSubmitForm,
  isSubmitted = false,
}) => {
  const { control, listPlaces } = useOfferForm();
  const { trigger } = useFormContext();

  const offer = useAppSelector(selectOffer);

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [isSubmitted]);

  return (
    <StyledCard>
      <Title level={2}>{messages.CONTAINER_PLACE_TITLE}</Title>
      <Controller
        control={control}
        name="inputPlace"
        rules={{
          required: true,
        }}
        defaultValue={offer?.inputPlace}
        render={({
          field: { onChange, value, name, ref },
          fieldState: { invalid },
        }) => (
          <RadioList
            name={name}
            label={messages.INPUT_PLACE}
            onChange={(newValue) => {
              onShouldNotSubmitForm(false);
              onChange(newValue);
            }}
            value={value}
            valueList={listPlaces?.map((el: IOption) => el.id) || []}
            options={listPlaces || []}
            isBig
            isValid={!invalid}
            inputRef={ref}
          />
        )}
      />
    </StyledCard>
  );
};

export default OfferPlaceForm;
