const SvgCalendar: React.FC<{ className?: string }> = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.556 1.5a.5.5 0 01.5.5l-.001 1.299 2.167.001A2.278 2.278 0 0120.5 5.578v12.644a2.278 2.278 0 01-2.278 2.278H5.778A2.278 2.278 0 013.5 18.222V5.578A2.278 2.278 0 015.778 3.3l2.166-.001V2a.5.5 0 011 0v1.299h6.111V2a.5.5 0 01.5-.5zM19.5 9.7h-15v8.522c0 .706.572 1.278 1.278 1.278h12.444c.706 0 1.278-.572 1.278-1.278V9.7zM7.944 4.299L5.778 4.3c-.706 0-1.278.572-1.278 1.278v3.121h15V5.578c0-.706-.572-1.278-1.278-1.278l-2.167-.001V5.6a.5.5 0 01-1 0V4.299H8.944V5.6a.5.5 0 11-1 0V4.299z"
    />
  </svg>
);

export default SvgCalendar;
