/*
 * Messages Upload
 *
 *
 */

const messages = {
  tabletLabel: 'Ajouter un fichier',
  desktopLabel: 'Déposez un fichier ou ',
  desktopLabelBis: 'sélectionnez-le',
  desktopLabelMultiple: 'Déposez vos fichiers ou ',
  desktopLabelMultipleBis: 'sélectionnez-les',
};

export default messages;
