import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0.8rem;
`;
