import { Badge, Icons } from '@components';
import {
  StyledCardWrapper,
  StyledCheckCircleIcon,
  StyledSearchIcon,
  StyledCrossCircleOutlinesIcon,
  StyledTitle,
  TextWrapper,
  Wrapper,
  WrapperBadge,
  WrapperCards,
  StyledTooltip,
} from './styles';
import * as messages from './messages';
import { updateConfiguration, useAppDispatch } from '@store';
import { ScoreLightType } from '@types';

const FormationModeScoreChoice: React.FC<{
  handleClick?: (choice?: ScoreLightType) => void;
}> = ({ handleClick = () => {} }) => {
  const dispatch = useAppDispatch();

  const saveScoreToStore = (choice: ScoreLightType) => {
    dispatch(updateConfiguration({ scoreLight: choice }));
    handleClick(choice);
  };

  return (
    <>
      <WrapperBadge>
        <Badge variant="formationModeModal">
          {messages.FORMATION_MODE_BADGE}
        </Badge>
      </WrapperBadge>

      <Wrapper>
        <StyledTitle level={1}>{messages.CONTAINER_TITLE}</StyledTitle>
        <TextWrapper>{messages.CONTAINER_SUBTITLE}</TextWrapper>
        <TextWrapper>
          {messages.CONTAINER_TEXT}
          <b>{messages.CONTAINER_TEXT_BOLD}</b>
        </TextWrapper>
        <WrapperCards>
          <StyledCardWrapper
            onClick={() => saveScoreToStore(ScoreLightType.VERT)}
          >
            <StyledCheckCircleIcon />
            <StyledTitle level={2}>{messages.GREEN_SUBTITLE}</StyledTitle>
            <TextWrapper>{messages.GREEN_TEXT}</TextWrapper>
          </StyledCardWrapper>
          <StyledCardWrapper
            onClick={() => saveScoreToStore(ScoreLightType.ORANGE)}
          >
            <StyledSearchIcon />
            <StyledTitle level={2}>{messages.ORANGE_SUBTITLE}</StyledTitle>
            <TextWrapper>{messages.ORANGE_TEXT}</TextWrapper>
          </StyledCardWrapper>
          <StyledCardWrapper
            onClick={() => saveScoreToStore(ScoreLightType.ROUGE)}
          >
            <StyledCrossCircleOutlinesIcon />
            <StyledTitle level={2}>{messages.RED_SUBTITLE}</StyledTitle>
            <TextWrapper>{messages.RED_TEXT}</TextWrapper>
          </StyledCardWrapper>
        </WrapperCards>
        <StyledTooltip
          skin="warning"
          isVisible
          icon={Icons.WarningIcon}
          hasArrowDown={false}
        >
          {messages.TOOLTIP_NOT_BACK}
        </StyledTooltip>
      </Wrapper>
    </>
  );
};

export default FormationModeScoreChoice;
