import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react';
import { WindowWithEnv } from '@types';

const MAX_ATTEMPTS = 5;
const GLOBAL_TIMEOUT = 45000;

const getQueriesMentionsLegales = () =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const startTime = Date.now();

      const returnUrl = (): string =>
        (window as unknown as WindowWithEnv)?._env_?.baseUrlRetailer ?? '';

      const result = await fetchBaseQuery({
        baseUrl: returnUrl(),
      })(args, api, extraOptions);

      const duration = Date.now() - startTime;

      if (result?.error) {
        return {
          ...result,
          error: result.error && { ...result.error, duration },
        };
      }
      return result;
    },
    {
      retryCondition(error: any, { attempt }) {
        return (
          attempt < MAX_ATTEMPTS &&
          !/^[45][0-9]{2}$/.test(error?.status + '' ?? '') &&
          error?.duration * (attempt + 1) < GLOBAL_TIMEOUT
        );
      },
    }
  );

const mentionLegalesApiSlice = createApi({
  reducerPath: 'mentionLegalesApi',
  baseQuery: getQueriesMentionsLegales(),
  endpoints: (builder) => ({
    getMentionsLegales: builder.query<any, string>({
      query: (vendorId): FetchArgs => {
        return {
          url: `/${vendorId}`,
          method: 'GET',
          credentials: 'omit',
        };
      },
    }),
  }),
});
export default mentionLegalesApiSlice;
export const { useLazyGetMentionsLegalesQuery } = mentionLegalesApiSlice;
export const mentionLegalesApiReducer = mentionLegalesApiSlice.reducer;
export const mentionLegalesApiMiddleware = mentionLegalesApiSlice.middleware;
