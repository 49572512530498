import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IDocument,
  IPerson,
  IPersonSynchronize,
  IState,
  IStateSynchronize,
  RoleCd,
} from './personTypes';
import { ICoEmprunteurRequest, IEmprunteurRequest } from '@types';

const getInitialState = () => {
  return {
    state: {},
    synchro: {},
  } as {
    state: IState;
    synchro: IStateSynchronize;
  };
};

// Initial State
export const initialState = getInitialState();

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    updatePerson(personState, action: PayloadAction<Partial<IState>>) {
      personState.state = { ...personState?.state, ...action.payload };
      if (personState?.state?.emprunteur) {
        personState.state.emprunteur = {
          ...personState?.state.emprunteur,
          ...action.payload,
          role: RoleCd.BORROWER,
        };
      }
      if (personState?.state?.coemprunteur) {
        personState.state.coemprunteur = {
          ...personState?.state.coemprunteur,
          ...action.payload,
          role: RoleCd.COBORROWER,
        };
      }
    },
    updatePersonEmprunteur(
      personState,
      action: PayloadAction<Partial<IPerson>>
    ) {
      personState.state.emprunteur = {
        ...personState?.state?.emprunteur,
        ...action.payload,
        role: RoleCd.BORROWER,
      };
    },
    updatePersonCoEmprunteur(
      personState,
      action: PayloadAction<Partial<IPerson>>
    ) {
      personState.state.coemprunteur = {
        ...personState?.state?.coemprunteur,
        ...action.payload,
        role: RoleCd.COBORROWER,
      };
    },
    clear(personState) {
      personState.state = getInitialState().state;
      personState.synchro = getInitialState().synchro;
    },
    clearCoEmprunteur(personState) {
      personState.state.coemprunteur = getInitialState().state.coemprunteur;
    },
    updateDocumentsEmprunteur(
      personState,
      action: PayloadAction<Partial<IDocument>>
    ) {
      personState.state.emprunteur = {
        ...personState?.state?.emprunteur,
        documents: [
          ...(personState?.state?.emprunteur?.documents ?? []),
          action.payload,
        ],
        role: RoleCd.BORROWER,
      };
    },
    updateDocumentsCoEmprunteur(
      personState,
      action: PayloadAction<Partial<IDocument>>
    ) {
      personState.state.coemprunteur = {
        ...personState?.state?.coemprunteur,
        documents: [
          ...(personState?.state?.coemprunteur?.documents ?? []),
          action.payload,
        ],
        role: RoleCd.COBORROWER,
      };
    },
    setOcrKo(personState, action: PayloadAction<string>) {
      personState.state.emprunteur = {
        ...personState?.state?.emprunteur,
        ocrKo: action.payload,
      };
    },
    synchronizePersonEmprunteurReset(
      personState,
      action: PayloadAction<{
        request?: IEmprunteurRequest;
        emprunteur?: Partial<IPersonSynchronize>;
      }>
    ) {
      personState.synchro.emprunteur = {
        attemptCount: personState.synchro?.emprunteur?.isSuccess
          ? 1
          : (personState.synchro.emprunteur?.attemptCount ?? 0) + 1,
        ...action?.payload?.emprunteur,
        isSaving: true,
        isAttempting: true,
        isSuccess: false,
        request: action?.payload?.request,
      };
    },
    synchronizePersonCoEmprunteurReset(
      personState,
      action: PayloadAction<{
        request?: ICoEmprunteurRequest;
        coEmprunteur?: Partial<IPersonSynchronize>;
      }>
    ) {
      personState.synchro.coemprunteur = {
        attemptCount: personState.synchro.coemprunteur?.isSuccess
          ? 1
          : (personState.synchro.coemprunteur?.attemptCount ?? 0) + 1,
        ...action?.payload?.coEmprunteur,
        isSaving: true,
        isAttempting: true,
        isSuccess: false,
        request: action?.payload?.request,
      };
    },
    synchronizePersonEmprunteurResponse(
      personState,
      action: PayloadAction<Partial<IPersonSynchronize>>
    ) {
      personState.synchro.emprunteur = {
        ...personState.synchro.emprunteur,
        ...action.payload,
        isSaving: false,
        role: RoleCd.BORROWER,
      };
    },
    synchronizePersonCoEmprunteurResponse(
      personState,
      action: PayloadAction<Partial<IPersonSynchronize>>
    ) {
      personState.synchro.coemprunteur = {
        ...personState.synchro.coemprunteur,
        ...action.payload,
        isSaving: false,
        role: RoleCd.COBORROWER,
      };
    },
  },
});

export const selectPerson = (personState: { person: { state: IState } }) =>
  personState.person.state;
export const selectSynchronizePerson = (personState: {
  person: { synchro: IStateSynchronize };
}) => personState.person.synchro;

export const {
  updatePerson,
  updatePersonEmprunteur,
  updatePersonCoEmprunteur,
  clear,
  clearCoEmprunteur,
  updateDocumentsEmprunteur,
  updateDocumentsCoEmprunteur,
  setOcrKo,
  synchronizePersonEmprunteurReset,
  synchronizePersonEmprunteurResponse,
  synchronizePersonCoEmprunteurReset,
  synchronizePersonCoEmprunteurResponse,
} = personSlice.actions;

export default personSlice.reducer;
