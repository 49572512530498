import { IOptionPricing } from 'types';
import dayjs from 'dayjs';
import { IProduit } from 'store/souscriptionApi/souscriptionApiTypes';

export const getPricingsList = (
  currentProduct: string,
  overdraft: number,
  desiredSituation: number,
  listProducts: IProduit[] | undefined
): IOptionPricing[] => {
  const currentDate = dayjs().format('YYYY-MM-DD');
  const product = listProducts?.find(
    (element) => element.id === currentProduct
  );
  const tarifications = product?.tarifications;
  const theRate = tarifications?.filter((currentValue, index, arr) => {
    let overdraftFlag = true;
    let durationFlag = true;

    const dateFlag =
      currentDate > currentValue.date_debut ||
      currentDate < currentValue.date_fin;

    // Overdraft filter
    if (overdraft !== 0) {
      // only enabled if overdraft is present
      overdraftFlag =
        Number(overdraft) >= Number(currentValue.montant_min) &&
        Number(overdraft) <= Number(currentValue.montant_max);
    }

    // Duration filter
    if (desiredSituation !== 0) {
      // only enabled if duration is present
      durationFlag =
        Number(desiredSituation) >= Number(currentValue.duree_min) &&
        Number(desiredSituation) <= Number(currentValue.duree_max);
    }

    return (
      dateFlag &&
      overdraftFlag &&
      durationFlag &&
      index === arr.indexOf(currentValue)
    );
  });

  return (theRate ?? []).map((item) => {
    return {
      id: item.id,
      label: `${item.libelle}`,
      deferment: item.reports,
      modality: item.modalites,
    };
  });
};
