import { useRedirection } from '@hooks';
import FooterWrapper from './styles';
import * as messages from './messages';

interface IFooterProps {
  returnUrl?: string;
}

const Footer: React.FC<IFooterProps> = () => {
  const { getUrlDonneesPersonnelles, getUrlMentionsLegales, getUrlCookies } =
    useRedirection();

  return (
    <FooterWrapper>
      <p className="links">
        <a target="_blank" href={getUrlDonneesPersonnelles()} rel="noreferrer">
          {messages.LINK_PERSONAL_DATA_LABEL}
        </a>
        <a target="_blank" href={getUrlMentionsLegales()} rel="noreferrer">
          {messages.LINK_LEGAL_MENTIONS_LABEL}
        </a>
        <a target="_blank" href={getUrlCookies()} rel="noreferrer">
          {messages.LINK_COOKIES_LABEL}
        </a>
      </p>
    </FooterWrapper>
  );
};

export default Footer;
