import styled from 'styled-components';

export const TitleWrapper = styled.div`
  height: 3.2rem;
  border-radius: 0.8rem 0.8rem 0 0;
  background-color: #2275d7;
  padding: 0.8rem 0 0.8rem 1.6rem;
  line-height: 1.6rem;
`;

export const CardTitle = styled.p`
font-size: ${({ theme }) => theme.variables.fonts.sizes.smallText};
line-height:1.6rem;
color: ${({ theme }) => theme.variables.colors.chartColors.white}};
margin:0;
`;

export const TextWrapper = styled.div`
  margin: 1.5rem 1rem;
  padding: 0 0.8rem;
`;

export const IntroText = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;
