import {
  selectConfiguration,
  selectError,
  setError,
  useAppDispatch,
  useAppSelector,
  useLazyGetIdDebranchementQRQuery,
} from '@store';
import { useEffect, useState } from 'react';
import { EModalState } from './ModalQRCode/ModalQRCode';
import {
  useListenerSwitchMobile,
  useParcoursType,
  usePersistingState,
} from '@hooks';
import {
  EModalErrorState,
  EventSwitchDevice,
  FINAL_EVENTS_SWITCH_DEVICE,
} from '@types';
import { getEnv } from '@utils';
import useSynchronizeDocuments from './useSynchronizeDocuments';

const useSwitchDevice = () => {
  const { souscriptionId, initAppUrlParams } =
    useAppSelector(selectConfiguration);
  const { code: errorCode } = useAppSelector(selectError);
  const [urlQRCode, setUrlQRCode] = useState('');
  const [isModalQRCodeVisible, setIsModalQRCodeVisible] = useState(false);
  const [isLoadingQRCodeButton, setIsLoadingQRCodeButton] = useState(false);
  const [ModalErrorState, setModalErrorState, getModalErrorState] =
    usePersistingState<EModalErrorState | undefined>(
      'modalErrorState',
      undefined
    );
  const dispatch = useAppDispatch();
  const [triggerGetIdDebranchementQRCode, resultGetIdDebranchementQRCode] =
    useLazyGetIdDebranchementQRQuery();

  const { sendDocumentsSynchronization } = useSynchronizeDocuments();

  const [isSwitchMobileConnected, setIsSwitchMobileConnected] = useState(false);
  const [modalState, setModalState] = usePersistingState<EModalState>(
    'modalSwitchDeviceState',
    EModalState.INITIAL
  );
  const { isParcoursNominal, isParcoursSwitchDevice } = useParcoursType();

  const [
    isFinishUploadMobile,
    setIsFinishUploadMobile,
    getIsFinishUploadMobile,
  ] = usePersistingState<boolean>('isFinishUploadMobile', false);

  const {
    triggerListenerSwitchMobile,
    responseListenerSwitchMobile,
    sendAddEventSwitchMobile,
    interrupteListener,
  } = useListenerSwitchMobile();

  const [
    isParcoursSwitchDeviceNotInitialized,
    setIsParcoursSwitchDeviceNotInitialized,
    getParcoursSwitchDeviceNotInitialized,
  ] = usePersistingState<boolean | null>(
    'listener-isParcoursSwitchDeviceNotInitialized',
    true
  );

  const [
    isUploadSwitchDeviceKO,
    setIsUploadSwitchDeviceKO,
    getIsUploadSwitchDeviceKO,
  ] = usePersistingState<boolean>('listener-isTelechargementInterrompu', false);

  const [
    isTechnicalErrorSent,
    setIsTechnicalErrorSent,
    getIsTechnicalErrorSent,
  ] = usePersistingState<boolean>('listener-isTechnicalErrorSent', false);

  useEffect(() => {
    if (
      !isParcoursSwitchDevice() ||
      getIsUploadSwitchDeviceKO() ||
      getIsFinishUploadMobile()
    ) {
      return;
    }
    triggerListenerSwitchMobile();
  }, [, isParcoursSwitchDevice()]);

  useEffect(() => {
    if (
      !isParcoursSwitchDevice() ||
      getParcoursSwitchDeviceNotInitialized() === false
    ) {
      return;
    }
    sendAddEventSwitchMobile(EventSwitchDevice.CONNEXION);
  }, [, isParcoursSwitchDevice(), isParcoursSwitchDeviceNotInitialized]);

  useEffect(() => {
    if (
      !isParcoursSwitchDevice() ||
      (!responseListenerSwitchMobile &&
        isParcoursSwitchDeviceNotInitialized !== false)
    ) {
      return;
    }
    setIsParcoursSwitchDeviceNotInitialized(false);
  }, [responseListenerSwitchMobile]);

  useEffect(() => {
    responseListenerSwitchMobile?.forEach((evenement) => {
      if (evenement?.evenementRealise === EventSwitchDevice.CONNEXION) {
        setIsSwitchMobileConnected(true);
        setModalState(EModalState.SWITCH_MOBILE_CONNECTED);
      }
      if (!FINAL_EVENTS_SWITCH_DEVICE.includes(evenement?.evenementRealise)) {
        return;
      }
      if (isParcoursSwitchDevice()) {
        setIsUploadSwitchDeviceKO(true);
        endParcoursReInit();
        return;
      }
      switch (evenement?.evenementRealise) {
        case EventSwitchDevice.ANALYSE_OCR_KO:
          setModalState(EModalState.UNAVAIABLE_SERVICE_OCR);

          break;
        case EventSwitchDevice.INACTIVITE:
          setIsModalQRCodeVisible(false);
          dispatch(
            setError({
              code: 'timeOut',
            })
          );

          break;
        case EventSwitchDevice.SERVICE_OCR_KO:
          setIsModalQRCodeVisible(false);
          setModalErrorState(EModalErrorState.UNAVAILABLE_SERVICE);

          break;
        case EventSwitchDevice.TECHNICAL_ERROR:
          setIsModalQRCodeVisible(false);
          dispatch(
            setError({
              code: 'ERREUR_INCONNUE',
            })
          );

          break;
        default:
          setModalState(EModalState.TELECHARGEMENT_REUSSI);
          sendDocumentsSynchronization();
          break;
      }
    });
  }, [responseListenerSwitchMobile]);

  useEffect(() => {
    if (!urlQRCode || !isParcoursNominal() || getEnv() === 'prod') {
      return;
    }
    console.log('URL Debranchement QRcode => ', urlQRCode);
  }, [urlQRCode]);

  useEffect(() => {
    if (
      !resultGetIdDebranchementQRCode?.data ||
      resultGetIdDebranchementQRCode?.status !== 'fulfilled' ||
      !initAppUrlParams
    ) {
      return;
    }
    sendAddEventSwitchMobile(EventSwitchDevice.CREATION);
    triggerListenerSwitchMobile();
    setUrlQRCode(
      window.location.origin +
        '/' +
        (window.location.hash ?? '').split('?')[0] +
        '?' +
        initAppUrlParams +
        '&isDebranchementQRCode=true' +
        '&switchDeviceId=' +
        resultGetIdDebranchementQRCode?.data
    );
    setIsLoadingQRCodeButton(false);
    setIsModalQRCodeVisible(true);
  }, [resultGetIdDebranchementQRCode]);

  useEffect(() => {
    if (!isParcoursSwitchDevice() || getIsTechnicalErrorSent() || !errorCode) {
      return;
    }
    sendAddEventSwitchMobile(EventSwitchDevice.TECHNICAL_ERROR);
    setIsTechnicalErrorSent(true);
  }, [errorCode]);

  const onClickFinishButton = () => {
    sendAddEventSwitchMobile(EventSwitchDevice.FERMETURE);
    setIsFinishUploadMobile(true);
  };

  const onClickQRCodeButton = () => {
    if (!souscriptionId) {
      return;
    }
    triggerGetIdDebranchementQRCode(souscriptionId);
    setIsLoadingQRCodeButton(true);
  };

  const onClickArretParcoursSwitchDevice = () => {
    sendDocumentsSynchronization();
    sendAddEventSwitchMobile(EventSwitchDevice.FERMETURE);
    endParcoursReInit();
  };

  const onClickCancel = () => {
    setModalState(
      isSwitchMobileConnected
        ? EModalState.SWITCH_MOBILE_CONNECTED
        : EModalState.INITIAL
    );
  };

  const onClickTelechargementReussi = () => {
    endParcoursReInit();
  };

  const endParcoursReInit = () => {
    setIsModalQRCodeVisible(false);
    setModalState(EModalState.INITIAL);
    setIsSwitchMobileConnected(false);
  };

  return {
    isFinishUploadMobile,
    urlQRCode,
    modalState,
    setModalState,
    isModalQRCodeVisible,
    isLoadingQRCodeButton,
    onClickFinishButton,
    onClickQRCodeButton,
    onClickArretParcoursSwitchDevice,
    onClickCancel,
    onClickTelechargementReussi,
    isUploadSwitchDeviceKO,
    getModalErrorState,
  };
};

export default useSwitchDevice;
