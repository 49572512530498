export const MONTANT_CREDIT = 'Montant crédit';
export const TAUX_ANNUEL = 'Taux Annuel Effectif Global';
export const MONTANT_TOTAL = 'Montant total du crédit';
export const PREMIERE_ECHEANCE = 'Première échéance';
export const DUREE_TOTALE = 'Durée totale du crédit';
export const TAUX_DEBITEUR = 'Taux débiteur fixe';
export const FRAIS_DOSSIER = 'Frais de dossier';
export const NOMBRE_ECHEANCES = "Nombre d'échéances";
export const MONTANT_ECHEANCE_HORS_ASSURANCE =
  'Montant par échéance (hors assurance facultative)';
export const MONTANT_TOTAL_HORS_ASSURANCE =
  'Montant total dû (hors assurance facultative)';
export const COUT_TOTAL_ASSURANCE_FACULTATIVE =
  "Coût total de l'assurance facultative";
export const COUT_MENSUEL_ASSURANCE_FACULTATIVE =
  "Coût mensuel de l'assurance facultative";
export const RETENUE_VENDEUR = 'Retenue vendeur';
export const TAEA_GLOBAL = 'TAEA global';
export const TAEA_DESC =
  "Le TAEA correspond au TAEA mono-emrpunteur ou TAEA global s'il y a 2 emprunteurs. Il est calculé sur la base de la formule la plus couvrante pour chacun d'entre eux.";
export const EMPRUNTEUR = "Pour l'emprunteur";
export const EMPRUNTEUR_ET_COEMPRUNTEUR =
  "Pour l'emprunteur et le co-emprunteur";
export const DIMC = 'DIMC';
export const DIM = 'DIM';
export const D = 'D';
export const DIMC2 = 'DIMC + DIMC';
export const DIM2 = 'DIM + DIM';
export const D2 = 'D + D';
